import { editorLogger, E_EVENT_KEY, E_EVENT_HANDLE, ThinkEditorEvent, ThinkEditorEventData } from "./ThinkEditor.Defined.js";
///ThinkEditorsInstance对应Tabs
export class ThinkEditorInstance extends EventTarget {
    // private _editorsChange: boolean | undefined;
    constructor(instanceId) {
        super();
        this.editors = new Array();
        this.instanceId = instanceId;
    }
    AddEditor(thinkEditor) {
        if (this.GetEditor(thinkEditor.docName) !== undefined) {
            console.error("无法添加相同docName的文档，请在外部UnInit该ThinkEditor！");
            return false;
        }
        if (this.editors.length == 0) {
            thinkEditor.selected = true;
        }
        this.editors.push(thinkEditor);
        this.addInnerEventListenerAgent(thinkEditor);
        this.CreateInstanceChangeEvent();
        return true;
    }
    SelectEditorByEditorId(editorId) {
        let editor = this.GetEditorByEditorId(editorId);
        if (editor === undefined) {
            return false;
        }
        return this.SelectEditor(editor.docName);
    }
    SelectEditor(docName) {
        if (this.GetEditor(docName) === undefined) {
            console.warn("SelectDoc失败：文档：", docName, " 在本实例中不存在！");
            return false;
        }
        let currentThinkEditor = this.GetSelectedEditor();
        if (currentThinkEditor != undefined) {
            currentThinkEditor.UnLoad();
        }
        let selectEditor;
        for (var i = 0; i < this.editors.length; i++) {
            if (this.editors[i].docName == docName) {
                this.editors[i].selected = true;
                selectEditor = this.editors[i];
            }
            else {
                this.editors[i].selected = false;
            }
        }
        //------------------------------
        if (selectEditor === undefined) {
            console.warn("SelectDoc失败：selectDocInfo is undefined");
            return false;
        }
        if (selectEditor === undefined) {
            console.warn("SelectDoc失败：thinkEditor is undefined");
            return false;
        }
        selectEditor.SelectDoc(docName);
        selectEditor.Load(selectEditor.container);
        selectEditor.selectedTime = new Date();
        this.CreateEditorFocusEvent(selectEditor);
        return true;
    }
    CreateInstanceChangeEvent() {
        var eventData = new ThinkEditorEventData(E_EVENT_HANDLE.event, E_EVENT_KEY.instanceChange, {});
        this.dispatchEditorsInstanceEvent(new ThinkEditorEvent(E_EVENT_KEY.instanceChange, eventData));
    }
    CreateEditorFocusEvent(editor) {
        var eventData = new ThinkEditorEventData(E_EVENT_HANDLE.event, E_EVENT_KEY.focusChange, {});
        eventData.editor = editor;
        this.dispatchEditorsInstanceEvent(new ThinkEditorEvent(E_EVENT_KEY.editorFocus, eventData));
    }
    dispatchEditorsInstanceEvent(e) {
        e.data.instance = this;
        return this.dispatchEvent(new ThinkEditorEvent(e.type, e.data));
    }
    GetLastEditor() {
        let editor;
        for (var i = 0; i < this.editors.length; i++) {
            if (editor === undefined) {
                editor = this.editors[i];
            }
            if (this.editors[i].selectedTime > editor.selectedTime) {
                editor = this.editors[i];
            }
        }
        return editor;
    }
    CloseEditor(docName) {
        let closeEditor = this.GetEditor(docName);
        if (closeEditor === undefined) {
            editorLogger.LogWarn("需要关闭的文档不存在：" + docName);
            return false;
        }
        let ret = this.CloseDocEditorHandle(closeEditor);
        this.CreateInstanceChangeEvent();
        return ret;
    }
    CloseAllEditors() {
        while (this.editors.length > 0) {
            this.CloseDocEditorHandle(this.editors[0]);
        }
        this.CreateInstanceChangeEvent();
    }
    CloseDocEditorHandle(closeThinkEditor) {
        this.removeInnerEventListenerAgent(closeThinkEditor);
        //this.removeEditorEventListener(closeDocInfo.thinkEditor);
        //closeDocInfo.Close();
        //let closeThinkEditor = closeDocInfo.thinkEditor;
        let docName = closeThinkEditor.docName;
        editorLogger.LogInfo("关闭文档：" + docName);
        if (closeThinkEditor !== undefined) {
            //不要关闭 1、使关闭速度更快 2、避免清理内存出现内存错误 closeThinkEditor.CloseDoc(docName);
            closeThinkEditor.UnInit();
        }
        this.editors.splice(this.editors.findIndex((x) => x.docName == docName), 1);
        //------------------------
        let lastEditor = this.GetLastEditor();
        if (lastEditor === undefined) {
            return false;
        }
        this.SelectEditor(lastEditor.docName);
        return true;
    }
    GetEditorByShowName(showName) {
        return this.editors.find((x) => x.showName == showName);
    }
    GetEditor(docName) {
        return this.editors.find((x) => x.docName == docName);
    }
    GetEditorByEditorId(editorId) {
        return this.editors.find((x) => (x === null || x === void 0 ? void 0 : x.editorId) == editorId);
    }
    GetSelectedEditor() {
        return this.editors.find((x) => x.selected);
    }
    GetSelectedDocName() {
        let selectedEditor = this.GetSelectedEditor();
        return selectedEditor ? selectedEditor.docName : "";
    }
    GetEditorCount() {
        return this.editors.length;
    }
    addInnerEventListenerAgent(editor) {
        //editor=》OnInnerEvent=》APP listener 内部产生的事件，被路由到this.OnInnerEvent
        for (let keyEvent in E_EVENT_KEY) {
            editor.addEventListener(keyEvent, (e) => this.OnInnerEvent(e));
        }
    }
    removeInnerEventListenerAgent(editor) {
        //editor=》OnInnerEvent=》APP listener 内部产生的事件，被路由到this.OnInnerEvent
        for (let keyEvent in E_EVENT_KEY) {
            editor.removeEventListener(keyEvent, (e) => this.OnInnerEvent(e));
        }
    }
    OnInnerEvent(evt) {
        let e = evt;
        editorLogger.LogEvent(new Date(), " OnInnerEvent==>", e);
        //e.data.instanceId = this.instanceId;
        //e.data.instance = this;
        /* E_EVENT_KEY.editorFocus事件路由及消费
        ThinkEditor.Instance.SelectEditor()=>ThinkEditor.SelectDoc()
          =>ThinkEditor产生event.editorFocus事
          =>router到ThinkEditor.Instance.OnInnerEvent()
          =>router到ThinkEditorInstance.Manager.OnInnerEvent()====>selected选中其管理的ThinkEditor.Instance
    
        */
        let ret = this.dispatchEditorsInstanceEvent(e);
        if (ret == false) {
            evt.preventDefault();
        }
    }
}
