export const tableDataMaterial = `<Table padding='0.026'> 
<Row height='0.000' xCfg='1'>
  <Cell eCfg='80' gridHeight='0.800' gridBorderWidth='0.026' gridBorderStyle='1' width='1.852' borderWidth='0.026' borderStyle='1'>
    <Paragraph xCfg='10' leftIndent='0.020' rightIndent='0.020'  lineSpaceValue='1.500'>
      <Font size='0.317' fontName='宋体'  /><Revise del='L76dT6001' />2022-09-07<Revise del='' /></Paragraph>
  </Cell>
  <Cell eCfg='80' gridHeight='0.800' gridBorderWidth='0.026' gridBorderStyle='1' width='0.863' borderWidth='0.026' borderStyle='1'>
    <Paragraph xCfg='10' leftIndent='0.020' rightIndent='0.020'  lineSpaceValue='1.500'>
      <Font size='0.317' fontName='宋体'  />19:20</Paragraph>
  </Cell>
  <Cell eCfg='80' gridHeight='0.800' gridBorderWidth='0.026' gridBorderStyle='1' width='0.715' borderWidth='0.026' borderStyle='1'>
    <Paragraph xCfg='12' leftIndent='0.020' rightIndent='0.020'  lineSpaceValue='1.500'>
      <Font size='0.317' fontName='宋体'  />36.3</Paragraph>
  </Cell>
  <Cell eCfg='80' gridHeight='0.800' gridBorderWidth='0.026' gridBorderStyle='1' width='0.677' borderWidth='0.026' borderStyle='1'>
    <Paragraph xCfg='12' leftIndent='0.020' rightIndent='0.020'  lineSpaceValue='1.500'>
      <Font size='0.317' fontName='宋体'  />166</Paragraph>
  </Cell>
  <Cell eCfg='80' gridHeight='0.800' gridBorderWidth='0.026' gridBorderStyle='1' width='0.423' borderWidth='0.026' borderStyle='1'>
    <Paragraph xCfg='12' leftIndent='0.020' rightIndent='0.020'  lineSpaceValue='1.500'>
      <Font size='0.317' fontName='宋体'  />24</Paragraph>
  </Cell>
  <Cell eCfg='80' gridHeight='0.800' gridBorderWidth='0.026' gridBorderStyle='1' width='1.184' borderWidth='0.026' borderStyle='1'>
    <Paragraph xCfg='12' leftIndent='0.020' rightIndent='0.020'  lineSpaceValue='1.500'>
      <Font size='0.317' fontName='宋体'  />80/50</Paragraph>
  </Cell>
  <Cell eCfg='80' gridHeight='0.800' gridBorderWidth='0.026' gridBorderStyle='1' width='0.465' borderWidth='0.026' borderStyle='1'>
    <Paragraph xCfg='12' leftIndent='0.020' rightIndent='0.020'  lineSpaceValue='1.500'>
      <Font size='0.317' fontName='宋体'  />1</Paragraph>
  </Cell>
  <Cell eCfg='80' gridHeight='0.800' gridBorderWidth='0.026' gridBorderStyle='1' width='0.677' borderWidth='0.026' borderStyle='1'>
    <Paragraph xCfg='12' leftIndent='0.020' rightIndent='0.020'  lineSpaceValue='1.500' />
  </Cell>
  <Cell eCfg='80' gridHeight='0.800' gridBorderWidth='0.026' gridBorderStyle='1' width='0.677' borderWidth='0.026' borderStyle='1'>
    <Paragraph xCfg='12' leftIndent='0.020' rightIndent='0.020'  lineSpaceValue='1.500' />
  </Cell>
  <Cell eCfg='80' gridHeight='0.800' gridBorderWidth='0.026' gridBorderStyle='1' width='0.634' borderWidth='0.026' borderStyle='1'>
    <Paragraph xCfg='12' leftIndent='0.020' rightIndent='0.020'  lineSpaceValue='1.500' />
  </Cell>
  <Cell eCfg='80' gridHeight='0.800' gridBorderWidth='0.026' gridBorderStyle='1' width='0.634' borderWidth='0.026' borderStyle='1'>
    <Paragraph xCfg='12' leftIndent='0.020' rightIndent='0.020'  lineSpaceValue='1.500' />
  </Cell>
  <Cell eCfg='80' gridHeight='0.800' gridBorderWidth='0.026' gridBorderStyle='1' width='0.508' borderWidth='0.026' borderStyle='1'>
    <Paragraph xCfg='12' leftIndent='0.020' rightIndent='0.020'  lineSpaceValue='1.500'>
      <Font size='0.317' fontName='宋体'  />√</Paragraph>
  </Cell>
  <Cell eCfg='80' gridHeight='0.800' gridBorderWidth='0.026' gridBorderStyle='1' width='0.804' borderWidth='0.026' borderStyle='1'>
    <Paragraph xCfg='12' leftIndent='0.020' rightIndent='0.020'  lineSpaceValue='1.500'>
      <Font size='0.317' fontName='宋体'  />96</Paragraph>
  </Cell>
  <Cell eCfg='80' gridHeight='0.800' gridBorderWidth='0.026' gridBorderStyle='1' width='0.592' borderWidth='0.026' borderStyle='1'>
    <Paragraph xCfg='12' leftIndent='0.020' rightIndent='0.020'  lineSpaceValue='1.500'>
      <Font size='0.317' fontName='宋体'  />2</Paragraph>
  </Cell>
  <Cell eCfg='80' gridHeight='0.800' gridBorderWidth='0.026' gridBorderStyle='1' width='0.508' borderWidth='0.026' borderStyle='1'>
    <Paragraph xCfg='12' leftIndent='0.020' rightIndent='0.020'  lineSpaceValue='1.500'>
      <Font size='0.317' fontName='宋体'  />1</Paragraph>
  </Cell>
  <Cell eCfg='80' gridHeight='0.800' gridBorderWidth='0.026' gridBorderStyle='1' width='0.761' borderWidth='0.026' borderStyle='1'>
    <Paragraph xCfg='12' leftIndent='0.020' rightIndent='0.020'  lineSpaceValue='1.500' />
  </Cell>
  <Cell eCfg='80' gridHeight='0.800' gridBorderWidth='0.026' gridBorderStyle='1' width='0.973' borderWidth='0.026' borderStyle='1'>
    <Paragraph xCfg='12' leftIndent='0.020' rightIndent='0.020'  lineSpaceValue='1.500' />
  </Cell>
  <Cell eCfg='80' gridHeight='0.800' gridBorderWidth='0.026' gridBorderStyle='1' width='0.973' borderWidth='0.026' borderStyle='1'>
    <Paragraph xCfg='12' leftIndent='0.020' rightIndent='0.020'  lineSpaceValue='1.500'>
      <Font size='0.317' fontName='宋体'  />通畅</Paragraph>
  </Cell>
  <Cell eCfg='80' gridHeight='0.800' gridBorderWidth='0.026' gridBorderStyle='1' width='1.692' borderWidth='0.026' borderStyle='1'>
    <Paragraph xCfg='12' leftIndent='0.020' rightIndent='0.020'  lineSpaceValue='1.500' />
  </Cell>
  <Cell eCfg='80' gridHeight='0.800' gridBorderWidth='0.026' gridBorderStyle='1' width='0.761' borderWidth='0.026' borderStyle='1'>
    <Paragraph xCfg='12' leftIndent='0.020' rightIndent='0.020'  lineSpaceValue='1.500' />
  </Cell>
  <Cell eCfg='80' gridHeight='0.800' gridBorderWidth='0.026' gridBorderStyle='1' width='1.531' borderWidth='0.026' borderStyle='1'>
    <Paragraph xCfg='12' leftIndent='0.020' rightIndent='0.020'  lineSpaceValue='1.500' />
  </Cell>
  <Cell eCfg='80' gridHeight='0.800' gridBorderWidth='0.026' gridBorderStyle='1' width='0.761' borderWidth='0.026' borderStyle='1'>
    <Paragraph xCfg='12' leftIndent='0.020' rightIndent='0.020'  lineSpaceValue='1.500' />
  </Cell>
  <Cell eCfg='80' gridHeight='0.800' gridBorderWidth='0.026' gridBorderStyle='1' width='1.073' borderWidth='0.026' borderStyle='1'>
    <Paragraph xCfg='12' leftIndent='0.020' rightIndent='0.020'  lineSpaceValue='1.500' />
  </Cell>
  <Cell eCfg='80' gridHeight='0.800' gridBorderWidth='0.026' gridBorderStyle='1' width='5.660' borderWidth='0.026' borderStyle='1'>
    <Paragraph xCfg='10' leftIndent='0.020' rightIndent='0.020'  lineSpaceValue='1.500'>
      <Font size='0.317' fontName='宋体'  />
      <Space count='5' />        
      <Revise del='L76dT6001' />患者因“咳嗽、咳痰20年，再发伴头昏、乏力2天<Revise del='' />”于19：19分由家属背入病房，立即通知医生。患者呼之能应，精神差，不能对答，家属诉咳嗽，咳较多白色泡沫痰，伴劳累后胸闷、气促，恶心，患者左侧臀部红肿，面积约5×5cm，触摸感硬，压之患者无反应，右侧红肿，面积约5×5ml，其中破溃面积3×3cm,有散在水泡，压之患者无反应。入院后遵医嘱予内科I级病危护理，予抗炎，化痰
      <Space />，扩容升压，营养心肌细胞，减慢心率等对症支持治疗,予防褥护理，褥疮局部予换药,睡气垫床，入院宣教已做。
      <Space />
    </Paragraph>
  </Cell>
  <Cell eCfg='80' gridHeight='0.800' gridBorderWidth='0.026' gridBorderStyle='1' width='1.418' borderWidth='0.026' borderStyle='1'>
    <Paragraph xCfg='10' leftIndent='0.020' rightIndent='0.020'  lineSpaceValue='1.500'>
      <Font size='0.317' fontName='宋体'  />张霞</Paragraph>
  </Cell>
</Row>
<Row height='0.000' xCfg='1'>
  <Cell eCfg='80' gridHeight='0.800' gridBorderWidth='0.026' gridBorderStyle='1' width='1.852' borderWidth='0.026' borderStyle='1'>
    <Paragraph xCfg='10' leftIndent='0.020' rightIndent='0.020'  lineSpaceValue='1.500'>
      <Font size='0.317' fontName='宋体'  />2022-09-07</Paragraph>
  </Cell>
  <Cell eCfg='80' gridHeight='0.800' gridBorderWidth='0.026' gridBorderStyle='1' width='0.863' borderWidth='0.026' borderStyle='1'>
    <Paragraph xCfg='10' leftIndent='0.020' rightIndent='0.020'  lineSpaceValue='1.500'>
      <Font size='0.317' fontName='宋体'  />20:00</Paragraph>
  </Cell>
  <Cell eCfg='80' gridHeight='0.800' gridBorderWidth='0.026' gridBorderStyle='1' width='0.715' borderWidth='0.026' borderStyle='1'>
    <Paragraph xCfg='12' leftIndent='0.020' rightIndent='0.020'  lineSpaceValue='1.500' />
  </Cell>
  <Cell eCfg='80' gridHeight='0.800' gridBorderWidth='0.026' gridBorderStyle='1' width='0.677' borderWidth='0.026' borderStyle='1'>
    <Paragraph xCfg='12' leftIndent='0.020' rightIndent='0.020'  lineSpaceValue='1.500'>
      <Font size='0.317' fontName='宋体'  />165</Paragraph>
  </Cell>
  <Cell eCfg='80' gridHeight='0.800' gridBorderWidth='0.026' gridBorderStyle='1' width='0.423' borderWidth='0.026' borderStyle='1'>
    <Paragraph xCfg='12' leftIndent='0.020' rightIndent='0.020'  lineSpaceValue='1.500'>
      <Font size='0.317' fontName='宋体'  />21</Paragraph>
  </Cell>
  <Cell eCfg='80' gridHeight='0.800' gridBorderWidth='0.026' gridBorderStyle='1' width='1.184' borderWidth='0.026' borderStyle='1'>
    <Paragraph xCfg='12' leftIndent='0.020' rightIndent='0.020'  lineSpaceValue='1.500'>
      <Font size='0.317' fontName='宋体'  />82/56</Paragraph>
  </Cell>
  <Cell eCfg='80' gridHeight='0.800' gridBorderWidth='0.026' gridBorderStyle='1' width='0.465' borderWidth='0.026' borderStyle='1'>
    <Paragraph xCfg='12' leftIndent='0.020' rightIndent='0.020'  lineSpaceValue='1.500' />
  </Cell>
  <Cell eCfg='80' gridHeight='0.800' gridBorderWidth='0.026' gridBorderStyle='1' width='0.677' borderWidth='0.026' borderStyle='1'>
    <Paragraph xCfg='12' leftIndent='0.020' rightIndent='0.020'  lineSpaceValue='1.500' />
  </Cell>
  <Cell eCfg='80' gridHeight='0.800' gridBorderWidth='0.026' gridBorderStyle='1' width='0.677' borderWidth='0.026' borderStyle='1'>
    <Paragraph xCfg='12' leftIndent='0.020' rightIndent='0.020'  lineSpaceValue='1.500' />
  </Cell>
  <Cell eCfg='80' gridHeight='0.800' gridBorderWidth='0.026' gridBorderStyle='1' width='0.634' borderWidth='0.026' borderStyle='1'>
    <Paragraph xCfg='12' leftIndent='0.020' rightIndent='0.020'  lineSpaceValue='1.500' />
  </Cell>
  <Cell eCfg='80' gridHeight='0.800' gridBorderWidth='0.026' gridBorderStyle='1' width='0.634' borderWidth='0.026' borderStyle='1'>
    <Paragraph xCfg='12' leftIndent='0.020' rightIndent='0.020'  lineSpaceValue='1.500' />
  </Cell>
  <Cell eCfg='80' gridHeight='0.800' gridBorderWidth='0.026' gridBorderStyle='1' width='0.508' borderWidth='0.026' borderStyle='1'>
    <Paragraph xCfg='12' leftIndent='0.020' rightIndent='0.020'  lineSpaceValue='1.500'>
      <Font size='0.317' fontName='宋体'  />√</Paragraph>
  </Cell>
  <Cell eCfg='80' gridHeight='0.800' gridBorderWidth='0.026' gridBorderStyle='1' width='0.804' borderWidth='0.026' borderStyle='1'>
    <Paragraph xCfg='12' leftIndent='0.020' rightIndent='0.020'  lineSpaceValue='1.500'>
      <Font size='0.317' fontName='宋体'  />96</Paragraph>
  </Cell>
  <Cell eCfg='80' gridHeight='0.800' gridBorderWidth='0.026' gridBorderStyle='1' width='0.592' borderWidth='0.026' borderStyle='1'>
    <Paragraph xCfg='12' leftIndent='0.020' rightIndent='0.020'  lineSpaceValue='1.500'>
      <Font size='0.317' fontName='宋体'  />2</Paragraph>
  </Cell>
  <Cell eCfg='80' gridHeight='0.800' gridBorderWidth='0.026' gridBorderStyle='1' width='0.508' borderWidth='0.026' borderStyle='1'>
    <Paragraph xCfg='12' leftIndent='0.020' rightIndent='0.020'  lineSpaceValue='1.500'>
      <Font size='0.317' fontName='宋体'  />1</Paragraph>
  </Cell>
  <Cell eCfg='80' gridHeight='0.800' gridBorderWidth='0.026' gridBorderStyle='1' width='0.761' borderWidth='0.026' borderStyle='1'>
    <Paragraph xCfg='12' leftIndent='0.020' rightIndent='0.020'  lineSpaceValue='1.500' />
  </Cell>
  <Cell eCfg='80' gridHeight='0.800' gridBorderWidth='0.026' gridBorderStyle='1' width='0.973' borderWidth='0.026' borderStyle='1'>
    <Paragraph xCfg='12' leftIndent='0.020' rightIndent='0.020'  lineSpaceValue='1.500' />
  </Cell>
  <Cell eCfg='80' gridHeight='0.800' gridBorderWidth='0.026' gridBorderStyle='1' width='0.973' borderWidth='0.026' borderStyle='1'>
    <Paragraph xCfg='12' leftIndent='0.020' rightIndent='0.020'  lineSpaceValue='1.500'>
      <Font size='0.317' fontName='宋体'  />通畅</Paragraph>
  </Cell>
  <Cell eCfg='80' gridHeight='0.800' gridBorderWidth='0.026' gridBorderStyle='1' width='1.692' borderWidth='0.026' borderStyle='1'>
    <Paragraph xCfg='12' leftIndent='0.020' rightIndent='0.020'  lineSpaceValue='1.500' />
  </Cell>
  <Cell eCfg='80' gridHeight='0.800' gridBorderWidth='0.026' gridBorderStyle='1' width='0.761' borderWidth='0.026' borderStyle='1'>
    <Paragraph xCfg='12' leftIndent='0.020' rightIndent='0.020'  lineSpaceValue='1.500' />
  </Cell>
  <Cell eCfg='80' gridHeight='0.800' gridBorderWidth='0.026' gridBorderStyle='1' width='1.531' borderWidth='0.026' borderStyle='1'>
    <Paragraph xCfg='12' leftIndent='0.020' rightIndent='0.020'  lineSpaceValue='1.500' />
  </Cell>
  <Cell eCfg='80' gridHeight='0.800' gridBorderWidth='0.026' gridBorderStyle='1' width='0.761' borderWidth='0.026' borderStyle='1'>
    <Paragraph xCfg='12' leftIndent='0.020' rightIndent='0.020'  lineSpaceValue='1.500' />
  </Cell>
  <Cell eCfg='80' gridHeight='0.800' gridBorderWidth='0.026' gridBorderStyle='1' width='1.073' borderWidth='0.026' borderStyle='1'>
    <Paragraph xCfg='12' leftIndent='0.020' rightIndent='0.020'  lineSpaceValue='1.500' />
  </Cell>
  <Cell eCfg='80' gridHeight='0.800' gridBorderWidth='0.026' gridBorderStyle='1' width='5.660' borderWidth='0.026' borderStyle='1'>
    <Paragraph xCfg='10' leftIndent='0.020' rightIndent='0.020'  lineSpaceValue='1.500'>
      <Font size='0.317' fontName='宋体'  />
      <Space count='5' />
    </Paragraph>
  </Cell>
  <Cell eCfg='80' gridHeight='0.800' gridBorderWidth='0.026' gridBorderStyle='1' width='1.418' borderWidth='0.026' borderStyle='1'>
    <Paragraph xCfg='10' leftIndent='0.020' rightIndent='0.020'  lineSpaceValue='1.500'>
      <Font size='0.317' fontName='宋体'  />张霞</Paragraph>
  </Cell>
</Row>
<Row height='0.000' xCfg='1'>
  <Cell eCfg='80' gridHeight='0.800' gridBorderWidth='0.026' gridBorderStyle='1' width='1.852' borderWidth='0.026' borderStyle='1'>
    <Paragraph xCfg='10' leftIndent='0.020' rightIndent='0.020'  lineSpaceValue='1.500'>
      <Font size='0.317' fontName='宋体'  />09-07</Paragraph>
  </Cell>
  <Cell eCfg='80' gridHeight='0.800' gridBorderWidth='0.026' gridBorderStyle='1' width='0.863' borderWidth='0.026' borderStyle='1'>
    <Paragraph xCfg='10' leftIndent='0.020' rightIndent='0.020'  lineSpaceValue='1.500'>
      <Font size='0.317' fontName='宋体'  />21:00</Paragraph>
  </Cell>
  <Cell eCfg='80' gridHeight='0.800' gridBorderWidth='0.026' gridBorderStyle='1' width='0.715' borderWidth='0.026' borderStyle='1'>
    <Paragraph xCfg='12' leftIndent='0.020' rightIndent='0.020'  lineSpaceValue='1.500' />
  </Cell>
  <Cell eCfg='80' gridHeight='0.800' gridBorderWidth='0.026' gridBorderStyle='1' width='0.677' borderWidth='0.026' borderStyle='1'>
    <Paragraph xCfg='12' leftIndent='0.020' rightIndent='0.020'  lineSpaceValue='1.500'>
      <Font size='0.317' fontName='宋体'  />163</Paragraph>
  </Cell>
  <Cell eCfg='80' gridHeight='0.800' gridBorderWidth='0.026' gridBorderStyle='1' width='0.423' borderWidth='0.026' borderStyle='1'>
    <Paragraph xCfg='12' leftIndent='0.020' rightIndent='0.020'  lineSpaceValue='1.500'>
      <Font size='0.317' fontName='宋体'  />23</Paragraph>
  </Cell>
  <Cell eCfg='80' gridHeight='0.800' gridBorderWidth='0.026' gridBorderStyle='1' width='1.184' borderWidth='0.026' borderStyle='1'>
    <Paragraph xCfg='12' leftIndent='0.020' rightIndent='0.020'  lineSpaceValue='1.500'>
      <Font size='0.317' fontName='宋体'  />89/60</Paragraph>
  </Cell>
  <Cell eCfg='80' gridHeight='0.800' gridBorderWidth='0.026' gridBorderStyle='1' width='0.465' borderWidth='0.026' borderStyle='1'>
    <Paragraph xCfg='12' leftIndent='0.020' rightIndent='0.020'  lineSpaceValue='1.500' />
  </Cell>
  <Cell eCfg='80' gridHeight='0.800' gridBorderWidth='0.026' gridBorderStyle='1' width='0.677' borderWidth='0.026' borderStyle='1'>
    <Paragraph xCfg='12' leftIndent='0.020' rightIndent='0.020'  lineSpaceValue='1.500' />
  </Cell>
  <Cell eCfg='80' gridHeight='0.800' gridBorderWidth='0.026' gridBorderStyle='1' width='0.677' borderWidth='0.026' borderStyle='1'>
    <Paragraph xCfg='12' leftIndent='0.020' rightIndent='0.020'  lineSpaceValue='1.500' />
  </Cell>
  <Cell eCfg='80' gridHeight='0.800' gridBorderWidth='0.026' gridBorderStyle='1' width='0.634' borderWidth='0.026' borderStyle='1'>
    <Paragraph xCfg='12' leftIndent='0.020' rightIndent='0.020'  lineSpaceValue='1.500' />
  </Cell>
  <Cell eCfg='80' gridHeight='0.800' gridBorderWidth='0.026' gridBorderStyle='1' width='0.634' borderWidth='0.026' borderStyle='1'>
    <Paragraph xCfg='12' leftIndent='0.020' rightIndent='0.020'  lineSpaceValue='1.500' />
  </Cell>
  <Cell eCfg='80' gridHeight='0.800' gridBorderWidth='0.026' gridBorderStyle='1' width='0.508' borderWidth='0.026' borderStyle='1'>
    <Paragraph xCfg='12' leftIndent='0.020' rightIndent='0.020'  lineSpaceValue='1.500'>
      <Font size='0.317' fontName='宋体'  />√</Paragraph>
  </Cell>
  <Cell eCfg='80' gridHeight='0.800' gridBorderWidth='0.026' gridBorderStyle='1' width='0.804' borderWidth='0.026' borderStyle='1'>
    <Paragraph xCfg='12' leftIndent='0.020' rightIndent='0.020'  lineSpaceValue='1.500'>
      <Font size='0.317' fontName='宋体'  />96</Paragraph>
  </Cell>
  <Cell eCfg='80' gridHeight='0.800' gridBorderWidth='0.026' gridBorderStyle='1' width='0.592' borderWidth='0.026' borderStyle='1'>
    <Paragraph xCfg='12' leftIndent='0.020' rightIndent='0.020'  lineSpaceValue='1.500'>
      <Font size='0.317' fontName='宋体'  />2</Paragraph>
  </Cell>
  <Cell eCfg='80' gridHeight='0.800' gridBorderWidth='0.026' gridBorderStyle='1' width='0.508' borderWidth='0.026' borderStyle='1'>
    <Paragraph xCfg='12' leftIndent='0.020' rightIndent='0.020'  lineSpaceValue='1.500'>
      <Font size='0.317' fontName='宋体'  />1</Paragraph>
  </Cell>
  <Cell eCfg='80' gridHeight='0.800' gridBorderWidth='0.026' gridBorderStyle='1' width='0.761' borderWidth='0.026' borderStyle='1'>
    <Paragraph xCfg='12' leftIndent='0.020' rightIndent='0.020'  lineSpaceValue='1.500' />
  </Cell>
  <Cell eCfg='80' gridHeight='0.800' gridBorderWidth='0.026' gridBorderStyle='1' width='0.973' borderWidth='0.026' borderStyle='1'>
    <Paragraph xCfg='12' leftIndent='0.020' rightIndent='0.020'  lineSpaceValue='1.500' />
  </Cell>
  <Cell eCfg='80' gridHeight='0.800' gridBorderWidth='0.026' gridBorderStyle='1' width='0.973' borderWidth='0.026' borderStyle='1'>
    <Paragraph xCfg='12' leftIndent='0.020' rightIndent='0.020'  lineSpaceValue='1.500'>
      <Font size='0.317' fontName='宋体'  />通畅</Paragraph>
  </Cell>
  <Cell eCfg='80' gridHeight='0.800' gridBorderWidth='0.026' gridBorderStyle='1' width='1.692' borderWidth='0.026' borderStyle='1'>
    <Paragraph xCfg='12' leftIndent='0.020' rightIndent='0.020'  lineSpaceValue='1.500' />
  </Cell>
  <Cell eCfg='80' gridHeight='0.800' gridBorderWidth='0.026' gridBorderStyle='1' width='0.761' borderWidth='0.026' borderStyle='1'>
    <Paragraph xCfg='12' leftIndent='0.020' rightIndent='0.020'  lineSpaceValue='1.500' />
  </Cell>
  <Cell eCfg='80' gridHeight='0.800' gridBorderWidth='0.026' gridBorderStyle='1' width='1.531' borderWidth='0.026' borderStyle='1'>
    <Paragraph xCfg='12' leftIndent='0.020' rightIndent='0.020'  lineSpaceValue='1.500' />
  </Cell>
  <Cell eCfg='80' gridHeight='0.800' gridBorderWidth='0.026' gridBorderStyle='1' width='0.761' borderWidth='0.026' borderStyle='1'>
    <Paragraph xCfg='12' leftIndent='0.020' rightIndent='0.020'  lineSpaceValue='1.500' />
  </Cell>
  <Cell eCfg='80' gridHeight='0.800' gridBorderWidth='0.026' gridBorderStyle='1' width='1.073' borderWidth='0.026' borderStyle='1'>
    <Paragraph xCfg='12' leftIndent='0.020' rightIndent='0.020'  lineSpaceValue='1.500' />
  </Cell>
  <Cell eCfg='80' gridHeight='0.800' gridBorderWidth='0.026' gridBorderStyle='1' width='5.660' borderWidth='0.026' borderStyle='1'>
    <Paragraph xCfg='10' leftIndent='0.020' rightIndent='0.020'  lineSpaceValue='1.500'>
      <Font size='0.317' fontName='宋体'  />
      <Space count='5' />予翻身。</Paragraph>
  </Cell>
  <Cell eCfg='80' gridHeight='0.800' gridBorderWidth='0.026' gridBorderStyle='1' width='1.418' borderWidth='0.026' borderStyle='1'>
    <Paragraph xCfg='10' leftIndent='0.020' rightIndent='0.020'  lineSpaceValue='1.500'>
      <Font size='0.317' fontName='宋体'  />张霞</Paragraph>
  </Cell>
</Row>
<Row height='0.000' xCfg='1'>
  <Cell eCfg='80' gridHeight='0.800' gridBorderWidth='0.026' gridBorderStyle='1' width='1.852' borderWidth='0.026' borderStyle='1'>
    <Paragraph xCfg='10' leftIndent='0.020' rightIndent='0.020'  lineSpaceValue='1.500'>
      <Font size='0.317' fontName='宋体'  />09-07</Paragraph>
  </Cell>
  <Cell eCfg='80' gridHeight='0.800' gridBorderWidth='0.026' gridBorderStyle='1' width='0.863' borderWidth='0.026' borderStyle='1'>
    <Paragraph xCfg='10' leftIndent='0.020' rightIndent='0.020'  lineSpaceValue='1.500'>
      <Font size='0.317' fontName='宋体'  />22:00</Paragraph>
  </Cell>
  <Cell eCfg='80' gridHeight='0.800' gridBorderWidth='0.026' gridBorderStyle='1' width='0.715' borderWidth='0.026' borderStyle='1'>
    <Paragraph xCfg='12' leftIndent='0.020' rightIndent='0.020'  lineSpaceValue='1.500' />
  </Cell>
  <Cell eCfg='80' gridHeight='0.800' gridBorderWidth='0.026' gridBorderStyle='1' width='0.677' borderWidth='0.026' borderStyle='1'>
    <Paragraph xCfg='12' leftIndent='0.020' rightIndent='0.020'  lineSpaceValue='1.500'>
      <Font size='0.317' fontName='宋体'  />164</Paragraph>
  </Cell>
  <Cell eCfg='80' gridHeight='0.800' gridBorderWidth='0.026' gridBorderStyle='1' width='0.423' borderWidth='0.026' borderStyle='1'>
    <Paragraph xCfg='12' leftIndent='0.020' rightIndent='0.020'  lineSpaceValue='1.500'>
      <Font size='0.317' fontName='宋体'  />22</Paragraph>
  </Cell>
  <Cell eCfg='80' gridHeight='0.800' gridBorderWidth='0.026' gridBorderStyle='1' width='1.184' borderWidth='0.026' borderStyle='1'>
    <Paragraph xCfg='12' leftIndent='0.020' rightIndent='0.020'  lineSpaceValue='1.500'>
      <Font size='0.317' fontName='宋体'  />90/60</Paragraph>
  </Cell>
  <Cell eCfg='80' gridHeight='0.800' gridBorderWidth='0.026' gridBorderStyle='1' width='0.465' borderWidth='0.026' borderStyle='1'>
    <Paragraph xCfg='12' leftIndent='0.020' rightIndent='0.020'  lineSpaceValue='1.500' />
  </Cell>
  <Cell eCfg='80' gridHeight='0.800' gridBorderWidth='0.026' gridBorderStyle='1' width='0.677' borderWidth='0.026' borderStyle='1'>
    <Paragraph xCfg='12' leftIndent='0.020' rightIndent='0.020'  lineSpaceValue='1.500' />
  </Cell>
  <Cell eCfg='80' gridHeight='0.800' gridBorderWidth='0.026' gridBorderStyle='1' width='0.677' borderWidth='0.026' borderStyle='1'>
    <Paragraph xCfg='12' leftIndent='0.020' rightIndent='0.020'  lineSpaceValue='1.500' />
  </Cell>
  <Cell eCfg='80' gridHeight='0.800' gridBorderWidth='0.026' gridBorderStyle='1' width='0.634' borderWidth='0.026' borderStyle='1'>
    <Paragraph xCfg='12' leftIndent='0.020' rightIndent='0.020'  lineSpaceValue='1.500' />
  </Cell>
  <Cell eCfg='80' gridHeight='0.800' gridBorderWidth='0.026' gridBorderStyle='1' width='0.634' borderWidth='0.026' borderStyle='1'>
    <Paragraph xCfg='12' leftIndent='0.020' rightIndent='0.020'  lineSpaceValue='1.500' />
  </Cell>
  <Cell eCfg='80' gridHeight='0.800' gridBorderWidth='0.026' gridBorderStyle='1' width='0.508' borderWidth='0.026' borderStyle='1'>
    <Paragraph xCfg='12' leftIndent='0.020' rightIndent='0.020'  lineSpaceValue='1.500'>
      <Font size='0.317' fontName='宋体'  />√</Paragraph>
  </Cell>
  <Cell eCfg='80' gridHeight='0.800' gridBorderWidth='0.026' gridBorderStyle='1' width='0.804' borderWidth='0.026' borderStyle='1'>
    <Paragraph xCfg='12' leftIndent='0.020' rightIndent='0.020'  lineSpaceValue='1.500'>
      <Font size='0.317' fontName='宋体'  />95</Paragraph>
  </Cell>
  <Cell eCfg='80' gridHeight='0.800' gridBorderWidth='0.026' gridBorderStyle='1' width='0.592' borderWidth='0.026' borderStyle='1'>
    <Paragraph xCfg='12' leftIndent='0.020' rightIndent='0.020'  lineSpaceValue='1.500'>
      <Font size='0.317' fontName='宋体'  />2</Paragraph>
  </Cell>
  <Cell eCfg='80' gridHeight='0.800' gridBorderWidth='0.026' gridBorderStyle='1' width='0.508' borderWidth='0.026' borderStyle='1'>
    <Paragraph xCfg='12' leftIndent='0.020' rightIndent='0.020'  lineSpaceValue='1.500'>
      <Font size='0.317' fontName='宋体'  />1</Paragraph>
  </Cell>
  <Cell eCfg='80' gridHeight='0.800' gridBorderWidth='0.026' gridBorderStyle='1' width='0.761' borderWidth='0.026' borderStyle='1'>
    <Paragraph xCfg='12' leftIndent='0.020' rightIndent='0.020'  lineSpaceValue='1.500' />
  </Cell>
  <Cell eCfg='80' gridHeight='0.800' gridBorderWidth='0.026' gridBorderStyle='1' width='0.973' borderWidth='0.026' borderStyle='1'>
    <Paragraph xCfg='12' leftIndent='0.020' rightIndent='0.020'  lineSpaceValue='1.500' />
  </Cell>
  <Cell eCfg='80' gridHeight='0.800' gridBorderWidth='0.026' gridBorderStyle='1' width='0.973' borderWidth='0.026' borderStyle='1'>
    <Paragraph xCfg='12' leftIndent='0.020' rightIndent='0.020'  lineSpaceValue='1.500'>
      <Font size='0.317' fontName='宋体'  />通畅</Paragraph>
  </Cell>
  <Cell eCfg='80' gridHeight='0.800' gridBorderWidth='0.026' gridBorderStyle='1' width='1.692' borderWidth='0.026' borderStyle='1'>
    <Paragraph xCfg='12' leftIndent='0.020' rightIndent='0.020'  lineSpaceValue='1.500' />
  </Cell>
  <Cell eCfg='80' gridHeight='0.800' gridBorderWidth='0.026' gridBorderStyle='1' width='0.761' borderWidth='0.026' borderStyle='1'>
    <Paragraph xCfg='12' leftIndent='0.020' rightIndent='0.020'  lineSpaceValue='1.500' />
  </Cell>
  <Cell eCfg='80' gridHeight='0.800' gridBorderWidth='0.026' gridBorderStyle='1' width='1.531' borderWidth='0.026' borderStyle='1'>
    <Paragraph xCfg='12' leftIndent='0.020' rightIndent='0.020'  lineSpaceValue='1.500' />
  </Cell>
  <Cell eCfg='80' gridHeight='0.800' gridBorderWidth='0.026' gridBorderStyle='1' width='0.761' borderWidth='0.026' borderStyle='1'>
    <Paragraph xCfg='12' leftIndent='0.020' rightIndent='0.020'  lineSpaceValue='1.500' />
  </Cell>
  <Cell eCfg='80' gridHeight='0.800' gridBorderWidth='0.026' gridBorderStyle='1' width='1.073' borderWidth='0.026' borderStyle='1'>
    <Paragraph xCfg='12' leftIndent='0.020' rightIndent='0.020'  lineSpaceValue='1.500' />
  </Cell>
  <Cell eCfg='80' gridHeight='0.800' gridBorderWidth='0.026' gridBorderStyle='1' width='5.660' borderWidth='0.026' borderStyle='1'>
    <Paragraph xCfg='10' leftIndent='0.020' rightIndent='0.020'  lineSpaceValue='1.500'>
      <Font size='0.317' fontName='宋体'  />
      <Space count='5' />遵医嘱予吸痰一次，吸出白色泡沫痰约3ml。</Paragraph>
  </Cell>
  <Cell eCfg='80' gridHeight='0.800' gridBorderWidth='0.026' gridBorderStyle='1' width='1.418' borderWidth='0.026' borderStyle='1'>
    <Paragraph xCfg='10' leftIndent='0.020' rightIndent='0.020'  lineSpaceValue='1.500'>
      <Font size='0.317' fontName='宋体'  />张霞</Paragraph>
  </Cell>
</Row>
<Row height='0.000' xCfg='1'>
  <Cell eCfg='80' gridHeight='0.800' gridBorderWidth='0.026' gridBorderStyle='1' width='1.852' borderWidth='0.026' borderStyle='1'>
    <Paragraph xCfg='10' leftIndent='0.020' rightIndent='0.020'  lineSpaceValue='1.500'>
      <Font size='0.317' fontName='宋体'  />09-07</Paragraph>
  </Cell>
  <Cell eCfg='80' gridHeight='0.800' gridBorderWidth='0.026' gridBorderStyle='1' width='0.863' borderWidth='0.026' borderStyle='1'>
    <Paragraph xCfg='10' leftIndent='0.020' rightIndent='0.020'  lineSpaceValue='1.500'>
      <Font size='0.317' fontName='宋体'  />23:00</Paragraph>
  </Cell>
  <Cell eCfg='80' gridHeight='0.800' gridBorderWidth='0.026' gridBorderStyle='1' width='0.715' borderWidth='0.026' borderStyle='1'>
    <Paragraph xCfg='12' leftIndent='0.020' rightIndent='0.020'  lineSpaceValue='1.500' />
  </Cell>
  <Cell eCfg='80' gridHeight='0.800' gridBorderWidth='0.026' gridBorderStyle='1' width='0.677' borderWidth='0.026' borderStyle='1'>
    <Paragraph xCfg='12' leftIndent='0.020' rightIndent='0.020'  lineSpaceValue='1.500'>
      <Font size='0.317' fontName='宋体'  />165</Paragraph>
  </Cell>
  <Cell eCfg='80' gridHeight='0.800' gridBorderWidth='0.026' gridBorderStyle='1' width='0.423' borderWidth='0.026' borderStyle='1'>
    <Paragraph xCfg='12' leftIndent='0.020' rightIndent='0.020'  lineSpaceValue='1.500'>
      <Font size='0.317' fontName='宋体'  />21</Paragraph>
  </Cell>
  <Cell eCfg='80' gridHeight='0.800' gridBorderWidth='0.026' gridBorderStyle='1' width='1.184' borderWidth='0.026' borderStyle='1'>
    <Paragraph xCfg='12' leftIndent='0.020' rightIndent='0.020'  lineSpaceValue='1.500'>
      <Font size='0.317' fontName='宋体'  />98/60</Paragraph>
  </Cell>
  <Cell eCfg='80' gridHeight='0.800' gridBorderWidth='0.026' gridBorderStyle='1' width='0.465' borderWidth='0.026' borderStyle='1'>
    <Paragraph xCfg='12' leftIndent='0.020' rightIndent='0.020'  lineSpaceValue='1.500' />
  </Cell>
  <Cell eCfg='80' gridHeight='0.800' gridBorderWidth='0.026' gridBorderStyle='1' width='0.677' borderWidth='0.026' borderStyle='1'>
    <Paragraph xCfg='12' leftIndent='0.020' rightIndent='0.020'  lineSpaceValue='1.500' />
  </Cell>
  <Cell eCfg='80' gridHeight='0.800' gridBorderWidth='0.026' gridBorderStyle='1' width='0.677' borderWidth='0.026' borderStyle='1'>
    <Paragraph xCfg='12' leftIndent='0.020' rightIndent='0.020'  lineSpaceValue='1.500' />
  </Cell>
  <Cell eCfg='80' gridHeight='0.800' gridBorderWidth='0.026' gridBorderStyle='1' width='0.634' borderWidth='0.026' borderStyle='1'>
    <Paragraph xCfg='12' leftIndent='0.020' rightIndent='0.020'  lineSpaceValue='1.500' />
  </Cell>
  <Cell eCfg='80' gridHeight='0.800' gridBorderWidth='0.026' gridBorderStyle='1' width='0.634' borderWidth='0.026' borderStyle='1'>
    <Paragraph xCfg='12' leftIndent='0.020' rightIndent='0.020'  lineSpaceValue='1.500' />
  </Cell>
  <Cell eCfg='80' gridHeight='0.800' gridBorderWidth='0.026' gridBorderStyle='1' width='0.508' borderWidth='0.026' borderStyle='1'>
    <Paragraph xCfg='12' leftIndent='0.020' rightIndent='0.020'  lineSpaceValue='1.500'>
      <Font size='0.317' fontName='宋体'  />√</Paragraph>
  </Cell>
  <Cell eCfg='80' gridHeight='0.800' gridBorderWidth='0.026' gridBorderStyle='1' width='0.804' borderWidth='0.026' borderStyle='1'>
    <Paragraph xCfg='12' leftIndent='0.020' rightIndent='0.020'  lineSpaceValue='1.500'>
      <Font size='0.317' fontName='宋体'  />96</Paragraph>
  </Cell>
  <Cell eCfg='80' gridHeight='0.800' gridBorderWidth='0.026' gridBorderStyle='1' width='0.592' borderWidth='0.026' borderStyle='1'>
    <Paragraph xCfg='12' leftIndent='0.020' rightIndent='0.020'  lineSpaceValue='1.500'>
      <Font size='0.317' fontName='宋体'  />2</Paragraph>
  </Cell>
  <Cell eCfg='80' gridHeight='0.800' gridBorderWidth='0.026' gridBorderStyle='1' width='0.508' borderWidth='0.026' borderStyle='1'>
    <Paragraph xCfg='12' leftIndent='0.020' rightIndent='0.020'  lineSpaceValue='1.500'>
      <Font size='0.317' fontName='宋体'  />1</Paragraph>
  </Cell>
  <Cell eCfg='80' gridHeight='0.800' gridBorderWidth='0.026' gridBorderStyle='1' width='0.761' borderWidth='0.026' borderStyle='1'>
    <Paragraph xCfg='12' leftIndent='0.020' rightIndent='0.020'  lineSpaceValue='1.500' />
  </Cell>
  <Cell eCfg='80' gridHeight='0.800' gridBorderWidth='0.026' gridBorderStyle='1' width='0.973' borderWidth='0.026' borderStyle='1'>
    <Paragraph xCfg='12' leftIndent='0.020' rightIndent='0.020'  lineSpaceValue='1.500' />
  </Cell>
  <Cell eCfg='80' gridHeight='0.800' gridBorderWidth='0.026' gridBorderStyle='1' width='0.973' borderWidth='0.026' borderStyle='1'>
    <Paragraph xCfg='12' leftIndent='0.020' rightIndent='0.020'  lineSpaceValue='1.500'>
      <Font size='0.317' fontName='宋体'  />通畅</Paragraph>
  </Cell>
  <Cell eCfg='80' gridHeight='0.800' gridBorderWidth='0.026' gridBorderStyle='1' width='1.692' borderWidth='0.026' borderStyle='1'>
    <Paragraph xCfg='12' leftIndent='0.020' rightIndent='0.020'  lineSpaceValue='1.500' />
  </Cell>
  <Cell eCfg='80' gridHeight='0.800' gridBorderWidth='0.026' gridBorderStyle='1' width='0.761' borderWidth='0.026' borderStyle='1'>
    <Paragraph xCfg='12' leftIndent='0.020' rightIndent='0.020'  lineSpaceValue='1.500' />
  </Cell>
  <Cell eCfg='80' gridHeight='0.800' gridBorderWidth='0.026' gridBorderStyle='1' width='1.531' borderWidth='0.026' borderStyle='1'>
    <Paragraph xCfg='12' leftIndent='0.020' rightIndent='0.020'  lineSpaceValue='1.500' />
  </Cell>
  <Cell eCfg='80' gridHeight='0.800' gridBorderWidth='0.026' gridBorderStyle='1' width='0.761' borderWidth='0.026' borderStyle='1'>
    <Paragraph xCfg='12' leftIndent='0.020' rightIndent='0.020'  lineSpaceValue='1.500' />
  </Cell>
  <Cell eCfg='80' gridHeight='0.800' gridBorderWidth='0.026' gridBorderStyle='1' width='1.073' borderWidth='0.026' borderStyle='1'>
    <Paragraph xCfg='12' leftIndent='0.020' rightIndent='0.020'  lineSpaceValue='1.500' />
  </Cell>
  <Cell eCfg='80' gridHeight='0.800' gridBorderWidth='0.026' gridBorderStyle='1' width='5.660' borderWidth='0.026' borderStyle='1'>
    <Paragraph xCfg='10' leftIndent='0.020' rightIndent='0.020'  lineSpaceValue='1.500'>
      <Font size='0.317' fontName='宋体'  />
      <Space count='5' />患者呼吸平稳，呼吸道通畅，安静入睡，持续吸氧，予翻身。</Paragraph>
  </Cell>
  <Cell eCfg='80' gridHeight='0.800' gridBorderWidth='0.026' gridBorderStyle='1' width='1.418' borderWidth='0.026' borderStyle='1'>
    <Paragraph xCfg='10' leftIndent='0.020' rightIndent='0.020'  lineSpaceValue='1.500'>
      <Font size='0.317' fontName='宋体'  />张霞</Paragraph>
  </Cell>
</Row>
<Row height='0.000' xCfg='1'>
  <Cell eCfg='80' gridHeight='0.800' gridBorderWidth='0.026' gridBorderStyle='1' width='1.852' borderWidth='0.026' borderStyle='1'>
    <Paragraph xCfg='10' leftIndent='0.020' rightIndent='0.020'  lineSpaceValue='1.500'>
      <Font size='0.317' fontName='宋体'  />09-07</Paragraph>
  </Cell>
  <Cell eCfg='80' gridHeight='0.800' gridBorderWidth='0.026' gridBorderStyle='1' width='0.863' borderWidth='0.026' borderStyle='1'>
    <Paragraph xCfg='10' leftIndent='0.020' rightIndent='0.020'  lineSpaceValue='1.500'>
      <Font size='0.317' fontName='宋体'  />23:58</Paragraph>
  </Cell>
  <Cell eCfg='80' gridHeight='0.800' gridBorderWidth='0.026' gridBorderStyle='1' width='0.715' borderWidth='0.026' borderStyle='1'>
    <Paragraph xCfg='12' leftIndent='0.020' rightIndent='0.020'  lineSpaceValue='1.500' />
  </Cell>
  <Cell eCfg='80' gridHeight='0.800' gridBorderWidth='0.026' gridBorderStyle='1' width='0.677' borderWidth='0.026' borderStyle='1'>
    <Paragraph xCfg='12' leftIndent='0.020' rightIndent='0.020'  lineSpaceValue='1.500'>
      <Font size='0.317' fontName='宋体'  />163</Paragraph>
  </Cell>
  <Cell eCfg='80' gridHeight='0.800' gridBorderWidth='0.026' gridBorderStyle='1' width='0.423' borderWidth='0.026' borderStyle='1'>
    <Paragraph xCfg='12' leftIndent='0.020' rightIndent='0.020'  lineSpaceValue='1.500'>
      <Font size='0.317' fontName='宋体'  />22</Paragraph>
  </Cell>
  <Cell eCfg='80' gridHeight='0.800' gridBorderWidth='0.026' gridBorderStyle='1' width='1.184' borderWidth='0.026' borderStyle='1'>
    <Paragraph xCfg='12' leftIndent='0.020' rightIndent='0.020'  lineSpaceValue='1.500'>
      <Font size='0.317' fontName='宋体'  />100/60</Paragraph>
  </Cell>
  <Cell eCfg='80' gridHeight='0.800' gridBorderWidth='0.026' gridBorderStyle='1' width='0.465' borderWidth='0.026' borderStyle='1'>
    <Paragraph xCfg='12' leftIndent='0.020' rightIndent='0.020'  lineSpaceValue='1.500' />
  </Cell>
  <Cell eCfg='80' gridHeight='0.800' gridBorderWidth='0.026' gridBorderStyle='1' width='0.677' borderWidth='0.026' borderStyle='1'>
    <Paragraph xCfg='12' leftIndent='0.020' rightIndent='0.020'  lineSpaceValue='1.500' />
  </Cell>
  <Cell eCfg='80' gridHeight='0.800' gridBorderWidth='0.026' gridBorderStyle='1' width='0.677' borderWidth='0.026' borderStyle='1'>
    <Paragraph xCfg='12' leftIndent='0.020' rightIndent='0.020'  lineSpaceValue='1.500' />
  </Cell>
  <Cell eCfg='80' gridHeight='0.800' gridBorderWidth='0.026' gridBorderStyle='1' width='0.634' borderWidth='0.026' borderStyle='1'>
    <Paragraph xCfg='12' leftIndent='0.020' rightIndent='0.020'  lineSpaceValue='1.500' />
  </Cell>
  <Cell eCfg='80' gridHeight='0.800' gridBorderWidth='0.026' gridBorderStyle='1' width='0.634' borderWidth='0.026' borderStyle='1'>
    <Paragraph xCfg='12' leftIndent='0.020' rightIndent='0.020'  lineSpaceValue='1.500' />
  </Cell>
  <Cell eCfg='80' gridHeight='0.800' gridBorderWidth='0.026' gridBorderStyle='1' width='0.508' borderWidth='0.026' borderStyle='1'>
    <Paragraph xCfg='12' leftIndent='0.020' rightIndent='0.020'  lineSpaceValue='1.500'>
      <Font size='0.317' fontName='宋体'  />√</Paragraph>
  </Cell>
  <Cell eCfg='80' gridHeight='0.800' gridBorderWidth='0.026' gridBorderStyle='1' width='0.804' borderWidth='0.026' borderStyle='1'>
    <Paragraph xCfg='12' leftIndent='0.020' rightIndent='0.020'  lineSpaceValue='1.500'>
      <Font size='0.317' fontName='宋体'  />95</Paragraph>
  </Cell>
  <Cell eCfg='80' gridHeight='0.800' gridBorderWidth='0.026' gridBorderStyle='1' width='0.592' borderWidth='0.026' borderStyle='1'>
    <Paragraph xCfg='12' leftIndent='0.020' rightIndent='0.020'  lineSpaceValue='1.500'>
      <Font size='0.317' fontName='宋体'  />2</Paragraph>
  </Cell>
  <Cell eCfg='80' gridHeight='0.800' gridBorderWidth='0.026' gridBorderStyle='1' width='0.508' borderWidth='0.026' borderStyle='1'>
    <Paragraph xCfg='12' leftIndent='0.020' rightIndent='0.020'  lineSpaceValue='1.500'>
      <Font size='0.317' fontName='宋体'  />1</Paragraph>
  </Cell>
  <Cell eCfg='80' gridHeight='0.800' gridBorderWidth='0.026' gridBorderStyle='1' width='0.761' borderWidth='0.026' borderStyle='1'>
    <Paragraph xCfg='12' leftIndent='0.020' rightIndent='0.020'  lineSpaceValue='1.500' />
  </Cell>
  <Cell eCfg='80' gridHeight='0.800' gridBorderWidth='0.026' gridBorderStyle='1' width='0.973' borderWidth='0.026' borderStyle='1'>
    <Paragraph xCfg='12' leftIndent='0.020' rightIndent='0.020'  lineSpaceValue='1.500' />
  </Cell>
  <Cell eCfg='80' gridHeight='0.800' gridBorderWidth='0.026' gridBorderStyle='1' width='0.973' borderWidth='0.026' borderStyle='1'>
    <Paragraph xCfg='12' leftIndent='0.020' rightIndent='0.020'  lineSpaceValue='1.500'>
      <Font size='0.317' fontName='宋体'  />通畅</Paragraph>
  </Cell>
  <Cell eCfg='80' gridHeight='0.800' gridBorderWidth='0.026' gridBorderStyle='1' width='1.692' borderWidth='0.026' borderStyle='1'>
    <Paragraph xCfg='12' leftIndent='0.020' rightIndent='0.020'  lineSpaceValue='1.500' />
  </Cell>
  <Cell eCfg='80' gridHeight='0.800' gridBorderWidth='0.026' gridBorderStyle='1' width='0.761' borderWidth='0.026' borderStyle='1'>
    <Paragraph xCfg='12' leftIndent='0.020' rightIndent='0.020'  lineSpaceValue='1.500' />
  </Cell>
  <Cell eCfg='80' gridHeight='0.800' gridBorderWidth='0.026' gridBorderStyle='1' width='1.531' borderWidth='0.026' borderStyle='1'>
    <Paragraph xCfg='12' leftIndent='0.020' rightIndent='0.020'  lineSpaceValue='1.500' />
  </Cell>
  <Cell eCfg='80' gridHeight='0.800' gridBorderWidth='0.026' gridBorderStyle='1' width='0.761' borderWidth='0.026' borderStyle='1'>
    <Paragraph xCfg='12' leftIndent='0.020' rightIndent='0.020'  lineSpaceValue='1.500' />
  </Cell>
  <Cell eCfg='80' gridHeight='0.800' gridBorderWidth='0.026' gridBorderStyle='1' width='1.073' borderWidth='0.026' borderStyle='1'>
    <Paragraph xCfg='12' leftIndent='0.020' rightIndent='0.020'  lineSpaceValue='1.500' />
  </Cell>
  <Cell eCfg='80' gridHeight='0.800' gridBorderWidth='0.026' gridBorderStyle='1' width='5.660' borderWidth='0.026' borderStyle='1'>
    <Paragraph xCfg='10' leftIndent='0.020' rightIndent='0.020'  lineSpaceValue='1.500'>
      <Font size='0.317' fontName='宋体'  />
      <Space count='5' />
    </Paragraph>
  </Cell>
  <Cell eCfg='80' gridHeight='0.800' gridBorderWidth='0.026' gridBorderStyle='1' width='1.418' borderWidth='0.026' borderStyle='1'>
    <Paragraph xCfg='10' leftIndent='0.020' rightIndent='0.020'  lineSpaceValue='1.500'>
      <Font size='0.317' fontName='宋体'  />张霞</Paragraph>
  </Cell>
</Row>
<Row height='0.000' xCfg='1'>
  <Cell eCfg='80' gridHeight='0.800' gridBorderWidth='0.026' gridBorderStyle='1' width='1.852' borderWidth='0.026' borderStyle='1'>
    <Paragraph xCfg='10' leftIndent='0.020' rightIndent='0.020'  lineSpaceValue='1.500'>
      <Font size='0.317' fontName='宋体'  />2022-09-08</Paragraph>
  </Cell>
  <Cell eCfg='80' gridHeight='0.800' gridBorderWidth='0.026' gridBorderStyle='1' width='0.863' borderWidth='0.026' borderStyle='1'>
    <Paragraph xCfg='10' leftIndent='0.020' rightIndent='0.020'  lineSpaceValue='1.500'>
      <Font size='0.317' fontName='宋体'  />01:00</Paragraph>
  </Cell>
  <Cell eCfg='80' gridHeight='0.800' gridBorderWidth='0.026' gridBorderStyle='1' width='0.715' borderWidth='0.026' borderStyle='1'>
    <Paragraph xCfg='12' leftIndent='0.020' rightIndent='0.020'  lineSpaceValue='1.500' />
  </Cell>
  <Cell eCfg='80' gridHeight='0.800' gridBorderWidth='0.026' gridBorderStyle='1' width='0.677' borderWidth='0.026' borderStyle='1'>
    <Paragraph xCfg='12' leftIndent='0.020' rightIndent='0.020'  lineSpaceValue='1.500'>
      <Font size='0.317' fontName='宋体'  />158</Paragraph>
  </Cell>
  <Cell eCfg='80' gridHeight='0.800' gridBorderWidth='0.026' gridBorderStyle='1' width='0.423' borderWidth='0.026' borderStyle='1'>
    <Paragraph xCfg='12' leftIndent='0.020' rightIndent='0.020'  lineSpaceValue='1.500'>
      <Font size='0.317' fontName='宋体'  />20</Paragraph>
  </Cell>
  <Cell eCfg='80' gridHeight='0.800' gridBorderWidth='0.026' gridBorderStyle='1' width='1.184' borderWidth='0.026' borderStyle='1'>
    <Paragraph xCfg='12' leftIndent='0.020' rightIndent='0.020'  lineSpaceValue='1.500'>
      <Font size='0.317' fontName='宋体'  />100/60</Paragraph>
  </Cell>
  <Cell eCfg='80' gridHeight='0.800' gridBorderWidth='0.026' gridBorderStyle='1' width='0.465' borderWidth='0.026' borderStyle='1'>
    <Paragraph xCfg='12' leftIndent='0.020' rightIndent='0.020'  lineSpaceValue='1.500' />
  </Cell>
  <Cell eCfg='80' gridHeight='0.800' gridBorderWidth='0.026' gridBorderStyle='1' width='0.677' borderWidth='0.026' borderStyle='1'>
    <Paragraph xCfg='12' leftIndent='0.020' rightIndent='0.020'  lineSpaceValue='1.500' />
  </Cell>
  <Cell eCfg='80' gridHeight='0.800' gridBorderWidth='0.026' gridBorderStyle='1' width='0.677' borderWidth='0.026' borderStyle='1'>
    <Paragraph xCfg='12' leftIndent='0.020' rightIndent='0.020'  lineSpaceValue='1.500' />
  </Cell>
  <Cell eCfg='80' gridHeight='0.800' gridBorderWidth='0.026' gridBorderStyle='1' width='0.634' borderWidth='0.026' borderStyle='1'>
    <Paragraph xCfg='12' leftIndent='0.020' rightIndent='0.020'  lineSpaceValue='1.500' />
  </Cell>
  <Cell eCfg='80' gridHeight='0.800' gridBorderWidth='0.026' gridBorderStyle='1' width='0.634' borderWidth='0.026' borderStyle='1'>
    <Paragraph xCfg='12' leftIndent='0.020' rightIndent='0.020'  lineSpaceValue='1.500' />
  </Cell>
  <Cell eCfg='80' gridHeight='0.800' gridBorderWidth='0.026' gridBorderStyle='1' width='0.508' borderWidth='0.026' borderStyle='1'>
    <Paragraph xCfg='12' leftIndent='0.020' rightIndent='0.020'  lineSpaceValue='1.500'>
      <Font size='0.317' fontName='宋体'  />√</Paragraph>
  </Cell>
  <Cell eCfg='80' gridHeight='0.800' gridBorderWidth='0.026' gridBorderStyle='1' width='0.804' borderWidth='0.026' borderStyle='1'>
    <Paragraph xCfg='12' leftIndent='0.020' rightIndent='0.020'  lineSpaceValue='1.500'>
      <Font size='0.317' fontName='宋体'  />94</Paragraph>
  </Cell>
  <Cell eCfg='80' gridHeight='0.800' gridBorderWidth='0.026' gridBorderStyle='1' width='0.592' borderWidth='0.026' borderStyle='1'>
    <Paragraph xCfg='12' leftIndent='0.020' rightIndent='0.020'  lineSpaceValue='1.500'>
      <Font size='0.317' fontName='宋体'  />2</Paragraph>
  </Cell>
  <Cell eCfg='80' gridHeight='0.800' gridBorderWidth='0.026' gridBorderStyle='1' width='0.508' borderWidth='0.026' borderStyle='1'>
    <Paragraph xCfg='12' leftIndent='0.020' rightIndent='0.020'  lineSpaceValue='1.500'>
      <Font size='0.317' fontName='宋体'  />1</Paragraph>
  </Cell>
  <Cell eCfg='80' gridHeight='0.800' gridBorderWidth='0.026' gridBorderStyle='1' width='0.761' borderWidth='0.026' borderStyle='1'>
    <Paragraph xCfg='12' leftIndent='0.020' rightIndent='0.020'  lineSpaceValue='1.500' />
  </Cell>
  <Cell eCfg='80' gridHeight='0.800' gridBorderWidth='0.026' gridBorderStyle='1' width='0.973' borderWidth='0.026' borderStyle='1'>
    <Paragraph xCfg='12' leftIndent='0.020' rightIndent='0.020'  lineSpaceValue='1.500' />
  </Cell>
  <Cell eCfg='80' gridHeight='0.800' gridBorderWidth='0.026' gridBorderStyle='1' width='0.973' borderWidth='0.026' borderStyle='1'>
    <Paragraph xCfg='12' leftIndent='0.020' rightIndent='0.020'  lineSpaceValue='1.500'>
      <Font size='0.317' fontName='宋体'  />通畅</Paragraph>
  </Cell>
  <Cell eCfg='80' gridHeight='0.800' gridBorderWidth='0.026' gridBorderStyle='1' width='1.692' borderWidth='0.026' borderStyle='1'>
    <Paragraph xCfg='12' leftIndent='0.020' rightIndent='0.020'  lineSpaceValue='1.500' />
  </Cell>
  <Cell eCfg='80' gridHeight='0.800' gridBorderWidth='0.026' gridBorderStyle='1' width='0.761' borderWidth='0.026' borderStyle='1'>
    <Paragraph xCfg='12' leftIndent='0.020' rightIndent='0.020'  lineSpaceValue='1.500' />
  </Cell>
  <Cell eCfg='80' gridHeight='0.800' gridBorderWidth='0.026' gridBorderStyle='1' width='1.531' borderWidth='0.026' borderStyle='1'>
    <Paragraph xCfg='12' leftIndent='0.020' rightIndent='0.020'  lineSpaceValue='1.500' />
  </Cell>
  <Cell eCfg='80' gridHeight='0.800' gridBorderWidth='0.026' gridBorderStyle='1' width='0.761' borderWidth='0.026' borderStyle='1'>
    <Paragraph xCfg='12' leftIndent='0.020' rightIndent='0.020'  lineSpaceValue='1.500' />
  </Cell>
  <Cell eCfg='80' gridHeight='0.800' gridBorderWidth='0.026' gridBorderStyle='1' width='1.073' borderWidth='0.026' borderStyle='1'>
    <Paragraph xCfg='12' leftIndent='0.020' rightIndent='0.020'  lineSpaceValue='1.500' />
  </Cell>
  <Cell eCfg='80' gridHeight='0.800' gridBorderWidth='0.026' gridBorderStyle='1' width='5.660' borderWidth='0.026' borderStyle='1'>
    <Paragraph xCfg='10' leftIndent='0.020' rightIndent='0.020'  lineSpaceValue='1.500'>
      <Font size='0.317' fontName='宋体'  />
      <Space count='5' />患者病情危重，神志清楚，精神差，呼吸平稳，呼吸通畅，间断入睡，持续吸氧,患者左侧臀部红肿，面积约5×5cm，触摸感硬，压之患者无反应，右侧红肿，面积约5×5ml，其中破溃面积3×3cm,有散在水泡，压之患者无反应，予翻身。</Paragraph>
  </Cell>
  <Cell eCfg='80' gridHeight='0.800' gridBorderWidth='0.026' gridBorderStyle='1' width='1.418' borderWidth='0.026' borderStyle='1'>
    <Paragraph xCfg='10' leftIndent='0.020' rightIndent='0.020'  lineSpaceValue='1.500'>
      <Font size='0.317' fontName='宋体'  />李斌</Paragraph>
  </Cell>
</Row>
<Row height='0.000' xCfg='1'>
  <Cell eCfg='80' gridHeight='0.800' gridBorderWidth='0.026' gridBorderStyle='1' width='1.852' borderWidth='0.026' borderStyle='1'>
    <Paragraph xCfg='10' leftIndent='0.020' rightIndent='0.020'  lineSpaceValue='1.500'>
      <Font size='0.317' fontName='宋体'  />2022-11-16</Paragraph>
  </Cell>
  <Cell eCfg='80' gridHeight='0.800' gridBorderWidth='0.026' gridBorderStyle='1' width='0.863' borderWidth='0.026' borderStyle='1'>
    <Paragraph xCfg='10' leftIndent='0.020' rightIndent='0.020'  lineSpaceValue='1.500'>
      <Font size='0.317' fontName='宋体'  />01:20</Paragraph>
  </Cell>
  <Cell eCfg='80' gridHeight='0.800' gridBorderWidth='0.026' gridBorderStyle='1' width='0.715' borderWidth='0.026' borderStyle='1'>
    <Paragraph xCfg='12' leftIndent='0.020' rightIndent='0.020'  lineSpaceValue='1.500' />
  </Cell>
  <Cell eCfg='80' gridHeight='0.800' gridBorderWidth='0.026' gridBorderStyle='1' width='0.677' borderWidth='0.026' borderStyle='1'>
    <Paragraph xCfg='12' leftIndent='0.020' rightIndent='0.020'  lineSpaceValue='1.500'>
      <Font size='0.317' fontName='宋体'  />155</Paragraph>
  </Cell>
  <Cell eCfg='80' gridHeight='0.800' gridBorderWidth='0.026' gridBorderStyle='1' width='0.423' borderWidth='0.026' borderStyle='1'>
    <Paragraph xCfg='12' leftIndent='0.020' rightIndent='0.020'  lineSpaceValue='1.500'>
      <Font size='0.317' fontName='宋体'  />20</Paragraph>
  </Cell>
  <Cell eCfg='80' gridHeight='0.800' gridBorderWidth='0.026' gridBorderStyle='1' width='1.184' borderWidth='0.026' borderStyle='1'>
    <Paragraph xCfg='12' leftIndent='0.020' rightIndent='0.020'  lineSpaceValue='1.500' />
  </Cell>
  <Cell eCfg='80' gridHeight='0.800' gridBorderWidth='0.026' gridBorderStyle='1' width='0.465' borderWidth='0.026' borderStyle='1'>
    <Paragraph xCfg='12' leftIndent='0.020' rightIndent='0.020'  lineSpaceValue='1.500' />
  </Cell>
  <Cell eCfg='80' gridHeight='0.800' gridBorderWidth='0.026' gridBorderStyle='1' width='0.677' borderWidth='0.026' borderStyle='1'>
    <Paragraph xCfg='12' leftIndent='0.020' rightIndent='0.020'  lineSpaceValue='1.500' />
  </Cell>
  <Cell eCfg='80' gridHeight='0.800' gridBorderWidth='0.026' gridBorderStyle='1' width='0.677' borderWidth='0.026' borderStyle='1'>
    <Paragraph xCfg='12' leftIndent='0.020' rightIndent='0.020'  lineSpaceValue='1.500' />
  </Cell>
  <Cell eCfg='80' gridHeight='0.800' gridBorderWidth='0.026' gridBorderStyle='1' width='0.634' borderWidth='0.026' borderStyle='1'>
    <Paragraph xCfg='12' leftIndent='0.020' rightIndent='0.020'  lineSpaceValue='1.500' />
  </Cell>
  <Cell eCfg='80' gridHeight='0.800' gridBorderWidth='0.026' gridBorderStyle='1' width='0.634' borderWidth='0.026' borderStyle='1'>
    <Paragraph xCfg='12' leftIndent='0.020' rightIndent='0.020'  lineSpaceValue='1.500' />
  </Cell>
  <Cell eCfg='80' gridHeight='0.800' gridBorderWidth='0.026' gridBorderStyle='1' width='0.508' borderWidth='0.026' borderStyle='1'>
    <Paragraph xCfg='12' leftIndent='0.020' rightIndent='0.020'  lineSpaceValue='1.500' />
  </Cell>
  <Cell eCfg='80' gridHeight='0.800' gridBorderWidth='0.026' gridBorderStyle='1' width='0.804' borderWidth='0.026' borderStyle='1'>
    <Paragraph xCfg='12' leftIndent='0.020' rightIndent='0.020'  lineSpaceValue='1.500' />
  </Cell>
  <Cell eCfg='80' gridHeight='0.800' gridBorderWidth='0.026' gridBorderStyle='1' width='0.592' borderWidth='0.026' borderStyle='1'>
    <Paragraph xCfg='12' leftIndent='0.020' rightIndent='0.020'  lineSpaceValue='1.500' />
  </Cell>
  <Cell eCfg='80' gridHeight='0.800' gridBorderWidth='0.026' gridBorderStyle='1' width='0.508' borderWidth='0.026' borderStyle='1'>
    <Paragraph xCfg='12' leftIndent='0.020' rightIndent='0.020'  lineSpaceValue='1.500' />
  </Cell>
  <Cell eCfg='80' gridHeight='0.800' gridBorderWidth='0.026' gridBorderStyle='1' width='0.761' borderWidth='0.026' borderStyle='1'>
    <Paragraph xCfg='12' leftIndent='0.020' rightIndent='0.020'  lineSpaceValue='1.500' />
  </Cell>
  <Cell eCfg='80' gridHeight='0.800' gridBorderWidth='0.026' gridBorderStyle='1' width='0.973' borderWidth='0.026' borderStyle='1'>
    <Paragraph xCfg='12' leftIndent='0.020' rightIndent='0.020'  lineSpaceValue='1.500' />
  </Cell>
  <Cell eCfg='80' gridHeight='0.800' gridBorderWidth='0.026' gridBorderStyle='1' width='0.973' borderWidth='0.026' borderStyle='1'>
    <Paragraph xCfg='12' leftIndent='0.020' rightIndent='0.020'  lineSpaceValue='1.500' />
  </Cell>
  <Cell eCfg='80' gridHeight='0.800' gridBorderWidth='0.026' gridBorderStyle='1' width='1.692' borderWidth='0.026' borderStyle='1'>
    <Paragraph xCfg='12' leftIndent='0.020' rightIndent='0.020'  lineSpaceValue='1.500' />
  </Cell>
  <Cell eCfg='80' gridHeight='0.800' gridBorderWidth='0.026' gridBorderStyle='1' width='0.761' borderWidth='0.026' borderStyle='1'>
    <Paragraph xCfg='12' leftIndent='0.020' rightIndent='0.020'  lineSpaceValue='1.500' />
  </Cell>
  <Cell eCfg='80' gridHeight='0.800' gridBorderWidth='0.026' gridBorderStyle='1' width='1.531' borderWidth='0.026' borderStyle='1'>
    <Paragraph xCfg='12' leftIndent='0.020' rightIndent='0.020'  lineSpaceValue='1.500' />
  </Cell>
  <Cell eCfg='80' gridHeight='0.800' gridBorderWidth='0.026' gridBorderStyle='1' width='0.761' borderWidth='0.026' borderStyle='1'>
    <Paragraph xCfg='12' leftIndent='0.020' rightIndent='0.020'  lineSpaceValue='1.500' />
  </Cell>
  <Cell eCfg='80' gridHeight='0.800' gridBorderWidth='0.026' gridBorderStyle='1' width='1.073' borderWidth='0.026' borderStyle='1'>
    <Paragraph xCfg='12' leftIndent='0.020' rightIndent='0.020'  lineSpaceValue='1.500' />
  </Cell>
  <Cell eCfg='80' gridHeight='0.800' gridBorderWidth='0.026' gridBorderStyle='1' width='5.660' borderWidth='0.026' borderStyle='1'>
    <Paragraph xCfg='10' leftIndent='0.020' rightIndent='0.020'  lineSpaceValue='1.500'>
      <Font size='0.317' fontName='宋体'  />
      <Space count='5' />多巴胺顺利泵完，遵医嘱予0.9%氯化钠注射液50ml+多巴胺80mg+间羟胺40mg,以10ml/h微量泵泵入。  
    </Paragraph>
  </Cell>
  <Cell eCfg='80' gridHeight='0.800' gridBorderWidth='0.026' gridBorderStyle='1' width='1.418' borderWidth='0.026' borderStyle='1'>
    <Paragraph xCfg='10' leftIndent='0.020' rightIndent='0.020'  lineSpaceValue='1.500'>
      <Font size='0.317' fontName='宋体'  />李斌</Paragraph>
  </Cell>
</Row>

</Table>`;
