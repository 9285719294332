<template>
  <div>
    <el-form ref="form" label-width="60px" size="mini">
      <el-divider content-position="left">属性</el-divider>
      <el-row>
        <el-col :span="12">
          <el-form-item label="ID">
            <el-input v-model="gridData.id"></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="名称">
            <el-input v-model="gridData.name"></el-input>
          </el-form-item>
        </el-col>
      </el-row>
      <el-divider content-position="left">配置</el-divider>
      <el-row>
        <el-col :span="12">
          <el-form-item label="宽度">
            <el-input-number v-model="gridData.width" controls-position="right" :step="0.05" :min="0.01" :max="100" :precision="2"></el-input-number>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="高度">
            <el-input-number v-model="gridData.height" controls-position="right" :step="0.05" :min="0.01" :max="100" :precision="2"></el-input-number>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="8">
          <el-form-item>
            <el-checkbox v-model="gridData.lockedFormat">锁定表格</el-checkbox>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-button size="mini" @click="openCheekWindow">边框和底纹</el-button>
        </el-col>
      </el-row>
      <el-divider content-position="left">对齐方式</el-divider>
      <el-row>
        <el-col :span="16">
          <section class="align-field">
            <article :class="choseAlingle(alignModel.Left)" @click="clickAlignBox(alignModel.Left)">
              <div class="font-icon">
                <!-- <span class="material-icons">border_all</span>
                <span class="material-icons last"> sort </span> -->
                <img src="@/assets/images/tableSet/TableLeftAlign.jpg" alt="" />
              </div>
              <span>左对齐</span>
            </article>
            <article :class="choseAlingle(alignModel.Center)" @click="clickAlignBox(alignModel.Center)">
              <div class="font-icon">
                <!-- <span class="material-icons">border_all</span>
                <span class="material-icons last"> filter_list </span> -->
                <img src="@/assets/images/tableSet/TableMiddleAlign.jpg" alt="" />
              </div>
              <span>居中对齐</span>
            </article>
            <article :class="choseAlingle(alignModel.Right)" @click="clickAlignBox(alignModel.Right)">
              <div class="font-icon">
                <!-- <span class="material-icons">border_all</span>
                <span class="material-icons last"> segment </span> -->
                <img src="@/assets/images/tableSet/TableRightAlign.jpg" alt="" />
              </div>
              <span>右对齐</span>
            </article>
          </section>
        </el-col>
        <el-col :span="8">
          <span class="right-indent">左缩进</span>
          <el-input-number v-model="gridData.leftIndent" controls-position="right" size="mini" :step="0.05" :min="0.01" :max="100" :precision="2"></el-input-number>
        </el-col>
      </el-row>
      <el-divider content-position="left">单元格默认距离</el-divider>
      <el-row>
        <el-col :span="12">
          <el-form-item label="上边距">
            <el-input-number v-model="gridData.topPadding" controls-position="right" :step="0.05" :min="0.01" :max="100" :precision="2"></el-input-number>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="下边距">
            <el-input-number v-model="gridData.bottomPadding" controls-position="right" :step="0.05" :min="0.01" :max="100" :precision="2"></el-input-number>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="12">
          <el-form-item label="左边距">
            <el-input-number v-model="gridData.leftPadding" controls-position="right" :step="0.05" :min="0.01" :max="100" :precision="2"></el-input-number>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="右边距">
            <el-input-number v-model="gridData.rightPadding" controls-position="right" :step="0.05" :min="0.01" :max="100" :precision="2"></el-input-number>
          </el-form-item>
        </el-col>
      </el-row>
    </el-form>
    <VacantDialog title="边框和底纹" width="30%" ref="borderTexture" @confirm="submitBorderSet">
      <border-set :borderData="gridData.border" ref="borderConfigDom"></border-set>
    </VacantDialog>
  </div>
</template>

<script>
import BorderSet from './border.vue';
import { E_ALIGN_HORIZONTAL_MODE, E_EVENT_KEY } from '@/components/editor/ThinkEditor.Defined';

export default {
  components: { BorderSet },
  props: {
    gridData: {
      type: Object,
      default: () => ({})
    }
  },
  data() {
    return {
      thinkEditor: undefined,
      alignModel: E_ALIGN_HORIZONTAL_MODE,
      alignModeId: null
    };
  },
  watch: {
    gridData: {
      handler(newVal) {
        this.alignModeId = newVal.alignHorizontalMode;
      },
      immediate: true
    }
  },
  created() {
    editorsInstanceManager.addEventListener(E_EVENT_KEY.editorFocus, this.OnDocEditorFocusChange);

    let currentEditorsInstance = editorsInstanceManager.GetSelectedEditorsInstance();
    this.thinkEditor = currentEditorsInstance?.GetSelectedEditor();
  },
  methods: {
    OnDocEditorFocusChange(e) {
      this.thinkEditor = e.data.editor;
    },
    choseAlingle(type) {
      if (this.alignModeId === type) {
        return 'active';
      } else {
        return '';
      }
    },
    clickAlignBox(type) {
      this.alignModeId = type;
      this.gridData.alignHorizontalMode = type;
    },
    openCheekWindow() {
      this.$refs.borderTexture.openDialog();
    },
    submitBorderSet() {
      const resBool = this.thinkEditor.SetTableConfig('', this.gridData);
      console.log('边框提交结果--->', resBool);
      this.$refs.borderTexture.closeDialog();
    }
  }
};
</script>

<style lang="scss" scoped>
.align-field {
  display: flex;
  article {
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    cursor: pointer;
    &.active {
      .font-icon {
        border-color: #43d6ca;
        border-width: 2px;
      }
      & > span {
        color: #43d6ca;
      }
    }
    .font-icon {
      width: 30%;
      margin-bottom: 6px;
      padding: 2px 8px;
      border: 1px solid #e1e1e1;
      display: flex;
      flex-direction: column;
      align-items: center;
      .last {
        margin-top: -8px;
      }
    }
    .right-indent {
      display: block;
    }
  }
}
</style>
