<template>
  <VacantDialog title="知识库管理" width="600px" ref="thisDialog" :top="'10%'" :footerShow="false" @close="closeWindow">
    <div style="min-height: 400px; max-height: 600px; overflow-y: auto">
      <div class="tab-style">
        <div @click="changeActive(item)" :class="active === item.value ? 'active' : ''" class="tab" v-for="item in tabList" :key="item.value">
          {{ item.label }}
        </div>
      </div>
      <algorithmSet :algorithmsManager="algorithmsManager" @update:algorithmsManager="updateAlgorithmsManager" :configData="configData.algorithm" v-show="active === 2" />
      <optionSet :optionsManager="optionsManager" @update:optionsManager="updateOptionsManager" :configData="configData.option" v-show="active === 1" />
    </div>
  </VacantDialog>
</template>

<script>
import algorithmSet from './component/algorithmSet.vue';
import optionSet from './component/optionSet.vue';
import { E_EVENT_KEY, OptionsManager, AlgorithmsManager } from '@/components/editor/ThinkEditor.Defined';
import { UpdateConfigData } from '@/components/editor/ThinkEditor.Utils';
import { editorsInstanceManager } from '@/components/editorHelper/ThinkEditorInstance.Manager';

export default {
  name: 'index',
  components: { algorithmSet, optionSet },
  data() {
    return {
      thinkEditor: undefined,
      algorithmsManager: new AlgorithmsManager(),
      optionsManager: new OptionsManager(),
      tabList: [
        { value: 1, label: '选项' },
        { value: 2, label: '算法' }
      ],
      active: 1,
      flag: false,
      confirmTxt: '插入',
      configData: { option: {}, algorithm: {} },
      saveDataTable: {},
      saveDataOption: {},
      algoGroup: '',
      algorithmSetData: '',
      optionSetData: '',
      disabled: true
    };
  },
  created() {
    editorsInstanceManager.addEventListener(E_EVENT_KEY.editorFocus, this.OnDocEditorFocusChange);
    let currentEditorsInstance = editorsInstanceManager.GetSelectedEditorsInstance();
    this.thinkEditor = currentEditorsInstance?.GetSelectedEditor();
  },
  methods: {
    OnDocEditorFocusChange(e) {
      this.thinkEditor = e.data.editor;
    },
    changeTable(val) {
      this.saveData = val;
    },
    updateOptionsManager(newOptionsManager) {
      this.optionsManager = newOptionsManager;
    },
    updateAlgorithmsManager(newAlgorithmsManager) {
      this.algorithmsManager = newAlgorithmsManager;
    },

    /**
     * 切换表头
     * @param item
     */
    changeActive(item) {
      this.active = item.value;
    },
    openDialog(configData, optionsManager, algorithmsManager) {
      UpdateConfigData(this, configData);
      this.algorithmsManager = algorithmsManager;
      this.optionsManager = optionsManager;
      this.$refs.thisDialog.openDialog();
    },

    closeDialog() {
      this.$refs.thisDialog.closeDialog();
      this.thinkEditor.SetInputFocus(true);
    },
    onCancle() {
      this.closeDialog();
    },
    closeWindow() {
      //窗口点X时，关闭窗口 并移除无效数据
      this.optionsManager.RemoveInvalidData();
      this.algorithmsManager.RemoveInvalidData();
    }
  }
};
</script>

<style scoped lang="scss">
::v-deep .el-input--medium .el-input__inner {
  height: 60px;
}

::v-deep .el-select-dropdown__item {
  height: 60px !important;
  line-height: 60px;
  border-bottom: 2px solid #ccc;
  margin: 0 10px;
}

::v-deep .el-form-item {
  margin-bottom: -8px !important;
}

.tab-style {
  display: flex;
  align-items: center;
  justify-content: flex-start;

  .tab {
    padding: 6px 10px;
    border: 1px solid #ccc;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    margin-right: 1px;
    background: #e0e0e0;
    cursor: pointer;
  }

  .active {
    background: #61a3e6;
  }
}
</style>
