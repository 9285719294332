<template>
  <div :style="pos" class="content">
    <div v-for="(item, index) in infoTips" :key="index">
      <div>
        {{ item.text }}
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'index',
  props: {
    configData: {
      type: Object,
      default: () => {}
    }
  },
  data() {
    return {
      pos: {
        top: '1px',
        left: '1px'
      },
      infoTips: []
    };
  },
  watch: {
    configData: {
      handler(val) {
        this.pos.top = val.inView.posY + 10 + 'px';
        this.pos.left = val.inView.posX + 10 + 'px';
        this.infoTips = val.tips;
      },
      deep: true,
      immediate: true
    }
  },
  methods: {}
};
</script>

<style scoped>
.content {
  position: absolute;
  padding: 6px;
  border: 1px solid #b2b2b2;
  border-radius: 5px;
  max-width: 500px;
  background: #fff;
}
</style>
