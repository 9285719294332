import { GetRandStr } from '@/components/editor/ThinkEditor.Utils';
import { ThinkEditor } from '@/components/editor/ThinkEditor';
import { E_PRINT_SCOPE_MODE, E_VIEW_MODE } from '@/components/editor/ThinkEditor.Defined';

export const DemoCaseHideElementHintPrint = {
  methods: {
    /**
     * @function DoDemoCaseHideElementHintPrint
     * @description 打印 奇数页 or 偶数页
     * @param {}
     * @return {}
     * @example
     */
    async DoDemoCaseHideElementHintPrint() {
      let docName = '空元素不打印';
      let currentTabsView = this.GetFocusTabsView();
      let currentEditorsInstance = currentTabsView.editorsInstance;
      let thinkEditor = currentEditorsInstance.GetEditor(docName);

      if (thinkEditor === undefined) {
        thinkEditor = await currentTabsView.CreateDocEditor(docName);

        var url = '/system/emr/演示/00-演示病例/AdmissionRecord.xml'; //"/system/test/pacsdemo.jpg";
        let response = await fetch(url, {
          credentials: 'same-origin'
        });
        let xml = await response.arrayBuffer();

        thinkEditor.CreateDoc(docName); //@20220722 必需，才能设置编辑者信息
        thinkEditor.SelectDoc(docName);
        thinkEditor.SetEditorInfo('pc', 'zltest', 'zl', 1, '');
        thinkEditor.ParseDocs(docName, '', xml, {});

        thinkEditor.SetViewMode(E_VIEW_MODE.Edit, {});
      }

      //UI 中显示该文档
      currentTabsView.SelectDocEditor(docName);

      //打印
      await thinkEditor.PrintDoc({
        printMode: 1, // 0：视图打印 1：矢量打印
        view: {
          mode: E_VIEW_MODE.Print,
          hideElementHint: true
        }
      });
    }
  }
};
