<template>
  <el-popover placement="right" ref="RefRadioBox" trigger="click">
    <div>
      <div style="max-height: 400px; overflow-y: auto">
        <div v-for="(item, index) in itemList" :key="item.value" :class="index === itemList.length - 1 ? '' : 'borderStyle'">
          <el-radio-group v-model="activeItems[item.value]" @input="commitOne">
            <div v-for="ite in item.items" :key="ite.id">
              <el-radio style="margin-top: 5px" :label="ite.id" @click.native.prevent="elradioClick(ite.id)">{{ ite.id }}</el-radio>
            </div>
          </el-radio-group>
        </div>
      </div>
      <div v-if="!groupExclusion">
        <el-button @click="commit(1)" style="margin-left: 10px; margin-top: 5px" size="small">确定</el-button>
        <el-button @click="close" style="margin-left: 10px" size="small">取消</el-button>
      </div>
    </div>
    <div slot="reference" :style="pos" class="content"></div>
  </el-popover>
</template>

<script>
import { E_INPUT_MODE } from '@/components/editor/ThinkEditor.Defined';

export default {
  name: 'index',
  props: {
    configData: {
      type: Object,
      default: {}
    }
  },
  data() {
    return {
      itemList: [],
      activeItems: {},
      activeItemsText: [],
      visible: false,
      groupExclusion: false,
      pos: {
        top: '1px',
        left: '1px'
      }
    };
  },
  watch: {
    configData: {
      handler(data) {
        this.visible = false;
        if (data.inputHandle.inputMode == E_INPUT_MODE.SelectOption /*2*/ && data.inputHandle.multipleChoice != 1) {
          this.pos.top = data.inView.posY + 10 + 'px';
          this.pos.left = data.inView.posX + 10 + 'px';
          this.groupExclusion = data.inputHandle.groupExclusion;
          this.activeItemsText = data.inputHandle.checked;
          this.InitItemList(data.inputHandle.items);
          this.$nextTick(() => {
            setTimeout(() => {
              this.$parent.$parent.$children[0].$children[18].$refs.RefRadioBox.doShow();
            }, 100);
          });
        } else {
          this.close();
        }
      },
      deep: true,
      immediate: true
    }
  },
  methods: {
    elradioClick(textName) {
      //解决单选框点击相同内容没有隐藏问题
      if (this.groupExclusion) {
        this.$emit('action:setValue', textName);
        this.close();
      }
    },
    /*
this.activeItemsText ["学生"] 是所有选中的项
this.itemList[] 成员{value:1,items:[{content:(...) group: (...)id: (...)weight: }]} => {value:组号,items:[]}
this.activeItems{} 结构为 {1:["学生"]} 即{group:[本组选择项]}
*/
    InitItemList(checkList) {
      let group = [];
      this.itemList = [];
      this.activeItems = {};
      checkList.forEach(item => {
        if (group.indexOf(item.group) === -1) {
          group.push(item.group);
        }
      });
      group.forEach(item => {
        this.itemList.push({ value: item, items: [] });
        this.$set(this.activeItems, item, []);
      });
      if (this.itemList.length === 1) {
        this.groupExclusion = true;
      }
      this.itemList.forEach(item => {
        checkList.forEach(ite => {
          if (item.value === ite.group) {
            item.items.push(ite);
          }
        });
      });
      this.itemList.forEach(item => {
        item.items.forEach(ite => {
          if (this.activeItemsText.indexOf(ite.id) !== -1) {
            this.activeItems[item.value] = ite.id;
          }
        });
      });
    },
    /**
     * 确定
     */
    commit() {
      let value = undefined;
      for (let key in this.activeItems) {
        if (!value) {
          value = this.activeItems[key];
        } else {
          value += ';' + this.activeItems[key];
        }
      }
      this.$emit('action:setValue', value);
      this.close();
    },
    commitOne(val) {
      if (this.groupExclusion) {
        this.$emit('action:setValue', val);
        this.close();
      }
    },
    close() {
      this.visible = false;
      this.$emit('action:hide', 'radioBox');
    }
  }
};
</script>

<style scoped lang="scss">
.content {
  position: absolute;
  //padding: 6px;
  border: 1px solid #8d8d8d;
  border-radius: 5px;
  background: #fff;
  //max-height: 500px;
  overflow-y: auto;
}

::v-deep .el-radio .el-radio__inner {
  width: 18px;
  height: 18px;
}

::v-deep .el-radio .el-radio__label {
  font-size: 16px;
}

.borderStyle {
  border-bottom: 1px solid #8d8d8d;
  min-width: 150px;
}
</style>
<style>
.popper__arrow {
  display: none !important;
}
</style>
