<template>
  <el-main>
    <div class="treeborder_right" @click="createEditorInstanceFocusChangeEvent">
      <!--treeborder_right是依赖内部大小的，所以预设1个空间 指定长宽尺寸-->
      <div @contextmenu.prevent="handleContextMenu" class="main-box">
        <el-tabs style="width: 100%; height: 100%" :closable="true" v-model="selectedDocName" type="card" @tab-click="tabSelected" @tab-remove="tabRemove" @tab-add="tabAdd">
          <el-tab-pane style="width: 100%; height: 100%" :label="item.showName" :name="item.docName" :key="item.docName" v-for="(item, index) in this.bindEditors">
            <!-- <component :is="ThinkEditorView" :thinkEditor="item" @dispatchEditorViewEvent="OnEditorViewEvent" /> -->
            <ThinkEditorView :thinkEditor="item" @dispatchEditorViewEvent="OnEditorViewEvent" />
          </el-tab-pane>
        </el-tabs>
        <RightClick />
      </div>

      <inputHandleNumberBox :configData="inputHandleConfigData" v-if="showInputHandleNumberBox" @action:setValue="OnInputHandleSetValue" @action:hide="OnHideInputHandleSelector" />
      <inputHandleCheckBox :configData="inputHandleConfigData" v-if="showInputHandleCheckBox" @action:setValue="OnInputHandleSetItems" @action:hide="OnHideInputHandleSelector" />

      <inputHandleRadioBox :configData="inputHandleConfigData" v-if="showInputHandleRadioBox" @action:setValue="OnInputHandleSetItems" @action:hide="OnHideInputHandleSelector" />
      <!--      日期时间选择-->
      <inputHandleDateTimeBox :configData="inputHandleConfigData" v-if="showInputHandleDateTimeBox" @action:setValue="OnInputHandleSetValue" @action:hide="OnHideInputHandleSelector" ref="inputHandleDateTimeBox" />
      <!--      仅选择时间-->
      <inputHandleTimeBox :configData="inputHandleConfigData" v-if="showInputHandleTimeBox" @action:setValue="OnInputHandleSetValue" @action:hide="OnHideInputHandleSelector" />

      <!--            信息提示框-->
      <infoTipsFloater :configData="infoTipsConfigData" v-show="showInfoTipsFloater" />
    </div>

    <!--            插入自定义表格-->
    <insertTablePicker ref="insertTablePicker" id="TablePicker" v-show="showInsertTablePicker" :pos="mouseClickPos" :row="9" :col="16" @action:setValue="OnInsertTable" />

    <!--      插入二维码-->
    <QRCodeBox ref="QRCodeBox" />

    <!--      插入选框-->
    <checkBoxSetBox ref="checkBoxSetBox" />

    <!--    插入页码-->
    <pageNumBox ref="pageNumBox" />

    <!--    替换和查找-->
    <findReplaceBox ref="findReplaceBox" />

    <!--    知识库管理-->
    <knowledgeBox ref="knowledgeBox" />

    <!--    图像属性-->
    <imageSetBox ref="imageSetBox" />
    <!--    页面设置-->
    <pageSetBox ref="pageSetBox" />

    <!--    插入公式-->
    <formulaBox ref="formulaBox" />

    <!-- 元素设置窗口 -->

    <elementSetBox ref="elementSetBox" />

    <!--    恒牙牙位选择弹窗-->
    <permanentTeethPosBox ref="permanentTeethPosBox" />
    <!--    乳牙牙位选择框-->
    <deciduousTeethPosBox ref="deciduousTeethPosBox" />

    <!-- 段落窗口 -->

    <paragraphSetBox ref="paragraphSetBox" />

    <fontSetBox ref="fontSetBox" />

    <tableSetBox ref="tableSetBox" />
  </el-main>
</template>

<script>
import { EventBus } from '@/utils/event-bus';
import RightClick from '@/components/RightClick.vue';
import PopupFrom from '@/components/PopupFrom.vue';
import PFrom from '@/components/PFrom.vue';
import inputHandleNumberBox from '@/view/inputHandleNumberBox/index.vue';
import inputHandleCheckBox from '@/view/inputHandleCheckBox/index.vue';
import inputHandleRadioBox from '@/view/inputHandleRadioBox/index.vue';
import inputHandleDateTimeBox from '@/view/inputHandleDateTimeBox/index.vue';
import inputHandleTimeBox from '@/view/inputHandleTimeBox/index.vue';
import fontSetBox from '@/view/fontSetBox/index.vue';
import elementSetBox from '@/view/elementSetBox/index.vue';
import paragraphSetBox from '@/view/paragraphSetBox/index.vue';
import infoTipsFloater from '@/view/infoTipsFloater/index.vue';
import insertTablePicker from '@/view/insertTablePicker/index.vue';

import formulaBox from '@/view/formulaBox/index.vue';
import HeaderNavigate from '@/components/HeaderNavigate/index.vue';
import tableSetBox from '@/view/tableSetBox/index';
import permanentTeethPosBox from '@/view/permanentTeethPosBox/index';
import deciduousTeethPosBox from '@/view/deciduousTeethPosBox/index';
import QRCodeBox from '@/view/QRCodeBox/index';
import checkBoxSetBox from '@/view/checkBoxSetBox/index';
import pageNumBox from '@/view/pageNumBox/index';
import findReplaceBox from '@/view/findReplaceBox/index';
import knowledgeBox from '@/view/knowledgeBox/index';
import imageSetBox from '@/view/imageSetBox/index';
import pageSetBox from '@/view/pageSetBox/index';
import ThinkEditorView from './ThinkEditorView.vue';

import { ThinkEditor } from '@/components/editor/ThinkEditor';
import { ThinkEditorInstance } from '@/components/editor/ThinkEditor.Instance';
import { shareDataManager, E_PASTE_TYPE, E_TABLE_DELETE_MODE, E_TABLE_INSERT_MODE, E_CELL_OPT_MODE, E_INSTANCE_EVENT_KEY, E_EVENT_KEY, E_EVENT_HANDLE, E_SYSTEM_AUTH_MODE, E_VIEW_MODE, E_LAYOUT_VERTICAL_ALIGN, E_FORMULA_STYLE, E_DOC_TYPE, E_DISPLAY_MODE, E_SET_MODE, E_INPUT_MODE, E_TIME_TYPE } from '@/components/editor/ThinkEditor.Defined';
import { editorsInstanceManager } from '@/components/editorHelper/ThinkEditorInstance.Manager';
import { Alert } from 'element-ui';
import { RGBA2Color, GetRandStr, CalcDocName } from '@/components/editor/ThinkEditor.Utils';
import { optionsManager, algorithmsManager, editorLogger, ThinkEditorEventData, ThinkEditorEvent } from '@/components/editor/ThinkEditor.Defined';
import { TabsViewUserDefined } from './EditorsTabsView.UserDefined';
import { provinceCityAreaHelper } from '@/components/editorHelper/ProvinceCityAreaHelper';

export default {
  mixins: [TabsViewUserDefined],
  props: { instanceId: String },
  components: {
    ThinkEditorView,
    RightClick,
    PopupFrom,
    PFrom,
    inputHandleNumberBox,
    inputHandleCheckBox,
    inputHandleRadioBox,
    inputHandleDateTimeBox,
    inputHandleTimeBox,

    HeaderNavigate,
    fontSetBox,
    elementSetBox,
    paragraphSetBox,
    infoTipsFloater,
    insertTablePicker,

    tableSetBox,
    formulaBox,
    permanentTeethPosBox,
    deciduousTeethPosBox,
    QRCodeBox,
    checkBoxSetBox,
    pageNumBox,
    findReplaceBox,
    knowledgeBox,
    imageSetBox,
    pageSetBox,
    ThinkEditor
  },
  data() {
    return {
      editorsInstance: undefined,
      bindEditors: [],
      thinkEditor: undefined,
      selectedDocName: undefined,
      inputHandleConfigData: undefined,
      //eyeCareState: 0,
      //strictEditState: 0,
      showInputHandleCheckBox: false, //复选框数据
      showInputHandleRadioBox: false, //单选框数据
      showInputHandleNumberBox: false,

      showInputHandleDateTimeBox: false,
      showInputHandleTimeBox: false,
      //日期选框
      showTimeBox: false,
      dataType: null,
      showTimePickerBox: false,
      styleBoxTime: {
        top: '',
        left: ''
      },
      //信息提示框
      showInfoTipsFloater: false,
      mouseClickPos: { top: '1px', left: '1px' },
      infoTipsConfigData: { inView: { posX: 0, posY: 0 }, tips: [] },

      //插入表格
      showInsertTablePicker: false,
      rightMenuContent: [],
      flag: true
    };
  },
  created() {
    provinceCityAreaHelper.InitAddressInfos();
    this.editorsInstance = new ThinkEditorInstance(this.instanceId);
    this.bindEditors = this.editorsInstance.editors;
    this.editorsInstance.addEventListener(E_EVENT_KEY.instanceChange, this.OnInstanceChange);
    this.editorsInstance.addEventListener(E_EVENT_KEY.editorFocus, this.OnDocEditorFocusChange);
    editorsInstanceManager.AddEditorsInstance(this.editorsInstance);
    this.thinkEditor = this.GetSelectedEditor();
  },
  async mounted() {
    //console.log('my instanceId:' + this.instanceId);
    //this.editorsInstance = new ThinkEditorInstance(this.instanceId);
    //editorsInstanceManager.AddEditorsInstance(this.editorsInstance);
    document.addEventListener('mouseup', this.OnMouseUp);
    document.addEventListener('mousedown', this.OnMouseDown);
    document.addEventListener('mouseup', e => this.OnClickOutsideHideDialog(e));
  },
  beforeDestroy() {
    document.removeEventListener('mouseup', this.OnMouseUp);
    document.removeEventListener('mousedown', this.OnMouseDown);
    document.removeEventListener('mousedown', this.OnClickOutsideHideDialog);
  },
  methods: {
    OnClickOutsideHideDialog(e) {
      if (!this.flag) {
        return;
      }
      this.flag = false;
      const mask = document.getElementById('TablePicker');
      if (mask && !mask.contains(e.target)) {
        this.showInsertTablePicker = false;
      }
      setTimeout(() => {
        this.flag = true;
      }, 300);
    },
    OnInputHandleSetValue(val) {
      this.thinkEditor.SetElementContent('', val);
    },
    OnInputHandleSetItems(val) {
      let inputHandle = this.inputHandleConfigData.inputHandle;
      let optionIdObj = optionsManager.GetOption(inputHandle.optionClass, inputHandle.optionId);
      if (optionIdObj !== undefined) {
        var setOptionIds = new Array();
        setOptionIds.push(optionIdObj);
        //更新一次文档内的选项Items,防止不同步的情况
        this.thinkEditor.SetOptions(setOptionIds);
      }
      this.thinkEditor.SetElementSelectItems('', val);
    },
    OnHideInputHandleSelector(selectorType) {
      if (selectorType == 'checkBox') {
        this.showInputHandleCheckBox = false;
      } else if (selectorType == 'radioBox') {
        this.showInputHandleRadioBox = false;
      } else if (selectorType == 'numberBox') {
        this.showInputHandleNumberBox = false;
      } else if (selectorType == 'dateTimeBox') {
        //console.warn('hide 1 DateTimeBox');
        this.showInputHandleDateTimeBox = false;
      } else if (selectorType == 'timeBox') {
        //console.warn('hide 1 TimeBox');
        this.showInputHandletimeBox = false;
      }
    },
    OnInstanceChange(e) {
      //触发vue2 element-ui tabs组件及时重绘
      this.bindEditors = [];
      this.bindEditors = this.editorsInstance.editors;
    },
    OnDocEditorFocusChange(e) {
      this.thinkEditor = e.data.editor;
    },
    async OnEditorViewEvent(e) {
      let type = e.data.type;
      let data = e.data.data;
      switch (type) {
        case E_EVENT_KEY.createdThinkEditorView: {
          break;
        }

        default:
          break;
      }
    },
    tabAdd(docName) {
      this.CreateDocEditor(docName);
    },
    tabRemove(docName) {
      //this.selectedDocName = docName; //fixed ui框架没有及时更新bug
      this.CloseDocEditor(docName);
    },
    tabSelected(tab) {
      this.SelectDocEditor(tab.name);
    },
    //加载字体，涉及cache,由ThinkEditor.js来做
    //加载wasm，涉及cache,由ThinkEditor.js来做
    //OnRequestFont: OnRequestFont,
    // setElemetnContentListener: setElemetnContentListener,
    GetSelectedEditor() {
      return this.editorsInstance.GetSelectedEditor();
    },
    /*********************************
     * 编辑器实例 文档相关接口封装
     * ********************************/
    /**
     * @function 创建1个 文档编辑对象 ThinkEditor
     * @description 用于打开1个文档时调用
     * @param {String} docName - 文档名,不能为空。创建后docName不可更改，可以动态更改showName
     * @return {DocEditor} DocEditor - 文档编辑器信息
     * @example
     *   CreateDocEditor("新文档名")
     */

    async CreateDocEditor(docName) {
      //this.editorsInstance.CloseAllEditors();
      docName = CalcDocName(docName);
      let existEditor = this.editorsInstance.GetEditor(docName);
      if (existEditor !== undefined) {
        console.warn('无法创建：存在同名文档编辑器！docName:', docName);
        return undefined;
      }

      let thinkEditorParam = {
        lib: '/editor/',
        fontPath: '/editor_fonts/',
        docName: docName,
        useInnerUI: true,
        useInnerMenuBox: false,
        auth: { mode: E_SYSTEM_AUTH_MODE.Demonstration }
      };
      var thinkEditor = new ThinkEditor(thinkEditorParam);
      /**************************
       * 用户可以不使用DocEditor，可结合自己的应用系统 直接在thinkEditor上附加文档信息、用户信息等业务信息
       *示例：
       thinkEditor.userDefined = {
        docName:'文档名',
        docShowName:'文档UI tab显示名',
        docNo:'文档在业务系统标识',
        userNo:'用户、患者标识'
       };
       * ****************************/

      this.addInstanceEventListener(thinkEditor); // 初始化事件
      //await this.editorsInstance.createCreateDocEditorEvent(thinkEditor);
      await thinkEditor.Init();

      this.editorsInstance.AddEditor(thinkEditor);
      console.log('CreateDocEditor:', this.editorsInstance.editors);

      return thinkEditor;
    },
    /**
     * @function 创建1个 空文档
     * @description
     * @param {String} docName - 文档名
     * @return {DocEditor}
     * @example
     *
     */
    async NewDocEditor(docName, docType) {
      if (docName === undefined || docName === '') {
        docName = '新文档' + GetRandStr();
      }
      let thinkEditor = await this.CreateDocEditor(docName);

      thinkEditor.NewDoc(docName, docType);
      this.UpdateDocsTabsView();
      return thinkEditor;
    },
    SelectDocEditor(docName) {
      this.editorsInstance.SelectEditor(docName);

      this.UpdateDocsTabsView();
    },
    CloseAllDocEditor() {
      while (this.editorsInstance.editors.length > 0) {
        this.CloseDocEditor(this.editorsInstance.editors[0].docName);
      }
    },
    CloseDocEditor(docName) {
      let thinkEditor = this.editorsInstance.GetEditor(docName);
      if (thinkEditor == undefined) {
        console.error('要关闭的文档不存在：', docName);
        return;
      }

      return this.CloseDocEditorByEntity(thinkEditor);
    },
    CloseDocEditorByShowName(showName) {
      let thinkEditor = this.editorsInstance.GetDocEditorByShowName(showName);
      if (thinkEditor == undefined) {
        console.error('要关闭的文档不存在：', showName);
        return;
      }

      return this.CloseDocByDocEntity(thinkEditor);
    },
    CloseDocEditorByEntity(thinkEditor) {
      //this.editorsInstance.createRemoveDocEditorEvent(thinkEditor);
      this.removeInstanceEventListener(thinkEditor);
      this.editorsInstance.CloseEditor(thinkEditor.docName);

      this.UpdateDocsTabsView();
    },

    SelectDocByShowName(showName) {
      let thinkEditor = this.editorsInstance.GetDocEditorByShowName(showName);
      if (thinkEditor == undefined) {
        return;
      }
      this.editorsInstance.SelectDocEditor(thinkEditor.docName);
    },
    addInstanceEventListener(eventTarget) {
      this.addEditorEventListener(eventTarget);
      this.addUIEditorEventListener(eventTarget);
    },
    addUIEditorEventListener(eventTarget) {
      eventTarget.addEventListener('openMenu', this.OnOpenMenu);

      eventTarget.addEventListener('closeMenu', this.OnCloseMenu);

      eventTarget.addEventListener('openParagraphSetWindow', this.OnOpenParagraphSetWindow);
      eventTarget.addEventListener('closeParagraphSetWindow', this.OnCloseParagraphSetWindow);

      /*****************TE 固定写法*******************/
      /**
       * 输入框打开关闭
       */
      eventTarget.addEventListener('openInputHandleSelector', this.OnOpenInputHandleSelector);
      eventTarget.addEventListener('openInputHandleSelector', this.OnUserDefineOpenInputHandleSelector);
      eventTarget.addEventListener('closeInputHandleSelector', this.OnCloseInputHandleSelector);
      /**
       * 浮动信息框
       */
      eventTarget.addEventListener('openInfoTips', this.OnOpenInfoTips);
      eventTarget.addEventListener('closeInfoTips', this.OnCloseInfoTips);

      /**
       * 修改公式
       */
      eventTarget.addEventListener('openFormulaSetWindow', this.OnOpenFormulaSetWindow);

      /**
       * 打开牙位选择
       */
      eventTarget.addEventListener('openToothPositionSelector', this.OnOpenToothPositionSelector);
      /**
       * 打开二维码
       */
      eventTarget.addEventListener('openBarCodeSetWindow', this.OnOpenBarCodeSetWindow);

      /**
       * 打开选框
       */
      eventTarget.addEventListener('openCheckBoxSetWindow', this.OnOpenCheckBoxSetWindow);
      /**
       * 插入页码
       */
      eventTarget.addEventListener('openPageNumSetWindow', this.OnOpenPageNumSetWindow);

      /**
       * 打开消息对话框
       */
      eventTarget.addEventListener('openMessageBoxWindow', this.OnOpenMessageBoxWindow);
      /**
       * 关闭消息对话框
       */
      eventTarget.addEventListener('closeMessageBoxWindow', this.OnCloseMessageBoxWindow);

      /**
       * 查找和替换
       */
      eventTarget.addEventListener('openSearchReplaceWindow', this.OnOpenSearchReplaceWindow);

      /**
       * 知识库管理
       */
      eventTarget.addEventListener('openKnowledgeSetWindow', this.OnOpenKnowledgeSetWindow);

      /**
       * 图片设置
       */
      eventTarget.addEventListener('openImageSetWindow', this.OnOpenImageSetWindow);

      /**
       * 页面设置
       */
      eventTarget.addEventListener('openPageSetWindow', this.OnOpenPageSetWindow);
      /**
       * 插入表格
       */
      // 监听  字体设置事件
      eventTarget.addEventListener('openFontSetWindow', this.OnOpenFontSetWindow);
      // 字体窗口关闭事件
      eventTarget.addEventListener('closeFontSetWindow', this.OnCloseFontSetWindow);
      // 文档打开操作监听
      //eventTarget.addEventListener('docsViewChange', this.onDocsViewChange);
      // 监听  段落设置事件
      // 段落窗口关闭事件
      // eventTarget.addEventListener('closePageSetWindow', this.closePageSetWindow);
      // 监听  元素设置事件
      eventTarget.addEventListener('openElementSetWindow', this.OnOpenElementSetWindow);

      eventTarget.addEventListener('closeElementSetWindow', this.OnCloseElementSetWindow);

      eventTarget.addEventListener('openTimeAxisSetWindow', this.OnOpenTimeAxisSetWindow);

      eventTarget.addEventListener('closeTimeAxisSetWindow', this.OnCloseTimeAxisSetWindow);

      // 表格打开事件
      eventTarget.addEventListener('openTableSetWindow', this.OnOpenTableSetWindow);
      eventTarget.addEventListener('closeTableSetWindow', this.OnCloseTableSetWindow);
    },
    removeInstanceEventListener(thinkEditor) {
      this.removeEditorEventListener(thinkEditor);
      this.removeUIEditorEventListener(thinkEditor);
    },
    removeUIEditorEventListener(thinkEditor) {
      thinkEditor.removeEventListener('openMenu', this.OnOpenMenu);

      thinkEditor.removeEventListener('closeMenu', this.OnCloseMenu);

      thinkEditor.removeEventListener('openParagraphSetWindow', this.OnOpenParagraphSetWindow);
      thinkEditor.removeEventListener('closeParagraphSetWindow', this.OnCloseParagraphSetWindow);

      /*****************TE 固定写法*******************/
      /**
       * 输入框打开关闭
       */
      thinkEditor.removeEventListener('openInputHandleSelector', this.OnOpenInputHandleSelector);
      thinkEditor.removeEventListener('closeInputHandleSelector', this.OnCloseInputHandleSelector);
      /**
       * 浮动信息框
       */
      thinkEditor.removeEventListener('openInfoTips', this.OnOpenInfoTips);
      thinkEditor.removeEventListener('closeInfoTips', this.OnCloseInfoTips);

      /**
       * 修改公式
       */
      thinkEditor.removeEventListener('openFormulaSetWindow', this.OnOpenFormulaSetWindow);

      /**
       * 打开牙位选择
       */
      thinkEditor.removeEventListener('openToothPositionSelector', this.OnOpenToothPositionSelector);
      /**
       * 打开二维码
       */
      thinkEditor.removeEventListener('openBarCodeSetWindow', this.OnOpenBarCodeSetWindow);

      /**
       * 打开选框
       */
      thinkEditor.removeEventListener('openCheckBoxSetWindow', this.OnOpenCheckBoxSetWindow);
      /**
       * 插入页码
       */
      thinkEditor.removeEventListener('openPageNumSetWindow', this.OnOpenPageNumSetWindow);

      /**
       * 打开消息对话框
       */
      thinkEditor.removeEventListener('openMessageBoxWindow', this.OnOpenMessageBoxWindow);
      /**
       * 关闭消息对话框
       */
      thinkEditor.removeEventListener('closeMessageBoxWindow', this.OnCloseMessageBoxWindow);

      /**
       * 查找和替换
       */
      thinkEditor.removeEventListener('openSearchReplaceWindow', this.OnOpenSearchReplaceWindow);

      /**
       * 知识库管理
       */
      thinkEditor.removeEventListener('openKnowledgeSetWindow', this.OnOpenKnowledgeSetWindow);

      /**
       * 图片设置
       */
      thinkEditor.removeEventListener('openImageSetWindow', this.OnOpenImageSetWindow);

      /**
       * 页面设置
       */
      thinkEditor.removeEventListener('openPageSetWindow', this.OnOpenPageSetWindow);
      /**
       * 插入表格
       */
      // 监听  字体设置事件
      thinkEditor.removeEventListener('openFontSetWindow', this.OnOpenFontSetWindow);
      // 字体窗口关闭事件
      thinkEditor.removeEventListener('closeFontSetWindow', this.OnCloseFontSetWindow);
      // 文档打开操作监听
      thinkEditor.removeEventListener('docsViewChange', this.OnDocsViewChange);
      // 监听  段落设置事件
      // 段落窗口关闭事件
      thinkEditor.removeEventListener('closePageSetWindow', this.closePageSetWindow);
      // 监听  元素设置事件
      thinkEditor.removeEventListener('openElementSetWindow', this.OnOpenElementSetWindow);
      thinkEditor.removeEventListener('closeElementSetWindow', this.OnCloseElementSetWindow);
      // 表格打开事件
      thinkEditor.removeEventListener('openTableSetWindow', this.OnOpenTableSetWindow);
      thinkEditor.removeEventListener('closeTableSetWindow', this.OnCloseTableSetWindow);
    },
    OnUserDefineOpenInputHandleSelector(e) {
      const data = e.data.data;

      /******************
       * 根据data中元素信息判断是否 弹出默认输入框
       * ******************/
      if (data.Id != '诊断') {
        //或sourceId、group等 自定义的可标识属性做业务
        //中断  弹出默认辅助输入框
        return;
      }

      //弹出 诊断选择自定义UI
    },
    /**
     * 弹出数字输入框、选择框等
     * @param e
     * @returns {Promise<void>}
     * @constructor
     */
    OnOpenInputHandleSelector(e) {
      const data = e.data.data;

      /******************
       * 根据data中元素信息判断是否 弹出默认输入框
       * ******************/
      //if (data.Id == '诊断') {
      //或sourceId、group等 自定义的可标识属性做业务
      //中断  弹出默认辅助输入框
      //return;
      //}

      this.inputHandleConfigData = data;

      this.showInfoTipsFloater = false;
      this.showInputHandleCheckBox = false; //复选框数据
      this.showInputHandleRadioBox = false; //单选框数据
      this.showInputHandleNumberBox = false;
      this.showInputHandleDateTimeBox = false;
      this.showInputHandleTimeBox = false;

      if (data.inputHandle.inputMode == E_INPUT_MODE.InputNumber /*1*/) {
        this.showInputHandleNumberBox = true;
      } else if (data.inputHandle.inputMode == E_INPUT_MODE.SelectOption /*2*/) {
        let optionIdObj = optionsManager.GetOption(data.inputHandle.optionClass, data.inputHandle.optionId);
        if (optionIdObj.items.length > 0) {
          data.inputHandle.items = optionIdObj.items; //同步UI上的可选项
        }
        if (data.inputHandle.multipleChoice == true) {
          this.showInputHandleCheckBox = true;
        } else {
          this.showInputHandleRadioBox = false;
          this.$nextTick(() => {
            this.showInputHandleRadioBox = true;
          });
        }
      } else if (data.inputHandle.inputMode == E_INPUT_MODE.SelectDateTime) {
        let timeType = data.inputHandle.timeType;
        if (timeType == E_TIME_TYPE.Date || timeType == E_TIME_TYPE.DateTime || timeType == E_TIME_TYPE.DateTimeNoSecond) {
          console.warn('show 1 DateTimeBox');
          this.showInputHandleDateTimeBox = true;
          //this.$refs.inputHandleDateTimeBox.show(data);
        } else {
          console.warn('show 1 TimeBox');

          this.$nextTick(() => {
            setTimeout(() => {
              this.showInputHandleTimeBox = true;
            }, 100);
          });
        }
      }
    },
    /**
     * 关闭弹窗
     * @constructor
     */
    OnCloseInputHandleSelector() {
      this.showInputHandleCheckBox = false; //复选框数据
      this.showInputHandleRadioBox = false; //单选框数据
      this.showInputHandleNumberBox = false;
      console.warn('hide 2 Date/TimeBox');
      this.showInputHandleDateTimeBox = false;
      this.showInputHandleTimeBox = false;
    },

    /**
     *获取点击位置
     * @param e
     * @constructor
     */
    OnMouseUp(e) {
      this.showInfoTipsFloater = false;
    },
    /**
     *获取点击位置
     * @param e
     * @constructor
     */
    OnMouseDown(e) {
      this.showInfoTipsFloater = false;
      if (!this.showInsertTablePicker) {
        this.mouseClickPos.top = e.pageY + 'px';
        this.mouseClickPos.left = e.pageX + 'px';
      }
    },
    async closeInputHandle() {
      /* 设计原则：
      弹出UI始终依赖Editor.openInputHandleSelector事件
      UI或代码状态辅助输入，始终调用接口Editor.DisplayInputHandleSelector(Hide)=>Editor产生closeInputHandleSelector事件->UI关闭InputHandle(main.vue CloseInput())

      */
      this.thinkEditor.DisplayInputHandleSelector(E_DISPLAY_MODE.Hide);
    },

    /**
     * 打开浮动信息框
     * @constructor
     */
    OnOpenInfoTips(e) {
      const data = e.data.data;
      this.infoTipsConfigData = data;

      if (!this.showNumberBox && !this.showRadioBox && !this.showInputHandleCheckBox && !this.showTimePickerBox && !this.showTimeBox) {
        this.showInfoTipsFloater = true;
      }
    },

    /**
     * 关闭浮动信息框
     * @param e
     * @constructor
     */
    OnCloseInfoTips(e) {
      this.showInfoTipsFloater = false;
    },

    /**
     * 节流
     * @param func
     * @param delay
     * @returns {(function(): void)|*}
     */
    throttle(func, delay = 0) {
      var timer = null;
      return function () {
        var context = this;
        var args = arguments;
        if (!timer) {
          timer = setTimeout(function () {
            func.apply(context, args);
            timer = null;
          }, delay);
        }
      };
    },

    /**
     * 打开牙位选择
     * @param e
     * @constructor
     */
    OnOpenToothPositionSelector(e) {
      let configData = e.data.data;
      if (configData.style === 8) {
        this.$refs.permanentTeethPosBox.openDialog(configData);
      } else {
        this.$refs.deciduousTeethPosBox.openDialog(configData);
      }
      this.showInfoTipsFloater = false;
    },

    /**
     * 打开二维码弹窗
     * @param e
     * @constructor
     */
    OnOpenBarCodeSetWindow(e) {
      let configData = e.data.data;
      this.$nextTick(() => {
        this.$refs.QRCodeBox.openDialog(configData);
      });
    },

    /**
     * 打开页面弹窗
     * @param e
     * @constructor
     */
    OnOpenPageNumSetWindow(e) {
      this.$refs.pageNumBox.openDialog(e.data.data);
    },

    /**
     * 打开消息对话框
     * @constructor
     */
    OnOpenMessageBoxWindow(e) {
      this.$alert(e.data.msg);
    },

    /**
     * 打开查找和替换
     * @constructor
     */
    OnOpenSearchReplaceWindow(e) {
      this.$refs.findReplaceBox.openDialog(e.data.data);
    },

    /**
     * 打开知识库管理
     * @constructor
     */
    OnOpenKnowledgeSetWindow(e) {
      this.$refs.knowledgeBox.openDialog(e.data.data, optionsManager, algorithmsManager);
    },
    /**
     * 打开图片设置
     * @param e
     * @constructor
     */
    OnOpenImageSetWindow(e) {
      this.$refs.imageSetBox.openDialog(e.data.data);
    },

    /**
     * 打开页面设置
     * @param e
     * @constructor
     */
    OnOpenPageSetWindow(e) {
      this.$refs.pageSetBox.openDialog(e.data.data);
    },

    OnPrintDoc(e) {
      console.log(e.data);
    },
    OnSelectRangeChange(e) {
      /**
       * 点击后关闭任何开启的弹窗
       * @type {boolean}
       */
      this.showNumberBox = false;
      this.showRadioBox = false;
      this.showInputHandle = false;
      this.showTimeBox = false;
      this.showTimePickerBox = false;
      this.showInsertTablePicker = false;

      var event = e.data.data;
      if (event.range == true) {
        //为框选状态
        //var xmlStr = this.thinkEditor.GetSelectRangeContent(E_DOC_FORMAT.XML);
        //console.log(xmlStr);
      }
    },
    OnOpenMenu(e) {
      var openMenuEvent = e.data;
      //解析menuEvent，得到需要展示的菜单信息
      var openMenuContent = openMenuEvent.data;
      this.rightMenuContent = openMenuContent.menu;
    },
    handleContextMenu(event) {
      const menu = this.rightMenuContent;
      if (menu.length == 0) {
        return;
      }
      const options = {
        menu,
        event
      };

      this.$rightCtx(options)
        .then(res => {
          switch (res.action) {
            case 'copy':
              this.thinkEditor.Copy();
              break;
            case 'cut':
              this.thinkEditor.Cut();
              break;
            case 'paste':
              this.thinkEditor.Paste(E_PASTE_TYPE.Normal);
              break;
            case 'pasteText':
              this.thinkEditor.Paste(E_PASTE_TYPE.OnlyText);
              break;
            case 'font':
              // 显示字体设置窗口,触发openFontSetWindow事件
              this.thinkEditor.DisplayFontSetWindow(E_DISPLAY_MODE.Show);
              break;
            case 'paragraph':
              // 触发段落设置事件
              this.thinkEditor.DisplayParagraphSetWindow(E_DISPLAY_MODE.Show);
              break;
            case 'element':
              // 触发元素设置事件
              this.thinkEditor.DisplayElementSetWindow(E_DISPLAY_MODE.Show, E_SET_MODE.ModifyCommon);
              break;
            case 'knowledge':
              // 触发知识库监听事件
              this.thinkEditor.DisplayKnowledgeSetWindow(E_DISPLAY_MODE.Show);
              break;
            case 'table':
              // 触发表格设置事件
              this.thinkEditor.DisplayTableSetWindow(E_DISPLAY_MODE.Show);
              break;
            case 'colLeft':
              this.thinkEditor.OnTextEditFocusedCB();
              break;
            case 'formula':
              // 显示公式设置窗口
              this.thinkEditor.DisplayFormulaSetWindow(E_DISPLAY_MODE.Show, E_SET_MODE.ModifyCommon);
              break;
            case 'barCode':
              // 显示二维码设置窗口
              this.thinkEditor.DisplayBarCodeSetWindow(E_DISPLAY_MODE.Show, E_SET_MODE.ModifyCommon);
              break;
            case 'pageNum':
              // 显示页码设置窗口
              this.thinkEditor.DisplayPageNumSetWindow(E_DISPLAY_MODE.Show, E_SET_MODE.ModifyCommon);
              break;
            case 'image':
              this.thinkEditor.DisplayImageSetWindow(E_DISPLAY_MODE.Show, E_SET_MODE.ModifyCommon);
              break;
            case 'imageEdit':
              this.thinkEditor.DisplayImageEditWindow(E_DISPLAY_MODE.Show, E_SET_MODE.ModifyCommon);
              break;
            case 'checkBox':
              // 选框设置
              this.thinkEditor.DisplayCheckBoxSetWindow(E_DISPLAY_MODE.Show, E_SET_MODE.ModifyCommon);
              break;
            case 'deleteCurrentRow':
              this.thinkEditor.TableDeleteOpt(E_TABLE_DELETE_MODE.Row);
              break;
            case 'deleteCurrentCol':
              this.thinkEditor.TableDeleteOpt(E_TABLE_DELETE_MODE.Col);
              break;
            case 'deleteCurrentTable':
              this.thinkEditor.TableDeleteOpt(E_TABLE_DELETE_MODE.Table);
              break;
            case 'insertColLeft':
              this.thinkEditor.TableInsertOpt(E_TABLE_INSERT_MODE.ColLeft);
              break;
            case 'insertColRight':
              this.thinkEditor.TableInsertOpt(E_TABLE_INSERT_MODE.ColRight);
              break;
            case 'insertRowUp':
              this.thinkEditor.TableInsertOpt(E_TABLE_INSERT_MODE.RowUp);
              break;
            case 'insertRowDown':
              this.thinkEditor.TableInsertOpt(E_TABLE_INSERT_MODE.RowDown);
              break;
            case 'mergeCells':
              this.thinkEditor.CellOpt(E_CELL_OPT_MODE.Merge);
              break;
            case 'splitCells':
              this.thinkEditor.CellOpt(E_CELL_OPT_MODE.Split);
              break;
            case 'replyComment':
              this.thinkEditor.InsertReplyComment('', {});
              break;
            case 'deleteReplyComment':
              this.thinkEditor.DeleteReplyComment({});
              break;
            case 'deleteComment':
              this.thinkEditor.DeleteComment({ id: '' });
              break;
            case 'resolveComment':
              this.thinkEditor.SetCommentResolve({ resolve: false });
              break;
          }
        })
        .catch(err => {
          console.log('失败', err);
        });
    },
    OnOpenParagraphSetWindow(e) {
      let eventData = e.data;
      let bodyData = eventData.data;
      this.$refs.paragraphSetBox.openDialog(bodyData);
    },
    OnCloseParagraphSetWindow() {
      this.$refs.paragraphSetBox.closeDialog();
    },
    /**
     * 修改公式
     * @param e
     * @constructor
     */
    OnOpenFormulaSetWindow(e) {
      this.$refs.formulaBox.openDialog(e.data.data);
    },
    /**
     * 打开选框弹窗
     * @param e
     * @constructor
     */
    OnOpenCheckBoxSetWindow(e) {
      this.$refs.checkBoxSetBox.openDialog(e.data.data, optionsManager, algorithmsManager);
    },

    OnOpenElementSetWindow(e) {
      let eventData = e.data;
      let bodyData = eventData.data;
      let thinkEditor = this.editorsInstance.GetDocEditorByEditorId(eventData.editorId);

      //this.$nextTick(() => {
      this.$refs.elementSetBox.openDialog(bodyData, thinkEditor.sourcesManager, window.optionsManager, window.algorithmsManager);
    },

    OnOpenTimeAxisSetWindow(e) {
      let eventData = e.data;
      let bodyData = eventData.data;
      let thinkEditor = this.editorsInstance.GetDocEditorByEditorId(eventData.editorId);
    },
    OnCloseTimeAxisSetWindow(e) {},
    OnCloseElementSetWindow() {
      this.$refs.elementSetBox.closeDialog();
    },
    OnAnnotateFocusChange(e) {
      console.log(e.data);
    },

    OnAlgorithmCalc(e) {
      console.log('OnAlgorithmCalc');
      var data = e.data;
      if ((data.type = 'algorithmCalc' && data.handle == 'request')) {
        //if(data.group == "calc_total")根据算集名进行自定义计算
        var doc_name = data.name;
        var cfg = {};
        cfg.pass_app_result = '2.13';
        //使用"only-fill"告知编辑器，计算已经完成，只进行内容填充显示过程
        //cfg.pass_app_msg = "only-fill";//interrupt、only-fill
        //this.thinkEditor.SetDocConfig(doc_name, cfg);
      }

      return true;
    },

    OnSaveDoc(e) {
      editorLogger.LogEvent(e);
    },
    OnVisibleDocChange(e) {
      editorLogger.LogEvent(e);
    },

    OnSetColor(e) {
      editorLogger.LogEvent(e);
      var data = e.data;
      if (data.handle == 'request') {
        //请求UI颜色
        var init_config = {};
        //init_config.tab_color = "ff00ff00";
        //this.thinkEditor.SetEditorConfig(init_config);
      }
      return true;
    },
    //OnSetElementContentListener(e) {
    //console.log(e);
    //return this.dispatchEvent(e);
    //},
    OnImageResource(e) {
      editorLogger.LogEvent(e);
      var allow = true;
      var data = e.data;
      if (data.handle == 'request') {
        /*
                        应用根据事件中提供的信息，去业务系统的文件系统或数据库中找到对应的数据加载编辑器中使用
                        最终目的是调用SetImageResource设置图片数据供编辑器使用
                    */
        if (data.file == '当前医师电子签名.jpg' && data.md5 == 'a04cfa91f403f799ce07252e85bda12b') {
          //也可以只匹配md5值来找到对应的图片
          var dangqian_yishi = 'xxx';
          var json_cfg = {};
          json_cfg.save_in_xml = true;
          console.log('OnImageResource->SetImageResource:' + data.file);
          var md5 = this.thinkEditor.SetImageResourceBase64('当前医师电子签名.jpg', dangqian_yishi, dangqian_yishi.length, json_cfg);
        } else if (data.file == '审核医师电子签名.jpg' && data.md5 == 'b3aa75a6c69fb17ec3cc4290bbea8a57') {
          var shenhe_yishi = 'xxx';
          var json_cfg = {};
          json_cfg.save_in_xml = true;
          console.log('OnImageResource->SetImageResource:' + data.file);
          var md5 = this.thinkEditor.SetImageResourceBase64('审核医师电子签名.jpg', shenhe_yishi, shenhe_yishi.length, json_cfg);
        }
      }

      return allow;
    },

    closeDocListener(e) {
      editorLogger.LogEvent(e);
      return true;
    },
    parseDocListener(e) {
      var doc_class = e.data.class;
      //console.error('no support parseDocListener');
    },
    getTypeRule(type) {
      var typeRule = this.treeTypes.filter(t => t.type == type)[0];
      return typeRule;
    },
    contextSelected(command) {
      switch (command) {
        case 'Create Basic':
          var node = {
            text: 'New Basic Plan',
            type: 'Basic',
            children: []
          };
          this.selectedNode.addNode(node);
          break;
        case 'Create Top-up':
          var node = {
            text: 'New Top-up',
            type: 'Top-up',
            children: []
          };
          this.selectedNode.addNode(node);
          break;
        case 'Rename':
          this.selectedNode.editName();
          break;
        case 'Remove':
          break;
      }
    },
    // 字体设置窗口打开
    OnOpenFontSetWindow(e) {
      let eventData = e.data;
      let bodyData = eventData.data;
      this.$refs.fontSetBox.openDialog(bodyData); // 打开字体设置窗口
    },
    // 字体设置窗口关闭
    OnCloseFontSetWindow() {
      this.$refs.fontSetBox.closeDialog();
    },
    UpdateDocsTabsView() {
      let editors = this.editorsInstance.editors;
      let selectedEditor = editors.find(x => x.selected);
      this.selectedDocName = selectedEditor ? selectedEditor.docName : '';
    },
    OnFuncClick(item) {
      let thinkEditor = this.thinkEditor;
      switch (item.val) {
        case 'eyeCare': {
          if (thinkEditor.eyeCareState === undefined) {
            thinkEditor.eyeCareState = true;
            thinkEditor.SetBackgroundColor('ffCFE8D2'); //207 232 210
            thinkEditor.SetEditorConfig({ pagesBackColor: RGBA2Color({ a: 1, r: 215, g: 231, b: 218 }) }); //'ffD7E7DA'215 231 218
            //thinkEditor.SetEditorConfig({ pagesBackColor: '#f1f4f6' }); //'ffD7E7DA'215 231 218
          } else {
            thinkEditor.eyeCareState = undefined;
            thinkEditor.SetBackgroundColor(RGBA2Color({ a: 1, r: 255, g: 255, b: 255 }));
            thinkEditor.SetEditorConfig({ pagesBackColor: RGBA2Color({ a: 1, r: 226, g: 235, b: 239 }) }); //'ffB0B0B0'
          }

          break;
        }
        case 'strictEdit': {
          let docProperties = thinkEditor.GetDocProperties();
          if (docProperties.behavior.strictEdit) {
            thinkEditor.SetDocConfig('', { strictEdit: false });
          } else {
            thinkEditor.SetDocConfig('', { strictEdit: true });
          }

          break;
        }
        case 'insertComment':
          {
            let commentId = thinkEditor.InsertComment('', '', { useDefaultStyle: true });
            let docProperties = thinkEditor.GetDocProperties();
            if (docProperties.view.mode != E_VIEW_MODE.Edit || docProperties.comment.displayCommentInfobox != true) {
              this.thinkEditor.SetViewMode(E_VIEW_MODE.Edit, {
                hideElementHint: false,
                displayEditAuxiliary: true,
                displaySelectionEffect: true,
                displayReviseAuxiliary: true,
                displayReviseDel: true,
                displayQCInfobox: true,
                displayCommentInfobox: true, //显示批注信息面板
                displayQCMode: true,
                displayCommentMode: true
              });
            }
          }
          break;
        case 'deleteComment':
          {
            //'' 删除当前选中批注
            thinkEditor.DeleteComment('', {});
          }
          break;
        default:
          console.log(typeItem);
      }
    },

    // 插入工具栏事件
    installToolClick(typeItem) {
      switch (typeItem.val) {
        case 'format':
          // 触发公式监听事件
          this.thinkEditor.DisplayFormulaSetWindow(E_DISPLAY_MODE.Show, E_SET_MODE.InsertCommon);
          // this.$refs.formulaBox.openDialog();
          break;
        case 'element':
          console.log('元素...');
          this.thinkEditor.DisplayElementSetWindow(E_DISPLAY_MODE.Show, E_SET_MODE.InsertCommon);
          break;
        case 'table':
          /**
           * 打开插入表格弹窗
           * @constructor
           */
          this.showInsertTablePicker = true;
          break;
        case 'qrcode':
          /**
           * 打开二维码
           * @constructor
           */
          this.thinkEditor.DisplayBarCodeSetWindow(E_DISPLAY_MODE.Show, E_SET_MODE.InsertCommon);
          break;
        case 'barcode':
          /**
           * 打开条形码
           * @constructor
           */
          this.thinkEditor.DisplayBarCodeSetWindow(E_DISPLAY_MODE.Show, E_SET_MODE.InsertSpecial);
          break;

        case 'selectBox':
          /**
           * 打开选框设置
           * @constructor
           */
          this.thinkEditor.DisplayCheckBoxSetWindow(E_DISPLAY_MODE.Show, E_SET_MODE.InsertCommon);
          break;
        case 'pages':
          /**
           * 打开页码设置
           * @constructor
           */
          this.thinkEditor.DisplayPageNumSetWindow(E_DISPLAY_MODE.Show, E_SET_MODE.InsertCommon);
          break;
        case 'image':
          /**
           * 直接插入图片
           * @constructor
           */
          this.InsertMyImage();
          break;

        default:
          console.log(typeItem);
      }
    },

    /**
     * 插入表格
     * @param row
     * @param col
     * @returns {Promise<void>}
     */
    async OnInsertTable(row, col) {
      await this.thinkEditor.InsertTable({ rowsCount: row, colsCount: col });
      this.showInsertTablePicker = false;
    },

    // 打开表格设置窗口的参数
    OnOpenTableSetWindow(e) {
      let eventData = e.data;
      let bodyData = eventData.data;
      this.$refs.tableSetBox.openDialog(bodyData);
    },
    // 打开表格设置窗口的参数
    OnCloseTableSetWindow(e) {
      this.$refs.tableSetBox.closeDialog();
    },

    async createEditorInstanceFocusChangeEvent() {
      editorsInstanceManager.SelectedEditorsInstance(this.instanceId);
      let selectedEditorsInstance = editorsInstanceManager.GetSelectedEditorsInstance();
      var eventData = new ThinkEditorEventData(E_EVENT_HANDLE.event, E_INSTANCE_EVENT_KEY.editorInstanceFocusChange, { instance: selectedEditorsInstance });
      this.$emit('dispatchEditorInstanceEvent', new ThinkEditorEvent(E_INSTANCE_EVENT_KEY.editorInstanceFocusChange, eventData));
    }
  }
};
</script>

<style lang="scss">
.treeborder_right {
  width: 50%;
  height: 100%;

  .main-box {
    width: 100%;
    height: calc(100% - 60px);
  }
}
.el-tabs__content {
  width: 100%; /* 设置宽度 */
  height: 100%; /* 设置高度 */
  overflow: auto; /* 如果内容超出固定宽高，可以滚动查看 */
}
</style>
