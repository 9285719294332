<template>
  <div>
    <el-tabs v-model="cheekActiveName" type="card">
      <el-tab-pane label="边框" name="cheek">
        <div class="opera-box">
          <div class="left-box">
            <span>设置</span>
            <div class="set-second-box" :class="choseSetAlingle(borderModelOrg.Null)" @click="clickSetAlignBox(borderModelOrg.Null)">
              <img src="@/assets/images/tableSet/BorderStyleNull.jpg" alt="" />
            </div>
            <div class="set-second-box" :class="choseSetAlingle(borderModelOrg.Outer)" @click="clickSetAlignBox(borderModelOrg.Outer)">
              <img src="@/assets/images/tableSet/BorderStyleOuter.jpg" alt="" />
            </div>
            <div class="set-third-box" :class="choseSetAlingle(borderModelOrg.All)" @click="clickSetAlignBox(borderModelOrg.All)">
              <img src="@/assets/images/tableSet/BorderStyleAll.jpg" alt="" />
            </div>
            <div class="set-four-box" :class="choseSetAlingle(borderModelOrg.UserDefined)" @click="clickSetAlignBox(borderModelOrg.UserDefined)">
              <img src="@/assets/images/tableSet/BorderStyleUserDefined.jpg" alt="" />
            </div>
          </div>
          <div class="middle-box">
            <span>线形</span>
            <div class="font-shape">
              <div class="inner-shape" :class="choseSetShape(borderStyleOrg.SOLID)" @click.stop="borderStyleChange(borderStyleOrg.SOLID)">
                <img src="@/assets/images/tableSet/Solid.jpg" alt="" />
              </div>
              <div class="inner-shape" :class="choseSetShape(borderStyleOrg.DOTTED)" @click.stop="borderStyleChange(borderStyleOrg.DOTTED)">
                <img src="@/assets/images/tableSet/Dot.jpg" alt="" />
              </div>
              <div class="inner-shape" :class="choseSetShape(borderStyleOrg.DASHED)" @click.stop="borderStyleChange(borderStyleOrg.DASHED)">
                <img src="@/assets/images/tableSet/Dash.jpg" alt="" />
              </div>
              <div class="inner-shape" :class="choseSetShape(borderStyleOrg.DASHED_O)" @click.stop="borderStyleChange(borderStyleOrg.DASHED_O)">
                <img src="@/assets/images/tableSet/DashDot.jpg" alt="" />
              </div>
              <div class="inner-shape" :class="choseSetShape(borderStyleOrg.DASHED_T)" @click.stop="borderStyleChange(borderStyleOrg.DASHED_T)">
                <img src="@/assets/images/tableSet/DashDotDot.jpg" alt="" />
              </div>
            </div>
          </div>
          <div class="right-box">
            <span>预览</span>
            <div class="preview-field">
              <section class="sect-top">
                <div class="preview-left">
                  <div class="inner">
                    <img src="@/assets/images/tableSet/BorderTop.jpg" alt="" :class="[setBorderBoxActive('top'), disabledBorderIcon('top')]" @click="borderSelected('top')" />
                    <img src="@/assets/images/tableSet/BorderHorizontalInner.jpg" alt="" :class="[setBorderBoxActive('horizontal'), disabledBorderIcon('horizontal')]" @click="borderSelected('horizontal')"/>
                    <img src="@/assets/images/tableSet/BorderBottom.jpg" alt="" :class="[setBorderBoxActive('bottom'), disabledBorderIcon('bottom')]" @click="borderSelected('bottom')" />
                  </div>
                </div>
                <div class="preview-right">
                  <div class="inner-box">
                    <div ref="innerStyleBox" class="inner-style-box" :class="[modifyStyleBorder, modifyStyleShape]" ></div>
                    <div class="italic-line-left" :class="modifyStyleItalicLeft" :style="{ borderColor: colorTransform.borderColor }"></div>
                    <div class="italic-line-right" :class="modifyStyleItalicRight" :style="{ borderColor: colorTransform.borderColor }"></div>
                    <img v-show="borderData.apply === 'table'" src="@/assets/images/tableSet/BorderPreview.jpg" alt="" />
                    <img v-show="borderData.apply !== 'table'" src="@/assets/images/tableSet/ParagraphPreview.jpg" alt="" />
                  </div>
                </div>
              </section>
              <section class="sect-middle">
                <div>
                  <img src="@/assets/images/tableSet/BorderUpDiagonal.jpg" alt="" :class="[setBorderBoxActive('italicLeft'), disabledBorderIcon('italicLeft')]" @click="borderSelected('italicLeft')" />
                  <img src="@/assets/images/tableSet/BorderLeft.jpg" alt="" :class="[setBorderBoxActive('left'), disabledBorderIcon('left')]" @click="borderSelected('left')" />
                  <img src="@/assets/images/tableSet/BorderVerticalInner.jpg" alt="" :class="[setBorderBoxActive('vertical'), disabledBorderIcon('vertical')]" @click="borderSelected('vertical')" />
                  <img src="@/assets/images/tableSet/BorderRight.jpg" alt="" :class="[setBorderBoxActive('right'), disabledBorderIcon('right')]" @click="borderSelected('right')" />
                  <img src="@/assets/images/tableSet/BorderDownDiagonal.jpg" alt="" :class="[setBorderBoxActive('italicRight'), disabledBorderIcon('italicRight')]" @click="borderSelected('italicRight')" />
                </div>
              </section>
              <section class="sect-bottom">
                <el-row>
                  <el-col :span="24">
                    <div class="colorField">
                      <span>应用于</span>
                      <el-select v-model="borderData.apply" placeholder="请选择" size="mini" style="width:70%;" @change="applyChange">
                        <el-option v-for="item in applArr" :key="item.value" :label="item.label" :value="item.value"></el-option>
                      </el-select>
                    </div>
                  </el-col>
                </el-row>
              </section>
            </div>
          </div>
        </div>
        <el-divider content-position="left">颜色和线宽</el-divider>
        <el-row>
          <el-col :span="6">
            <div class="colorField">
              <span>颜色</span>
              <el-color-picker v-model="colorTransform.borderColor" :predefine="predefineColors" show-alpha @change="borderColorChange"></el-color-picker>
            </div>
          </el-col>
          <el-col :span="12">
            <div class="colorField">
              <span>线宽</span>
              <el-input-number v-model="borderData.borderWidth" size="mini" :step="0.05" :min="0.01" :max="100" :precision="2" @change="borderWidthChange"></el-input-number>
            </div>
          </el-col>
          <!--
          <el-col :span="6">
            <div class="colorField">
              <span>下</span>
              <el-color-picker v-model="colorTransform.bottomBorderColor" :predefine="predefineColors" show-alpha @change="bottomColorChange"></el-color-picker>
            </div>
          </el-col>
          <el-col :span="6">
            <div class="colorField">
              <span>左</span>
              <el-color-picker v-model="colorTransform.leftBorderColor" show-alpha :predefine="predefineColors" @change="leftColorChange"></el-color-picker>
            </div>
          </el-col>
          <el-col :span="6">
            <div class="colorField">
              <span>右</span>
              <el-color-picker v-model="colorTransform.rightBorderColor" :predefine="predefineColors" show-alpha @change="rightColorChange"></el-color-picker>
            </div>
          </el-col>
-->
        </el-row>
  <!--        <el-row class="second-row">
  
        
          <el-col :span="12">
            <div class="colorField">
              <span>背景颜色</span>
              <el-color-picker v-model="colorTransform.backColor" :predefine="predefineColors" show-alpha @change="backColorChange"></el-color-picker>
            </div>
          </el-col>
 -->
        </el-row>
      </el-tab-pane>
      <el-tab-pane label="底纹" name="bottom-texture">
        <el-divider content-position="left">填充</el-divider>
        <el-row>
          <el-col :span="12">
            <div class="colorField">
              <span>填充颜色</span>
              <el-color-picker v-model="topColor" show-alpha></el-color-picker>
            </div>
          </el-col>
        </el-row>

        <el-divider content-position="left">图案</el-divider>
        <el-row>
          <el-col :span="12">
            <div class="colorField">
              <span>样式</span>
              <el-select v-model="styleCheckVal" placeholder="请选择" size="mini" style="width:70%;">
                <el-option v-for="item in styleArr" :key="item.value" :label="item.label" :value="item.value">
                  <span class="material-icons iconbox">{{ item.icon }}</span>
                  <span>{{ item.label }}</span>
                </el-option>
              </el-select>
            </div>
          </el-col>
          <el-col :span="8">
            <div class="colorField">
              <span>颜色</span>
              <el-color-picker v-model="topColor" show-alpha></el-color-picker>
            </div>
          </el-col>
        </el-row>
        <el-divider content-position="left">应用于</el-divider>
        <el-row>
          <el-col :span="12">
            <div class="colorField">
              <span>应用于</span>
              <el-select v-model="applVal" placeholder="请选择" size="mini" style="width:70%;">
                <el-option v-for="item in applArr" :key="item.value" :label="item.label" :value="item.value"></el-option>
              </el-select>
            </div>
          </el-col>
        </el-row>
      </el-tab-pane>
    </el-tabs>
  </div>
</template>

<script>
import ComposeIcon from './composeIcon.vue';
import { Color2RGBAStr, RGBAStr2Color } from '@/components/editor/ThinkEditor.Utils';
const E_BORDER_SET_MODE = {
  Null: 0,
  Outer: 1,
  All: 2,
  UserDefined: 3
};
const E_BORDER_STYLE = {
  NULL: 0,
  SOLID: 1,
  DOTTED: 2,
  DASHED: 3,
  DASHED_O: 4,
  DASHED_T: 5
};
export default {
  props: {
    borderData: {
      type: Object,
      default: () => ({})
    }
  },
  components: { ComposeIcon },
  data() {
    return {
      //thinkEditorDemo: new ThinkEditorDemo(),
      colorTransform: {
        borderColor: '#000',
        //bottomBorderColor: '#000',
        //leftBorderColor: '#000',
        //rightBorderColor: '#000',
        //topBorderColor: '#000',
       // backColor: '#000'
      },
      borderDisabledArr: [],
      borderSelectedArr: [],//选中的项
      borderModelOrg: E_BORDER_SET_MODE,
      borderStyleOrg: E_BORDER_STYLE,
      borderSetMode: null, //设置
      borderStyle: null, //线型
      innerRightFontSize: '40px',
      innertRightMarginTop: '-20px',
      lineWidth: 1.5,
      cheekActiveName: 'cheek',
      topColor: 'rgba(255, 69, 0, 0.68)',
      predefineColors: ['#ff4500', '#ff8c00', '#ffd700', '#90ee90', '#00ced1', '#1e90ff', '#c71585', 'rgba(255, 69, 0, 0.68)', 'rgb(255, 120, 0)', 'hsv(51, 100, 98)', 'hsva(120, 40, 94, 0.5)', 'hsl(181, 100%, 37%)', 'hsla(209, 100%, 56%, 0.73)', '#c7158577'],
      applVal: '',
      applArr: [
        //{
        //  value: 'text',
        //  label: '文本'
        //},
        {
          value: 'cell',
          label: '单元格'
        },
       // {
        //  value: 'paragraph',
        //  label: '段落'
        //},
        {
          value: 'table',
          label: '表格'
        }
      ],
      styleCheckVal: 'blank',
      styleArr: [
        {
          value: 'blank',
          label: '空白',
          icon: 'check_box_outline_blank'
        },
        {
          value: 'subside',
          label: '触觉减退',
          icon: 'invert_colors_off'
        },
        {
          value: 'disappear',
          label: '触觉消失',
          icon: 'streetview'
        },
        {
          value: 'exception',
          label: '触觉过敏或异常',
          icon: 'directions_railway'
        }
      ]
    };
  },
  watch: {
    borderData: {
      handler(newVal) {
        if (newVal) {
          this.borderSetMode = this.InitBorderSetMode();
          this.borderStyle = this.InitBorderStyle();
          if (!newVal.hasOwnProperty('apply')) {
            this.$set(this.borderData, 'apply', 'table'); //设置默认属性
            this.applyChange('table'); // 手动触发change
          }
          this.clickSetAlignBox(this.borderSetMode); // 手动初始化设置选项
          // 颜色值转换
          if (newVal.borderColor) {
            this.colorTransform.borderColor = Color2RGBAStr(newVal.borderColor);
          }
          this.initBorderPreviewByBorderStyles();
          /*
          if (newVal.bottomBorderColor) {
            this.colorTransform.bottomBorderColor = Color2RGBAStr(newVal.bottomBorderColor);
          }
          if (newVal.rightBorderColor) {
            this.colorTransform.rightBorderColor = Color2RGBAStr(newVal.rightBorderColor);
          }
          if (newVal.leftBorderColor) {
            this.colorTransform.leftBorderColor = Color2RGBAStr(newVal.leftBorderColor);
          }
          if (newVal.backColor) {
            this.colorTransform.backColor = Color2RGBAStr(newVal.backColor);
          }*/
        }
      },
      immediate: true
    }
  },
  computed: {
    // 根据不同的操作返回不同边框样式
    modifyStyleBorder() {
      let currStyle = ''; //对应border.scss
      switch (this.borderSetMode) {
        case E_BORDER_SET_MODE.Null:
          currStyle = 'null';
          break;
        case E_BORDER_SET_MODE.Outer:
          currStyle = 'outer ';
          // 再次判断是否选中了上下左右边框
          currStyle += this.borderSelectedArr.join(' ');
          break;
        case E_BORDER_SET_MODE.All:
          currStyle = 'all ';
          // 再次判断是否选中了上下左右边框
          currStyle += this.borderSelectedArr.join(' ');
          break;
        case E_BORDER_SET_MODE.UserDefined:
          //么都不做
          currStyle = 'all ';
          currStyle += this.borderSelectedArr.join(' ');
          break;
        default:
          break;
      }
      /*******************修改配置参数*******************/
      if (this.borderSelectedArr.includes('top')) {
        this.borderData.topBorderStyle = this.borderStyle;
      } else {
        this.borderData.topBorderStyle = E_BORDER_STYLE.NULL;
      }
      if (this.borderSelectedArr.includes('horizontal')) {
        this.borderData.innerHorizontalBorderStyle = this.borderStyle;
      } else {
        this.borderData.innerHorizontalBorderStyle = E_BORDER_STYLE.NULL;
      }
      if (this.borderSelectedArr.includes('bottom')) {
        this.borderData.bottomBorderStyle = this.borderStyle;
      } else {
        this.borderData.bottomBorderStyle = E_BORDER_STYLE.NULL;
      }

      if (this.borderSelectedArr.includes('italicLeft')) {
        this.borderData.upDiagonalBorderStyle = E_BORDER_STYLE.NULL;
      } else {
        this.borderData.upDiagonalBorderStyle = E_BORDER_STYLE.NULL;
      }

      if (this.borderSelectedArr.includes('left')) {
        this.borderData.leftBorderStyle = this.borderStyle;
      } else {
        this.borderData.leftBorderStyle = E_BORDER_STYLE.NULL;
      }
      if (this.borderSelectedArr.includes('vertical')) {
        this.borderData.innerVerticalBorderStyle = this.borderStyle;
      } else {
        this.borderData.innerVerticalBorderStyle = E_BORDER_STYLE.NULL;
      }
      if (this.borderSelectedArr.includes('right')) {
        this.borderData.rightBorderStyle = this.borderStyle;
      } else {
        this.borderData.rightBorderStyle = E_BORDER_STYLE.NULL;
      }

      if (this.borderSelectedArr.includes('italicRight')) {
        this.borderData.downDiagonalBorderStyle = E_BORDER_STYLE.NULL;
      } else {
        this.borderData.downDiagonalBorderStyle = E_BORDER_STYLE.NULL;
      }

      return currStyle;
    },
    // 线形
    modifyStyleShape() {
      let currStyle = '';
      switch (this.borderStyle) {
        case E_BORDER_STYLE.NULL:
          currStyle = 'shape-null';
          break;
        case E_BORDER_STYLE.SOLID:
          currStyle = 'shape-solid';
          break;
        case E_BORDER_STYLE.DOTTED:
          currStyle = 'shape-dotted';
          break;
        case E_BORDER_STYLE.DASHED:
        case E_BORDER_STYLE.DASHED_O:
        case E_BORDER_STYLE.DASHED_T:
          currStyle = 'shape-dash';
          break;
      }
      return currStyle;
    },
    modifyStyleItalicLeft() {
      if (this.borderData.apply === 'cell') {
        if (this.borderSelectedArr.includes('italicLeft')) {
          return 'italicLeft';
        }
      }
    },
    modifyStyleItalicRight() {
      if (this.borderData.apply === 'cell') {
        if (this.borderSelectedArr.includes('italicRight')) {
          return 'italicRight';
        }
      }
    }
  },
  methods: {
    InitBorderSetMode()
    {
      if(this.borderData.topBorderStyle == E_BORDER_STYLE.NULL
      && this.borderData.bottomBorderStyle == E_BORDER_STYLE.NULL
      && this.borderData.leftBorderStyle == E_BORDER_STYLE.NULL
      && this.borderData.rightBorderStyle == E_BORDER_STYLE.NULL
      && this.borderData.downDiagonalBorderStyle == E_BORDER_STYLE.NULL
      && this.borderData.upDiagonalBorderStyle == E_BORDER_STYLE.NULL
      && this.borderData.innerHorizontalBorderStyle == E_BORDER_STYLE.NULL
      && this.borderData.innerVerticalBorderStyle == E_BORDER_STYLE.NULL){
        return E_BORDER_SET_MODE.Null;
      }
      else if(this.borderData.topBorderStyle != E_BORDER_STYLE.NULL
      && this.borderData.bottomBorderStyle != E_BORDER_STYLE.NULL
      && this.borderData.leftBorderStyle != E_BORDER_STYLE.NULL
      && this.borderData.rightBorderStyle != E_BORDER_STYLE.NULL
      && this.borderData.innerHorizontalBorderStyle == E_BORDER_STYLE.NULL
      && this.borderData.innerHorizontalBorderStyle == E_BORDER_STYLE.NULL){
        return E_BORDER_SET_MODE.Outer;
      }      
      else if(this.borderData.topBorderStyle != E_BORDER_STYLE.NULL
      && this.borderData.bottomBorderStyle != E_BORDER_STYLE.NULL
      && this.borderData.leftBorderStyle != E_BORDER_STYLE.NULL
      && this.borderData.rightBorderStyle != E_BORDER_STYLE.NULL
      && this.borderData.innerHorizontalBorderStyle != E_BORDER_STYLE.NULL
      && this.borderData.innerHorizontalBorderStyle != E_BORDER_STYLE.NULL){
        return E_BORDER_SET_MODE.All;
      }else{
        return E_BORDER_SET_MODE.UserDefined;
      }
    },
    InitBorderStyle()
    {
      if(this.borderData.topBorderStyle != E_BORDER_STYLE.NULL){
        return this.borderData.topBorderStyle;
      }
      if(this.borderData.bottomBorderStyle != E_BORDER_STYLE.NULL){
        return this.borderData.bottomBorderStyle;
      }
      if(this.borderData.leftBorderStyle != E_BORDER_STYLE.NULL){
        return this.borderData.leftBorderStyle;
      }
      if(this.borderData.rightBorderStyle != E_BORDER_STYLE.NULL){
        return this.borderData.rightBorderStyle;
      }
      return E_BORDER_STYLE.SOLID;
    },
    /**
     * 边框操作图标，选中后添加到borderSelectedArr
     * 用于class 激活，同时添加对应的边框线样式
     * @param {*} type
     */
    borderSelected(type) {
      //点击了预览，一定是 自定义
      this.borderSetMode = E_BORDER_SET_MODE.UserDefined;

      if (this.borderSelectedArr.includes(type)) {
        this.borderSelectedArr.splice(
          this.borderSelectedArr.findIndex(e => e === type),
          1
        );
      } else {
        this.borderSelectedArr.push(type);
      }
    },
    choseSetAlingle(type) {
      if (this.borderSetMode === type) {
        return 'active';
      } else {
        return '';
      }
    },
    choseSetShape(type) {
      if (this.borderStyle === type) {
        return 'active';
      } else {
        return '';
      }
    },
    disabledBorderIcon(type) {
      if (this.borderDisabledArr.includes(type)) {
        return 'disabled-icon';
      }
    },
    
    initBorderPreviewByBorderStyles()
    {
      this.borderSelectedArr = [];
      let borderInfo = this.borderData;
      if(borderInfo.topBorderStyle != E_BORDER_STYLE.NULL)
      {
        this.borderSelectedArr.push('top');
      }   
      if(borderInfo.innerHorizontalBorderStyle != E_BORDER_STYLE.NULL)
      {
        this.borderSelectedArr.push('horizontal');
      }      
      if(borderInfo.bottomBorderStyle != E_BORDER_STYLE.NULL)
      {
        this.borderSelectedArr.push('bottom');
      }
      if(borderInfo.upDiagonalBorderStyle != E_BORDER_STYLE.NULL)
      {
        this.borderSelectedArr.push('italicLeft');
      }   
      if(borderInfo.leftBorderStyle != E_BORDER_STYLE.NULL)
      {
        this.borderSelectedArr.push('left');
      }      
      if(borderInfo.innerVerticalBorderStyle != E_BORDER_STYLE.NULL)
      {
        this.borderSelectedArr.push('vertical');
      }
      if(borderInfo.rightBorderStyle != E_BORDER_STYLE.NULL)
      {
        this.borderSelectedArr.push('right');
      }   
      if(borderInfo.downDiagonalBorderStyle != E_BORDER_STYLE.NULL)
      {
        this.borderSelectedArr.push('italicRight');
      }      
      this.$nextTick(() => {
          this.showPicker = true;
          setTimeout(() => {
            this.borderColorChange(this.colorTransform.borderColor);
          }, 100);
        });
  
    },
    /**
     * 设置项中的图标选中事件
     * 配合下拉‘应用于’，判断预览列表
     * 可以选中的边框操作图标
     * @param {*} type
     */
    clickSetAlignBox(type) {
      switch (type) {
        case E_BORDER_SET_MODE.Null:
          this.borderSelectedArr = [];
          break;
        case E_BORDER_SET_MODE.Outer:
          const resOurter = ['top', 'bottom', 'left', 'right'];//this.choseIconArrByType();
          this.borderSelectedArr.splice(0, this.borderSelectedArr.length, ...resOurter);
          break;
        case E_BORDER_SET_MODE.All:
          const resAll = ['horizontal', 'vertical', 'top', 'bottom', 'left', 'right'];//this.choseIconArrByType();
          this.borderSelectedArr.splice(0, this.borderSelectedArr.length, ...resAll);
          break;
        case E_BORDER_SET_MODE.UserDefined:
          //什么都不做 this.borderSelectedArr = [];
          break;
      }
      this.borderSetMode = type;
    },
    // 根据下拉应用类型，选取边框图标
    choseIconArrByType() {
      let res = [];
      switch (this.borderData.apply) {
        case 'text':
          res = ['top', 'bottom', 'left', 'right'];
          break;
        case 'cell':
          res = ['italicRight', 'italicLeft', 'top', 'bottom', 'left', 'right'];
          break;
        case 'paragraph':
          res = ['top', 'bottom', 'left', 'right'];
          break;
        case 'table':
          //res = ['horizontal', 'vertical', 'top', 'bottom', 'left', 'right'];
          res = ['top', 'bottom', 'left', 'right'];
          break;
      }
      return res;
    },
    borderStyleChange(type) {
      this.borderStyle = type;
    },
    setBorderBoxActive(type) {
      if (this.borderSelectedArr.includes(type)) {
        return 'active';
      } else {
        return '';
      }
    },
    /**
     * 应用于下拉选中后，触发边框图标禁用操作
     */
    applyChange(val) {
      switch (val) {
        case 'text':
          this.borderDisabledArr = ['italicRight', 'italicLeft', 'horizontal', 'vertical'];
          break;
        case 'cell':
          this.borderDisabledArr = ['horizontal', 'vertical'];
          break;
        case 'paragraph':
          this.borderDisabledArr = ['italicRight', 'italicLeft', 'horizontal', 'vertical'];
          break;
        case 'table':
          this.borderDisabledArr = ['italicRight', 'italicLeft'];
          break;
      }
    },
    borderColorChange(new_color) {
      this.$refs.innerStyleBox.style.borderTopColor = new_color;
      this.$refs.innerStyleBox.style.borderBottomColor = new_color;
      this.$refs.innerStyleBox.style.borderLeftColor = new_color;
      this.$refs.innerStyleBox.style.borderRightColor = new_color;
      //this.$refs.innerStyleBox.$preview-right-border-color  = new_color;
      this.borderData.borderColor = RGBAStr2Color(new_color);
    },
    topColorChange(new_color) {
      this.$refs.innerStyleBox.style.borderTopColor = new_color;
      this.borderData.topBorderColor = RGBAStr2Color(new_color);
    },
    bottomColorChange(new_color) {
      this.$refs.innerStyleBox.style.borderBottomColor = new_color;
      this.borderData.bottomBorderColor = RGBAStr2Color(new_color);
    },
    leftColorChange(new_color) {
      this.$refs.innerStyleBox.style.borderLeftColor = new_color;
      this.borderData.leftBorderColor = RGBAStr2Color(new_color);
    },
    rightColorChange(new_color) {
      this.$refs.innerStyleBox.style.borderRightColor = new_color;
      this.borderData.rightBorderColor = RGBAStr2Color(new_color);
    },
    backColorChange(new_color) {
      this.borderData.backColor = RGBAStr2Color(new_color);
    },
    borderWidthChange(currentValue, oldValue) {
      let borderWidth = this.$refs.innerStyleBox.style.borderWidth;
      borderWidth = borderWidth ? borderWidth.replace('px', '') : borderWidth;
      let calcuVal = '';
      // 判断是加还是减
      if (currentValue > oldValue) {
        calcuVal = borderWidth ? parseFloat(borderWidth) + parseFloat(currentValue) + 'px' : 1 + parseFloat(currentValue) + 'px';
      } else {
        calcuVal = borderWidth ? parseFloat(borderWidth) - parseFloat(currentValue) + 'px' : 1 - parseFloat(currentValue) + 'px';
      }

      this.$refs.innerStyleBox.style.borderWidth = calcuVal;
      document.styleSheets[0].insertRule('.inner-style-box.all.vertical::after { border-right-width: ' + calcuVal + ';}', 0);
    }
  }
};
</script>

<style lang="scss" scoped>
@import './css/border.scss';
</style>
