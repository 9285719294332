import { GetPrintIframe } from '@/components/editor/ThinkEditor.Utils';
import { ThinkEditor } from '@/components/editor/ThinkEditor';
import { E_VIEW_MODE, E_SYSTEM_AUTH_MODE } from '@/components/editor/ThinkEditor.Defined';

export const DemoCaseGetPrintData = {
  methods: {
    /**
     * @function GetPrintData
     * @description 合并打印文档。
     * 1、可控制子文档是否分页合并
     * 2、可控制是否显示合并后的文档
     * @param {backgroundPrint} true: 不打开显示的合并后文档  false: 打开显示的合并后文档
     * @return {}
     * @example
     */
    async DoDemoCaseGetPrintData() {
      let docName = '获得打印数据(Electron打印)';

      let thinkEditor = new ThinkEditor({
        lib: '/editor/', //库文件在服务器上路径
        fontPath: '/editor_fonts/', //字体文件在服务器上路径
        docName: docName, //文档编辑器Id，用于区分不同的文档编辑器
        auth: { mode: E_SYSTEM_AUTH_MODE.Demonstration }
      });

      await thinkEditor.Init();

      var url = '/system/emr/演示/00-演示病例/AdmissionRecord.xml';
      let response = await fetch(url, {
        credentials: 'same-origin'
      });
      let xml = await response.arrayBuffer();

      thinkEditor.CreateDoc(docName); //@20220722 必需，才能设置编辑者信息
      thinkEditor.SelectDoc(docName);

      thinkEditor.ParseDocs(docName, '', xml, {});

      thinkEditor.SetViewMode(E_VIEW_MODE.Edit, {});

      //[0] 注意await,后续可能关闭文档或编辑器
      var dataBuffer = await thinkEditor.GetPDF({
        view: {
          mode: E_VIEW_MODE.Print
        }
      }); //代替thinkEditor.PrintDoc

      /* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *
       * * * * * * dataBuffer即为需要打印的数据* * * * *
       * 用户可以将dataBuffer交给桌面版打印插件、或Electron打印组件打印
       * 以下示例 使用iframe打印（目前不适用于Electron）
       * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */
      let blob = new Blob([dataBuffer], { type: 'application/pdf' });
      let blobURL = URL.createObjectURL(blob);
      var iframe = GetPrintIframe();
      iframe.style.display = 'none';
      iframe.src = blobURL;
      iframe.onload = function () {
        setTimeout(function () {
          iframe.focus();
          iframe.contentWindow.print();
        }, 1);
      };
      /************************************************************/

      //注意：后台打印 需在退出时使用UnInit释放资源
      thinkEditor.UnInit();
    }
  }
};
