import { E_EVENT_HANDLE, E_EVENT_KEY, E_INSTANCE_EVENT_KEY } from '@/components/editor/ThinkEditor.Defined';

export const AppStable = {
  methods: {
    async OnEditorInstanceEvent(e) {
      let type = e.data.type;
      let data = e.data.data;
      switch (type) {
        case E_INSTANCE_EVENT_KEY.editorInstanceFocusChange: {
          this.EditorInstanceFocusChange(data.instance.instanceId);
          break;
        }
        case E_INSTANCE_EVENT_KEY.createdDocEditor: {
          this.OnCreatedDocEditor(e);
          break;
        }
        case E_INSTANCE_EVENT_KEY.removedDocEditor: {
          this.OnRemovedDocEditor(e);
          break;
        }
        case E_INSTANCE_EVENT_KEY.setElementContent: {
          this.OnSetElementContent(e);
          break;
        }
        default:
          break;
      }
    }
  }
};
