import { render, staticRenderFns } from "./attrSet.vue?vue&type=template&id=1cb608cb&scoped=true"
import script from "./attrSet.vue?vue&type=script&lang=js"
export * from "./attrSet.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1cb608cb",
  null
  
)

export default component.exports