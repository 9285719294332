<template>
  <div :style="pos" class="content" @mouseout="onMouseEnter" @mouseover="onMouseleave">
    <el-time-picker v-if="showPicker" @mouseout="onMouseEnter" @mouseover="onMouseleave" v-model="time" :format="format" ref="datePickerRef" @change="onChange" @input="onInput" @blur="onBlur" class="timnePickCSS" placeholder="任意时间点"></el-time-picker>

    <!--    <el-button @click="commit" style="margin-left: 10px;margin-top: 5px" size="small">确定</el-button>-->
    <!--    <el-button @click="close" style="margin-left: 10px" size="small">取消</el-button>-->
  </div>
</template>

<script>
import { E_INPUT_MODE, E_TIME_TYPE } from '@/components/editor/ThinkEditor.Defined';
import { UpdateConfigData } from '@/components/editor/ThinkEditor.Utils';
export default {
  name: 'index',
  props: {
    configData: {
      type: Object,
      default: {
        content: '2022-02-02',
        inputHandle: {
          inputMode: 0,
          timeType: 0,
          inView: {
            posX: 0,
            posY: 0,
            with: 1,
            height: 1
          }
        }
      }
    }
  },
  data() {
    return {
      format: 'HH:mm:ss',
      //valueFormat: 'HH:mm:ss',
      time: null,
      showPicker: false,
      pos: {
        top: '1px',
        left: '1px'
      }
    };
  },
  watch: {
    configData: {
      handler(data) {
        this.SelfInit(data);
      },
      immediate: true
    }
  },
  mounted() {
    setTimeout(() => {
      this.$refs.datePickerRef.focus();
    }, 100);
  },
  methods: {
    SelfInit(data) {
      let timeType = data.inputHandle.timeType;
      this.showPicker = false;
      if (data.inputHandle.inputMode == E_INPUT_MODE.SelectDateTime) {
        this.pos.top = data.inView.posY + data.inView.height + 5 + 'px';
        this.pos.left = data.inView.posX + 'px';

        this.time = new Date(data.inputHandle.time * 1000); // data.content; //或data.inputHandle.time '2019-09-09 09:16:23'

        if (timeType == E_TIME_TYPE.Time) {
          this.format = 'HH:mm:ss';
          //this.valueFormat = 'HH:mm:ss';
        } else if (timeType == E_TIME_TYPE.TimeNoSecond) {
          this.format = 'HH:mm';
          //this.valueFormat = 'HH:mm';
        } else {
          this.close();
          return;
        }
        console.warn('show TimeBox');
        this.$nextTick(() => {
          this.showPicker = true;
          setTimeout(() => {
            this.$refs.datePickerRef.focus();
          }, 100);
        });
      } else {
        this.close();
      }
    },
    onMouseEnter() {
      console.warn('TimeBox onMouseEnter');
    },
    onMouseleave() {
      console.warn('TimeBox mouseleave');
    },
    onBlur() {
      console.warn('TimeBox onBlur');
    },
    onInput() {
      console.warn('TimeBox onInput');
    },
    /**
     * 确定
     */
    onChange(val) {
      if (val == '') {
        //实测 取消时也是出发change事件，只能使用==""来区分
        this.close();
      } else {
        console.warn('show 1 setTimeBox');
        //@20230702 缺陷 弹出后 单击空白处 picker会隐藏，此时会产生 确定onChange 目前无法避免
        this.$emit('action:setValue', ((this.time.getTime() / 1000) | 0) + '');
      }
    },
    close() {
      this.$emit('action:hide');
    }
  }
};
</script>

<style scoped>
.content {
  position: absolute;
  /*padding: 6px;*/
  /*border: 1px solid #ccc;*/
  /*border-radius: 5px;*/
  /*background: #fff;*/
}

.timnePickCSS {
  position: absolute;
  top: 30%;
  left: 70%;
  z-index: -1;
}
</style>
