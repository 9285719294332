<template>
  <div class="colort-box" title="背景颜色">
    <el-color-picker ref="fontBgColorPicker" v-model="fontBgColor" show-alpha color-format="rgb" :predefine="predefineColors" size="mini" @change="colorChange" @active-change="selectedColorChange"></el-color-picker>
    <span class="material-icons bgCtrlIcon" @click="fontBgColorChange">arrow_drop_down</span>
  </div>
</template>

<script>
import { RGBAStr2RGBA, RGBA2RGBAStr, Color2RGBAStr, RGBAStr2Color } from '@/components/editor/ThinkEditor.Utils';
import { E_EVENT_KEY } from '@/components/editor/ThinkEditor.Defined';
import { editorsInstanceManager } from '@/components/editorHelper/ThinkEditorInstance.Manager';

export default {
  data() {
    return {
      fontBgColor: null,
      predefineColors: ['rgba(0, 0, 0, 0.0)', '#000000', '#d0d0d0', '#ff4500', '#ff8c00', '#ffd700', '#90ee90', '#00ced1', '#1e90ff', '#c71585']
      //thinkEditorDemo: new ThinkEditorDemo(),
    };
  },
  mounted() {
    editorsInstanceManager.addEventListener(E_EVENT_KEY.fontFormat, this.OnFontFormatChange);
  },
  methods: {
    fontBgColorChange() {
      this.$refs.fontBgColorPicker.handleTrigger();
    },
    OnFontFormatChange(e) {
      let data = e.data.data;
      this.fontBgColor = Color2RGBAStr(data.backColor);
      //console.log('OnCanvasMouseDown OnFontFormatChange SetFontBackColor:' + data.backColor);
    },
    colorChange(new_color) {
      // 把rgba(238, 144, 227, 1)转换为对象

      let color = RGBAStr2Color(new_color);

      let currentEditorsInstance = editorsInstanceManager.GetSelectedEditorsInstance();
      let currentEditor = currentEditorsInstance?.GetSelectedEditor();
      //console.log('colorChange OnFontFormatChange SetFontBackColor:' + color);
      currentEditor.SetFontBackColor(color);
    },
    selectedColorChange(new_color) {
      // 把rgba(238, 144, 227, 1)转换为对象
      let rgba = RGBAStr2RGBA(new_color);
      if (rgba.a != 0.0 || rgba.r != 0 || rgba.g != 0 || rgba.b != 0) {
        //非透明顔色，则强制a=1.0 不透明
        rgba.a = 1.0;
        this.fontBgColor = RGBA2RGBAStr(rgba);
      }
      new_color = this.fontBgColor;
      //console.log('selectedColorChange SetFontBackColor:' + new_color);
    }
  }
};
</script>

<style>
.el-color-alpha-slider {
  display: none;
}
</style>

<style lang="scss" scoped>
.colort-box {
  display: flex;
  align-items: center;
  .bgCtrlIcon {
    margin-left: -8px;
    cursor: pointer;
  }
}
</style>
