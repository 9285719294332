<template>
  <div class="fold-filed">
    <el-card>
      <el-button size="mini" v-for="(item, idx) in firestTagList" @click="$emit('toolbarClick', item)" :key="idx">{{ item.label }}</el-button>
    </el-card>
    <el-card>
      <el-button size="mini" class="single" @click="$emit('toolbarClick', item)" v-for="(item, idx) in twoTagList" :key="idx">{{ item.label }}</el-button>
    </el-card>
    <el-card>
      <el-button size="mini" v-for="(item, idx) in threeTagList" @click="$emit('toolbarClick', item)" :key="idx">{{ item.label }}</el-button>
    </el-card>
  </div>
</template>
<script>
export default {
  data() {
    return {
      firestTagList: [
        {
          label: '新建',
          val: 'newDoc'
        },
        {
          label: '保存',
          val: 'saveDoc'
        },
        {
          label: '保存为模版',
          val: 'saveAsTemplate'
        },
        //{
        //  label: '打开',
        //  val: 'openDoc'
        //},
        //{
        //  label: '查看XML',
        //  val: 'viewXML'
        //},
        {
          label: '转HTML',
          val: 'toHTML'
        },

        {
          label: '转PDF',
          val: 'toPdf'
        },
        {
          label: '转图片',
          val: 'toImg'
        }
      ],
      twoTagList: [
        {
          label: '页面设置',
          val: 'setPage'
        }
      ],
      threeTagList: [
        {
          label: '整洁编辑',
          val: 'cleanEdit'
        },
        {
          label: '审阅编辑',
          val: 'reviseEdit'
        },
        {
          label: '整洁浏览',
          val: 'cleanBrowser'
        },
        {
          label: '审阅浏览',
          val: 'reviseBrowser'
        },
        {
          label: '整洁打印',
          val: 'cleanPrint'
        },
        {
          label: '审阅打印',
          val: 'revisePrint'
        },
        {
          label: '打印选择页',
          val: 'SelectPagePrint'
        },
        {
          label: '选择打印',
          val: 'SelectContentPrint'
        },
        {
          label: '续打',
          val: 'ContinuePrint'
        }
      ]
    };
  }
};
</script>
<style lang="scss">
.fold-filed {
  width: 75%;
  display: flex;
  .el-card {
    overflow: visible;
    margin-right: 3px;
  }
  .el-card__body,
  .el-main {
    padding: 2px;
  }
  .el-card__body .el-button:first-child {
    margin-left: 4px;
  }
  .el-button {
    &.single {
      margin-top: 6px;
    }
  }

  .el-button + .el-button {
    margin-left: 4px;
    margin-top: 6px;
  }
  .el-button--mini,
  .el-button--mini.is-round {
    padding: 7px 12px;
  }
}
</style>
