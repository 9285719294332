<template>
  <VacantDialog title="图像属性" width="600px" ref="thisDialog" :top="'10%'" :confirmTxt="confirmTxt" @close="onCancle" @confirm="onConfirm">
    <el-form :model="configData" ref="configData" label-width="80px" class="demo-dynamic">
      <el-row>
        <el-col :span="12">
          <el-form-item label-width="80px" prop="id" label="ID">
            <el-input size="mini" style="width: 200px" v-model="configData.id"></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label-width="80px" prop="name" label="名称">
            <el-input size="mini" style="width: 200px" v-model="configData.name"></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label-width="80px" prop="width" label="宽度">
            <el-input-number style="width: 200px" size="mini" v-model="configData.width" controls-position="right" :precision="2" :step="0.5" :min="0.0"></el-input-number>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label-width="80px" prop="height" label="高度">
            <el-input-number style="width: 200px" size="mini" v-model="configData.height" controls-position="right" :precision="2" :step="0.5" :min="0.0"></el-input-number>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label-width="80px" prop="alignVerticalLayout" label="垂直对齐">
            <el-select size="mini" style="width: 200px" v-model="configData.alignVerticalLayout" placeholder="请选择">
              <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value" :disabled="item.disabled"></el-option>
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label-width="80px" prop="" label="">
            <el-checkbox v-model="saveImageResource">保存图像数据</el-checkbox>
          </el-form-item>
        </el-col>
      </el-row>
    </el-form>
  </VacantDialog>
</template>

<script>
import { E_EVENT_KEY } from '@/components/editor/ThinkEditor.Defined';
import { UpdateConfigData } from '@/components/editor/ThinkEditor.Utils';
import { editorsInstanceManager } from '@/components/editorHelper/ThinkEditorInstance.Manager';

export default {
  name: 'index',
  components: {},
  data() {
    return {
      thinkEditor: undefined,
      active: 1,
      disabled: false,
      flag: false,
      confirmTxt: '插入',
      configData: {},
      options: [
        { value: 1, label: '居中对齐' },
        { value: 0, label: '底部对齐' }
      ]
    };
  },
  computed: {
    saveImageResource: {
      get: function () {
        return !this.configData.notSaveImageResource;
      },
      set: function (newValue) {
        return (this.configData.notSaveImageResource = !newValue);
      }
    }
  },
  created() {
    editorsInstanceManager.addEventListener(E_EVENT_KEY.editorFocus, this.OnDocEditorFocusChange);
    let currentEditorsInstance = editorsInstanceManager.GetSelectedEditorsInstance();
    this.thinkEditor = currentEditorsInstance?.GetSelectedEditor();
  },
  methods: {
    OnDocEditorFocusChange(e) {
      this.thinkEditor = e.data.editor;
    },
    openDialog(configData) {
      this.confirmTxt = '确定';
      UpdateConfigData(this, configData);

      this.$refs.thisDialog.openDialog();
    },
    closeDialog() {
      this.$refs.thisDialog.closeDialog();
      this.thinkEditor.SetInputFocus(true);
    },
    onCancle() {
      this.closeDialog();
    },
    onConfirm() {
      const params = JSON.parse(JSON.stringify(this.configData));

      this.thinkEditor.SetImageConfig('', params);
      this.closeDialog();
    }
  }
};
</script>

<style scoped lang="scss">
::v-deep .el-input--medium .el-input__inner {
  height: 60px;
}

::v-deep .el-select-dropdown__item {
  height: 60px !important;
  line-height: 60px;
  border-bottom: 2px solid #ccc;
  margin: 0 10px;
}

::v-deep .el-form-item {
  margin-bottom: -8px !important;
}

.tab-style {
  display: flex;
  align-items: center;
  justify-content: flex-start;

  .tab {
    padding: 6px 10px;
    border: 1px solid #ccc;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    margin-right: 1px;
    background: #e0e0e0;
    cursor: pointer;
  }

  .active {
    background: #61a3e6;
  }
}

::v-deep .el-popper {
  margin-top: 0;
}
</style>
