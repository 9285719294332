<template>
  <div v-cust-drag-dialog>
    <el-dialog :title="title" :visible.sync="dialogVisible" :top="top" :width="width" :lock-scroll="false" :fullscreen="fullscreen" :close-on-click-modal="closeClickModal" :append-to-body="appendToBody" @close="$emit('close')">
      <slot />
      <div :class="showClass ? 'dialog-footer' : 'dialog-footer1'" v-if="footerShow">
        <slot name="left" />
        <slot name="footer">
          <el-button style="margin-right: 20px" type="primary" size="mini" :loading="confirmLoading" @click="$emit('confirm')">
            {{ confirmTxt }}
          </el-button>
          <span slot="footer" class="dialog-footer">
            <el-button type="info" size="mini" @click="dialogVisible = false">
              取消
            </el-button>
          </span>
        </slot>
      </div>
    </el-dialog>
  </div>
</template>

<script>
export default {
  props: {
    loading: {
      type: Boolean,
      default: false
    },
    /**
     * 弹窗名称
     */
    title: {
      type: String,
      default: '弹窗'
    },
    /**
     * 距离顶部高度
     */
    top: {
      type: String,
      default: '12vh'
    },
    /**
     * 弹窗宽度
     */
    width: {
      type: String,
      default: '50%'
    },
    /**
     * 是否可以通过点击 modal 关闭 弹窗
     */
    closeClickModal: {
      type: Boolean,
      default: true
    },
    /**
     * 按钮名称
     */
    confirmTxt: {
      type: String,
      default: '确定'
    },
    appendToBody: {
      type: Boolean,
      default: true
    },
    /**
     * 是否启用手写样式
     */
    showClass: {
      type: Boolean,
      default: true
    },
    fullscreen: {
      type: Boolean,
      default: false
    },
    footerShow: {
      type: Boolean,
      default: true
    }
  },

  data() {
    return {
      dialogVisible: false
    };
  },
  computed: {
    confirmLoading: {
      get({ loading }) {
        return loading;
      }
    }
  },
  methods: {
    openDialog(flag) {
      this.dialogVisible = true;
    },

    closeDialog() {
      this.dialogVisible = false;
    }
  }
};
</script>

<style scoped>
.dialog-footer {
  height: 60px;
  display: flex;
  justify-content: right;
  align-items: flex-end;
}

::v-deep .el-dialog__header {
  padding: 12px !important;
}

::v-deep .el-dialog__headerbtn {
  top: 12px !important;
}

.dialog-footer1 {
  height: 20px;
  display: flex;
  justify-content: left;
  align-items: center;
}

::v-deep .el-dialog .el-dialog__body {
  padding: 0px 5px 5px;
}
</style>
