import { E_EVENT_KEY, E_VIEW_MODE, E_SHOW_BG_MODE } from '@/components/editor/ThinkEditor.Defined';

export const DemoCaseComment = {
  methods: {
    async DoDemoCaseComment() {
      let editorsTabsView = this.defaultEditorsTabsView;
      let currentEditorsInstance = editorsTabsView.editorsInstance;
      //用户只使用一个editorsInstance
      var docName = '批注&答复';
      let thinkEditor = currentEditorsInstance.GetEditor(docName);

      if (thinkEditor === undefined) {
        thinkEditor = await editorsTabsView.CreateDocEditor(docName);

        var url = '/system/emr/演示/00-演示病例/AdmissionRecord.xml'; //"/system/test/pacsdemo.jpg";
        let response = await fetch(url, {
          credentials: 'same-origin'
        });
        let xml = await response.arrayBuffer();

        thinkEditor.CreateDoc(docName); //@20220722 必需，才能设置编辑者信息
        thinkEditor.SelectDoc(docName);
        thinkEditor.SetEditorInfo('pc', 'zltest', 'zl', 1, '');
        thinkEditor.ParseDocs(docName, '', xml, {});

        /*************************************************
         * 配置显示批注信息框 和 批注内容背景色
         * **********************************************/
        thinkEditor.SetViewMode(E_VIEW_MODE.Edit, { displayCommentInfobox: true, displayCommentBgMode: E_SHOW_BG_MODE.AlwaysShow });

        thinkEditor.addEventListener(E_EVENT_KEY.docModified, this.OnDocModified);
        //or currentEditorsInstance.addEventListener(E_EVENT_KEY.docModified, this.OnDocModified);
      }
      //UI 中显示该文档
      editorsTabsView.SelectDocEditor(docName);
    },
    async OnDocModified(e) {
      let thinkEditor = e.data.editor;
      this.InitCommentReport(thinkEditor);
    },
    async InitCommentReport(currentThinkEditor) {
      currentThinkEditor.commentBiz = currentThinkEditor.commentBiz || {};
      currentThinkEditor.commentBiz.moveIdx = currentThinkEditor.commentBiz.moveIdx || -1;
      currentThinkEditor.commentBiz.report = currentThinkEditor.GetCommentReport();
    },
    async OnGetCommentReportTest() {
      let currentTabsView = this.defaultEditorsTabsView;
      let currentEditorsInstance = currentTabsView.editorsInstance;
      let currentThinkEditor = currentEditorsInstance.GetSelectedEditor();
      if (currentThinkEditor === undefined) {
        return;
      }

      this.InitCommentReport(currentThinkEditor);

      this.popDialogTitle = 'GetCommentReport';
      this.popDialogText = currentThinkEditor.commentBiz.report;
      this.popDialogVisible = true;
    },
    async OnGotoNextCommentTest() {
      let currentTabsView = this.defaultEditorsTabsView;
      let currentEditorsInstance = currentTabsView.editorsInstance;
      let currentThinkEditor = currentEditorsInstance.GetSelectedEditor();
      if (currentThinkEditor === undefined) {
        return;
      }
      if (currentThinkEditor.commentBiz == undefined) {
        this.InitCommentReport(currentThinkEditor);
      }
      let report = currentThinkEditor.commentBiz.report;
      let count = report.code;
      if (++currentThinkEditor.commentBiz.moveIdx >= count) {
        currentThinkEditor.commentBiz.moveIdx = 0;
      }
      let item = report.data[currentThinkEditor.commentBiz.moveIdx];

      currentThinkEditor.SelectComment({ id: item.id });
    },
    async OnGotoPreviousCommentTest() {
      let currentTabsView = this.defaultEditorsTabsView;
      let currentEditorsInstance = currentTabsView.editorsInstance;
      let currentThinkEditor = currentEditorsInstance.GetSelectedEditor();
      if (currentThinkEditor === undefined) {
        return;
      }
      if (currentThinkEditor.commentBiz == undefined) {
        this.InitCommentReport(currentThinkEditor);
      }
      let report = currentThinkEditor.commentBiz.report;
      let count = report.code;
      if (--currentThinkEditor.commentBiz.moveIdx < 0) {
        currentThinkEditor.commentBiz.moveIdx = count - 1;
      }
      let item = report.data[currentThinkEditor.commentBiz.moveIdx];

      currentThinkEditor.SelectComment({ id: item.id });
    },
    async OnInsertCommentTest() {
      let currentTabsView = this.defaultEditorsTabsView;
      let currentEditorsInstance = currentTabsView.editorsInstance;
      let currentThinkEditor = currentEditorsInstance.GetSelectedEditor();
      if (currentThinkEditor === undefined) {
        return;
      }

      currentThinkEditor.InsertComment('', '自定义批注', {});
    },
    OnDeleteCommentTest() {
      let currentTabsView = this.defaultEditorsTabsView;
      let currentEditorsInstance = currentTabsView.editorsInstance;
      let currentThinkEditor = currentEditorsInstance.GetSelectedEditor();
      if (currentThinkEditor === undefined) {
        return;
      }

      currentThinkEditor.DeleteComment({});
    },
    OnInsertReplyCommentTest() {
      let currentTabsView = this.defaultEditorsTabsView;
      let currentEditorsInstance = currentTabsView.editorsInstance;
      let currentThinkEditor = currentEditorsInstance.GetSelectedEditor();
      if (currentThinkEditor === undefined) {
        return;
      }

      currentThinkEditor.InsertReplyComment('自定义答复', {});
    },
    OnDeleteReplyCommentTest() {
      let currentTabsView = this.defaultEditorsTabsView;
      let currentEditorsInstance = currentTabsView.editorsInstance;
      let currentThinkEditor = currentEditorsInstance.GetSelectedEditor();
      if (currentThinkEditor === undefined) {
        return;
      }

      currentThinkEditor.DeleteReplyComment({});
    }
  }
};
