<template>
  <el-popover placement="right" trigger="click" ref="RefCheckBox">
    <div>
      <div v-for="(item, index) in itemList" :key="item.value" :class="index === itemList.length - 1 ? '' : 'borderStyle'">
        <el-checkbox-group @change="changeData" v-model="activeItems[item.value]">
          <div v-for="ite in item.items" :key="ite.id">
            <el-checkbox style="margin-top: 5px" :label="ite.id">{{ ite.id }}</el-checkbox>
          </div>
        </el-checkbox-group>
      </div>
      <el-button @click="commit" style="margin-left: 10px; margin-top: 5px" size="small">确定</el-button>
      <el-button @click="close" style="margin-left: 10px" size="small">取消</el-button>
    </div>
    <div slot="reference" :style="pos" class="content"></div>
  </el-popover>
</template>

<script>
import { E_INPUT_MODE } from '@/components/editor/ThinkEditor.Defined';
export default {
  name: 'index',
  props: {
    configData: {
      type: Object,
      default: {}
    }
  },
  data() {
    return {
      itemList: [],
      activeItems: {},
      activeItemsText: [],
      visible: false,
      pos: {
        top: '1px',
        left: '1px'
      }
    };
  },
  watch: {
    configData: {
      handler(data) {
        if (data.inputHandle.inputMode === E_INPUT_MODE.SelectOption /*2*/ && data.inputHandle.multipleChoice === 1) {
          this.pos.top = data.inView.posY + 10 + 'px';
          this.pos.left = data.inView.posX + 10 + 'px';
          this.groupExclusion = data.inputHandle.groupExclusion;
          this.activeItemsText = data.inputHandle.checked;
          this.InitItemList(data.inputHandle.items);
          this.$nextTick(() => {
            setTimeout(() => {
              this.$parent.$parent.$children[0].$children[18].$refs.RefCheckBox.doShow();
            }, 100);
          });
        } else {
          this.close();
        }
      },
      deep: true,
      immediate: true
    }
  },
  methods: {
    /*
this.activeItemsText ["学生"] 是所有选中的项
this.itemList[] 成员{value:1,items:[{content:(...) group: (...)id: (...)weight: }]} => {value:组号,items:[]}
this.activeItems{} 结构为 {1:["学生"]} 即{group:[本组选择项]}
*/
    InitItemList(checkList) {
      let group = [];
      this.itemList = [];
      this.activeItems = {};
      checkList.forEach(item => {
        if (group.indexOf(item.group) === -1) {
          group.push(item.group);
        }
      });
      group.forEach(item => {
        this.itemList.push({ value: item, items: [] });
        this.$set(this.activeItems, item, []);
      });
      this.itemList.forEach(item => {
        checkList.forEach(ite => {
          if (item.value === ite.group) {
            item.items.push(ite);
          }
        });
      });
      this.itemList.forEach(item => {
        item.items.forEach(ite => {
          if (this.activeItemsText.indexOf(ite.id) !== -1) {
            this.activeItems[item.value].push(ite.id);
          }
        });
      });
    },
    changeData(val) {
      if (this.groupExclusion) {
        let result = '';
        for (let i in this.activeItems) {
          if (this.activeItems[i].length) {
            this.activeItems[i].forEach(item => {
              if (val.indexOf(item) !== -1) {
                result = i;
              }
            });
          }
        }
        for (let i in this.activeItems) {
          if (i !== result) {
            this.activeItems[i] = [];
          }
        }
      }
    },
    /**
     * 确定
     */
    commit() {
      let value = undefined;
      for (let key in this.activeItems) {
        this.activeItems[key].forEach(item => {
          if (!value) {
            value = item;
          } else {
            value += ';' + item; //固定使用分号拼接
          }
        });
      }
      if (value !== undefined) {
        this.$emit('action:setValue', value);
      }
      this.close();
    },
    close() {
      this.visible = false;
      this.$emit('action:hide', 'checkBox');
    }
  }
};
</script>

<style scoped>
.content {
  position: absolute;
  //padding: 6px;
  //border: 1px solid #ccc;
  //border-radius: 5px;
  //background: #fff;
  //min-width: 150px;
}

::v-deep .el-checkbox-group .el-checkbox .el-checkbox__input .el-checkbox__inner {
  width: 18px !important;
  height: 18px !important;
}

::v-deep .el-checkbox-group .el-checkbox .el-checkbox__input .el-checkbox__inner::after {
  height: 9px;
  left: 6px;
}

::v-deep .el-checkbox-group .el-checkbox .el-checkbox__input .el-checkbox__label {
  font-size: 16px;
}

.borderStyle {
  border-bottom: 1px solid #8d8d8d;
  min-width: 150px;
}
</style>
<style>
.popper__arrow {
  display: none !important;
}
</style>
