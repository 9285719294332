import { GetRandStr } from '@/components/editor/ThinkEditor.Utils';
import { ThinkEditor } from '@/components/editor/ThinkEditor';
import { E_PRINT_SCOPE_MODE, E_VIEW_MODE } from '@/components/editor/ThinkEditor.Defined';

export const DemoCaseOddEvenPrint = {
  methods: {
    /**
     * @function DoDemoCaseOddEvenPrint
     * @description 打印 奇数页 or 偶数页
     * @param {}
     * @return {}
     * @example
     */
    async DoDemoCaseOddEvenPrint(docName, isOddNumbers) {
      let editorsTabsView = this.defaultEditorsTabsView;
      let currentEditorsInstance = editorsTabsView.editorsInstance;
      let thinkEditor = currentEditorsInstance.GetEditor(docName);
      //step2: 在该编辑器实例中创建1个文档编辑实体

      if (thinkEditor === undefined) {
        thinkEditor = await editorsTabsView.CreateDocEditor(docName);
        var url = '/system/emr/功能/打印/pages5.xml'; //"/system/test/pacsdemo.jpg";
        let response = await fetch(url, {
          credentials: 'same-origin'
        });
        let xml = await response.arrayBuffer();

        thinkEditor.CreateDoc(docName); //@20220722 必需，才能设置编辑者信息
        thinkEditor.SelectDoc(docName);
        thinkEditor.SetEditorInfo('pc', 'zltest', 'zl', 1, '');
        thinkEditor.ParseDocs(docName, '', xml, {});

        //UI 中显示该文档
        thinkEditor.SetViewMode(E_VIEW_MODE.Edit, {});
      }

      editorsTabsView.SelectDocEditor(docName);

      //打印
      await thinkEditor.PrintDoc({
        printMode: 1, // 0：视图打印 1：矢量打印
        printScopeMode: isOddNumbers ? E_PRINT_SCOPE_MODE.OddNumbers : E_PRINT_SCOPE_MODE.EvenNumbers,
        view: {
          mode: E_VIEW_MODE.Print
          //hideElementHint: true
        }
      });
    }
  }
};
