import { E_TABLE_INSERT_MODE, E_EVENT_KEY, E_VIEW_MODE, E_TABLE_DELETE_MODE } from '@/components/editor/ThinkEditor.Defined';

export const DemoCaseButton = {
  methods: {
    async DoDemoCaseButton() {
      let editorsTabsView = this.defaultEditorsTabsView;
      let currentEditorsInstance = editorsTabsView.editorsInstance;

      var docName = '按钮点击事件';
      let thinkEditor = currentEditorsInstance.GetEditor(docName);

      if (thinkEditor === undefined) {
        thinkEditor = await editorsTabsView.CreateDocEditor(docName);

        var url = '/system/emr/功能/常规/ButtonTest.xml'; //"/system/test/pacsdemo.jpg";
        let response = await fetch(url, {
          credentials: 'same-origin'
        });
        let xml = await response.arrayBuffer();

        thinkEditor.CreateDoc(docName); //@20220722 必需，才能设置编辑者信息
        thinkEditor.SelectDoc(docName);

        thinkEditor.ParseDocs(docName, '', xml, {});

        thinkEditor.SetViewMode(E_VIEW_MODE.Edit, {});

        thinkEditor.addEventListener(E_EVENT_KEY.docModified, this.OnDocModified);
        //or currentEditorsInstance.addEventListener(E_EVENT_KEY.docModified, this.OnDocModified);

        thinkEditor.addEventListener(E_EVENT_KEY.buttonPressed, this.OnButtonPressed);
      }
      //UI 中显示该文档
      editorsTabsView.SelectDocEditor(docName);
    },
    async OnDocModified(e) {
      let thinkEditor = e.data.editor;
    },
    async OnButtonPressed(e) {
      let thinkEditor = e.data.editor;
      let data = e.data.data;
      if (data.id == 'addRow1' || data.action == 'addRow') {
        //自定义 判断条件
        let tableProperties = thinkEditor.GetTableProperties('table1');
        //将输入点 选择到表格最后一行
        let result = thinkEditor.SelectTable(tableProperties.id, { rowIdx: tableProperties.rowsCount - 1, colIdx: 0 });
        if (result) {
          //插入一行
          thinkEditor.TableInsertOpt(E_TABLE_INSERT_MODE.RowDown);
        }
      } else if (data.id == 'delRow1' || data.action == 'delRow') {
        //自定义 判断条件
        let tableProperties = thinkEditor.GetTableProperties('table1');
        if (tableProperties.rowsCount == 1) {
          alert('最后一行，当前应用场景无法删除，应当保留！');
          return;
        }
        //将输入点 选择到表格最后一行
        let result = thinkEditor.SelectTable(tableProperties.id, { rowIdx: tableProperties.rowsCount - 1, colIdx: 0 });
        if (result) {
          //删除当前行
          thinkEditor.TableDeleteOpt(E_TABLE_DELETE_MODE.Row);
        }
      }
    },
    async OnInsertButtonAddRowTest() {
      let currentTabsView = this.defaultEditorsTabsView;
      let currentEditorsInstance = currentTabsView.editorsInstance;
      let currentThinkEditor = currentEditorsInstance.GetSelectedEditor();
      if (currentThinkEditor === undefined) {
        return;
      }

      currentThinkEditor.InsertButton({ id: 'addRow1', width: 2.5, height: 0.8, text: '新增行', action: 'addRow' });
    },
    async OnInsertButtonDelRowTest() {
      let currentTabsView = this.defaultEditorsTabsView;
      let currentEditorsInstance = currentTabsView.editorsInstance;
      let currentThinkEditor = currentEditorsInstance.GetSelectedEditor();
      if (currentThinkEditor === undefined) {
        return;
      }

      currentThinkEditor.InsertButton({ id: 'delRow1', width: 2.5, height: 0.8, text: '删除行', action: 'delRow' });
    }
  }
};
