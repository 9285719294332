<template>
  <el-form ref="form" :model="configData" label-width="80px" size="mini">
    <el-row style="margin-bottom: 10px; min-height: 400px; max-height: 600px; overflow-y: auto">
      <el-col :span="12">
        <el-form-item label="运算集">
          <el-input v-model="inputAlgoGroup" clearble style="width: 200px" size="mini"></el-input>
        </el-form-item>
      </el-col>
      <el-col :span="12">
        <el-form-item label="请选择">
          <el-select @change="changeAlgoGroup" size="mini" style="width: 200px" v-model="selectAlgoGroupStr" placeholder="请选择">
            <el-option v-for="item in algorithmsManager.algorithms" :key="item.algoGroup" :label="item.algoGroup" :value="item.algoGroup"></el-option>
          </el-select>
        </el-form-item>
      </el-col>
      <el-col :span="12">
        <el-form-item label="算法">
          <el-select size="mini" style="width: 200px" v-model="selectAlgoMode" placeholder="请选择">
            <el-option v-for="item in algoModeList" :key="item.value" :label="item.label" :value="item.value" :disabled="item.disabled"></el-option>
          </el-select>
        </el-form-item>
      </el-col>
      <el-col :span="12">
        <el-form-item label="范围">
          <el-select size="mini" style="width: 200px" v-model="selectAlgoScope" placeholder="请选择">
            <el-option v-for="item in algoScopeList" :key="item.value" :label="item.label" :value="item.value" :disabled="item.disabled"></el-option>
          </el-select>
        </el-form-item>
      </el-col>
    </el-row>
    <el-row style="justify-content: right">
      <el-button @click="deleteAlgorithm" size="mini" type="warning">删除算法</el-button>
      <el-button @click="saveAlgorithm" size="mini" type="success">保存</el-button>
    </el-row>
  </el-form>
</template>

<script>
import { AlgorithmsManager, Algorithm, E_ALGO_MODE, E_ALGO_SCOPE } from '@/components/editor/ThinkEditor.Defined';
export default {
  name: 'algorithmSet',
  props: {
    configData: {
      algoGroup: '',
      algoRole: '' //知识库管理时 未用
    },
    algorithmsManager: {
      type: Object,
      default: () => new AlgorithmsManager()
    }
  },
  computed: {},
  data() {
    return {
      inputAlgoGroup: undefined,
      selectAlgoGroupStr: '',
      selectAlgoMode: E_ALGO_MODE.Common,
      selectAlgoScope: E_ALGO_SCOPE.Doc,

      //currentAlgorithmObj: new Algorithm(),
      algoModeList: [
        { value: E_ALGO_MODE.Common, label: '通用' },
        { value: E_ALGO_MODE.Addition, label: '加法' }
      ],
      algoScopeList: [
        { value: E_ALGO_SCOPE.Doc, label: '文档' },
        { value: E_ALGO_SCOPE.Table, label: '表格' },
        { value: E_ALGO_SCOPE.Rom, label: '行' }
      ]
    };
  },
  watch: {
    configData: {
      handler(val) {
        this.SelfInit();
      },
      deep: true
    },
    inputAlgoGroup: {
      handler(val) {
        if (val) {
          this.SelfInit();
        }
      },
      deep: true
    }
  },
  created() {
    this.SelfInit();
  },
  methods: {
    deleteAlgorithm() {
      this.$confirm(`确定删除 运算集:[${this.inputAlgoGroup ? this.inputAlgoGroup : ''}]`, '删除', {
        cancelButtonText: '取消',
        confirmButtonText: '确定',
        type: 'warning',
        center: true
      })
        .then(async () => {
          this.algorithmsManager.DelAlgorithm(this.inputAlgoGroup);
          this.inputAlgoGroup = '';
          this.selectAlgoGroupStr = '';
          this.selectAlgoMode = E_ALGO_MODE.Common;
          this.selectAlgoScope = E_ALGO_SCOPE.Doc;
        })
        .catch(() => {});
    },
    saveAlgorithm() {
      this.algorithmsManager.AddAlgorithm(this.inputAlgoGroup, this.selectAlgoMode, this.selectAlgoScope); //新增 或更新
      this.algorithmsManager.RemoveInvalidData();
      this.selectAlgoGroupStr = this.inputAlgoGroup;
    },
    SelfInit() {
      if (this.inputAlgoGroup === undefined) {
        this.inputAlgoGroup = this.configData.algoGroup; //configData只起初始值作用
      }

      let algorithmObj = this.algorithmsManager.GetAlgorithm(this.inputAlgoGroup);
      if (algorithmObj !== undefined) {
        this.selectAlgoGroupStr = algorithmObj.algoGroup;
        this.selectAlgoMode = algorithmObj.algoMode;
        this.selectAlgoScope = algorithmObj.algoScope;
      }
    },
    changeAlgoGroup(val) {
      this.inputAlgoGroup = val;
    }
  }
};
</script>

<style scoped lang="scss">
::v-deep .el-col {
  margin-top: 10px;
}
</style>
