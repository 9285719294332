<template>
  <el-form :model="dynamicValidateForm" ref="dynamicValidateForm" label-width="80px" class="demo-dynamic">
    <el-row>
      <el-col :span="24" style="margin-bottom: -5px;margin-top: -5px">
        <el-form-item label-width="80px" prop="alignVerticalLayout" label="边距">
          <div style="margin-top: 20px; border-bottom: 1px solid #afafaf"></div>
        </el-form-item>
      </el-col>
      <el-col :span="12">
        <el-form-item label-width="120px" prop="topMargin" label="页眉顶端边距">
          <el-input-number style="width: 160px" size="mini" v-model="dynamicValidateForm.header.topMargin" controls-position="right" :precision="2" :step="0.5" :min="0.0"></el-input-number>
        </el-form-item>
      </el-col>
      <el-col :span="12">
        <el-form-item label-width="120px" prop="bottomMargin" label="页脚底端边距">
          <el-input-number style="width: 160px" size="mini" v-model="dynamicValidateForm.footer.bottomMargin" controls-position="right" :precision="2" :step="0.5" :min="0.0"></el-input-number>
        </el-form-item>
      </el-col>
      <el-col :span="12">
        <el-form-item label-width="120px" prop="bottomMargin" label="">
          <div style="display: flex;align-items: center">
            <el-checkbox v-model="dynamicValidateForm.header.showLine">显示页眉线</el-checkbox>
            <el-checkbox v-model="dynamicValidateForm.footer.showLine">显示页脚线</el-checkbox>
          </div>
          <div style="display: flex;align-items: center">
            <el-checkbox v-model="dynamicValidateForm.header.hide">隐藏页眉</el-checkbox>
            <el-checkbox v-model="dynamicValidateForm.footer.hide">隐藏页脚</el-checkbox>
          </div>
        </el-form-item>
      </el-col>

      <el-col :span="24" style="margin-bottom: -5px;margin-top: -5px">
        <el-form-item label-width="130px" prop="alignVerticalLayout" label="页眉页脚不同">
          <div style="margin-top: 20px; border-bottom: 1px solid #afafaf"></div>
        </el-form-item>
      </el-col>
      <el-col :span="24">
        <el-form-item label-width="80px" prop="weight" label="">
          <div style="display: flex;align-items: center;margin-left: 40px">
            <el-checkbox v-model="dynamicValidateForm.fristPageDiff">首页不同</el-checkbox>
            <el-checkbox v-model="dynamicValidateForm.evenPageDiff">奇偶页不同</el-checkbox>
          </div>
        </el-form-item>
      </el-col>
      <el-col :span="24" style="margin-bottom: -5px;margin-top: -5px">
        <el-form-item label-width="80px" prop="alignVerticalLayout" label="预览">
          <div style="margin-top: 20px; border-bottom: 1px solid #afafaf"></div>
        </el-form-item>
      </el-col>
      <el-col :span="12">
        <el-form-item label-width="80px" prop="optionClass" label="应用于">
          <el-select size="mini" style="width: 200px" v-model="dynamicValidateForm.optionClass" placeholder="请选择">
            <el-option v-for="item in dataList" :key="item.value" :label="item.label" :value="item.value" :disabled="item.disabled"></el-option>
          </el-select>
        </el-form-item>
      </el-col>
    </el-row>
  </el-form>
</template>

<script>
export default {
  name: 'vForm',
  props: {
    value: {
      type: Object,
      default: () => ({})
    }
  },
  components: {},
  data() {
    return {
      disabled: false,
      dynamicValidateForm: {},
      dataList: [
        { value: 0, label: '整篇文档' },
        { value: 1, label: '当前文档节' },
        { value: 2, label: '产生新文档节' }
      ],

      optionIdList: [],
      OptionList: []
    };
  },
  watch: {
    dynamicValidateForm: {
      handler(val) {
        this.$emit('input', val);
      },
      deep: true
    },
    value: {
      handler(val) {
        this.dynamicValidateForm = val;
      },
      deep: true,
      immediate: true
    }
  },
  created() {},
  mounted() {},
  beforeDestroy() {},
  methods: {}
};
</script>

<style scoped></style>
