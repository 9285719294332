<template>
  <VacantDialog title="查找和替换" width="500px" ref="thisDialog" :top="'10%'" :confirmTxt="confirmTxt" @close="closeDialog">
    <el-form :model="configData" ref="configData" label-width="80px" class="demo-dynamic">
      <el-row>
        <el-col :span="22">
          <el-form-item label-width="80px" prop="selectBox" label="查找内容">
            <el-input clearble size="mini" v-model="configData.searchStr"></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="22" style="position: relative">
          <el-checkbox style="position: absolute; top: 11px; left: 5px" v-model="disabled"></el-checkbox>
          <el-form-item label-width="80px" prop="alignVerticalLayout" label="替换为">
            <el-input clearble size="mini" v-model="configData.replaceStr" :disabled="!disabled" style="width: 100%"></el-input>
          </el-form-item>
        </el-col>
      </el-row>
    </el-form>
    <div style="display: flex; align-items: center">
      <div style="border: 1px solid #ccc; border-radius: 5px; padding: 5px">
        <div style="margin-bottom: 5px">
          <el-checkbox v-model="configData.upSearch" :true-label="1" label="向上查找"></el-checkbox>
        </div>
      </div>
      <div style="margin-left: 20px">
        <div style="margin-bottom: 5px">
          <el-checkbox v-model="configData.caseInsensitive" label="">不区分大小写</el-checkbox>
          <el-checkbox v-model="configData.includeElementHint" label="includeElementHint">包括元素提示信息</el-checkbox>
        </div>
        <el-checkbox v-model="configData.searchObjectType" :true-label="2">作用于对象ID</el-checkbox>
      </div>
    </div>
    <template #footer>
      <el-button @click="search(0)" size="mini" type="primary">查找</el-button>
      <el-button @click="search(1)" size="mini" type="primary">替换</el-button>
      <el-button @click="search(2)" size="mini" type="primary">替换所有</el-button>
      <el-button @click="closeDialog" size="mini" type="info">取消</el-button>
    </template>
  </VacantDialog>
</template>

<script>
import { E_EVENT_KEY, E_IDENTITY_OBJECT_TYPE } from '@/components/editor/ThinkEditor.Defined';
import { UpdateConfigData } from '@/components/editor/ThinkEditor.Utils';
import { editorsInstanceManager } from '@/components/editorHelper/ThinkEditorInstance.Manager';

export default {
  name: 'index',
  components: {},
  data() {
    return {
      thinkEditor: undefined,
      active: 1,
      disabled: false,
      flag: false,
      confirmTxt: '插入',
      configData: {
        searchStr: '',
        replaceStr: '',
        caseInsensitive: false,
        includeElementHint: false,
        searchObjectType: E_IDENTITY_OBJECT_TYPE.Unknown,
        upSearch: false //默认不反向查找
      }
    };
  },
  created() {
    editorsInstanceManager.addEventListener(E_EVENT_KEY.editorFocus, this.OnDocEditorFocusChange);
    let currentEditorsInstance = editorsInstanceManager.GetSelectedEditorsInstance();
    this.thinkEditor = currentEditorsInstance?.GetSelectedEditor();
  },
  methods: {
    OnDocEditorFocusChange(e) {
      this.thinkEditor = e.data.editor;
    },
    openDialog(data) {
      UpdateConfigData(this, data);

      this.$refs.thisDialog.openDialog();
    },
    closeDialog() {
      this.$refs.thisDialog.closeDialog();
      this.thinkEditor.SetInputFocus(true);
    },
    search(val) {
      this.configData.mode = val;
      if (this.configData.searchObjectType == E_IDENTITY_OBJECT_TYPE.Unknown) {
        this.thinkEditor.SearchReplace(this.configData);
      } else {
        //按元素Id查找
        this.thinkEditor.Search(this.configData);
      }
    }
  }
};
</script>

<style scoped lang="scss">
::v-deep .el-input--medium .el-input__inner {
  height: 60px;
}

::v-deep .el-select-dropdown__item {
  height: 60px !important;
  line-height: 60px;
  border-bottom: 2px solid #ccc;
  margin: 0 10px;
}

::v-deep .el-form-item {
  margin-bottom: -8px !important;
}

.tab-style {
  display: flex;
  align-items: center;
  justify-content: flex-start;

  .tab {
    padding: 6px 10px;
    border: 1px solid #ccc;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    margin-right: 1px;
    background: #e0e0e0;
    cursor: pointer;
  }

  .active {
    background: #61a3e6;
  }
}

::v-deep .el-popper {
  margin-top: 0;
}
</style>
