<template>
  <el-form ref="form" :model="configData" label-width="80px" size="mini">
    <el-row style="margin-bottom: 10px">
      <el-col :span="12">
        <el-form-item prop="id" label="选项类">
          <el-input v-model="inputOptionClass" clearble size="mini"></el-input>
        </el-form-item>
      </el-col>
      <el-col :span="12">
        <el-form-item prop="id" label="请选择">
          <el-select @change="changeOptionClass" size="mini" style="width: 200px" v-model="selectOptionClassStr" placeholder="请选择">
            <el-option v-for="item in optionsManager.options" :key="item.optionClass" :label="item.optionClass" :value="item.optionClass"></el-option>
          </el-select>
        </el-form-item>
      </el-col>
      <el-col :span="12">
        <el-form-item prop="id" label="选项组">
          <el-input v-model="inputOptionId" clearble size="mini"></el-input>
        </el-form-item>
      </el-col>
      <el-col :span="12">
        <el-form-item label="请选择">
          <el-select size="mini" style="width: 200px" v-model="selectOptionIdStr" @change="changeOptionId" placeholder="请选择">
            <el-option v-for="item in currentOptionClassObj.optionIds" :key="item.optionId" :label="item.optionId" :value="item.optionId"></el-option>
          </el-select>
        </el-form-item>
      </el-col>
    </el-row>
    <el-table height="350" :data="currentOptionIdObj.items" style="min-height: 400px; max-height: 600px; overflow-y: auto">
      <el-table-column prop="id" label="选项ID" align="center" width="120">
        <template slot-scope="scope">
          <el-input v-model="currentOptionIdObj.items[scope.$index].id" clearble size="mini" @input="handleAdd(scope.$index, scope.row)"></el-input>
        </template>
      </el-table-column>
      <el-table-column prop="group" label="组ID" align="center">
        <template slot-scope="scope">
          <el-input v-model="currentOptionIdObj.items[scope.$index].group" clearble size="mini" @input="handleAdd(scope.$index, scope.row)"></el-input>
        </template>
      </el-table-column>
      <el-table-column prop="weight" label="权值" align="center">
        <template slot-scope="scope">
          <el-input v-model="currentOptionIdObj.items[scope.$index].weight" clearble size="mini" @input="handleAdd(scope.$index, scope.row)"></el-input>
        </template>
      </el-table-column>
      <el-table-column prop="content" label="内容" align="center" width="190">
        <template slot-scope="scope">
          <el-input v-model="currentOptionIdObj.items[scope.$index].content" clearble size="mini" @input="handleAdd(scope.$index, scope.row)"></el-input>
        </template>
      </el-table-column>
    </el-table>
    <el-row style="justify-content: right">
      <el-button @click="deleteOption" size="mini" type="warning">删除选项组</el-button>
      <el-button @click="saveOption" size="mini" type="success">保存</el-button>
    </el-row>
  </el-form>
</template>

<script>
import { OptionsManager, OptionClass, OptionId } from '@/components/editor/ThinkEditor.Defined';
export default {
  name: 'optionSet',
  props: {
    configData: {
      optionClass: '',
      optionId: ''
    },
    optionsManager: {
      type: Object,
      default: () => new OptionsManager()
    }
  },
  computed: {},
  data() {
    return {
      inputOptionClass: undefined,
      inputOptionId: undefined,
      selectOptionClassStr: '',
      selectOptionIdStr: '',
      currentOptionClassObj: new OptionClass(),
      currentOptionIdObj: new OptionId()
    };
  },
  watch: {
    configData: {
      handler(val) {
        if (val) {
          this.SelfInit();
        }
      },
      deep: true
    },
    inputOptionClass: {
      handler(val) {
        if (val) {
          this.SelfInit();
        }
      },
      deep: true
    },
    inputOptionId: {
      handler(val) {
        if (val) {
          this.SelfInit();
        }
      },
      deep: true
    }
  },
  created() {
    this.SelfInit();
  },

  methods: {
    deleteOption() {
      this.$confirm(`确定删除 选项类:[${this.inputOptionClass}] 下 选项组:[${this.inputOptionId}]`, '删除', {
        cancelButtonText: '取消',
        confirmButtonText: '确定',
        type: 'warning',
        center: true
      })
        .then(async () => {
          this.optionsManager.DeleteOptionId(this.inputOptionClass, this.inputOptionId);
          this.inputOptionId = '';
          this.selectOptionIdStr = '';
          this.currentOptionIdObj = new OptionId();
        })
        .catch(() => {});
    },
    saveOption() {
      this.optionsManager.AddOption(this.inputOptionClass, this.inputOptionId, this.currentOptionIdObj.items);
      this.optionsManager.RemoveInvalidData();
      this.currentOptionClassObj = this.optionsManager.GetOptionClass(this.inputOptionClass);
      if (this.currentOptionClassObj === undefined) {
        this.selectOptionClassStr = '';
        this.currentOptionClassObj = new OptionClass();
      } else {
        this.selectOptionClassStr = this.inputOptionClass;
        this.currentOptionIdObj = this.currentOptionClassObj.GetOptionId(this.inputOptionId);
        if (this.currentOptionIdObj === undefined) {
          this.selectOptionIdStr = '';
          this.currentOptionIdObj = new OptionId();
        } else {
          this.selectOptionIdStr = this.inputOptionId;
        }
      }
      this.addInputRow();
    },
    onCancle() {},
    addInputRow() {
      let items = this.currentOptionIdObj.items;
      for (let item of items) {
        if (item.id == '') {
          return;
        }
      }

      items.push({
        id: '',
        group: '1',
        weight: '1.00',
        content: ''
      });
    },
    SelfInit() {
      if (this.inputOptionClass === undefined) {
        this.inputOptionClass = this.configData.optionClass; //configData只起初始值作用
      }
      if (this.inputOptionId === undefined) {
        this.inputOptionId = this.configData.optionId; //configData只起初始值作用
      }
      let optionClassObj = this.optionsManager.GetOptionClass(this.inputOptionClass);
      if (optionClassObj === undefined) {
        this.selectOptionClassStr = '';
        this.selectOptionIdStr = '';
        this.currentOptionClassObj = new OptionClass();
        this.currentOptionIdObj = new OptionId();
      } else {
        this.selectOptionClassStr = this.inputOptionClass;
        this.currentOptionClassObj = optionClassObj;

        let currentOptionIdObj = optionClassObj.GetOptionId(this.inputOptionId);
        if (currentOptionIdObj === undefined) {
          this.selectOptionIdStr = '';
          this.currentOptionIdObj = new OptionId();
        } else {
          this.selectOptionIdStr = this.inputOptionId;
          this.currentOptionIdObj = currentOptionIdObj;
        }
      }

      this.addInputRow();
    },
    // 添加一行表格
    handleAdd(index, row) {
      if (index + 1 === this.currentOptionIdObj.items.length) {
        this.currentOptionIdObj.items.push({
          content: '',
          group: '1',
          id: '',
          weight: '1.00'
        });
      }
    },
    changeOptionClass(val) {
      this.inputOptionClass = val;
    },
    changeOptionId(val) {
      this.inputOptionId = val;
    }
  }
};
</script>

<style scoped lang="scss">
::v-deep .el-col {
  margin-top: 10px;
}

::v-deep .el-table__cell {
  padding: 5px 0 !important;
}
</style>
