import { E_EVENT_KEY, E_VIEW_MODE } from '@/components/editor/ThinkEditor.Defined';

export const DemoCaseOwner = {
  methods: {
    async DoDemoCaseOwner() {
      let editorsTabsView = this.defaultEditorsTabsView;
      let currentEditorsInstance = editorsTabsView.editorsInstance;

      var docName = '[归属]-权限';
      let thinkEditor = currentEditorsInstance.GetEditor(docName);

      if (thinkEditor === undefined) {
        thinkEditor = await editorsTabsView.CreateDocEditor(docName);

        var url = '/system/emr/演示/00-演示病例/AdmissionRecord.xml';
        let response = await fetch(url, {
          credentials: 'same-origin'
        });
        let xml = await response.arrayBuffer();

        thinkEditor.CreateDoc(docName); //@20220722 必需，才能设置编辑者信息
        thinkEditor.SelectDoc(docName);

        thinkEditor.ParseDocs(docName, '', xml, {});
        /*************************************************
         * 配置编辑者信息
         * **********************************************/
        thinkEditor.SetEditorInfo('pc', 'appGuid1', '李护士', 1, {});

        thinkEditor.SetViewMode(E_VIEW_MODE.Edit, {});
      }
      //UI 中显示该文档
      editorsTabsView.SelectDocEditor(docName);
    },

    async OnSetEditorInfoTest_1() {
      let currentTabsView = this.defaultEditorsTabsView;
      let currentEditorsInstance = currentTabsView.editorsInstance;
      let currentThinkEditor = currentEditorsInstance.GetSelectedEditor();
      if (currentThinkEditor === undefined) {
        return;
      }

      currentThinkEditor.SetEditorInfo('pc', 'appGuid1', '李护士', 1, {});
    },

    async OnSetEditorInfoTest_2() {
      let currentTabsView = this.defaultEditorsTabsView;
      let currentEditorsInstance = currentTabsView.editorsInstance;
      let currentThinkEditor = currentEditorsInstance.GetSelectedEditor();
      if (currentThinkEditor === undefined) {
        return;
      }

      currentThinkEditor.SetEditorInfo('pc', 'appGuid2', '张护士', 1, {});
    },
    async OnSetObjectOwnerTest() {
      if (this.ownerClassValue == 'row') {
        this.OnSetRowOwnerTest();
      } else if (this.ownerClassValue == 'cell') {
        this.OnSetCellOwnerTest();
      } else if (this.ownerClassValue == 'paragraph') {
        this.OnSetParagraphOwnerTest();
      }
    },
    async OnClearObjectOwnerTest() {
      if (this.ownerClassValue == 'row') {
        this.OnClearRowOwnerTest();
      } else if (this.ownerClassValue == 'cell') {
        this.OnClearCellOwnerTest();
      } else if (this.ownerClassValue == 'paragraph') {
        this.OnClearParagraphOwnerTest();
      }
    },
    async OnSetRowOwnerTest() {
      let currentTabsView = this.defaultEditorsTabsView;
      let currentEditorsInstance = currentTabsView.editorsInstance;
      let currentThinkEditor = currentEditorsInstance.GetSelectedEditor();
      if (currentThinkEditor === undefined) {
        return;
      }
      var focusProperties = currentThinkEditor.GetFocusProperties();
      if (focusProperties.table == undefined) {
        return;
      }

      //let rowsCount = focusProperties.table.rowsCount;
      let colsCount = focusProperties.table.colsCount;
      let rowIdx = focusProperties.table.row.rowIdx;

      //锁定当前行所有者并签名 ownerIsCurrentEditor
      let ret = currentThinkEditor.SetObjectOwner({ object: 'row', rowIdx: rowIdx, ownerIsCurrentEditor: true });
    },
    async OnClearRowOwnerTest() {
      let currentTabsView = this.defaultEditorsTabsView;
      let currentEditorsInstance = currentTabsView.editorsInstance;
      let currentThinkEditor = currentEditorsInstance.GetSelectedEditor();
      if (currentThinkEditor === undefined) {
        return;
      }

      var focusProperties = currentThinkEditor.GetFocusProperties();
      if (focusProperties.table == undefined) {
        return;
      }
      let rowIdx = focusProperties.table.row.rowIdx;

      //解除当前行编辑者限定 clearOwner
      currentThinkEditor.SetObjectOwner({ object: 'row', rowIdx: rowIdx, clearOwner: true });
    },
    async OnSetCellOwnerTest() {
      let currentTabsView = this.defaultEditorsTabsView;
      let currentEditorsInstance = currentTabsView.editorsInstance;
      let currentThinkEditor = currentEditorsInstance.GetSelectedEditor();
      if (currentThinkEditor === undefined) {
        return;
      }
      var focusProperties = currentThinkEditor.GetFocusProperties();
      if (focusProperties.table == undefined) {
        return;
      }

      let rowIdx = focusProperties.table.cell.rowIdx;
      let colIdx = focusProperties.table.cell.colIdx;
      //锁定当前行所有者并签名 ownerIsCurrentEditor
      currentThinkEditor.SetObjectOwner({ object: 'cell', rowIdx: rowIdx, colIdx: colIdx, ownerIsCurrentEditor: true });
    },
    async OnClearCellOwnerTest() {
      let currentTabsView = this.defaultEditorsTabsView;
      let currentEditorsInstance = currentTabsView.editorsInstance;
      let currentThinkEditor = currentEditorsInstance.GetSelectedEditor();
      if (currentThinkEditor === undefined) {
        return;
      }

      var focusProperties = currentThinkEditor.GetFocusProperties();
      if (focusProperties.table == undefined) {
        return;
      }

      let rowIdx = focusProperties.table.cell.rowIdx;
      let colIdx = focusProperties.table.cell.colIdx;

      currentThinkEditor.SetObjectOwner({ object: 'cell', rowIdx: rowIdx, colIdx: colIdx, clearOwner: true });
    },
    async OnSetParagraphOwnerTest() {
      let currentTabsView = this.defaultEditorsTabsView;
      let currentEditorsInstance = currentTabsView.editorsInstance;
      let currentThinkEditor = currentEditorsInstance.GetSelectedEditor();
      if (currentThinkEditor === undefined) {
        return;
      }
      var focusProperties = currentThinkEditor.GetFocusProperties();
      if (focusProperties.paragraph == undefined) {
        return;
      }

      currentThinkEditor.SetObjectOwner({ object: 'paragraph', ownerIsCurrentEditor: true });
    },
    async OnClearParagraphOwnerTest() {
      let currentTabsView = this.defaultEditorsTabsView;
      let currentEditorsInstance = currentTabsView.editorsInstance;
      let currentThinkEditor = currentEditorsInstance.GetSelectedEditor();
      if (currentThinkEditor === undefined) {
        return;
      }

      var focusProperties = currentThinkEditor.GetFocusProperties();
      if (focusProperties.paragraph == undefined) {
        return;
      }

      //解除当前行编辑者限定 clearOwner
      currentThinkEditor.SetObjectOwner({ object: 'paragraph', clearOwner: true });
    }
  }
};
