<template>
  <VacantDialog title="段落" width="608px" ref="thisDialog" :footer-show="false">
    <el-form ref="form" :inline="true" :model="configData" label-width="80px" size="mini">
      <el-tabs v-model="activeName" type="card" @tab-click="handleClick" style="margin-bottom: 20px">
        <el-tab-pane label="属性" name="first">
          <el-divider content-position="left">常规</el-divider>
          <el-form-item label="ID">
            <el-input v-model="configData.id" style="width: 192px"></el-input>
          </el-form-item>
          <el-form-item label="名称">
            <el-input v-model="configData.name" style="width: 192px"></el-input>
          </el-form-item>
          <el-form-item label="对齐方式">
            <el-select v-model="configData.alignHorizontalMode" placeholder="" @change="alignment">
              <el-option v-for="item in eAlign" :key="item.value" :label="item.label" :value="item.value"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="大纲级别">
            <el-select v-model="configData.listLevel" placeholder="" @change="listLe">
              <el-option v-for="item in tLevel" :key="item.value" :label="item.label" :value="item.value"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="段落样式">
            <el-select v-model="configData.listMode" placeholder="" @change="listModese">
              <el-option v-for="item in tMode" :key="item.value" :label="item.label" :value="item.value"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="样式定义">
            <el-input v-model="ListHeaderone" style="width: 96px" @input="listHeaderFun"></el-input>
            <el-input v-model="ListHeadertwo" style="width: 96px" @input="listHeaderFun"></el-input>
          </el-form-item>

          <el-divider content-position="left">缩进</el-divider>
          <el-form-item label="左缩进">
            <el-input-number v-model="configData.leftIndent" controls-position="right" :min="0" :step="0.05" :precision="2" @change="leftInt"></el-input-number>
          </el-form-item>
          <el-form-item label="右缩进">
            <el-input-number v-model="configData.rightIndent" controls-position="right" :min="0" :step="0.05" :precision="2" @change="rightInt"></el-input-number>
          </el-form-item>
          <el-form-item label="特殊缩进">
            <el-select v-model="configData.specificIndentFormat" placeholder="" @change="specificIndentFormatChange">
              <el-option v-for="item in tFormat" :key="item.value" :label="item.label" :value="item.value"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="特殊缩进值" label-width="100px">
            <el-input-number v-model="configData.specificIndentValue" controls-position="right" :min="0" :step="0.05" :precision="2" @change="indentationVal"></el-input-number>
          </el-form-item>

          <el-divider content-position="left">间距</el-divider>
          <el-form-item label="段前">
            <el-input-number v-model="configData.spaceBefore" controls-position="right" :min="0" :step="0.05" :precision="2" @change="SpaceBefore"></el-input-number>
          </el-form-item>
          <el-form-item label="段后">
            <el-input-number v-model="configData.spaceAfter" controls-position="right" :min="0" :step="0.05" :precision="2" @change="SpaceAfter"></el-input-number>
          </el-form-item>
          <el-form-item label="行距">
            <el-select v-model="configData.lineSpaceRule" placeholder="" @change="lineSpaceRuleChange">
              <el-option v-for="item in eRule" :key="item.value" :label="item.label" :value="item.value"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="行距值">
            <el-input-number v-model="configData.lineSpaceValue" controls-position="right" :min="0" :step="0.05" :precision="2" @change="lineSpaceValueChange"></el-input-number>
          </el-form-item>
        </el-tab-pane>
        <el-tab-pane label="配置" name="second">
          <el-divider content-position="left">控制属性</el-divider>
          <el-checkbox v-model="configData.forbidSeriesSplit" :true-label="1" :false-label="0">禁止连词分行</el-checkbox>

          <br />
          <el-form-item label="分段模式">
            <el-select v-model="configData.splitParagraphMode" placeholder="">
              <el-option v-for="item in hMode" :key="item.value" :label="item.label" :value="item.value"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item size="large" style="margin-left: 398px; margin-top: 20px">
            <el-button type="primary" @click="onConfirm">确定</el-button>
            <el-button @click="onCancle">取消</el-button>
          </el-form-item>
        </el-tab-pane>
      </el-tabs>
    </el-form>
  </VacantDialog>
</template>
<script>
import {
  E_EVENT_KEY,
  E_DISPLAY_MODE,
  E_ALIGN_HORIZONTAL_MODE, //对齐方式
  E_LIST_LEVEL, //大纲级别
  E_LIST_MODE, //段落样式
  E_SPECIFIC_INDENT_FORMAT, //特殊缩进
  E_LINE_SPACE_RULE //行距
} from '@/components/editor/ThinkEditor.Defined';
import { editorsInstanceManager } from '@/components/editorHelper/ThinkEditorInstance.Manager';

import { UpdateConfigData } from '@/components/editor/ThinkEditor.Utils';
export default {
  props: {},
  data() {
    return {
      configData: {},
      thinkEditor: undefined,
      activeName: 'first',
      eAlign: [
        {
          key: 'Left',
          label: '左对齐',
          value: 0
        },
        {
          key: 'Center',
          label: '水平居中',
          value: 1
        },
        {
          key: 'Right',
          label: '右对齐',
          value: 2
        },
        {
          key: 'Justify',
          label: '两端对齐',
          value: 3
        },
        {
          key: 'Distribute',
          label: '分散对齐',
          value: 4
        }
      ],

      tLevel: [
        {
          key: 'Normal',
          label: '无',
          value: 0
        },
        {
          key: 'Level_1',
          label: '一级',
          value: 1
        },
        {
          key: 'Level_2',
          label: '二级',
          value: 2
        },
        {
          key: 'Level_3',
          label: '三级',
          value: 3
        },
        {
          key: 'Level_4',
          label: '四级',
          value: 4
        },
        {
          key: 'Level_5',
          label: '五级',
          value: 5
        },
        {
          key: 'Level_6',
          label: '六级',
          value: 6
        },
        {
          key: 'Level_7',
          label: '七级',
          value: 7
        },
        {
          key: 'Level_8',
          label: '八级',
          value: 8
        },
        {
          key: 'Level_9',
          label: '九级',
          value: 9
        }
      ],

      tMode: [
        {
          key: 'SymbolListModel_0',
          value: 0,
          label: ' '
        },
        {
          key: 'SymbolListModel_0',
          value: 1,
          label: '●'
        },
        {
          key: 'SymbolListModel_1',
          value: 2,
          label: '■'
        },
        {
          key: 'SymbolListModel_2',
          value: 3,
          label: '√'
        },
        {
          key: 'OrderListModel_0',
          value: 11,
          label: '1 2 3'
        },
        {
          key: 'OrderListModel_1',
          value: 12,
          label: 'a b c'
        },
        {
          key: 'OrderListModel_2',
          value: 13,
          label: 'A B C'
        },
        {
          key: 'OrderListModel_3',
          value: 14,
          label: 'i ii iii iv'
        },
        {
          key: 'OrderListModel_4',
          value: 15,
          label: 'I II III IV'
        },
        {
          key: 'OrderListModel_5',
          value: 16,
          label: '一'
        },
        {
          key: 'OrderListModel_6',
          value: 17,
          label: '壹'
        },
        {
          key: 'OrderListModel_7',
          value: 18,
          label: '甲 乙'
        },
        {
          key: 'OrderListModel_8',
          value: 19,
          label: '子 丑'
        }
      ],

      tFormat: [
        {
          key: 'NoIndent',
          value: 0,
          label: '无'
        },
        {
          key: 'FristLine',
          value: 1,
          label: '首行缩进'
        },
        {
          key: 'Hanging',
          value: 2,
          label: '悬挂缩进'
        }
      ],
      eRule: [
        {
          key: 'LineSpaceSingle',
          value: 0,
          label: '单倍行距'
        },
        {
          key: 'LineSpace1pt5',
          value: 1,
          label: '1.5倍行距'
        },
        {
          key: 'LineSpaceDouble',
          value: 2,
          label: '2倍行距'
        },
        {
          key: 'LineSpaceAtLeast',
          value: 3,
          label: '最小值'
        },
        {
          key: 'LineSpaceExactly',
          value: 4,
          label: '固定值'
        },
        {
          key: 'LineSpaceMultiple',
          value: 5,
          label: '多倍行距'
        }
      ],

      hMode: [
        {
          key: 'FollowParent',
          value: 0,
          label: '跟随父级	'
        },
        {
          key: 'BreakLine0SplitParagraph0',
          value: 1,
          label: '禁止-断行分段	'
        },
        {
          key: 'BreakLine1SplitParagraph0',
          value: 2,
          label: '可断行-不分段	'
        },
        {
          key: 'BreakLine1SplitParagraph1',
          value: 3,
          label: '可断行-可分段	'
        }
      ],

      ListHeaderArr: [],
      ListHeaderone: '',
      ListHeadertwo: ''
    };
  },

  watch: {},
  created() {
    editorsInstanceManager.addEventListener(E_EVENT_KEY.editorFocus, this.OnDocEditorFocusChange);
    let currentEditorsInstance = editorsInstanceManager.GetSelectedEditorsInstance();
    this.thinkEditor = currentEditorsInstance?.GetSelectedEditor();
  },

  methods: {
    OnDocEditorFocusChange(e) {
      this.thinkEditor = e.data.editor;
    },
    openDialog(configData) {
      UpdateConfigData(this, configData);
      this.Init();
      this.$refs.thisDialog.openDialog();
    },
    closeDialog() {
      this.$refs.thisDialog.closeDialog();
      this.thinkEditor.SetInputFocus(true);
    },
    onConfirm() {
      this.thinkEditor.SetParagraphConfig('', this.configData);
    },

    onCancle() {
      this.closeDialog();
    },

    Init() {
      let seg = this.configData.headerStr.split('$');
      this.ListHeaderArr = seg;
      this.ListHeaderone = seg[0];
      this.ListHeadertwo = seg[1];
    },
    handleClick(tab, event) {},
    handleCheckedChange() {},

    // 对齐方式
    alignment(val) {
      this.thinkEditor.SetParagraphAlignContent(val);
    },
    // 大纲级别
    listLe(val) {
      this.thinkEditor.GotoOutline(val);
    },

    // 段落样式
    listModese(val) {
      this.thinkEditor.SetParagraphListMode(val);
    },

    //样式定义
    listHeaderFun(val) {
      this.thinkEditor.SetParagraphListHeaderStr(val);
    },

    // 左缩进
    leftInt(val) {
      this.thinkEditor.SetParagraphLeftIndent(val);
    },
    // 右缩进
    rightInt(val) {
      this.thinkEditor.SetParagraphRightIndent(val);
    },

    // 特殊缩进
    specificIndentFormatChange(val) {
      if (val == E_SPECIFIC_INDENT_FORMAT.NoIndent) {
        this.configData.specificIndentValue = 0;
      } else if (val == E_SPECIFIC_INDENT_FORMAT.FristLine) {
        this.configData.specificIndentValue = 2;
      } else if (val == E_SPECIFIC_INDENT_FORMAT.Hanging) {
        this.configData.specificIndentValue = 0;
      }
      this.thinkEditor.SetParagraphSpecificIndentFormat(val);
    },
    // 特殊缩进值
    indentationVal(val) {
      this.thinkEditor.SetParagraphSpecificIndentCharacterCount(val);
    },

    // 段前间距
    SpaceBefore(val) {
      this.thinkEditor.SetParagraphSpaceBefore(val);
    },
    // 段后间距
    SpaceAfter(val) {
      this.thinkEditor.SetParagraphSpaceAfter(val);
    },
    // 行距
    lineSpaceRuleChange(val) {
      if (val == E_LINE_SPACE_RULE.LineSpaceSingle) {
        this.configData.lineSpaceValue = 1.0;
      } else if (val == E_LINE_SPACE_RULE.LineSpace1pt5) {
        this.configData.lineSpaceValue = 1.5;
      } else if (val == E_LINE_SPACE_RULE.LineSpaceDouble) {
        this.configData.lineSpaceValue = 2.0;
      } else if (val == E_LINE_SPACE_RULE.LineSpaceMultiple) {
        //多倍
        this.configData.lineSpaceValue = 3.0;
      } else if (val == E_LINE_SPACE_RULE.LineSpaceAtLeast) {
        //最小值
        this.configData.lineSpaceValue = 0.42;
      } else if (val == E_LINE_SPACE_RULE.LineSpaceExactly) {
        //固定值
        this.configData.lineSpaceValue = 0.42;
      }
      this.thinkEditor.SetParagraphLineSpacingRule(this.configData.lineSpaceValue);
    },

    // 行距值
    lineSpaceValueChange(val) {
      let newLineSpacingRule = E_LINE_SPACE_RULE.LineSpaceMultiple;
      if (val == 1.0) {
        newLineSpacingRule = E_LINE_SPACE_RULE.LineSpaceSingle;
      } else if (val == 1.5) {
        newLineSpacingRule = E_LINE_SPACE_RULE.LineSpace1pt5;
      } else if (val == 2.0) {
        newLineSpacingRule = E_LINE_SPACE_RULE.LineSpaceDouble;
      }
      this.configData.lineSpaceRule = newLineSpacingRule;
      this.thinkEditor.SetParagraphLineSpacingValue(val);
    }
  }
};
</script>

<style scoped>
.el-divider--horizontal {
  margin: 14px 0;
}
.el-form-item--mini.el-form-item,
.el-form-item--small.el-form-item {
  margin-bottom: 5px;
}
:deep(.dialog-footer) {
  height: 0;
}
:deep(.el-divider--horizontal) {
  margin: 15px 0;
}

:deep(.el-form-item--mini.el-form-item, .el-form-item--small.el-form-item) {
  margin-bottom: 8px;
}

:deep(.el-dialog .dialog-footer[data-v-784e3bea]) {
  height: 0px;
}

:deep(.el-tabs--border-card > .el-tabs__content) {
  padding: 0px 15px;
}
</style>
