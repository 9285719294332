<template>
  <el-form :model="dynamicValidateForm" ref="dynamicValidateForm" label-width="80px" class="demo-dynamic">
    <el-row>
      <el-col :span="24" style="margin-bottom: -5px;margin-top: -5px">
        <el-form-item label-width="80px" prop="alignVerticalLayout" label="尺寸">
          <div style="margin-top: 20px; border-bottom: 1px solid #afafaf"></div>
        </el-form-item>
      </el-col>
      <el-col :span="12">
        <el-form-item prop="checkFigureStyle" label="页面尺寸">
          <el-select ref="paperSizeDom" size="mini" style="width: 200px" v-model="paperInfo.paperSize" placeholder="请选择" @change="paperSizeChange">
            <el-option v-for="item in paperSizeList" :key="item.value" :label="item.label" :value="item.value" :disabled="item.disabled"></el-option>
          </el-select>
        </el-form-item>
      </el-col>
      <el-col :span="12">
        <el-form-item prop="checkFigureStyle" label="页面方向">
          <el-select ref="paperDirectionDom" size="mini" style="width: 200px" v-model="paperInfo.paperDirection" placeholder="请选择" @change="paperDirectionChange">
            <el-option v-for="item in paperDirectionList" :key="item.value" :label="item.label" :value="item.value" :disabled="item.disabled"></el-option>
          </el-select>
        </el-form-item>
      </el-col>
      <el-col :span="12">
        <el-form-item label-width="80px" prop="width" label="宽度">
          <el-input-number ref="paperWidthDom" style="width: 200px" size="mini" v-model="dynamicValidateForm.width" controls-position="right" :precision="2" :step="0.5" :min="0.0"></el-input-number>
        </el-form-item>
      </el-col>
      <el-col :span="12">
        <el-form-item label-width="80px" prop="height" label="高度">
          <el-input-number ref="paperHeightDom" style="width: 200px" size="mini" v-model="dynamicValidateForm.height" controls-position="right" :precision="2" :step="0.5" :min="0.0"></el-input-number>
        </el-form-item>
      </el-col>
      <el-col :span="24" style="margin-bottom: -5px;margin-top: -5px">
        <el-form-item label-width="80px" prop="alignVerticalLayout" label="页边距">
          <div style="margin-top: 20px; border-bottom: 1px solid #afafaf"></div>
        </el-form-item>
      </el-col>
      <el-col :span="12">
        <el-form-item label-width="80px" prop="topPadding" label="上">
          <el-input-number style="width: 200px" size="mini" v-model="dynamicValidateForm.topPadding" controls-position="right" :precision="2" :step="0.5" :min="0.0"></el-input-number>
        </el-form-item>
      </el-col>
      <el-col :span="12">
        <el-form-item label-width="80px" prop="bottomPadding" label="下">
          <el-input-number style="width: 200px" size="mini" v-model="dynamicValidateForm.bottomPadding" controls-position="right" :precision="2" :step="0.5" :min="0.0"></el-input-number>
        </el-form-item>
      </el-col>
      <el-col :span="12">
        <el-form-item label-width="80px" prop="leftPadding" label="左">
          <el-input-number style="width: 200px" size="mini" v-model="dynamicValidateForm.leftPadding" controls-position="right" :precision="2" :step="0.5" :min="0.0"></el-input-number>
        </el-form-item>
      </el-col>
      <el-col :span="12">
        <el-form-item label-width="80px" prop="rightPadding" label="右">
          <el-input-number style="width: 200px" size="mini" v-model="dynamicValidateForm.rightPadding" controls-position="right" :precision="2" :step="0.5" :min="0.0"></el-input-number>
        </el-form-item>
      </el-col>
      <el-col :span="24" style="margin-bottom: -5px;margin-top: -5px">
        <el-form-item label-width="60px" prop="alignVerticalLayout" label="装订线">
          <div style="margin-top: 20px; border-bottom: 1px solid #afafaf"></div>
        </el-form-item>
      </el-col>
      <el-col :span="12">
        <el-form-item label-width="80px" prop="margin" label="装订线">
          <el-input-number style="width: 200px" size="mini" v-model="dynamicValidateForm.bindLine.margin" controls-position="right" :precision="2" :step="0.5" :min="0.0"></el-input-number>
        </el-form-item>
      </el-col>
      <el-col :span="12">
        <el-form-item label-width="100px" prop="position" label="装订线位置">
          <el-select size="mini" style="width: 180px" v-model="dynamicValidateForm.bindLine.position" placeholder="请选择">
            <el-option v-for="item in positionList" :key="item.value" :label="item.label" :value="item.value" :disabled="item.disabled"></el-option>
          </el-select>
        </el-form-item>
      </el-col>
      <el-col :span="12">
        <el-form-item label-width="120px" prop="showLine" label="">
          <el-checkbox v-model="dynamicValidateForm.bindLine.showLine">是否显示装订线</el-checkbox>
        </el-form-item>
      </el-col>
      <el-col :span="24" style="margin-bottom: -5px;margin-top: -5px">
        <el-form-item label-width="80px" prop="alignVerticalLayout" label="预览">
          <div style="margin-top: 20px; border-bottom: 1px solid #afafaf"></div>
        </el-form-item>
      </el-col>
      <el-col :span="12">
        <el-form-item label-width="80px" prop="optionClass" label="应用于">
          <el-select size="mini" style="width: 200px" v-model="dynamicValidateForm.optionClass" placeholder="请选择">
            <el-option v-for="item in dataList" :key="item.value" :label="item.label" :value="item.value" :disabled="item.disabled"></el-option>
          </el-select>
        </el-form-item>
      </el-col>
    </el-row>
  </el-form>
</template>

<script>
export default {
  name: 'vForm',
  computed: {},
  props: {
    value: {
      type: Object,
      default: () => ({})
    }
  },
  data() {
    return {
      disabled: false,
      paperInfo: {},
      dynamicValidateForm: {},
      paperSizeList: [
        { value: 'A3', label: 'A3' },
        { value: 'A4', label: 'A4' },
        { value: 'A5', label: 'A5' },
        { value: '自定义', label: '自定义' }
      ],
      paperDirectionList: [
        { value: '纵向', label: '纵向' },
        { value: '横向', label: '横向' },
        { value: '无', label: '无' }
      ],
      positionList: [
        { value: 1, label: '左' },
        { value: 2, label: '上' },
        { value: 3, label: '下' }
      ],
      dataList: [
        { value: 0, label: '整篇文档' },
        { value: 1, label: '当前文档节' },
        { value: 2, label: '产生新文档节' }
      ],
      showSelectOnly: false,
      options: [
        { value: 0, label: '输入' },
        { value: 1, label: '输出' }
      ],
      optionIdList: [],
      OptionList: []
    };
  },
  watch: {
    dynamicValidateForm: {
      handler(val) {
        this.$emit('input', val);
      },
      deep: true
    },
    value: {
      handler(val) {
        this.dynamicValidateForm = val;
        this.paperInfo = this.getPaperInfo(this.dynamicValidateForm.width, this.dynamicValidateForm.height);
      },
      deep: true,
      immediate: true
    }
  },
  created() {},
  mounted() {},
  methods: {
    getPaperInfo(paperWidth, paperHeight) {
      let paperSize, paperDirection;
      if (paperWidth == '29.70' && paperHeight == '42.00') {
        paperSize = 'A3';
        paperDirection = '纵向';
      } else if (paperWidth == '42.00' && paperHeight == '29.70') {
        paperSize = 'A3';
        paperDirection = '横向';
      } else if (paperWidth == '21.00' && paperHeight == '29.70') {
        paperSize = 'A4';
        paperDirection = '纵向';
      } else if (paperWidth == '29.70' && paperHeight == '21.00') {
        paperSize = 'A4';
        paperDirection = '横向';
      } else if (paperWidth == '14.00' && paperHeight == '21.00') {
        paperSize = 'A5';
        paperDirection = '纵向';
      } else if (paperWidth == '21.00' && paperHeight == '14.00') {
        paperSize = 'A5';
        paperDirection = '横向';
      } else {
        paperSize = '自定义';
        paperDirection = '无';
      }
      return { paperSize: paperSize, paperDirection: paperDirection };
    },
    paperSizeChange(val) {
      let paperSizeInfo = this.paperSizeList.find(i => {
        return i.value === val;
      });

      switch (paperSizeInfo.label) {
        case 'A3':
          {
            this.dynamicValidateForm.width = '29.70';
            this.dynamicValidateForm.height = '42.00';
            this.paperInfo.paperDirection = '纵向';
          }
          break;
        case 'A4':
          {
            this.dynamicValidateForm.width = '21.00';
            this.dynamicValidateForm.height = '29.70';
            this.paperInfo.paperDirection = '横向';
          }
          break;
        case 'A5':
          {
            this.dynamicValidateForm.width = '14.00';
            this.dynamicValidateForm.height = '21.00';
            this.paperInfo.paperDirection = '横向';
          }
          break;
        default:
          {
            this.paperInfo.paperDirection = '无';
          }
          break;
      }
      this.$forceUpdate();
    },
    paperDirectionChange(val) {
      this.paperInfo = this.getPaperInfo(this.dynamicValidateForm.width, this.dynamicValidateForm.height);

      if (val != this.paperInfo.paperDirection) {
        //纸张方向需要调整
        let tmp = this.dynamicValidateForm.width;
        this.dynamicValidateForm.width = this.dynamicValidateForm.height;
        this.dynamicValidateForm.height = tmp;
      }
    }
  }
};
</script>

<style scoped></style>
