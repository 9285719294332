<template>
  <VacantDialog title="二维码/条形码" width="600px" ref="thisDialog" :top="'10%'" :confirmTxt="confirmTxt" @close="onClose" @confirm="onConfirm">
    <el-form :model="configData" ref="configData" label-width="80px" class="demo-dynamic">
      <el-row>
        <el-col :span="12">
          <el-form-item prop="id" label="ID">
            <el-input size="mini" style="width: 200px" v-model="configData.id"></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label-width="80px" prop="name" label="名称">
            <el-input size="mini" style="width: 200px" v-model="configData.name"></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item prop="width" label="宽度">
            <el-input-number @change="updateBarCode" style="width: 200px" size="mini" v-model="configData.width" controls-position="right" :precision="2" :step="0.5" :min="0.0"></el-input-number>
          </el-form-item>
        </el-col>

        <el-col :span="12">
          <el-form-item label-width="80px" prop="height" label="高度">
            <el-input-number @change="updateBarCode" style="width: 200px" size="mini" v-model="configData.height" controls-position="right" :precision="2" :step="0.5" :min="0.0"></el-input-number>
          </el-form-item>
        </el-col>
        <el-col :span="24">
          <el-form-item prop="width" label="文本">
            <el-input @input="changeText" size="mini" style="width: 450px" v-model="configData.text"></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label-width="80px" prop="alignVerticalLayout" label="种类">
            <el-select @change="onClassChange" size="mini" style="width: 200px" v-model="barCodeClass" placeholder="请选择">
              <el-option v-for="item in classOptions" :key="item.value" :label="item.label" :value="item.value" :disabled="item.disabled"></el-option>
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label-width="80px" prop="alignVerticalLayout" label="类型">
            <el-select @change="changeType" size="mini" style="width: 200px" v-model="configData.type" placeholder="请选择">
              <el-option v-for="item in typeOptions" :key="item.value" :label="item.label" :value="item.value" :disabled="item.disabled"></el-option>
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label-width="80px" prop="alignVerticalLayout" label="垂直对齐">
            <el-select size="mini" style="width: 200px" v-model="configData.alignVerticalLayout" placeholder="请选择">
              <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value" :disabled="item.disabled"></el-option>
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label-width="80px" prop="alignVerticalLayout" label="">
            <el-checkbox @change="changeShowText" v-model="configData.showText">显示文本</el-checkbox>
          </el-form-item>
        </el-col>
        <el-col :span="22">
          <el-form-item label-width="60px" prop="styles" label="">
            <div class="img-style">
              <img
                :style="{
                  width: configData.width * 28 + 'px',
                  height: configData.height * 28 + 'px'
                }"
                :src="'data:image/png;base64,' + configData.imageData"
                alt=""
              />
            </div>
          </el-form-item>
        </el-col>
      </el-row>
    </el-form>
  </VacantDialog>
</template>

<script>
import { E_EVENT_KEY, E_SET_MODE } from '@/components/editor/ThinkEditor.Defined';
import { UpdateConfigData } from '@/components/editor/ThinkEditor.Utils';
import { editorsInstanceManager } from '@/components/editorHelper/ThinkEditorInstance.Manager';
export default {
  name: 'index',
  components: {},
  data() {
    return {
      thinkEditor: undefined,
      configData: {
        class: 0,
        type: 58,
        text: 123456,
        showText: false,
        data: '',
        imageData: '',
        id: '',
        name: '',
        width: '',
        height: '',
        alignVerticalLayout: 1
      },
      classOptions: [
        { value: 0, label: '二维码' },
        { value: 1, label: '条形码' }
      ],
      options: [
        { value: 1, label: '自身居中' },
        { value: 0, label: '底端对齐' },
        { value: 2, label: '其它居中' }
      ],
      barCodeClass: undefined,
      lastQRType: 58, //最近选择的二维码 BARCODE_QRCODE=58
      lastBarCodeType: 20, //最近选择的条形码 CODE128=20

      typeOptions: [],
      flag: false,
      confirmTxt: '插入',

      BarCodeList: []
    };
  },
  created() {
    editorsInstanceManager.addEventListener(E_EVENT_KEY.editorFocus, this.OnDocEditorFocusChange);

    let currentEditorsInstance = editorsInstanceManager.GetSelectedEditorsInstance();
    this.thinkEditor = currentEditorsInstance?.GetSelectedEditor();
  },
  methods: {
    OnDocEditorFocusChange(e) {
      this.thinkEditor = e.data.editor;
    },
    openDialog(data) {
      UpdateConfigData(this, data);
      if (this.configData.setMode == E_SET_MODE.ModifyCommon) {
        this.confirmTxt = '确定';
      } else {
        this.confirmTxt = '插入';
      }

      this.BarCodeList = this.thinkEditor.GetBarCodeList();
      this.barCodeClass = this.thinkEditor.GetBarCodeClass(this.configData.type);
      this.changeClass(this.barCodeClass);

      this.configData.showText = !!this.configData.showText;

      this.updateBarCode();
      this.$refs.thisDialog.openDialog();
    },
    onClassChange(val) {
      this.changeClass(val);
      this.updateBarCode();
    },
    changeClass(val) {
      //0：二维码 1: 条形码
      let codeClass = this.thinkEditor.GetBarCodeClass(this.configData.type);
      if (codeClass != val) {
        if (val == 0) {
          this.configData.type = this.lastQRType;
        } else {
          this.configData.type = this.lastBarCodeType;
        }
      }

      this.typeOptions = this.BarCodeList.filter(item => {
        return item.class === val;
      }).map(item => {
        return {
          value: item.type,
          label: item.typeName
        };
      });
    },
    changeType(val) {
      //0：二维码 1: 条形码
      if (this.barCodeClass == 0) {
        this.lastQRType = val;
      } else {
        this.lastBarCodeType = val;
      }
      this.updateBarCode();
    },
    changeShowText(val) {
      this.updateBarCode();
    },
    updateBarCode() {
      const params = {
        type: this.configData.type,
        data: this.$Base64.encode(this.configData.text + ''),
        showText: this.configData.showText,
        width: Number(this.configData.width),
        height: Number(this.configData.height)
      };

      const data = this.thinkEditor.CalcBarCodeProperties(params);

      this.configData.width = data.width;
      this.configData.height = data.height;

      this.$set(this.configData, 'imageData', data.imageData);
    },
    changeText(val) {
      this.updateBarCode();
    },
    close() {
      this.$refs.thisDialog.closeDialog();
      this.thinkEditor.SetInputFocus(true);
    },
    onClose() {
      this.close();
    },
    onConfirm() {
      if (!this.flag) {
        let params = {
          id: this.configData.id,
          name: this.configData.name,
          width: this.configData.width,
          height: this.configData.height,
          class: this.configData.class,
          type: this.configData.type,
          data: this.$Base64.encode(this.configData.text + ''), //Number(val),
          showText: this.configData.showText
        };

        if (this.confirmTxt === '确定') {
          this.thinkEditor.SetBarCodeConfig('', params);
        } else {
          this.thinkEditor.InsertBarCode(params);
        }
        this.close();
        this.flag = true;
      }
      setTimeout(() => {
        this.flag = false;
      }, 1000);
    }
  }
};
</script>

<style scoped>
::v-deep .el-input--medium .el-input__inner {
  height: 60px;
}

.img-style {
  width: 100%;
  height: 300px;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid #ccc;
  border-radius: 5px;
  text-align: center;
  margin-top: 5px;
}

::v-deep .el-select-dropdown__item {
  height: 60px !important;
  line-height: 60px;
  border-bottom: 2px solid #ccc;
  margin: 0 10px;
}

::v-deep .el-form-item {
  margin-bottom: -8px !important;
}

/*::v-deep .el-col:nth-last-child(1){*/
/*  height: 35px;*/
/*}*/
</style>
