<template>
  <div class="select-box">
    <div @click="selectFormatStyle" class="setdiv" style="height: 60px; width: 340px; border: 1px solid #dcdfe6; border-radius: 8px" v-html="content"></div>
    <transition name="fade">
      <ul class="terminalList" v-show="isShow">
        <li v-for="(item, index) in options" :key="index" class="terminal-item" @click="changeStyle(item.value)" :class="{ 'active-class': selectedStyle === item.vlue }">
          <div ref="Common" v-if="item.value === 0 /*E_FORMULA_STYLE.Common*/" style="float: left; border: 1px solid #ccc">
            <div style="display: flex; align-items: center; border-bottom: 1px solid #000">
              <div style="height: 30px; line-height: 30px; border-right: 1px solid #000; padding-right: 10px; padding-left: 33px; min-width: 70px">Common1</div>
              <div style="height: 30px; line-height: 30px; padding-left: 10px; padding-right: 33px; min-width: 70px">Common2</div>
            </div>
            <div style="display: flex; align-items: center">
              <div style="height: 29px; line-height: 29px; border-right: 1px solid #000; padding-right: 10px; padding-left: 33px; min-width: 70px">Common3</div>
              <div style="height: 29px; line-height: 29px; padding-left: 10px; padding-right: 33px; min-width: 70px">Common4</div>
            </div>
          </div>
          <div ref="MensesStyle_1" v-if="item.value === 1 /*E_FORMULA_STYLE.MensesStyle_1*/" style="float: left; border: 1px solid #ccc">
            <div style="display: flex; align-items: center; padding-left: 30px; padding-right: 30px; width: 167px">
              <div style="min-width: 55px">Value1</div>
              <div>
                <div style="height: 30px; min-width: 46px; border-bottom: 1px solid #000; padding-bottom: 7px; margin-top: -10px">Value2</div>
                <div style="height: 30px; min-width: 46px">Value3</div>
              </div>
              <div style="padding-left: 10px; min-width: 46px">Value4</div>
            </div>
          </div>
          <div ref="MensesStyle_2" v-if="item.value === 2 /*E_FORMULA_STYLE.MensesStyle_2*/" style="float: left; border: 1px solid #ccc">
            <div style="display: flex; align-items: center; border-bottom: 1px solid #000">
              <div style="text-align: right; height: 30px; line-height: 30px; border-right: 1px solid #000; padding-right: 10px; padding-left: 33px; min-width: 70px">Value1</div>
              <div style="height: 30px; line-height: 30px; padding-left: 10px; padding-right: 33px; min-width: 70px">Value2</div>
            </div>
            <div style="display: flex; align-items: center">
              <div style="text-align: right; height: 29px; line-height: 29px; border-right: 1px solid #000; padding-right: 10px; padding-left: 33px; min-width: 70px">Value3</div>
              <div style="height: 29px; line-height: 29px; padding-left: 10px; padding-right: 33px; min-width: 70px">Value4</div>
            </div>
          </div>
          <div ref="MensesStyle_3" v-if="item.value === 3 /*E_FORMULA_STYLE.MensesStyle_3*/" style="float: left; border: 1px solid #ccc">
            <div class="triangle">
              <div style="position: absolute; top: 9px; left: 30px">Value1</div>
              <div style="position: absolute; top: -6px; left: 92px">Value2</div>
              <div style="position: absolute; top: 9px; right: 30px">Value3</div>
              <div style="position: absolute; bottom: -6px; left: 92px">Value4</div>
            </div>
          </div>
          <div ref="MensesStyle_4" v-if="item.value === 4 /*E_FORMULA_STYLE.MensesStyle_4*/" style="float: left; border: 1px solid #ccc">
            <div style="width: 227px; display: flex; align-items: center; justify-content: center">
              <div style="display: flex; align-items: center">
                <div>Value1</div>
                <div class="triangle1"></div>
              </div>
              <div style="margin-left: 10px">
                <div style="height: 30px; line-height: 30px">Value2</div>
                <div style="height: 30px; line-height: 30px">Value3</div>
              </div>
            </div>
          </div>
          <div ref="PupilPosition" v-if="item.value === 5 /*E_FORMULA_STYLE.PupilPosition*/" style="float: left; border: 1px solid #ccc">
            <div style="width: 227px; display: flex; align-items: center; justify-content: center">
              <div>
                <div style="height: 20px; line-height: 20px">Value1</div>
                <div style="height: 20px; line-height: 20px">Value3</div>
                <div style="height: 20px; line-height: 20px">Value6</div>
              </div>
              <div style="margin-left: 10px">
                <div>Value4</div>
              </div>
              <div style="margin-left: 10px">
                <div style="height: 20px; line-height: 20px">Value2</div>
                <div style="height: 20px; line-height: 20px">Value5</div>
                <div style="height: 20px; line-height: 20px">Value7</div>
              </div>
            </div>
          </div>
          <div ref="LightProjection" v-if="item.value === 6 /*E_FORMULA_STYLE.LightProjection*/" style="float: left; border: 1px solid #ccc">
            <div style="width: 227px; display: flex; align-items: center; justify-content: center">
              <div>
                <div style="height: 20px; line-height: 20px">Value1</div>
                <div style="height: 20px; line-height: 20px">Value4</div>
                <div style="height: 20px; line-height: 20px">Value7</div>
              </div>
              <div style="margin-left: 10px">
                <div style="height: 20px; line-height: 20px">Value2</div>
                <div style="height: 20px; line-height: 20px">Value5</div>
                <div style="height: 20px; line-height: 20px">Value8</div>
              </div>
              <div style="margin-left: 10px">
                <div style="height: 20px; line-height: 20px">Value3</div>
                <div style="height: 20px; line-height: 20px">Value6</div>
                <div style="height: 20px; line-height: 20px">Value9</div>
              </div>
            </div>
          </div>
          <div ref="FetalHeart" v-if="item.value === 7 /*E_FORMULA_STYLE.FetalHeart*/" style="float: left; border: 1px solid #ccc">
            <div style="width: 227px; display: flex; align-items: center; justify-content: center; height: 60px">
              <div style="border-right: 1px solid #000; padding-right: 10px">
                <div style="height: 15px; line-height: 15px">Value1</div>
                <div style="height: 20px; line-height: 20px">Value3</div>
                <div style="height: 15px; line-height: 15px">Value5</div>
              </div>
              <div style="margin-left: 10px">
                <div style="height: 15px; line-height: 15px">Value2</div>
                <div style="height: 20px; line-height: 20px">Value4</div>
                <div style="height: 15px; line-height: 15px">Value6</div>
              </div>
            </div>
          </div>
          <div ref="PermanentTeethPos" v-if="item.value === 8 /*E_FORMULA_STYLE.PermanentTeethPos*/" style="float: left; border: 1px solid #ccc">
            <div style="display: flex; align-items: center; border-bottom: 1px solid #000">
              <div style="text-align: right; height: 30px; line-height: 30px; border-right: 1px solid #000; padding-right: 10px; padding-left: 33px; min-width: 70px">87654321</div>
              <div style="height: 30px; line-height: 30px; padding-left: 10px; padding-right: 33px; min-width: 70px">12345678</div>
            </div>
            <div style="display: flex; align-items: center">
              <div style="text-align: right; height: 29px; line-height: 29px; border-right: 1px solid #000; padding-right: 10px; padding-left: 33px; min-width: 70px">87654321</div>
              <div style="height: 29px; line-height: 29px; padding-left: 10px; padding-right: 33px; min-width: 70px">12345678</div>
            </div>
          </div>
          <div ref="DeciduousTeethPos" v-if="item.value === 9 /*E_FORMULA_STYLE.DeciduousTeethPos*/" style="float: left; border: 1px solid #ccc">
            <div style="display: flex; align-items: center; border-bottom: 1px solid #000">
              <div style="height: 30px; line-height: 30px; border-right: 1px solid #000; padding-right: 10px; padding-left: 33px; min-width: 70px">ⅤⅣⅢⅡⅠ</div>
              <div style="height: 30px; line-height: 30px; padding-left: 10px; padding-right: 33px; min-width: 70px">ⅠⅡⅢⅣⅤ</div>
            </div>
            <div style="display: flex; align-items: center">
              <div style="height: 29px; line-height: 29px; border-right: 1px solid #000; padding-right: 10px; padding-left: 33px; min-width: 70px">ⅤⅣⅢⅡⅠ</div>
              <div style="height: 29px; line-height: 29px; padding-left: 10px; padding-right: 33px; min-width: 70px">ⅠⅡⅢⅣⅤ</div>
            </div>
          </div>
          <div :key="item.value" ref="refLabel" :class="{ 'active-label-style': selectedStyle === item.value }" class="label-style">
            {{ item.label }}
            <i class="el-icon-caret-bottom"></i>
          </div>
        </li>
      </ul>
    </transition>
  </div>
</template>

<script>
import { E_FORMULA_STYLE } from '@/components/editor/ThinkEditor.Defined';

export default {
  props: {
    //value: E_FORMULA_STYLE, //E_FORMULA_STYLE
    currentStyle: E_FORMULA_STYLE
  },
  data() {
    return {
      isShow: false,
      selectedStyle: E_FORMULA_STYLE.Common,
      content: '',
      options: [
        {
          value: E_FORMULA_STYLE.Common,
          label: '通用公式'
        },
        {
          value: E_FORMULA_STYLE.MensesStyle_1,
          label: '月经公式1'
        },
        {
          value: E_FORMULA_STYLE.MensesStyle_2,
          label: '月经公式2'
        },
        {
          value: E_FORMULA_STYLE.MensesStyle_3,
          label: '月经公式3'
        },
        {
          value: E_FORMULA_STYLE.MensesStyle_4,
          label: '月经公式4'
        },
        {
          value: E_FORMULA_STYLE.PupilPosition,
          label: '瞳位图'
        },
        {
          value: E_FORMULA_STYLE.LightProjection,
          label: '光定位图'
        },
        {
          value: E_FORMULA_STYLE.FetalHeart,
          label: '胎心图'
        },
        {
          value: E_FORMULA_STYLE.PermanentTeethPos,
          label: '恒牙位图'
        },
        {
          value: E_FORMULA_STYLE.DeciduousTeethPos,
          label: '乳牙位图'
        }
      ]
    };
  },
  watch: {
    currentStyle: {
      handler(val) {
        // this.changeStyle(val);
        this.selectedStyle = val;
      },
      immediate: true
    }
  },
  async mounted() {
    this.changeStyle(this.selectedStyle);
  },
  methods: {
    selectFormatStyle() {
      this.isShow = !this.isShow;
    },
    changeStyle(newStyle) {
      this.selectedStyle = newStyle;
      let index = newStyle;

      switch (newStyle) {
        case E_FORMULA_STYLE.Common:
          this.content = this.$refs.Common[0].outerHTML + this.$refs.refLabel[index].outerHTML;
          break;
        case E_FORMULA_STYLE.MensesStyle_1:
          this.content = this.$refs.MensesStyle_1[0].outerHTML + this.$refs.refLabel[index].outerHTML;
          break;
        case E_FORMULA_STYLE.MensesStyle_2:
          this.content = this.$refs.MensesStyle_2[0].outerHTML + this.$refs.refLabel[index].outerHTML;
          break;
        case E_FORMULA_STYLE.MensesStyle_3:
          this.content = this.$refs.MensesStyle_3[0].outerHTML + this.$refs.refLabel[index].outerHTML;
          break;
        case E_FORMULA_STYLE.MensesStyle_4:
          this.content = this.$refs.MensesStyle_4[0].outerHTML + this.$refs.refLabel[index].outerHTML;
          break;
        case E_FORMULA_STYLE.PupilPosition:
          this.content = this.$refs.PupilPosition[0].outerHTML + this.$refs.refLabel[index].outerHTML;
          break;
        case E_FORMULA_STYLE.LightProjection:
          this.content = this.$refs.LightProjection[0].outerHTML + this.$refs.refLabel[index].outerHTML;
          break;
        case E_FORMULA_STYLE.FetalHeart:
          this.content = this.$refs.FetalHeart[0].outerHTML + this.$refs.refLabel[index].outerHTML;
          break;
        case E_FORMULA_STYLE.PermanentTeethPos:
          this.content = this.$refs.PermanentTeethPos[0].outerHTML + this.$refs.refLabel[index].outerHTML;
          break;
        case E_FORMULA_STYLE.DeciduousTeethPos:
          this.content = this.$refs.DeciduousTeethPos[0].outerHTML + this.$refs.refLabel[index].outerHTML;
          break;
      }
      this.isShow = false;
      this.$emit('changeFormatStyle', this.selectedStyle);
    }
  }
};
</script>

<style scoped lang="scss">
.select-box {
  width: 125px;
  height: 100%;
}

.terminalList {
  width: 340px;
  max-height: 400px;
  overflow-y: auto;
  border: 1px solid #ccc;
  border-radius: 10px;
  box-shadow: 0 2px 12px 0 rgb(0 0 0 / 10%);
  position: absolute;
  padding-left: 10px;
  padding-top: 10px;
  padding-bottom: 10px;
  left: 0px;
  top: 50px;
  background: #fff;

  .terminal-item {
    //border: 1px solid #ccc;
    width: 310px;
    height: 60px;
    color: #000;
    display: flex;
    flex-direction: row; /* 子元素横向排列 */
    justify-content: space-between; /* 相对父元素水平居中 */
    align-items: center;
    cursor: pointer;

    &:hover {
      color: #62aff1;

      .label-style {
        background: #9d9d9d;
      }
    }
  }

  .active-class {
    color: #62aff1;
  }
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.8s;
}

.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}

/* 整个滚动条 */
::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}

::-webkit-scrollbar-track {
  background-color: transparent;
  -webkit-border-radius: 2em;
  -moz-border-radius: 2em;
  border-radius: 2em;
}

::-webkit-scrollbar-thumb {
  background-color: rgb(147, 147, 153, 0.5);
  -webkit-border-radius: 2em;
  -moz-border-radius: 2em;
  border-radius: 2em;
}

.label-style {
  float: right;
  color: #000;
  min-width: 90px;
  height: 60px;
  line-height: 60px;
  text-align: center;
  font-size: 14px;
  background: #ebebeb;
  border: 1px solid #ccc;

  &:hover {
    background: #9d9d9d;
  }
}

.active-label-style {
  float: right;
  color: #000;
  min-width: 90px;
  height: 60px;
  line-height: 60px;
  text-align: center;
  font-size: 14px;
  background: #9d9d9d;
  border: 1px solid #ccc;
}

.triangle {
  position: relative;
  width: 227px;
  height: 60px;
  background: linear-gradient(to top right, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0) calc(50% - 1px), rgba(0, 0, 0, 0.8) 50%, rgba(0, 0, 0, 0) calc(50% + 1px), rgba(0, 0, 0, 0) 100%), linear-gradient(to bottom right, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0) calc(50% - 1px), rgba(0, 0, 0, 0.8) 50%, rgba(0, 0, 0, 0) calc(50% + 1px), rgba(0, 0, 0, 0) 100%);
}

.setdiv {
  .label-style {
    min-width: 109px;
  }
}

.triangle1 {
  width: 20px;
  height: 30px;
  line-height: 30px;
  background: linear-gradient(to top right, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0) calc(50% - 1px), rgba(0, 0, 0, 0) 50%, rgba(0, 0, 0, 0) calc(50% + 1px), rgba(0, 0, 0, 0) 100%), linear-gradient(to bottom right, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0) calc(50% - 1px), rgba(0, 0, 0, 0.8) 50%, rgba(0, 0, 0, 0) calc(50% + 1px), rgba(0, 0, 0, 0) 100%);
}
</style>
