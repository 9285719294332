<template>
  <el-tabs v-model="activeValue" @tab-click="tabClick" type="border-card">
    <el-tab-pane v-for="item in tabList" :key="item.name" :label="item.label" :name="item.name">
      <PFrom :form="item.formData.form" :inline="true" :options="item.formData.options" :rules="item.formData.rules" :labelWidth="item.formData.labelWidth"></PFrom>
    </el-tab-pane>
  </el-tabs>
</template>
<script>
import PFrom from './PFrom.vue';

export default {
  data() {
    return {
      label: ''
    };
  },
  components: { PFrom },
  props: {
    active: {
      type: String,
      default: 'first'
    },
    // tabList: {
    //   type: Array,
    //   default: () => ([])
    // },
    formData: {
      type: Object,
      default: () => ({})
    }
  },
  data() {
    return {
      activeValue: this.active,
      tabList: [
        {
          label: '属性',
          name: '属性',
          form: {},
          formData: {
            options: [
              {
                title: 'id',
                key: 'id',
                type: 'text',
                rules: false
              },
              {
                title: '名称',
                key: 'name',
                type: 'text',
                rules: false
              },
              {
                title: '起始边框',
                key: 'beginBorder',
                type: 'text',
                rules: false
              },
              {
                title: '结束边框',
                key: 'endBorder',
                type: 'text',
                rules: false
              },
              {
                title: '前导标签',
                key: 'beforeTag',
                type: 'text',
                rules: false
              },
              {
                title: '后置标签',
                key: 'afterTag',
                type: 'text',
                rules: false
              },
              {
                title: '前景文本',
                key: 'hint',
                type: 'text',
                rules: false
              },
              {
                title: '提示文本',
                key: 'tip',
                type: 'text',
                rules: false
              },
              {
                title: '对齐方式',
                key: 'alignHorizontalMode',
                type: 'select',
                option: {
                  data: [
                    {
                      id: 1,
                      title: '左对齐',
                      type: 0
                    },
                    {
                      id: 2,
                      title: '右对齐',
                      type: 1
                    }
                  ],
                  props: {
                    key: 'id', // 指定key对应data的字段
                    label: 'title', // 指定label对应data的字段
                    value: 'type' // 指定value对应data的字段
                  }
                }
              },
              {
                title: '宽度',
                key: 'width',
                type: 'number',
                rules: false
              },
              {
                title: '加密',
                key: 'hideKeyWord',
                type: 'text',
                rules: false
              },
              {
                dividerV: true,
                dividerName: '源加载'
              },
              {
                title: '源类型',
                key: 'sourceClass',
                type: 'select',
                option: {
                  data: [
                    {
                      id: 1,
                      title: '左对齐',
                      type: 0
                    },
                    {
                      id: 2,
                      title: '右对齐',
                      type: 1
                    }
                  ],
                  props: {
                    key: 'id', // 指定key对应data的字段
                    label: 'title', // 指定label对应data的字段
                    value: 'type' // 指定value对应data的字段
                  }
                }
              },
              {
                title: '源',
                key: 'sourceId',
                type: 'select',
                option: {
                  data: [
                    {
                      id: 1,
                      title: '左对齐',
                      type: 0
                    },
                    {
                      id: 2,
                      title: '右对齐',
                      type: 1
                    }
                  ],
                  props: {
                    key: 'id', // 指定key对应data的字段
                    label: 'title', // 指定label对应data的字段
                    value: 'type' // 指定value对应data的字段
                  }
                }
              },

              {
                title: ' ',
                key: 'desc',
                type: 'checkbox',
                option: {
                  data: [
                    {
                      id: 1,
                      title: '动态加载',
                      type: 0
                    },
                    {
                      id: 2,
                      title: '回填',
                      type: 1
                    }
                  ],
                  props: {
                    key: 'id', // 指定key对应data的字段
                    label: 'title', // 指定label对应data的字段
                    value: 'type' // 指定value对应data的字段
                  }
                }
              }
            ],
            rules: {},
            labelWidth: '80px'
          }
        },
        {
          label: '配置',
          name: '配置',
          formData: {},

          formData: {
            options: [
              {
                title: '',
                key: 'desc',
                type: 'checkbox',
                option: {
                  data: [
                    {
                      id: 1,
                      title: '禁止删除',
                      type: 0
                    },
                    {
                      id: 2,
                      title: '锁定内容',
                      type: 1
                    },
                    {
                      id: 3,
                      title: '锁定样式',
                      type: 0
                    },
                    {
                      id: 4,
                      title: '禁止空格',
                      type: 1
                    },
                    {
                      id: 5,
                      title: '背景特效',
                      type: 1
                    },
                    {
                      id: 6,
                      title: '焦点特效',
                      type: 0
                    },
                    {
                      id: 7,
                      title: '下划线',
                      type: 1
                    },
                    {
                      id: 8,
                      title: '允许跳转',
                      type: 0
                    },
                    {
                      id: 9,
                      title: '内容适应宽度',
                      type: 1
                    },
                    {
                      id: 10,
                      title: '显示边框',
                      type: 1
                    }
                  ],
                  props: {
                    key: 'id', // 指定key对应data的字段
                    label: 'title', // 指定label对应data的字段
                    value: 'type' // 指定value对应data的字段
                  }
                }
              },
              {
                title: '分段模式',
                key: 'splitParagraphMode',
                type: 'select',
                option: {
                  data: [
                    {
                      id: 1,
                      title: '左对齐',
                      type: 0
                    },
                    {
                      id: 2,
                      title: '右对齐',
                      type: 1
                    }
                  ],
                  props: {
                    key: 'id', // 指定key对应data的字段
                    label: 'title', // 指定label对应data的字段
                    value: 'type' // 指定value对应data的字段
                  }
                }
              },
              {
                dividerV: true,
                dividerName: '运算配置'
              },
              {
                title: '运算集',
                key: 'algoGroup',
                type: 'select',
                option: {
                  data: [
                    {
                      id: 1,
                      title: '左对齐',
                      type: 0
                    },
                    {
                      id: 2,
                      title: '右对齐',
                      type: 1
                    }
                  ],
                  props: {
                    key: 'id', // 指定key对应data的字段
                    label: 'title', // 指定label对应data的字段
                    value: 'type' // 指定value对应data的字段
                  }
                }
              },
              {
                title: '角色',
                key: 'algoRole',
                type: 'select',
                option: {
                  data: [
                    {
                      id: 1,
                      title: '左对齐',
                      type: 0
                    },
                    {
                      id: 2,
                      title: '右对齐',
                      type: 1
                    }
                  ],
                  props: {
                    key: 'id', // 指定key对应data的字段
                    label: 'title', // 指定label对应data的字段
                    value: 'type' // 指定value对应data的字段
                  }
                }
              },
              {
                title: '算法',
                key: 'algoMode',
                type: 'select',
                option: {
                  data: [
                    {
                      id: 1,
                      title: '左对齐',
                      type: 0
                    },
                    {
                      id: 2,
                      title: '右对齐',
                      type: 1
                    }
                  ],
                  props: {
                    key: 'id', // 指定key对应data的字段
                    label: 'title', // 指定label对应data的字段
                    value: 'type' // 指定value对应data的字段
                  }
                }
              },
              {
                title: '范围',
                key: 'algoScope',
                type: 'select',
                option: {
                  data: [
                    {
                      id: 1,
                      title: '左对齐',
                      type: 0
                    },
                    {
                      id: 2,
                      title: '右对齐',
                      type: 1
                    }
                  ],
                  props: {
                    key: 'id', // 指定key对应data的字段
                    label: 'title', // 指定label对应data的字段
                    value: 'type' // 指定value对应data的字段
                  }
                }
              },
              {
                dividerV: true,
                dividerName: '输入模式'
              },
              {
                title: '',
                key: 'inputMode',
                type: 'radio',
                option: {
                  data: [
                    {
                      id: 1,
                      title: '输入文本',
                      type: 0
                    },
                    {
                      id: 2,
                      title: '输入数值',
                      type: 1
                    },
                    {
                      id: 1,
                      title: '选择选项',
                      type: 0
                    },
                    {
                      id: 2,
                      title: '选择时间',
                      type: 1
                    }
                  ],
                  props: {
                    key: 'id', // 指定key对应data的字段
                    label: 'title', // 指定label对应data的字段
                    value: 'type' // 指定value对应data的字段
                  }
                }
              }
            ],
            rules: {},
            labelWidth: '80px'
          }
        },
        {
          label: '质控',
          name: '质控',
          formData: {
            options: [
              {
                title: '异常类型',
                key: 'level',
                type: 'select',
                option: {
                  data: [
                    {
                      id: 1,
                      title: '左对齐',
                      type: 0
                    },
                    {
                      id: 2,
                      title: '右对齐',
                      type: 1
                    }
                  ],
                  props: {
                    key: 'id', // 指定key对应data的字段
                    label: 'title', // 指定label对应data的字段
                    value: 'type' // 指定value对应data的字段
                  }
                }
              },
              {
                title: '',
                key: 'forbidEmpty',
                type: 'checkbox',
                option: {
                  data: [
                    {
                      id: 1,
                      title: '禁止为空',
                      type: 0
                    }
                  ],
                  props: {
                    key: 'id', // 指定key对应data的字段
                    label: 'title', // 指定label对应data的字段
                    value: 'type' // 指定value对应data的字段
                  }
                }
              },

              {
                title: '提示信息',
                key: 'notice',
                type: 'text',
                rules: false
              },
              {
                title: '禁止关键字',
                key: 'exclude',
                type: 'text',
                rules: false
              },
              {
                dividerV: true,
                dividerName: '格式限定'
              },
              {
                title: '',
                key: '',
                type: 'radio',
                option: {
                  data: [
                    {
                      id: 1,
                      title: '文本',
                      type: 0
                    },
                    {
                      id: 2,
                      title: '数值',
                      type: 1
                    },
                    {
                      id: 1,
                      title: '日期时间',
                      type: 0
                    },
                    {
                      id: 2,
                      title: '正则表达式',
                      type: 1
                    }
                  ],
                  props: {
                    key: 'id', // 指定key对应data的字段
                    label: 'title', // 指定label对应data的字段
                    value: 'type' // 指定value对应data的字段
                  }
                }
              },

              {
                dividerV: true,
                dividerName: ''
              },
              {
                title: '',
                key: 'useByteLen',
                type: 'checkbox',
                option: {
                  data: [
                    {
                      id: 1,
                      title: '字节长度模式',
                      type: 0
                    }
                  ],
                  props: {
                    key: 'id', // 指定key对应data的字段
                    label: 'title', // 指定label对应data的字段
                    value: 'type' // 指定value对应data的字段
                  }
                }
              },
              {
                dividerV: true,
                dividerName: ''
              },
              {
                title: '',
                key: '',
                type: 'checkbox',
                option: {
                  data: [
                    {
                      id: 1,
                      title: '',
                      type: 0
                    }
                  ],
                  props: {
                    key: 'id', // 指定key对应data的字段
                    label: 'title', // 指定label对应data的字段
                    value: 'type' // 指定value对应data的字段
                  }
                }
              },
              {
                title: '最小长度',
                key: 'min',
                type: 'number',
                rules: false
              },
              {
                dividerV: true,
                dividerName: ''
              },
              {
                title: '',
                key: '',
                type: 'checkbox',
                option: {
                  data: [
                    {
                      id: 1,
                      title: '',
                      type: 0
                    }
                  ],
                  props: {
                    key: 'id', // 指定key对应data的字段
                    label: 'title', // 指定label对应data的字段
                    value: 'type' // 指定value对应data的字段
                  }
                }
              },
              {
                title: '最大长度',
                key: 'max',
                type: 'number',
                rules: false
              }
            ],
            rules: {},
            labelWidth: '80px'
          }
        },
        {
          label: '事件',
          name: '事件',
          formData: {}
        }
      ]
    };
  },
  methods: {
    tabClick() {
      this.$emit('update:active', this.activeValue);
    }
  }
};
</script>

<style lang="scss" scoped></style>
