import { GetRandStr } from '@/components/editor/ThinkEditor.Utils';
import { ThinkEditor } from '@/components/editor/ThinkEditor';
import { E_DOCS_ORGANIZE_MODE, E_VIEW_MODE, E_SYSTEM_AUTH_MODE } from '@/components/editor/ThinkEditor.Defined';

export const DemoCaseRotatePrint = {
  methods: {
    /**
     * @function DoDemoCaseRotatePrint
     * @description 旋转打印当前文档
     * @param {}
     * @return {}
     * @example
     */
    async DoDemoCaseRotatePrint() {
      let editorsTabsView = this.defaultEditorsTabsView;
      let currentEditorsInstance = editorsTabsView.editorsInstance;
      let thinkEditor = currentEditorsInstance.GetSelectedEditor();
      if (thinkEditor === undefined) {
        console.error('旋转打印失败：当前视图未打开文档！');
      }

      //[0] 注意await,后续可能关闭文档或编辑器
      await thinkEditor.PrintDoc({
        printMode: 1, // 0：视图打印 1：矢量打印
        rotate: 1, //0：不旋转 1：旋转90度
        pagesRange: '',
        view: {
          mode: E_VIEW_MODE.Print
          //hideElementHint: true
        }
      });
    }
  }
};
