class AddressInfo {
  constructor() {
    this.code = '';
    this.name = '';
    this.children = new Array();
  }
}
export class ProvinceCityAreaHelper {
  constructor() {
    this.addressInfos = undefined;
  }
  async InitAddressInfos(addressInfosPath = '\\material\\') {
    if (this.addressInfos == undefined) {
      this.addressInfos = new Array();
      let response = await fetch(addressInfosPath + 'pca-code.json');
      let pcaCodeJsonData = await response.arrayBuffer();
      const decoder = new TextDecoder('utf-8');
      const pcaCodeJsonStr = decoder.decode(pcaCodeJsonData);
      this.addressInfos = JSON.parse(pcaCodeJsonStr);
    }
  }
  GetProvinceList() {
    let items = new Array();
    if (this.addressInfos == undefined) {
      return items;
    }
    for (let addressInfo of this.addressInfos) {
      items.push(addressInfo.name);
    }
    return items;
  }
  GetCityList(provinceName) {
    let items = new Array();
    if (this.addressInfos == undefined) {
      return items;
    }
    for (let addressInfo of this.addressInfos) {
      if (addressInfo.name == provinceName) {
        for (let cityInfo of addressInfo.children) {
          items.push(cityInfo.name);
        }
      }
    }
    return items;
  }
  GetAreaList(provinceName, cityName) {
    let items = new Array();
    if (this.addressInfos == undefined) {
      return items;
    }
    for (let addressInfo of this.addressInfos) {
      if (addressInfo.name == provinceName) {
        for (let cityInfo of addressInfo.children) {
          if (cityInfo.name == cityName) {
            for (let areaInfo of cityInfo.children) {
              items.push(areaInfo.name);
            }
          }
        }
      }
    }
    return items;
  }
}
const provinceCityAreaHelper = new ProvinceCityAreaHelper();

export { provinceCityAreaHelper };
