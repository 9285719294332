<template>
  <VacantDialog title="页面设置" width="600px" ref="formulaDialog" :top="'10%'" :confirmTxt="confirmTxt" @close="onCancle" @confirm="onConfirm">
    <div style="min-height: 400px; max-height: 700px">
      <div class="tab-style">
        <div @click="changeActive(item)" :class="active === item.value ? 'active' : ''" class="tab" v-for="item in tabList" :key="item.value">
          {{ item.label }}
        </div>
      </div>
      <vForm v-model="dataVForm" v-show="active === 1"></vForm>
      <vTable v-model="dataVTable" v-show="active === 2"></vTable>
    </div>
  </VacantDialog>
</template>

<script>
import vForm from './component/vForm.vue';
import vTable from './component/table.vue';

import { E_EVENT_KEY, E_DISPLAY_MODE } from '@/components/editor/ThinkEditor.Defined';
import { editorsInstanceManager } from '@/components/editorHelper/ThinkEditorInstance.Manager';

export default {
  name: 'index',
  components: { vForm, vTable },
  data() {
    return {
      thinkEditor: undefined,
      tabList: [
        { value: 1, label: '页面' },
        { value: 2, label: '页眉页脚' }
      ],
      dataVForm: {
        width: null,
        height: null,
        id: '',
        name: '',
        notSave: false,
        alignVerticalLayout: 'Bottom',
        bindLine: {},
        bottomPadding: null,
        leftPadding: null,
        rightPadding: null,
        topPadding: null
      },
      dataVTable: {
        header: {},
        footer: {},
        evenPageDiff: false,
        fristPageDiff: false
      },
      active: 1,
      flag: false,
      confirmTxt: '确定',
      contentVal: null
    };
  },
  created() {
    editorsInstanceManager.addEventListener(E_EVENT_KEY.editorFocus, this.OnDocEditorFocusChange);
    let currentEditorsInstance = editorsInstanceManager.GetSelectedEditorsInstance();
    this.thinkEditor = currentEditorsInstance?.GetSelectedEditor();
  },
  methods: {
    /**
     * 切换表头
     * @param item
     */
    changeActive(item) {
      this.active = item.value;
    },
    openDialog(data) {
      for (let i in this.dataVTable) {
        for (let a in data) {
          if (i === a) {
            this.dataVTable[i] = data[a];
          }
        }
      }
      for (let i in this.dataVForm) {
        for (let a in data) {
          if (i === a) {
            this.dataVForm[i] = data[a];
          }
        }
      }
      this.$refs.formulaDialog.openDialog();
    },
    OnDocEditorFocusChange(e) {
      this.thinkEditor = e.data.editor;
    },
    closeDialog() {
      this.$refs.formulaDialog.closeDialog();
      this.thinkEditor.SetInputFocus(true);
    },
    onCancle() {
      this.closeDialog();
    },
    onConfirm() {
      const params = Object.assign(this.dataVForm, this.dataVTable);
      this.thinkEditor.SetPageConfig(params);
      this.closeDialog();
    }
  }
};
</script>

<style scoped lang="scss">
::v-deep .el-input--medium .el-input__inner {
  height: 60px;
}

::v-deep .el-select-dropdown__item {
  height: 60px !important;
  line-height: 60px;
  border-bottom: 2px solid #ccc;
  margin: 0 10px;
}

::v-deep .el-form-item {
  margin-bottom: -8px !important;
}

.tab-style {
  display: flex;
  align-items: center;
  justify-content: flex-start;

  .tab {
    padding: 6px 10px;
    border: 1px solid #ccc;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    margin-right: 1px;
    background: #e0e0e0;
    cursor: pointer;
  }

  .active {
    background: #61a3e6;
  }
}
</style>
