<template>
  <VacantDialog title="医学表达式" width="700px" ref="thisDialog" :top="'10%'" :confirmTxt="confirmTxt" @close="onCancle" @confirm="onConfirm">
    <el-form :model="configData" ref="configData" label-width="60px" class="demo-dynamic">
      <el-row>
        <el-col :span="12">
          <el-form-item prop="id" label="ID">
            <el-input size="mini" style="width: 240px" v-model="configData.id"></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label-width="80px" prop="alignVerticalLayout" label="垂直对齐">
            <el-select size="mini" style="width: 240px" v-model="configData.alignVerticalLayout" placeholder="请选择">
              <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value" :disabled="item.disabled"></el-option>
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :span="18">
          <el-form-item label-width="60px" prop="styles" label="样式">
            <formatStyleOptions :currentStyle="currentStyle" v-if="showFormatStyleOptions" v-model="configData.style" @changeFormatStyle="OnChangeFormatStyle"></formatStyleOptions>
          </el-form-item>
        </el-col>
      </el-row>
    </el-form>
  </VacantDialog>
</template>

<script>
import formatStyleOptions from '@/view/formulaBox/formatStyleOptions.vue';
import { E_EVENT_KEY, E_DISPLAY_MODE, E_SET_MODE, E_LAYOUT_VERTICAL_ALIGN, E_FORMULA_STYLE } from '@/components/editor/ThinkEditor.Defined';
import { UpdateConfigData } from '@/components/editor/ThinkEditor.Utils';
import { editorsInstanceManager } from '@/components/editorHelper/ThinkEditorInstance.Manager';

export default {
  name: 'index',
  components: { formatStyleOptions },
  data() {
    return {
      configData: {
        setMode: E_SET_MODE.InsertCommon,
        id: '',
        name: '',
        alignVerticalLayout: E_LAYOUT_VERTICAL_ALIGN.SelfMiddle,
        style: E_FORMULA_STYLE.Common,
        union: []
      },
      showFormatStyleOptions: false,
      options: [
        { value: E_LAYOUT_VERTICAL_ALIGN.SelfMiddle, label: '自身居中' },
        { value: E_LAYOUT_VERTICAL_ALIGN.Bottom, label: '底端对齐' },
        { value: E_LAYOUT_VERTICAL_ALIGN.OtherMiddle, label: '其它居中' }
      ],
      flag: false,
      confirmTxt: '插入',
      currentStyle: E_FORMULA_STYLE.Common
    };
  },
  created() {
    editorsInstanceManager.addEventListener(E_EVENT_KEY.editorFocus, this.OnDocEditorFocusChange);
    let currentEditorsInstance = editorsInstanceManager.GetSelectedEditorsInstance();
    this.thinkEditor = currentEditorsInstance?.GetSelectedEditor();
  },
  methods: {
    OnChangeFormatStyle(newStyle) {
      this.configData.style = newStyle;
    },

    openDialog(configData) {
      UpdateConfigData(this, configData);

      //this.configData = {
      //  ...configData
      //};
      if (this.configData.setMode === E_SET_MODE.ModifyCommon) {
        this.confirmTxt = '确定';
      } else {
        this.confirmTxt = '插入';
      }
      this.currentStyle = this.configData.style;

      this.showFormatStyleOptions = true;
      this.$refs.thisDialog.openDialog();
    },
    OnDocEditorFocusChange(e) {
      this.thinkEditor = e.data.editor;
    },
    closeDialog() {
      this.showFormatStyleOptions = false;
      this.$refs.thisDialog.closeDialog();
      this.thinkEditor.SetInputFocus(true);
    },
    async onCancle() {
      this.closeDialog();
    },
    async onConfirm() {
      if (this.configData.setMode == E_SET_MODE.InsertCommon) {
        await this.thinkEditor.InsertFormula(this.configData);
      } else {
        await this.thinkEditor.SetFormulaConfig('', this.configData);
      }
      this.closeDialog();
    }
  }
};
</script>

<style scoped>
::v-deep .el-input--medium .el-input__inner {
  height: 60px;
}

::v-deep .el-select-dropdown__item {
  height: 60px !important;
  line-height: 60px;
  border-bottom: 2px solid #ccc;
  margin: 0 10px;
}
</style>
