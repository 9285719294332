<template>
  <div class="install-filed">
    <el-card>
      <el-button size="mini" v-for="(item, idx) in tagList" :key="idx" @click="$emit('installBtnClick', item)">{{ item.label }}</el-button>
    </el-card>
  </div>
</template>
<script>
export default {
  data() {
    return {
      tagList: [
        {
          label: '元素',
          val: 'element'
        },
        {
          label: '选框',
          val: 'selectBox'
        },
        {
          label: '公式',
          val: 'format'
        },
        {
          label: '表格',
          val: 'table'
        },
        {
          label: '图像',
          val: 'image'
        },
        {
          label: '页码',
          val: 'pages'
        },
        {
          label: '二维码',
          val: 'qrcode'
        },
        {
          label: '条形码',
          val: 'barcode'
        }
      ]
    };
  }
};
</script>
<style lang="scss">
.install-filed {
  width: 25%;

  .el-card {
    overflow: visible;
    margin-right: 3px;
  }
  .el-card__body,
  .el-main {
    padding: 2px;
  }
  .el-card__body .el-button:first-child {
    margin-left: 4px;
  }
  .el-button + .el-button {
    margin-left: 4px;
    margin-top: 6px;
  }
  .button-new-tag {
    margin-left: 10px;
    height: 32px;
    line-height: 30px;
    padding-top: 0;
    padding-bottom: 0;
  }
}
</style>
