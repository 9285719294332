import { E_EVENT_KEY, E_VIEW_MODE, E_SHOW_BG_MODE } from '@/components/editor/ThinkEditor.Defined';

export const DemoCaseOutline = {
  methods: {
    async DoDemoCaseOutline() {
      let editorsTabsView = this.defaultEditorsTabsView;
      let currentEditorsInstance = editorsTabsView.editorsInstance;

      var docName = '大纲导航';
      let thinkEditor = currentEditorsInstance.GetEditor(docName);

      if (thinkEditor === undefined) {
        thinkEditor = await editorsTabsView.CreateDocEditor(docName);

        var url = '/system/emr/演示/00-演示病例/AdmissionRecord.xml'; //"/system/test/pacsdemo.jpg";
        let response = await fetch(url, {
          credentials: 'same-origin'
        });
        let xml = await response.arrayBuffer();

        thinkEditor.CreateDoc(docName); //@20220722 必需，才能设置编辑者信息
        thinkEditor.SelectDoc(docName);

        thinkEditor.ParseDocs(docName, '', xml, {});
        /*************************************************
         * 配置编辑者信息
         * **********************************************/
        thinkEditor.SetEditorInfo('pc', 'appGuid1', 'zl', 1, '');

        thinkEditor.SetViewMode(E_VIEW_MODE.Edit, {});

        thinkEditor.addEventListener(E_EVENT_KEY.docModified, this.OnDocModified);
        //or currentEditorsInstance.addEventListener(E_EVENT_KEY.docModified, this.OnDocModified);
      }
      //UI 中显示该文档
      editorsTabsView.SelectDocEditor(docName);
    },
    async OnDocModified(e) {
      let thinkEditor = e.data.editor;
      this.InitOutlineReport(thinkEditor);
    },
    InitOutlineReport(currentThinkEditor) {
      currentThinkEditor.outlineBiz = currentThinkEditor.outlineBiz || {};
      currentThinkEditor.outlineBiz.moveIdx = currentThinkEditor.outlineBiz.moveIdx || -1;
      let outline = currentThinkEditor.GetOutline({});

      //--------简化处理
      let paragraphs = outline[0].body.paragraphs;

      let objects = [];
      paragraphs.forEach(paragraph => {
        paragraph.members.forEach(member => {
          objects.push({ object: member.object, uuid: member.uuid });
        });
      });

      currentThinkEditor.outlineBiz.report = outline;
      currentThinkEditor.outlineBiz.objects = objects;
    },
    async OnGetOutlineReportTest() {
      let currentTabsView = this.defaultEditorsTabsView;
      let currentEditorsInstance = currentTabsView.editorsInstance;
      let currentThinkEditor = currentEditorsInstance.GetSelectedEditor();
      if (currentThinkEditor === undefined) {
        return;
      }

      this.InitOutlineReport(currentThinkEditor);

      this.popDialogTitle = 'Outline';
      this.popDialogText = JSON.stringify(currentThinkEditor.outlineBiz.report, null, 2);
      this.popDialogVisible = true;
    },
    async OnGotoOutlineNextTest() {
      let currentTabsView = this.defaultEditorsTabsView;
      let currentEditorsInstance = currentTabsView.editorsInstance;
      let currentThinkEditor = currentEditorsInstance.GetSelectedEditor();
      if (currentThinkEditor === undefined) {
        return;
      }
      if (currentThinkEditor.outlineBiz == undefined) {
        this.InitOutlineReport(currentThinkEditor);
      }
      let report = currentThinkEditor.outlineBiz.objects;
      let count = report.length;
      if (++currentThinkEditor.outlineBiz.moveIdx >= count) {
        currentThinkEditor.outlineBiz.moveIdx = 0;
      }
      let item = report[currentThinkEditor.outlineBiz.moveIdx];

      currentThinkEditor.GotoOutline(item);
    },
    async OnGotoOutlinePreviousTest() {
      let currentTabsView = this.defaultEditorsTabsView;
      let currentEditorsInstance = currentTabsView.editorsInstance;
      let currentThinkEditor = currentEditorsInstance.GetSelectedEditor();
      if (currentThinkEditor === undefined) {
        return;
      }
      if (currentThinkEditor.outlineBiz == undefined) {
        this.InitOutlineReport(currentThinkEditor);
      }
      let report = currentThinkEditor.outlineBiz.objects;
      let count = report.length;
      if (--currentThinkEditor.outlineBiz.moveIdx < 0) {
        currentThinkEditor.outlineBiz.moveIdx = count - 1;
      }
      let item = report[currentThinkEditor.outlineBiz.moveIdx];

      currentThinkEditor.GotoOutline(item);
    }
  }
};
