<template>
  <el-form :model="configData" ref="configData" label-width="80px" class="demo-dynamic">
    <el-row>
      <el-col :span="12">
        <el-form-item prop="checkFigureStyle" label="选框样式">
          <checkBoxStyleOptions :contentVal="configData.checkFigureStyle" v-model="configData.checkFigureStyle"></checkBoxStyleOptions>
        </el-form-item>
      </el-col>
      <el-col :span="12">
        <el-form-item label-width="80px" prop="selectBox" label="">
          <div style="display: flex; align-items: center; height: 40px">
            <!-- configData.setMode 为 插入=0 时，可修改 -->
            <el-radio :disabled="configData.setMode == 1" v-model="configData.isCheckBoxGroup" :label="false">选框</el-radio>
            <el-radio :disabled="configData.setMode == 1" v-model="configData.isCheckBoxGroup" :label="true">选集</el-radio>
          </div>
        </el-form-item>
      </el-col>
      <el-col :span="24">
        <el-form-item label="">
          <!-- 为 选框 或 修改1时 禁用 -->
          <el-checkbox :disabled="!configData.isCheckBoxGroup || configData.setMode == 1" v-model="configData.option.multipleChoice" label="强制多选"></el-checkbox>
          <el-checkbox :disabled="!configData.isCheckBoxGroup || configData.setMode == 1" v-model="configData.option.dynamicLoad" label="动态加载"></el-checkbox>
          <el-checkbox :disabled="!configData.isCheckBoxGroup || configData.setMode == 1" v-model="configData.enableEmpty" label="强制为空"></el-checkbox>
        </el-form-item>
      </el-col>
      <span v-show="(configData.setMode == 0 && !configData.isCheckBoxGroup) || configData.setMode == 1">
        <!-- 插入0-选项 | 修改1 时显示 -->
        <el-col :span="12">
          <el-form-item label-width="80px" prop="id" label="ID">
            <el-input size="mini" style="width: 200px" v-model="configData.id"></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label-width="80px" prop="name" label="名称">
            <el-input size="mini" style="width: 200px" v-model="configData.name"></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label-width="80px" prop="weight" label="权值">
            <el-input-number style="width: 200px" size="mini" v-model="configData.weight" controls-position="right" :precision="2" :step="0.05" :min="0.0"></el-input-number>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label-width="80px" prop="content" label="内容">
            <el-input size="mini" style="width: 200px" v-model="configData.content"></el-input>
          </el-form-item>
        </el-col>
      </span>
      <span v-show="configData.setMode == 0 && configData.isCheckBoxGroup">
        <!-- 插入0-选集 时显示 -->
        <el-col :span="12">
          <el-form-item label-width="80px" prop="optionClass" label="选项类">
            <el-select size="mini" style="width: 200px" v-model="configData.option.optionClass" placeholder="请选择">
              <el-option v-for="item in optionsManager.options" :key="item.optionClass" :label="item.optionClass" :value="item.optionClass" :disabled="item.disabled"></el-option>
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label-width="80px" prop="optionId" label="选项组">
            <el-select size="mini" style="width: 200px" v-model="configData.option.optionId" placeholder="请选择">
              <el-option v-for="item in currentOptionClassObj.optionIds" :key="item.optionId" :label="item.optionId" :value="item.optionId" :disabled="item.disabled"></el-option>
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label-width="80px" prop="optionName" label="选集名">
            <el-input size="mini" style="width: 200px" v-model="configData.option.optionName"></el-input>
          </el-form-item>
        </el-col>
      </span>
      <el-col :span="24">
        <el-form-item label-width="80px" label="运算配置">
          <div style="margin-top: 20px; border-bottom: 1px solid #afafaf"></div>
        </el-form-item>
      </el-col>
      <el-col :span="12">
        <el-form-item label-width="80px" prop="algoGroup" label="运算集">
          <el-select size="mini" style="width: 200px" v-model="configData.algorithm.algoGroup" placeholder="请选择">
            <el-option v-for="item in algorithmsManager.algorithms" :key="item.algoGroup" :label="item.algoGroup" :value="item.algoGroup" :disabled="item.disabled"></el-option>
          </el-select>
        </el-form-item>
      </el-col>
      <el-col :span="12">
        <el-form-item label-width="80px" prop="algoRole" label="角色">
          <el-select size="mini" style="width: 200px" v-model="configData.algorithm.algoRole" placeholder="请选择">
            <el-option v-for="item in algoRoleList" :key="item.value" :label="item.label" :value="item.value" :disabled="item.disabled"></el-option>
          </el-select>
        </el-form-item>
      </el-col>
      <el-col :span="12">
        <el-form-item label-width="80px" prop="alignVerticalLayout" label="算法">
          <el-select disabled size="mini" style="width: 200px" v-model="configData.algorithm.algoMode" placeholder="请选择">
            <el-option v-for="item in algoModeList" :key="item.value" :label="item.label" :value="item.value" :disabled="item.disabled"></el-option>
          </el-select>
        </el-form-item>
      </el-col>
      <el-col :span="12">
        <el-form-item label-width="80px" prop="alignVerticalLayout" label="范围">
          <el-select disabled size="mini" style="width: 200px" v-model="configData.algorithm.algoScope" placeholder="请选择">
            <el-option v-for="item in algoScopeList" :key="item.value" :label="item.label" :value="item.value" :disabled="item.disabled"></el-option>
          </el-select>
        </el-form-item>
      </el-col>
    </el-row>
  </el-form>
</template>

<script>
import checkBoxStyleOptions from './checkBoxStyleOptions.vue';
import { editorsInstanceManager } from '@/components/editorHelper/ThinkEditorInstance.Manager';
import { E_EVENT_KEY, OptionsManager, AlgorithmsManager, E_SET_MODE, OptionClass, E_ALGO_ROLE, E_ALGO_SCOPE, E_ALGO_MODE } from '@/components/editor/ThinkEditor.Defined';
export default {
  name: 'vForm',
  props: {
    configData: {
      option: {},
      algorithm: { algoRole: E_ALGO_ROLE.In }
    },
    optionsManager: {
      type: Object,
      default: () => new OptionsManager()
    },
    algorithmsManager: {
      type: Object,
      default: () => new AlgorithmsManager()
    }
  },
  computed: {},
  components: { checkBoxStyleOptions },
  data() {
    return {
      thinkEditor: undefined,
      isCheckBoxGroup: false,
      showCheckBoxStyleOptions: false,
      currentOptionClassObj: new OptionClass(),

      algoRoleList: [
        { value: E_ALGO_ROLE.In, label: '输入' },
        { value: E_ALGO_ROLE.Out, label: '输出' }
      ],
      algoModeList: [
        { value: E_ALGO_MODE.Common, label: '通用' },
        { value: E_ALGO_MODE.Addition, label: '加法' }
      ],
      algoScopeList: [
        { value: E_ALGO_SCOPE.Doc, label: '文档' },
        { value: E_ALGO_SCOPE.Table, label: '表格' },
        { value: E_ALGO_SCOPE.Row, label: '行' }
      ]
    };
  },
  watch: {
    configData: {
      handler(data) {
        this.SelfInit();
      },
      deep: true,
      immediate: true
    },
    'configData.isCheckBoxGroup': function (val) {
      this.$emit('tabsChange', val);
    }
  },
  created() {
    editorsInstanceManager.addEventListener(E_EVENT_KEY.editorFocus, this.OnDocEditorFocusChange);
    let currentEditorsInstance = editorsInstanceManager.GetSelectedEditorsInstance();

    this.thinkEditor = currentEditorsInstance?.GetSelectedEditor();
    this.SelfInit();
  },
  mounted() {},
  beforeDestroy() {},
  methods: {
    SelfInit() {
      let data = this.configData;
      //this.isCheckBoxGroup = data.isCheckBoxGroup;
      //if (data.option.optionClass != '') {
      //this.isCheckBoxGroup = true;
      //} else {
      //this.isCheckBoxGroup = false;
      //

      let optionClassObj = this.optionsManager.GetOptionClass(data.option.optionClass);
      if (optionClassObj !== undefined) {
        this.currentOptionClassObj = optionClassObj;
      } else {
        this.currentOptionClassObj = new OptionClass();
      }

      let algorithmObj = this.algorithmsManager.GetAlgorithm(this.configData.algorithm.algoGroup);
      if (algorithmObj !== undefined) {
        this.configData.algorithm.algoMode = algorithmObj.algoMode;
        this.configData.algorithm.algoScope = algorithmObj.algoScope;
      }
      if (this.configData.algorithm.algoRole === undefined) {
        this.$set(this.configData.algorithm, 'algoRole', E_ALGO_ROLE.In);
      }
    },
    OnDocEditorFocusChange(e) {
      this.thinkEditor = e.data.editor;

      this.SelfInit();
    }
  }
};
</script>

<style scoped></style>
