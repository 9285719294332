<template>
  <div class="sudoku-div" :style="pos">
    <div style="margin-bottom: 5px">
      <span style="margin-right: 5px">行</span>
      <el-input-number v-model="rowNum" size="mini" controls-position="right" :min="1"></el-input-number>
      <span style="margin-right: 5px;margin-left: 15px">列</span>
      <el-input-number style="margin-right: 7px" v-model="colNum" size="mini" controls-position="right" :min="1"></el-input-number>
      <el-button @click="commit" style="margin-left: 10px" size="small">插入</el-button>
    </div>

    <div class="sudoku-row" :style="'width:' + (col * 18 + 3) + 'px;'" v-for="r in row">
      <div ref="sudokuCol" v-for="c in col" :row="r" :col="c" :class="'sudoku-col ' + r + '-' + c" @mouseover="mouseoverCol(r, c)" @mouseleave="mouseleaveCol(r, c)" @click="clickCol(r, c)"></div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'sudoku',
  props: {
    pos: { top: '1px', left: '1px' },
    row: {
      // 行
      type: Number,
      default: 3
    },
    col: {
      //列
      type: Number,
      default: 3
    }
  },
  data() {
    return {
      rowNum: null,
      colNum: null
    };
  },
  mounted() {
    //document.addEventListener('mouseup', this.OnMouseUp);
  },
  methods: {
    OnMouseUp(e) {
      //this.showInsertTablePicker = false;
      this.pos.top = e.pageY + 10 + 'px';
      this.pos.left = e.pageX + 10 + 'px';
    },
    mouseoverCol(row, col) {
      this.rowNum = row;
      this.colNum = col;
      var array = this.$refs.sudokuCol;
      array.forEach(array => {
        var r = array.attributes['row'].value;
        var c = array.attributes['col'].value;
        if (r <= row && c <= col) {
          array.style.border = '1px solid rgb(32, 165, 214);';
          array.style.backgroundColor = 'rgb(166,166,166)';
        } else {
          array.style.border = '1px solid black';
          array.style.backgroundColor = 'white';
        }
      });
    },
    mouseleaveCol(row, col) {
      // var array= this.$refs.sudokuCol;
      // array.forEach(array => {
      //   array.style.border="1px solid black";
      //   array.style.backgroundColor="white";
      // });
    },
    clickCol(row, col) {
      this.rowNum = row;
      this.colNum = col;
      this.$emit('action:setValue', Number(row), Number(col));
    },
    commit() {
      this.$emit('action:setValue', this.rowNum, this.colNum);
    }
  }
};
</script>

<style scoped lang="scss">
.sudoku-div {
  position: fixed;
  background-color: white;
  padding: 10px 10px 0px 10px;
  border: 1px solid #bbbbbb;
  border-radius: 8px;
}

.sudoku-row {
  padding: 0px;
  margin: 0px;
}

.sudoku-col {
  border: 1px solid #151515;
  width: 15px;
  height: 15px;
  background-color: white;
  display: inline-block;
  padding: 0;
  margin-right: 1px;
  border-radius: 4px;
}

.sudoku-row .sudoku-col:last-child {
  margin-right: 0px;
}

::v-deep .el-input-number {
  width: 79px;

  .el-input--mini {
    .el-input__inner {
      //width:50px;
      //padding-left: 5px !important;
      //padding-right: 5px !important;
    }
  }
}
</style>
