<template>
  <div class="cell-page">
    <el-form ref="form" label-width="60px" size="mini">
      <el-divider content-position="left">属性</el-divider>
      <el-row>
        <el-col :span="12">
          <el-form-item label="ID">
            <el-input v-model="cellData.cell.id"></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="名称">
            <el-input v-model="cellData.cell.name"></el-input>
          </el-form-item>
        </el-col>
      </el-row>
      <el-divider content-position="left">尺寸</el-divider>
      <el-row>
        <el-col :span="12">
          <el-form-item label="宽度">
            <el-input-number v-model="cellData.cell.width" controls-position="right" :step="0.05" :min="0.01" :max="100" :precision="2"></el-input-number>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item>
            <el-checkbox v-model="cellData.cell.fitContent">内容自适应</el-checkbox>
          </el-form-item>
        </el-col>
      </el-row>
      <el-divider content-position="left">垂直对齐</el-divider>
      <el-row>
        <el-col :span="16">
          <section class="align-field">
            <article>
              <div class="font-icon" :class="alignVerticalMode === orgVerticalEnum.Top ? 'active' : ''" @click="verticalCheck(orgVerticalEnum.Top)">
                <img src="../../../assets/images/tableSet/CellTopAlign.jpg" alt="" />
              </div>
              <span>顶端对齐</span>
            </article>
            <article>
              <div class="font-icon" :class="alignVerticalMode === orgVerticalEnum.Middle ? 'active' : ''" @click="verticalCheck(orgVerticalEnum.Middle)">
                <img src="../../../assets/images/tableSet/CellMiddleAlign.jpg" alt="" />
              </div>
              <span>居中对齐</span>
            </article>
            <article>
              <div class="font-icon" :class="alignVerticalMode === orgVerticalEnum.Bottom ? 'active' : ''" @click="verticalCheck(orgVerticalEnum.Bottom)">
                <img src="../../../assets/images/tableSet/CellBottomAlign.jpg" alt="" />
              </div>
              <span>底端对齐</span>
            </article>
          </section>
        </el-col>
      </el-row>
      <el-divider content-position="left">单元格默认边距</el-divider>
      <el-row>
        <el-col :sapn="8">
          <el-checkbox v-model="cellData.cell.followTableDefaultCellPadding">跟随表格设置</el-checkbox>
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="12">
          <el-form-item label="上边距">
            <el-input-number v-model="cellData.cell.topPadding" controls-position="right" :step="0.05" :min="0.01" :max="100" :precision="2"></el-input-number>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="下边距">
            <el-input-number v-model="cellData.cell.bottomPadding" controls-position="right" :step="0.05" :min="0.01" :max="100" :precision="2"></el-input-number>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="12">
          <el-form-item label="左边距">
            <el-input-number v-model="cellData.cell.leftPadding" controls-position="right" :step="0.05" :min="0.01" :max="100" :precision="2"></el-input-number>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="右边距">
            <el-input-number v-model="cellData.cell.rightPadding" controls-position="right" :step="0.05" :min="0.01" :max="100" :precision="2"></el-input-number>
          </el-form-item>
        </el-col>
      </el-row>
    </el-form>
  </div>
</template>

<script>
import { E_ALIGN_VERTICAL_MODE } from '@/components/editor/ThinkEditor.Defined';
export default {
  props: {
    cellData: {
      type: Object,
      default: () => ({})
    }
  },
  data() {
    return {
      orgVerticalEnum: E_ALIGN_VERTICAL_MODE,
      alignVerticalMode: 0
    };
  },
  watch: {
    cellData: {
      handler(newVal) {
        this.alignVerticalMode = newVal.cell.alignVerticalMode;
      },
      immediate: true
    }
  },
  methods: {
    verticalCheck(type) {
      this.cellData.cell.alignVerticalMode = type;
      this.alignVerticalMode = type;
    }
  }
};
</script>

<style lang="scss" scoped>
.cell-page {
  .align-field {
    display: flex;
    article {
      flex: 1;
      display: flex;
      flex-direction: column;
      align-items: center;
      .font-icon {
        width: 30%;
        margin-bottom: 6px;
        padding: 2px 8px;
        border: 1px solid #e1e1e1;
        display: flex;
        flex-direction: column;
        align-items: center;
        cursor: pointer;
        & > span {
          font-size: 38px;
        }
        &.active {
          border-color: #43d6ca;
          & + span {
            color: #43d6ca;
          }
        }
      }
    }
  }
}
</style>
