<template>
  <div>
    <el-input v-model="styleResTxt" placeholder="请输入" @change="inputSelect" @click.stop.native="$event.target.focus()"></el-input>
  </div>
</template>
<script>
export default {
  data() {
    return {
      styleResTxt: ''
    };
  },
  methods: {
    inputSelect() {
      console.log('text change=================>');
    }
  }
};
</script>
