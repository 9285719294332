<template>
  <el-popover ref="bottomElPopover" placement="right" trigger="click">
    <div>
      <div class="top">
        <el-input :placeholder="placeholder" size="small" v-model="number"></el-input>
        <el-button @click="commit" style="margin-left: 10px" size="small">确定</el-button>
      </div>
      <div class="content-num">
        <div>
          <div class="container">
            <el-button @click="changeNumber(item)" class="container-con" v-for="item in dataList" :key="item.value" size="small">{{ item.value }}</el-button>
          </div>
        </div>
      </div>
    </div>
    <div slot="reference" :style="pos" class="content"></div>
  </el-popover>
</template>

<script>
import { E_INPUT_MODE } from '@/components/editor/ThinkEditor.Defined';
export default {
  name: 'index',
  props: {
    configData: {
      type: Object,
      default: {}
    }
  },
  data() {
    return {
      number: undefined,
      dataList: [{ value: 7 }, { value: 8 }, { value: 9 }, { value: '<' }, { value: 4 }, { value: 5 }, { value: 6 }, { value: '↑' }, { value: 1 }, { value: 2 }, { value: 3 }, { value: '↓' }, { value: '-' }, { value: '0' }, { value: '.' }, { value: 'c' }],
      placeholder: '',
      pos: {
        top: '1px',
        left: '1px'
      },
      visible: false
    };
  },
  watch: {
    configData: {
      handler(data) {
        if (data.inputHandle.inputMode == E_INPUT_MODE.InputNumber) {
          this.number = data.text;
          this.pos.top = data.inView.posY + 10 + 'px';
          this.pos.left = data.inView.posX + 10 + 'px';
          if (data.qualityControl.max > 0) {
            this.placeholder = data.qualityControl.min + '~' + data.qualityControl.max;
          }
          this.$nextTick(() => {
            setTimeout(() => {
              this.$parent.$parent.$children[0].$children[18].$refs.bottomElPopover.doShow();
            }, 100);
          });
        } else {
          this.close();
        }
      },
      deep: true,
      immediate: true
    }
  },
  methods: {
    changeNumber(item) {
      if (item.value === '<') {
        if (this.number) {
          this.number = this.number.slice(0, this.number.length - 1);
        }
      } else if (item.value === '↑') {
        if (this.number) {
          this.number = Number(this.number);
          this.number += 1;
        } else {
          this.number = 0;
          this.number += 1;
        }
      } else if (item.value === '↓') {
        if (this.number) {
          this.number = Number(this.number);
          this.number -= 1;
        } else {
          this.number = 0;
          this.number -= 1;
        }
      } else if (item.value === 'c') {
        this.number = '';
      } else {
        if (this.number) {
          this.number = this.number + '' + item.value;
        } else {
          this.number = '' + item.value;
        }
      }
    },
    /**
     * 确定
     */
    commit() {
      let max = this.configData.qualityControl.max;
      let min = this.configData.qualityControl.min;
      if (this.number && max !== undefined && max > 0) {
        if (Number(this.number) < min || Number(this.number) > max) {
          this.number = '';
          return; //输入不合法
        }
      }
      if (this.number !== undefined) {
        this.$emit('action:setValue', this.number + '');
      }

      this.close();
    },
    close() {
      this.visible = false;
      this.$emit('action:hide', 'numberBox');
    }
  }
};
</script>

<style scoped>
.content {
  position: absolute;
  //padding: 6px;
  //border: 1px solid #ccc;
  //border-radius: 5px;
  //background: #fff;
}

.top {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.container {
  margin-top: 10px;
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 10px;
}

.container-con {
  margin-left: 0 !important;
  padding: 10px 20px;
  background: #f1f1f1;
}
</style>
