import { E_EVENT_KEY, E_VIEW_MODE } from '@/components/editor/ThinkEditor.Defined';

export const DemoCaseClaim = {
  methods: {
    async DoDemoCaseClaim() {
      let editorsTabsView = this.defaultEditorsTabsView;
      let currentEditorsInstance = editorsTabsView.editorsInstance;

      var docName = '[声明]-权限';
      let thinkEditor = currentEditorsInstance.GetEditor(docName);

      if (thinkEditor === undefined) {
        thinkEditor = await editorsTabsView.CreateDocEditor(docName);

        var url = '/system/emr/功能/权限/ClaimTest.xml';
        let response = await fetch(url, {
          credentials: 'same-origin'
        });
        let xml = await response.arrayBuffer();

        thinkEditor.CreateDoc(docName); //@20220722 必需，才能设置编辑者信息
        thinkEditor.SelectDoc(docName);

        thinkEditor.ParseDocs(docName, '', xml, {});
        /*************************************************
         * 配置编辑者信息
         * **********************************************/
        thinkEditor.SetUserProfile({ terminal: 'pc', id: 'appGuid1', name: '主治医生1', level: 1, claims: 'claim1' });

        thinkEditor.SetViewMode(E_VIEW_MODE.Edit, {});
      }
      //UI 中显示该文档
      editorsTabsView.SelectDocEditor(docName);
    },
    queryClaimSuggestions(queryString, cb) {
      // 调用 callback 返回建议列表的数据
      cb(this.claimSuggestionsItems);
    },
    loadClaimSuggestionsItems() {
      return [{ value: 'claim1' }, { value: 'claim2' }, { value: '可自定义' }];
    },
    async OnSetEditorInfoTest_主治医生1() {
      let currentTabsView = this.defaultEditorsTabsView;
      let currentEditorsInstance = currentTabsView.editorsInstance;
      let currentThinkEditor = currentEditorsInstance.GetSelectedEditor();
      if (currentThinkEditor === undefined) {
        return;
      }

      currentThinkEditor.SetUserProfile({ terminal: 'pc', id: 'appGuid1', name: '主治医生1', level: 1, claims: 'claim1' });
    },

    async OnSetEditorInfoTest_主治医生2() {
      let currentTabsView = this.defaultEditorsTabsView;
      let currentEditorsInstance = currentTabsView.editorsInstance;
      let currentThinkEditor = currentEditorsInstance.GetSelectedEditor();
      if (currentThinkEditor === undefined) {
        return;
      }

      currentThinkEditor.SetUserProfile({ terminal: 'pc', id: 'appGuid2', name: '主治医生2', level: 1, claims: 'claim1' });
    },
    async OnSetEditorInfoTest_上级医生() {
      let currentTabsView = this.defaultEditorsTabsView;
      let currentEditorsInstance = currentTabsView.editorsInstance;
      let currentThinkEditor = currentEditorsInstance.GetSelectedEditor();
      if (currentThinkEditor === undefined) {
        return;
      }

      currentThinkEditor.SetUserProfile({ terminal: 'pc', id: 'appGuid3', name: '上级医生', level: 1, claims: 'claim2;' });
    },
    async OnSetEditorInfoTest_主任医生() {
      let currentTabsView = this.defaultEditorsTabsView;
      let currentEditorsInstance = currentTabsView.editorsInstance;
      let currentThinkEditor = currentEditorsInstance.GetSelectedEditor();
      if (currentThinkEditor === undefined) {
        return;
      }

      currentThinkEditor.SetUserProfile({ terminal: 'pc', id: 'appGuid4', name: '主任医生', level: 1, claims: 'claim1;claim2;' });
    },
    async OnSetObjectClaimTest() {
      if (this.claimClassValue == 'row') {
        this.OnSetRowClaimTest();
      } else if (this.claimClassValue == 'cell') {
        this.OnSetCellClaimTest();
      } else if (this.claimClassValue == 'paragraph') {
        this.OnSetParagraphClaimTest();
      }
    },
    async OnClearObjectClaimTest() {
      if (this.claimClassValue == 'row') {
        this.OnClearRowClaimTest();
      } else if (this.claimClassValue == 'cell') {
        this.OnClearCellClaimTest();
      } else if (this.claimClassValue == 'paragraph') {
        this.OnClearParagraphClaimTest();
      }
    },
    async OnSetRowClaimTest() {
      let currentTabsView = this.defaultEditorsTabsView;
      let currentEditorsInstance = currentTabsView.editorsInstance;
      let currentThinkEditor = currentEditorsInstance.GetSelectedEditor();
      if (currentThinkEditor === undefined) {
        return;
      }
      var focusProperties = currentThinkEditor.GetFocusProperties();
      if (focusProperties.table == undefined) {
        return;
      }

      let rowIdx = focusProperties.table.row.rowIdx;

      let ret = currentThinkEditor.SetObjectClaim({ object: 'row', rowIdx: rowIdx, claim: this.inputClaimValue });
    },
    async OnClearRowClaimTest() {
      let currentTabsView = this.defaultEditorsTabsView;
      let currentEditorsInstance = currentTabsView.editorsInstance;
      let currentThinkEditor = currentEditorsInstance.GetSelectedEditor();
      if (currentThinkEditor === undefined) {
        return;
      }

      var focusProperties = currentThinkEditor.GetFocusProperties();
      if (focusProperties.table == undefined) {
        return;
      }
      let rowIdx = focusProperties.table.row.rowIdx;

      //解除当前行编辑者限定 clearClaim
      currentThinkEditor.SetObjectClaim({ object: 'row', rowIdx: rowIdx, claim: '' });
    },
    async OnSetCellClaimTest() {
      let currentTabsView = this.defaultEditorsTabsView;
      let currentEditorsInstance = currentTabsView.editorsInstance;
      let currentThinkEditor = currentEditorsInstance.GetSelectedEditor();
      if (currentThinkEditor === undefined) {
        return;
      }
      var focusProperties = currentThinkEditor.GetFocusProperties();
      if (focusProperties.table == undefined) {
        return;
      }

      let rowIdx = focusProperties.table.cell.rowIdx;
      let colIdx = focusProperties.table.cell.colIdx;
      //锁定当前行所有者并签名 ClaimIsCurrentEditor
      currentThinkEditor.SetObjectClaim({ object: 'cell', rowIdx: rowIdx, colIdx: colIdx, claim: this.inputClaimValue });
    },
    async OnClearCellClaimTest() {
      let currentTabsView = this.defaultEditorsTabsView;
      let currentEditorsInstance = currentTabsView.editorsInstance;
      let currentThinkEditor = currentEditorsInstance.GetSelectedEditor();
      if (currentThinkEditor === undefined) {
        return;
      }

      var focusProperties = currentThinkEditor.GetFocusProperties();
      if (focusProperties.table == undefined) {
        return;
      }

      let rowIdx = focusProperties.table.cell.rowIdx;
      let colIdx = focusProperties.table.cell.colIdx;

      currentThinkEditor.SetObjectClaim({ object: 'cell', rowIdx: rowIdx, colIdx: colIdx, claim: '' });
    },
    async OnSetParagraphClaimTest() {
      let currentTabsView = this.defaultEditorsTabsView;
      let currentEditorsInstance = currentTabsView.editorsInstance;
      let currentThinkEditor = currentEditorsInstance.GetSelectedEditor();
      if (currentThinkEditor === undefined) {
        return;
      }
      var focusProperties = currentThinkEditor.GetFocusProperties();
      if (focusProperties.paragraph == undefined) {
        return;
      }

      currentThinkEditor.SetObjectClaim({ object: 'paragraph', claim: this.inputClaimValue });
    },
    async OnClearParagraphClaimTest() {
      let currentTabsView = this.defaultEditorsTabsView;
      let currentEditorsInstance = currentTabsView.editorsInstance;
      let currentThinkEditor = currentEditorsInstance.GetSelectedEditor();
      if (currentThinkEditor === undefined) {
        return;
      }

      var focusProperties = currentThinkEditor.GetFocusProperties();
      if (focusProperties.paragraph == undefined) {
        return;
      }

      //解除当前行编辑者限定 clearClaim
      currentThinkEditor.SetObjectClaim({ object: 'paragraph', claim: '' });
    }
  }
};
