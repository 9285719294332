<template>
  <div class="colort-box" title="字体颜色">
    <el-color-picker ref="fontColorPicker" v-model="fontColor" color-format="rgb" :predefine="predefineColors" size="mini" @change="colorChange"></el-color-picker>
    <span class="material-icons fontCtrlIcon" @click="fontColorChange">arrow_drop_down</span>
  </div>
</template>

<script>
import { RGBA2Color, Color2RGBAStr, RGBAStr2Color } from '@/components/editor/ThinkEditor.Utils';
import { E_EVENT_KEY } from '@/components/editor/ThinkEditor.Defined';
import { editorsInstanceManager } from '@/components/editorHelper/ThinkEditorInstance.Manager';
export default {
  data() {
    return {
      fontColor: null,
      predefineColors: ['#000000', '#d0d0d0', '#ff4500', '#ff8c00', '#ffd700', '#90ee90', '#00ced1', '#1e90ff', '#c71585']
    };
  },
  mounted() {
    editorsInstanceManager.addEventListener(E_EVENT_KEY.fontFormat, this.OnFontFormatChange);
  },
  methods: {
    OnFontFormatChange(e) {
      let data = e.data.data;
      this.fontColor = Color2RGBAStr(data.color);
    },
    fontColorChange() {
      this.$refs.fontColorPicker.handleTrigger();
    },
    colorChange(new_color) {
      // 把rgba(238, 144, 227, 1)转换为对象
      // 为空验证
      if (new_color) {
        let color = RGBAStr2Color(new_color);
        let currentEditorsInstance = editorsInstanceManager.GetSelectedEditorsInstance();
        let currentEditor = currentEditorsInstance?.GetSelectedEditor();
        currentEditor.SetFontColor(color);
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.colort-box {
  display: flex;
  align-items: center;
  .fontCtrlIcon {
    margin-left: -8px;
    cursor: pointer;
  }
}
</style>
