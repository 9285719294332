<template>
  <div class="select-box">
    <div
      @click="selectGoods"
      class="setdiv"
      style="height: 27px; width: 240px; border: 1px solid #dcdfe6; border-radius: 5px; margin-top: 6px; overflow: hidden"
      v-html="content"
    ></div>
    <transition name="fade">
      <ul class="terminalList" v-show="isShow">
        <li v-for="(item, index) in options" :key="index" class="terminal-item" @click="selectEquipment(item, index)" :class="{ 'active-class': activeIndex === index }">
          <div ref="Common" v-if="item.value === 0" style="float: left; margin-top: 5px; margin-left: 5px">
            <div style="height: 12px; width: 12px; border-radius: 50%; border: 2px solid #000; position: relative">
              <div style="height: 4px; width: 4px; border-radius: 50%; background: #000; position: absolute; left: 50%; top: 50%; transform: translate(-50%, -50%)"></div>
            </div>
          </div>
          <div ref="MensesStyle_1" v-if="item.value === 1" style="float: left; margin-top: 5px; margin-left: 5px">
            <div style="height: 12px; width: 12px; border: 2px solid #000; position: relative">
              <div id="cycOne" style="height: 40px; width: 10px; position: absolute; left: 50%; top: 50%; transform: translate(-50%, -50%)"></div>
            </div>
          </div>
          <div ref="MensesStyle_2" v-if="item.value === 2" style="float: left; margin-top: 5px; margin-left: 5px">
            <div style="height: 12px; width: 12px; border-radius: 50%; border: 2px solid #000; position: relative">
              <div id="cycOne" style="height: 40px; width: 10px; position: absolute; left: 50%; top: 50%; transform: translate(-50%, -50%)"></div>
            </div>
          </div>
          <div ref="MensesStyle_3" v-if="item.value === 3" style="float: left; margin-top: 5px; margin-left: 5px">
            <div style="height: 12px; width: 12px; border-radius: 50%; border: 2px solid #000; position: relative">
              <div style="height: 6px; width: 6px; background: #000; position: absolute; left: 50%; top: 50%; transform: translate(-50%, -50%)"></div>
            </div>
          </div>
          <div ref="MensesStyle_4" v-if="item.value === 4" style="float: left; margin-top: 5px; margin-left: 5px">
            <div style="height: 12px; width: 12px; border-radius: 50%; border: 2px solid #000; position: relative">
              <div id="cyc" style="height: 40px; width: 10px; position: absolute; left: 50%; top: 50%; transform: translate(-50%, -50%)"></div>
            </div>
          </div>
        </li>
      </ul>
    </transition>
  </div>
</template>

<script>
export default {
  props: {
    value: Number,
    contentVal: Number,
  },
  data() {
    return {
      isShow: false,
      activeIndex: "",
      activeValue: "Common",
      triangle:
        '<div style="width: 27px;height: 27px;border: 1px solid rgb(220, 223, 230);box-sizing: border-box; float: right; background: #ccc">' +
        '<div style="width: 0;\n' +
        "height: 0;\n" +
        "border-top: 8px solid #000;\n" +
        "border-right: 8px solid transparent;\n" +
        "border-left: 8px solid transparent;\n" +
        "float: right;\n" +
        "margin-top: 10px;\n" +
        "margin-right: 5px;\n" +
        'border-bottom: 8px solid transparent;"></div>' +
        "</div>",
      content:
        '<div data-v-47fd27d6="" style="float: left; margin-top: 5px; margin-left: 5px;">' +
        '<div data-v-47fd27d6="" style="height: 12px; width: 12px; border-radius: 50%; border: 2px solid rgb(0, 0, 0); position: relative;">' +
        '<div data-v-47fd27d6="" style="height: 4px; width: 4px; border-radius: 50%; background: rgb(0, 0, 0); position: absolute; left: 50%; top: 50%; transform: translate(-50%, -50%);"></div>' +
        "</div></div>" +
        '<div style="width: 27px;height: 27px;border: 1px solid rgb(220, 223, 230);box-sizing: border-box; float: right; background: #ccc">' +
        '<div style="width: 0;\n' +
        "height: 0;\n" +
        "border-top: 8px solid #000;\n" +
        "border-right: 8px solid transparent;\n" +
        "border-left: 8px solid transparent;\n" +
        "float: right;\n" +
        "margin-top: 10px;\n" +
        "margin-right: 5px;\n" +
        'border-bottom: 8px solid transparent;"></div>' +
        "</div>",
      options: [
        {
          value: 0,
          label: "",
        },
        {
          value: 1,
          label: "",
        },
        {
          value: 2,
          label: "",
        },
        {
          value: 3,
          label: "",
        },
        {
          value: 4,
          label: "",
        },
      ],
    };
  },
  watch: {
    contentVal: {
      handler(val) {
        if (val) {
          switch (val) {
            case 0:
              this.content =
                '<div data-v-47fd27d6="" style="float: left; margin-top: 5px; margin-left: 5px;"><div data-v-47fd27d6="" style="height: 12px; width: 12px; border-radius: 50%; border: 2px solid rgb(0, 0, 0); position: relative;"><div data-v-47fd27d6="" style="height: 4px; width: 4px; border-radius: 50%; background: rgb(0, 0, 0); position: absolute; left: 50%; top: 50%; transform: translate(-50%, -50%);"></div></div></div>' +
                this.triangle;
              break;
            case 1:
              this.content =
                '<div data-v-47fd27d6="" style="float: left; margin-top: 5px; margin-left: 5px;"><div data-v-47fd27d6="" style="height: 12px; width: 12px; border: 2px solid rgb(0, 0, 0); position: relative;"><div data-v-47fd27d6="" id="cycOne" style="height: 40px; width: 10px; position: absolute; left: 50%; top: 50%; transform: translate(-50%, -50%);"></div></div></div>' +
                this.triangle;
              break;
            case 2:
              this.content =
                '<div data-v-47fd27d6="" style="float: left; margin-top: 5px; margin-left: 5px;"><div data-v-47fd27d6="" style="height: 12px; width: 12px; border-radius: 50%; border: 2px solid rgb(0, 0, 0); position: relative;"><div data-v-47fd27d6="" id="cycOne" style="height: 40px; width: 10px; position: absolute; left: 50%; top: 50%; transform: translate(-50%, -50%);"></div></div></div>' +
                this.triangle;
              break;
            case 3:
              this.content =
                '<div data-v-47fd27d6="" style="float: left; margin-top: 5px; margin-left: 5px;"><div data-v-47fd27d6="" style="height: 12px; width: 12px; border-radius: 50%; border: 2px solid rgb(0, 0, 0); position: relative;"><div data-v-47fd27d6="" style="height: 6px; width: 6px; background: rgb(0, 0, 0); position: absolute; left: 50%; top: 50%; transform: translate(-50%, -50%);"></div></div></div>' +
                this.triangle;
              break;
            case 4:
              this.content =
                '<div data-v-47fd27d6="" style="float: left; margin-top: 5px; margin-left: 5px;"><div data-v-47fd27d6="" style="height: 12px; width: 12px; border-radius: 50%; border: 2px solid rgb(0, 0, 0); position: relative;"><div data-v-47fd27d6="" id="cyc" style="height: 40px; width: 10px; position: absolute; left: 50%; top: 50%; transform: translate(-50%, -50%);"></div></div></div>' +
                this.triangle;
              break;
          }
        }
      },
      immediate: true,
    },
  },
  methods: {
    selectGoods() {
      this.isShow = !this.isShow;
    },
    selectEquipment(item, index) {
      this.activeIndex = index;
      this.activeValue = item.value;
      this.$nextTick(() => {
        switch (item.value) {
          case 0:
            this.content = this.$refs.Common[0].outerHTML + this.triangle;
            break;
          case 1:
            this.content = this.$refs.MensesStyle_1[0].outerHTML + this.triangle;
            break;
          case 2:
            this.content = this.$refs.MensesStyle_2[0].outerHTML + this.triangle;
            break;
          case 3:
            this.content = this.$refs.MensesStyle_3[0].outerHTML + this.triangle;
            break;
          case 4:
            this.content = this.$refs.MensesStyle_4[0].outerHTML + this.triangle;
            break;
        }
      });
      this.isShow = false;
      this.$emit("input", this.activeValue);
    },
  },
};
</script>

<style scoped lang="scss">
#cyc::before {
  content: "";
  display: block;
  width: 8px;
  height: 2px;
  background: #000;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%) rotate(45deg);
}

#cyc::after {
  content: "";
  display: block;
  width: 8px;
  height: 2px;
  background: #000;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%) rotate(-45deg);
}

#cycOne::before {
  content: "";
  display: block;
  width: 3px;
  height: 2px;
  background: #000;
  position: absolute;
  top: 50%;
  left: 26%;
  transform: translate(-50%, -50%) rotate(45deg);
}

#cycOne::after {
  content: "";
  display: block;
  width: 8px;
  height: 2px;
  background: #000;
  position: absolute;
  top: 50%;
  left: 67%;
  transform: translate(-50%, -50%) rotate(-45deg);
}

.select-box {
  width: 125px;
  height: 100%;
}

.terminalList {
  width: 240px;
  max-height: 400px;
  overflow-y: auto;
  border: 1px solid #ccc;
  border-radius: 10px;
  box-shadow: 0 2px 12px 0 rgb(0 0 0 / 10%);
  position: absolute;
  padding-left: 10px;
  padding-top: 10px;
  padding-bottom: 10px;
  left: 0px;
  top: 28px;
  background: #fff;
  z-index: 9999;

  .terminal-item {
    //border: 1px solid #ccc;
    width: 230px;
    height: 30px;
    color: #000;
    display: flex;
    flex-direction: row; /* 子元素横向排列 */
    justify-content: space-between; /* 相对父元素水平居中 */
    align-items: center;
    cursor: pointer;

    &:hover {
      background: #62aff1;

      .label-style {
        background: #9d9d9d;
      }
    }
  }

  .active-class {
    background: #ccc;
  }
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.8s;
}

.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}

/* 整个滚动条 */
::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}

::-webkit-scrollbar-track {
  background-color: transparent;
  -webkit-border-radius: 2em;
  -moz-border-radius: 2em;
  border-radius: 2em;
}

::-webkit-scrollbar-thumb {
  background-color: rgb(147, 147, 153, 0.5);
  -webkit-border-radius: 2em;
  -moz-border-radius: 2em;
  border-radius: 2em;
}

.label-style {
  float: right;
  color: #000;
  min-width: 90px;
  height: 60px;
  line-height: 60px;
  text-align: center;
  font-size: 14px;
  background: #ebebeb;
  border: 1px solid #ccc;

  &:hover {
    background: #9d9d9d;
  }
}

.active-label-style {
  float: right;
  color: #000;
  min-width: 90px;
  height: 60px;
  line-height: 60px;
  text-align: center;
  font-size: 14px;
  background: #9d9d9d;
  border: 1px solid #ccc;
}

.triangle {
  position: relative;
  width: 227px;
  height: 60px;
  background: linear-gradient(to top right, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0) calc(50% - 1px), rgba(0, 0, 0, 0.8) 50%, rgba(0, 0, 0, 0) calc(50% + 1px), rgba(0, 0, 0, 0) 100%),
    linear-gradient(to bottom right, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0) calc(50% - 1px), rgba(0, 0, 0, 0.8) 50%, rgba(0, 0, 0, 0) calc(50% + 1px), rgba(0, 0, 0, 0) 100%);
}

.setdiv {
  .label-style {
    min-width: 109px;
  }
}

.triangle1 {
  width: 20px;
  height: 30px;
  line-height: 30px;
  background: linear-gradient(to top right, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0) calc(50% - 1px), rgba(0, 0, 0, 0) 50%, rgba(0, 0, 0, 0) calc(50% + 1px), rgba(0, 0, 0, 0) 100%),
    linear-gradient(to bottom right, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0) calc(50% - 1px), rgba(0, 0, 0, 0.8) 50%, rgba(0, 0, 0, 0) calc(50% + 1px), rgba(0, 0, 0, 0) 100%);
}
</style>
