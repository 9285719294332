import { GetRandStr } from '@/components/editor/ThinkEditor.Utils';
import { ThinkEditor } from '@/components/editor/ThinkEditor';
import { E_DOCS_ORGANIZE_MODE, E_VIEW_MODE, E_SYSTEM_AUTH_MODE } from '@/components/editor/ThinkEditor.Defined';

export const DemoCaseMultDocPrint = {
  methods: {
    /**
     * @function DoDemoCaseMultDocPrint
     * @description 合并打印文档。
     * 1、可控制子文档是否分页合并
     * 2、可控制是否显示合并后的文档
     * @param {backgroundPrint} true: 不打开显示的合并后文档  false: 打开显示的合并后文档
     * @return {}
     * @example
     */
    async DoDemoCaseMultDocPrint() {
      let docName = '合并打印Case';
      let editorsTabsView = this.defaultEditorsTabsView;
      let currentEditorsInstance = editorsTabsView.editorsInstance;
      let thinkEditor = currentEditorsInstance.GetEditor(docName);
      if (thinkEditor === undefined) {
        thinkEditor = await editorsTabsView.CreateDocEditor(docName);

        var url = '/system/emr/1.xml'; //"/system/test/pacsdemo.jpg";
        let response = await fetch(url, {
          credentials: 'same-origin'
        });
        let xml1 = await response.arrayBuffer();

        var json_cfg = {};
        json_cfg.organizeMode = E_DOCS_ORGANIZE_MODE.UnionSection;
        json_cfg.recoverSelection = 2;
        json_cfg.setUnionSelection = 0;
        thinkEditor.CreateDoc(docName); //@20220722 必需，才能设置编辑者信息
        thinkEditor.SelectDoc(docName);
        thinkEditor.SetEditorInfo('pc', 'zltest', 'zl', 1, '');
        thinkEditor.ParseDocs(docName, '首次病程记录-1-13333', xml1, json_cfg);

        url = '/system/emr/2.xml'; //"/system/test/pacsdemo.jpg";
        response = await fetch(url, {
          credentials: 'same-origin'
        });
        let xml2 = await response.arrayBuffer();
        json_cfg.organizeMode = E_DOCS_ORGANIZE_MODE.UnionContent;
        json_cfg.recoverSelection = 2;
        json_cfg.setUnionSelection = 1;
        thinkEditor.ParseDocs(docName, '首次病程记录-1-147449', xml2, json_cfg);

        url = '/system/emr/3.xml'; //"/system/test/pacsdemo.jpg";
        response = await fetch(url, {
          credentials: 'same-origin'
        });
        let xml3 = await response.arrayBuffer();
        json_cfg.organizeMode = E_DOCS_ORGANIZE_MODE.UnionSection;
        json_cfg.recoverSelection = 2;
        json_cfg.setUnionSelection = 0;
        thinkEditor.ParseDocs(docName, '首次病程记录-1-3', xml3, json_cfg);

        url = '/system/emr/4.xml'; //"/system/test/pacsdemo.jpg";
        response = await fetch(url, {
          credentials: 'same-origin'
        });
        let xml4 = await response.arrayBuffer();
        json_cfg.organizeMode = E_DOCS_ORGANIZE_MODE.UnionSection;
        json_cfg.recoverSelection = 2;
        json_cfg.setUnionSelection = 0;
        thinkEditor.ParseDocs(docName, '首次病程记录-1-4', xml4, json_cfg);

        thinkEditor.SetViewMode(E_VIEW_MODE.Edit, {});
      }

      //UI 中显示该文档
      editorsTabsView.SelectDocEditor(docName);

      //[0] 注意await,后续可能关闭文档或编辑器
      await thinkEditor.PrintDoc({
        printMode: 1, // 0：视图打印 1：矢量打印
        view: {
          mode: E_VIEW_MODE.Print
          //hideElementHint: true
        }
      });
    }
  }
};
