<template>
  <el-form ref="form" :inline="true" :model="configData" label-width="80px" size="mini">
    <el-table height="350" :data="configData.expressions">
      <el-table-column label="触发事件" prop="event" align="center" width="140">
        <template slot-scope="scope">
          <el-select v-model="configData.expressions[scope.$index].event" placeholder="" size="mini">
            <el-option v-for="item in triggerEventList" :key="item.val" :label="item.label" :value="item.val"></el-option>
          </el-select>
        </template>
      </el-table-column>
      <el-table-column label="执行动作" align="center" prop="action">
        <template slot-scope="scope">
          <el-input v-model="configData.expressions[scope.$index].action" clearble size="mini" @input="handleAdd(scope.$index, scope.row)">
            <template slot="append">
              <el-popover placement="bottom" title="" width="400" trigger="click" v-model="configData.expressions[scope.$index].popVisible" content="" @show="actionShow(configData.expressions[scope.$index].action)">
                <el-form-item label="如果满足条件" label-width="100px">
                  <el-input style="width: 300px" v-model="ifAction" @input="actionFun(1, ifAction)"></el-input>
                </el-form-item>
                <el-form-item label="则执行" label-width="100px">
                  <el-input style="width: 300px" v-model="oneAction" @input="actionFun(2, oneAction)"></el-input>
                </el-form-item>
                <el-form-item label="否则执行" label-width="100px">
                  <el-input style="width: 300px" v-model="towAction" @input="actionFun(3, towAction)"></el-input>
                </el-form-item>
                <el-form-item label="">
                  <el-input type="textarea" v-model="awAction" disabled></el-input>
                </el-form-item>

                <div style="text-align: right; margin: 16px 0px 8px">
                  <el-button size="mini" type="text" @click="configData.expressions[scope.$index].popVisible = false">取消</el-button>
                  <el-button type="primary" size="mini" @click="writeIn(scope.$index)">确定</el-button>
                </div>
                <el-button slot="reference">.</el-button>
              </el-popover>
            </template>
          </el-input>
        </template>
      </el-table-column>
      <el-table-column label="模式" align="center" prop="mode" width="90">
        <template slot-scope="scope">
          <el-select v-model="configData.expressions[scope.$index].mode" placeholder="" size="mini">
            <el-option v-for="item in executeModeList" :key="item.val" :label="item.label" :value="item.val"></el-option>
          </el-select>
        </template>
      </el-table-column>
    </el-table>
  </el-form>
</template>

<script>
import { Expression, E_TRIGGER_EVENT, E_EXECUTE_MODE } from '@/components/editor/ThinkEditor.Defined';
export default {
  name: 'table',
  props: {
    configData: {
      option: {},
      expressions: [new Expression(E_TRIGGER_EVENT.ValueChange, E_EXECUTE_MODE.Always, '')]
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      triggerEventList: [
        {
          label: 'valueChange',
          val: E_TRIGGER_EVENT.ValueChange
        },
        {
          label: 'click',
          val: E_TRIGGER_EVENT.Click
        },
        {
          label: 'dbClick',
          val: E_TRIGGER_EVENT.DbClick
        }
      ],
      executeModeList: [
        {
          label: '每次',
          val: E_EXECUTE_MODE.Always
        },
        {
          label: '一次',
          val: E_EXECUTE_MODE.Once
        },
        {
          label: '重置',
          val: E_EXECUTE_MODE.Reset
        }
      ],
      ifAction: '',
      oneAction: '',
      towAction: '',
      awAction: ''
    };
  },
  watch: {
    configData: {
      handler(val) {
        this.SelfInit();
      },
      deep: true,
      immediate: true
    }
  },
  created() {},
  methods: {
    SelfInit() {
      let data = this.configData;
      if (data.expressions.length == 0) {
        //data.expressions.push(new Expression());
        this.$set(this.configData.expressions, 0, new Expression(E_TRIGGER_EVENT.ValueChange, E_EXECUTE_MODE.Always, ''));
      }
    },
    // 事件执行动作
    actionFun(num, val) {
      if (num === 1) {
        this.awAction = val + '?' + this.oneAction + ':' + this.towAction;
      } else if (num === 2) {
        this.awAction = this.ifAction + '?' + val + ':' + this.towAction;
      } else {
        this.awAction = this.ifAction + '?' + this.oneAction + ':' + val;
      }
    },
    actionShow(val) {
      this.visible = true;
      if (val.indexOf('?') != -1 || val.indexOf(':') != -1) {
        let shows = val.split('?');
        this.ifAction = shows[0];
        if (shows[1].indexOf('?') != -1 || shows[1].indexOf(':') != -1) {
          this.oneAction = shows[1].split(':')[0];
          this.towAction = shows[1].split(':')[1];
        }
        this.awAction = val;
      } else {
        this.ifAction = '';
        this.oneAction = '';
        this.towAction = '';
        this.awAction = '';
      }
    },
    writeIn(index) {
      this.configData.expressions[index].action = this.awAction;
      this.configData.expressions[index].popVisible = false;
      this.handleAdd(index);
    },

    // 添加一行表格
    handleAdd(index, row) {
      if (index + 1 === this.configData.expressions.length) {
        this.configData.expressions.push({
          event: 0,
          mode: 0,
          action: '',
          popVisible: false
        });
      }
    }
  }
};
</script>

<style scoped>
::v-deep .el-table__cell {
  padding: 5px 0 !important;
}
</style>
