<template>
  <el-form ref="form" :model="configData" label-width="80px" size="mini">
    <el-row style="margin-bottom: 10px; margin-top: 10px">
      <el-col :span="12">
        <el-form-item prop="id" label="选项类">
          <el-select size="mini" style="width: 200px" v-model="configData.option.optionClass" placeholder="请选择">
            <el-option v-for="item in optionsManager.options" :key="item.optionClass" :label="item.optionClass" :value="item.optionClass" :disabled="item.disabled"></el-option>
          </el-select>
        </el-form-item>
      </el-col>
      <el-col :span="12">
        <el-form-item prop="id" label="选项组">
          <el-select size="mini" style="width: 200px" v-model="configData.option.optionId" placeholder="请选择">
            <el-option v-for="item in currentOptionClassObj.optionIds" :key="item.optionId" :label="item.optionId" :value="item.optionId" :disabled="item.disabled"></el-option>
          </el-select>
        </el-form-item>
      </el-col>
      <el-col v-if="isCheckBoxGroup" style="margin-top: 10px" :span="12">
        <el-form-item prop="id" label="选集名">
          <el-input size="mini" style="width: 200px" v-model="configData.option.optionName"></el-input>
        </el-form-item>
      </el-col>
      <el-col v-if="isCheckBoxGroup" style="margin-top: 10px" :span="12">
        <el-form-item prop="id" label="当前选项">
          <el-input :disabled="disabled" size="mini" style="width: 200px" v-model="configData.itemId"></el-input>
        </el-form-item>
      </el-col>
    </el-row>
    <el-table height="350" :data="currentOptionIdObj.items">
      <el-table-column label="选项ID" prop="id" align="center" width="120">
        <template slot-scope="scope">
          <el-input v-model="currentOptionIdObj.items[scope.$index].id" clearble disabled size="mini"></el-input>
        </template>
      </el-table-column>
      <el-table-column label="组ID" align="center" prop="group">
        <template slot-scope="scope">
          <el-input v-model="currentOptionIdObj.items[scope.$index].group" clearble disabled size="mini"></el-input>
        </template>
      </el-table-column>
      <el-table-column label="权值" align="center" prop="weight">
        <template slot-scope="scope">
          <el-input v-model="currentOptionIdObj.items[scope.$index].weight" clearble disabled size="mini"></el-input>
        </template>
      </el-table-column>
      <el-table-column label="内容" align="center" prop="content" width="190">
        <template slot-scope="scope">
          <el-input v-model="currentOptionIdObj.items[scope.$index].content" clearble disabled size="mini"></el-input>
        </template>
      </el-table-column>
    </el-table>
  </el-form>
</template>

<script>
import { E_EVENT_KEY, OptionsManager, E_SET_MODE, OptionClass, OptionId, OptionItem } from '@/components/editor/ThinkEditor.Defined';
import { editorsInstanceManager } from '@/components/editorHelper/ThinkEditorInstance.Manager';

export default {
  name: 'table',
  props: {
    configData: {
      option: {},
      algorithm: {}
    },
    optionsManager: {
      type: Object,
      default: () => new OptionsManager()
    }
  },
  computed: {},
  data() {
    return {
      thinkEditor: undefined,
      isCheckBoxGroup: false,
      currentOptionClassObj: new OptionClass(),
      currentOptionIdObj: undefined
    };
  },
  watch: {
    configData: {
      handler(val) {
        this.SelfInit();
      },
      deep: true,
      immediate: true
    }
  },
  created() {
    editorsInstanceManager.addEventListener(E_EVENT_KEY.editorFocus, this.OnDocEditorFocusChange);

    let currentEditorsInstance = editorsInstanceManager.GetSelectedEditorsInstance();
    this.thinkEditor = currentEditorsInstance?.GetSelectedEditor();
  },
  mounted() {},
  beforeDestroy() {},
  methods: {
    SelfInit() {
      let data = this.configData;
      let optionClassObj = this.optionsManager.GetOptionClass(data.option.optionClass);
      if (optionClassObj === undefined) {
        this.currentOptionClassObj = new OptionClass();
        this.currentOptionIdObj = new OptionId();
      } else {
        this.currentOptionClassObj = optionClassObj;

        let currentOptionIdObj = optionClassObj.GetOptionId(data.option.optionId);
        if (currentOptionIdObj === undefined) {
          this.currentOptionIdObj = new OptionId();
        } else {
          this.currentOptionIdObj = currentOptionIdObj;
        }
      }
    },
    OnDocEditorFocusChange(e) {
      this.thinkEditor = e.data.editor;

      this.SelfInit();
    }
  }
};
</script>

<style scoped>
::v-deep .el-table__cell {
  padding: 5px 0 !important;
}
</style>
