<template>
  <div class="icon-box" @click="$emit('boxClick')">
    <span class="material-icons transfor-txt" :style="{ fontSize: iconFontSize }">short_text</span>
    <span class="material-icons last-icon" :style="{ fontSize: iconFontSize, marginTop: resetMarginTop }">subject</span>
  </div>
</template>

<script>
export default {
  props: {
    iconFontSize: {
      type: String,
      default: '14px'
    },
    resetMarginTop: {
      type: String,
      default: '-12px'
    }
  }
};
</script>

<style lang="scss" scoped>
.icon-box {
  display: flex;
  flex-direction: column;
  align-items: center;
  .transfor-txt {
    transform: rotate(180deg);
    // font-size: 44px;
  }
}
</style>
