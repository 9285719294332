export const DemoCaseForbidPasteFromOuter = {
  methods: {
    async OnAllowPasteFromOuterTest() {
      let currentTabsView = this.defaultEditorsTabsView;
      let currentEditorsInstance = currentTabsView.editorsInstance;
      let currentThinkEditor = currentEditorsInstance.GetSelectedEditor();
      if (currentThinkEditor === undefined) {
        return;
      }

      currentThinkEditor.SetEditorConfig({ forbidPasteFromOuter: false });
    },
    async OnForbidPasteFromOuterTest() {
      let currentTabsView = this.defaultEditorsTabsView;
      let currentEditorsInstance = currentTabsView.editorsInstance;
      let currentThinkEditor = currentEditorsInstance.GetSelectedEditor();
      if (currentThinkEditor === undefined) {
        return;
      }

      currentThinkEditor.SetEditorConfig({ forbidPasteFromOuter: true });
    }
  }
};
