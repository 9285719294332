<template>
  <VacantDialog title="字体设置" top="48vh" width="30%" ref="thisDialog" :footer-show="false">
    <div class="font-box">
      <el-form ref="form" label-width="60px" size="mini">
        <el-divider content-position="left">常规</el-divider>
        <el-row>
          <el-col :span="8">
            <el-form-item label="字体">
              <el-select v-model="configData.fontName" placeholder="请选择字体" @change="fontNameChange">
                <el-option v-for="(item, idx) in fontNameList" :label="item" :value="item" :key="item"></el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="字形">
              <el-select v-model="fontGlyph" placeholder="请选择字形" @change="fontGlyphChange">
                <el-option :label="item" :value="item" v-for="(item, idc) in fontGlyphList" :key="item"></el-option>
              </el-select>
            </el-form-item>
          </el-col>

          <el-col :span="8">
            <el-form-item label="字号">
              <el-select v-model="configData.fontSizeItem" placeholder="请选择字号" @change="fontSizeChange">
                <el-option :label="fzItem" :value="fzItem" v-for="(fzItem, fzIdx) in fontSizeList" :key="fzIdx"></el-option>
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>

        <el-divider content-position="left">颜色</el-divider>
        <el-row>
          <el-col :span="11">
            <div class="colorField">
              <span>文字颜色</span>
              <el-color-picker v-model="fontColor" show-alpha :predefine="predefineColors" @change="fontColorChange"></el-color-picker>
            </div>
          </el-col>
          <el-col :span="11">
            <div class="colorField">
              <span>背景颜色</span>
              <el-color-picker v-model="fontBackColor" show-alpha :predefine="predefineColors" @change="fontBackColorChange"></el-color-picker>
            </div>
          </el-col>
        </el-row>
        <el-divider content-position="left">效果</el-divider>
        <el-row>
          <el-col :span="24">
            <el-checkbox v-model="configData.underline" @change="fontUnderlineChange" :true-label="1" :false-label="0">下划线</el-checkbox>
            <el-checkbox v-model="configData.strikethrough" @change="fontStrikethroughChange" :true-label="1" :false-label="0">删除线</el-checkbox>
            <el-checkbox v-model="configData.script" :true-label="1" :false-label="0" @change="fontScriptChange">上标</el-checkbox>
            <el-checkbox v-model="configData.script" :true-label="2" :false-label="0" @change="fontScriptChange">下标</el-checkbox>
          </el-col>
        </el-row>
      </el-form>
    </div>
  </VacantDialog>
</template>

<script>
import { E_EVENT_KEY, E_SCRIPT_MODE } from '@/components/editor/ThinkEditor.Defined';
import { Color2RGBAStr, RGBAStr2Color, UpdateConfigData } from '@/components/editor/ThinkEditor.Utils';
import { editorsInstanceManager } from '@/components/editorHelper/ThinkEditorInstance.Manager';

export default {
  props: {},
  data() {
    return {
      configData: {
        /*fontName: '黑体'*/
      },
      thinkEditor: undefined,
      fontGlyph: '',
      fontBackColor: '#ff4500',
      fontColor: '#ff8c00',
      predefineColors: ['#ff4500', '#ff8c00', '#ffd700', '#90ee90', '#00ced1', '#1e90ff', '#c71585', 'rgba(255, 69, 0, 0.68)', 'rgb(255, 120, 0)', 'hsv(51, 100, 98)', 'hsva(120, 40, 94, 0.5)', 'hsl(181, 100%, 37%)', 'hsla(209, 100%, 56%, 0.73)', '#c7158577'],
      fontSizeList: ['初号', '小初', '一号', '小一', '二号', '小二', '三号', '小三', '四号', '小四', '五号', '小五', '六号', '小六', '七号', '八号', '5', '5.5', '6.5', '7.5', '8', '9', '10', '10.5', '11', '12', '14', '18', '20', '26', '28', '38', '48', '56', '72'],
      fontGlyphList: ['常规', '加粗', '倾斜', '加粗 倾斜'],
      fontNameList: ['宋体', '黑体']
    };
  },
  watch: {
    /*
       * 如果bold =0 && italic=0 ===》常规
       * bold =1&&italic =1 ====》加粗 倾斜
       * bold===》 0：否，1：是
       * italic===》 0：否，1：是
       *underline 是否有下划线 0：否 1：是
       * strikethrough 是否有删除线 0：否 1：是
       * script 上下标
       *  Normal = 0,//无
          SuperScript = 1,//上标
          SubScript = 2,//下标
       * */
    configData: {
      handler(newVal, oldVal) {
        this.SelfInit(newVal);
      },
      immediate: true
    }
  },
  created() {
    editorsInstanceManager.addEventListener(E_EVENT_KEY.editorFocus, this.OnDocEditorFocusChange);
    let currentEditorsInstance = editorsInstanceManager.GetSelectedEditorsInstance();
    this.thinkEditor = currentEditorsInstance?.GetSelectedEditor();
  },
  methods: {
    SelfInit() {
      let cfg = this.configData;
      if (cfg.bold === 0 && cfg.italic === 0) {
        this.fontGlyph = '常规';
      } else if (cfg.bold === 1 && cfg.italic === 1) {
        this.fontGlyph = '加粗 倾斜';
      } else if (cfg.bold === 1 && cfg.italic === 0) {
        this.fontGlyph = '加粗';
      } else if (cfg.bold === 0 && cfg.italic === 1) {
        this.fontGlyph = '倾斜';
      } else {
        this.fontGlyph = '';
      }

      if (cfg.backColor !== undefined) {
        this.fontBackColor = Color2RGBAStr(cfg.backColor);
      }
      if (cfg.color !== undefined) {
        //ffd.color
        this.fontColor = Color2RGBAStr(cfg.color);
      }
    },
    openDialog(configData) {
      UpdateConfigData(this, configData);

      this.SelfInit();
      this.$refs.thisDialog.openDialog();
    },

    closeDialog() {
      this.$refs.thisDialog.closeDialog();
      this.thinkEditor.SetInputFocus(true);
    },
    OnDocEditorFocusChange(e) {
      this.thinkEditor = e.data.editor;
    },
    fontScriptChange(val) {
      this.thinkEditor.SetFontScript(this.configData.script);
    },
    fontStrikethroughChange() {
      this.thinkEditor.SetFontStrikethrough(this.configData.strikethrough);
    },
    fontUnderlineChange() {
      this.thinkEditor.SetFontUnderline(this.configData.underline);
    },
    // 字体
    fontNameChange() {
      this.thinkEditor.SetFontType(this.configData.fontName);
    },
    // 字形
    fontGlyphChange() {
      // 字形需要根据不同值进行判断
      let bold = false;
      let italic = false;
      switch (this.fontGlyph) {
        case '常规':
          italic = 0;
          bold = 0;
          break;
        case '加粗 倾斜':
          italic = 1;
          bold = 1;
          break;
        case '加粗':
          italic = 0;
          bold = 1;
          break;
        case '倾斜':
          italic = 1;
          bold = 0;
          break;
      }

      this.thinkEditor.SetFontItalic(italic);
      this.thinkEditor.SetFontBold(bold);
    },
    // 字号
    fontSizeChange() {
      this.thinkEditor.SetFontSizeItem(this.configData.fontSizeItem);
    },
    fontColorChange(new_color) {
      this.thinkEditor.SetFontColor(RGBAStr2Color(new_color));
    },
    fontBackColorChange(new_color) {
      this.thinkEditor.SetFontBackColor(RGBAStr2Color(new_color));
    },
    transforRgbaObjFromRgba(new_color) {
      // 把rgba(238, 144, 227, 1)转换为对象
      var colorVal = new_color.substring(new_color.indexOf('(') + 1, new_color.indexOf(')'));
      var colorValArr = colorVal.split(',');
      var colorReqObj = {
        rgba: {
          r: colorValArr[0],
          g: colorValArr[1],
          b: colorValArr[2],
          a: colorValArr[3]
        }
      };
      return colorReqObj;
    }
  }
};
</script>

<style lang="scss" scoped>
.font-box {
  margin: 4px 8px 20px;
}
.colorField {
  display: flex;
  align-items: center;
}
:deep(.el-divider--horizontal) {
  margin: 15px 0;
}
</style>
