import { E_EVENT_KEY, E_VIEW_MODE, E_DOC_FORMAT } from '@/components/editor/ThinkEditor.Defined';

export const DemoCaseGetSelectRangeContent = {
  methods: {
    async DoDemoCaseGetSelectRangeContent() {
      let editorsTabsView = this.defaultEditorsTabsView;
      let currentEditorsInstance = editorsTabsView.editorsInstance;

      var docName = '获取框选内容';
      let thinkEditor = currentEditorsInstance.GetEditor(docName);

      if (thinkEditor === undefined) {
        thinkEditor = await editorsTabsView.CreateDocEditor(docName);

        var url = '/system/emr/演示/00-演示病例/AdmissionRecord.xml'; //"/system/test/pacsdemo.jpg";
        let response = await fetch(url, {
          credentials: 'same-origin'
        });
        let xml = await response.arrayBuffer();

        thinkEditor.CreateDoc(docName); //@20220722 必需，才能设置编辑者信息
        thinkEditor.SelectDoc(docName);

        thinkEditor.ParseDocs(docName, '', xml, {});
        /*************************************************
         * 配置编辑者信息
         * **********************************************/
        thinkEditor.SetEditorInfo('pc', 'appGuid1', 'zl', 1, '');

        thinkEditor.SetViewMode(E_VIEW_MODE.Edit, {});

        thinkEditor.addEventListener(E_EVENT_KEY.docModified, this.OnDocModified);
        //or currentEditorsInstance.addEventListener(E_EVENT_KEY.docModified, this.OnDocModified);
      }
      //UI 中显示该文档
      editorsTabsView.SelectDocEditor(docName);
    },
    async OnDocModified(e) {
      let thinkEditor = e.data.editor;
    },
    // formatXml(xml) {
    //   const parser = new DOMParser();
    //   const xmlDoc = parser.parseFromString(xml, 'text/xml');
    //   return new XMLSerializer().serializeToString(xmlDoc.documentElement);
    // },
    async OnGetSelectRangeContentXmlTest() {
      let currentTabsView = this.defaultEditorsTabsView;
      let currentEditorsInstance = currentTabsView.editorsInstance;
      let currentThinkEditor = currentEditorsInstance.GetSelectedEditor();
      if (currentThinkEditor === undefined) {
        return;
      }

      let xml = currentThinkEditor.GetSelectRangeContent(E_DOC_FORMAT.XML);
      this.popDialogTitle = 'GetSelectRangeContent(E_DOC_FORMAT.XML)';
      this.popDialogText = xml.replace(/&/g, '&amp;').replace(/</g, '&lt;').replace(/>/g, '&gt;').replace(/"/g, '&quot;').replace(/'/g, '&#039;');
      this.popDialogVisible = true;
    },
    async OnGetSelectRangeContentHtmlTest() {
      let currentTabsView = this.defaultEditorsTabsView;
      let currentEditorsInstance = currentTabsView.editorsInstance;
      let currentThinkEditor = currentEditorsInstance.GetSelectedEditor();
      if (currentThinkEditor === undefined) {
        return;
      }

      let html = currentThinkEditor.GetSelectRangeContent(E_DOC_FORMAT.HTML);
      this.popDialogTitle = 'GetSelectRangeContent(E_DOC_FORMAT.HTML)';
      this.popDialogText = html.replace(/&/g, '&amp;').replace(/</g, '&lt;').replace(/>/g, '&gt;').replace(/"/g, '&quot;').replace(/'/g, '&#039;');
      this.popDialogVisible = true;
    },
    async OnGetSelectRangeContentTextTest() {
      let currentTabsView = this.defaultEditorsTabsView;
      let currentEditorsInstance = currentTabsView.editorsInstance;
      let currentThinkEditor = currentEditorsInstance.GetSelectedEditor();
      if (currentThinkEditor === undefined) {
        return;
      }

      let text = currentThinkEditor.GetSelectRangeContent(E_DOC_FORMAT.TEXT);
      this.popDialogTitle = 'GetSelectRangeContent(E_DOC_FORMAT.TEXT)';
      this.popDialogText = text.replace(/&/g, '&amp;').replace(/</g, '&lt;').replace(/>/g, '&gt;').replace(/"/g, '&quot;').replace(/'/g, '&#039;');
      this.popDialogVisible = true;
    }
  }
};
