<template>
  <vue-file-toolbar-menu :content="my_menu_one" />
</template>
<script>
import VueFileToolbarMenu from 'vue-file-toolbar-menu';
import { E_ALIGN_HORIZONTAL_MODE, E_EVENT_KEY, E_LIST_MODE } from '@/components/editor/ThinkEditor.Defined';
import { editorsInstanceManager } from '@/components/editorHelper/ThinkEditorInstance.Manager';
export default {
  components: { VueFileToolbarMenu },
  mounted() {
    editorsInstanceManager.addEventListener(E_EVENT_KEY.editorFocus, this.OnEditorFocusChange);
  },
  methods: {
    OnEditorFocusChange(e) {
      this.thinkEditor = e.data.editor;
    }
  },
  computed: {
    my_menu_one() {
      return [
        {
          icon: 'crop_square',
          click: () => this.thinkEditor.SetParagraphListMode(E_LIST_MODE.Normal)
        },
        {
          icon: 'circle',
          click: () => this.thinkEditor.SetParagraphListMode(E_LIST_MODE.SymbolListModel_0)
        },
        {
          icon: 'check_box_outline_blank',
          click: () => this.thinkEditor.SetParagraphListMode(E_LIST_MODE.SymbolListModel_1)
        },
        {
          icon: 'check',
          click: () => this.thinkEditor.SetParagraphListMode(E_LIST_MODE.SymbolListModel_2)
        }
      ];
    }
  }
};
</script>
