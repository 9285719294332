import { E_IDENTITY_OBJECT_TYPE, E_DOC_FORMAT, shareDataManager, optionsManager, algorithmsManager, E_EVENT_HANDLE, E_EVENT_KEY, Source, Option, OptionItem } from '@/components/editor/ThinkEditor.Defined';
import { provinceCityAreaHelper } from '@/components/editorHelper/ProvinceCityAreaHelper';
export const TabsViewUserDefined = {
  methods: {
    async InsertMyImage() {
      var url = '/system/test/sign.svg'; //"/system/test/pacsdemo.jpg";
      let response = await fetch(url, {
        credentials: 'same-origin'
      });
      let data = await response.arrayBuffer();
      await this.thinkEditor.InsertImage('', 0, 0, 'pacsdemo', data, data.byteLength, {});
    },
    /**
     * addEditorEventListener
     * @param thinkEditor 为ThinkEditor或ThinkEditorManager
     * @returns
     * @constructor
     */
    addEditorEventListener(thinkEditor) {
      thinkEditor.addEventListener(E_EVENT_KEY.docModified, this.OnDocModified);
      thinkEditor.addEventListener(E_EVENT_KEY.setElementContent, this.OnSetElementContent);
      //-----------
      thinkEditor.addEventListener(E_EVENT_KEY.setSources, this.OnSetSourcesRequest);
      thinkEditor.addEventListener(E_EVENT_KEY.setOptions, this.OnSetOptions);
      thinkEditor.addEventListener(E_EVENT_KEY.setAlgorithms, this.OnSetAlgorithms);
      thinkEditor.addEventListener(E_EVENT_KEY.optionResource, this.OnOptionResourceRequest);
      thinkEditor.addEventListener(E_EVENT_KEY.elementContentChange, this.OnElementContentChangeEvent);
      //--------------
      thinkEditor.addEventListener('imageResource', this.OnImageResource);
      thinkEditor.addEventListener('closeDoc', this.closeDocListener);
      thinkEditor.addEventListener('parseDoc', this.parseDocListener);
      thinkEditor.addEventListener('loaded', this.loadedListener);
      thinkEditor.addEventListener('saveDoc', this.OnSaveDoc);
      thinkEditor.addEventListener('visibleDocChange', this.OnVisibleDocChange);
      thinkEditor.addEventListener('algorithmCalc', this.OnAlgorithmCalc);
      thinkEditor.addEventListener('selectRangeChange', this.OnSelectRangeChange);

      thinkEditor.addEventListener('printDoc', this.OnPrintDoc);
      thinkEditor.addEventListener('annotateFocusChange', this.OnAnnotateFocusChange);

      thinkEditor.addEventListener('setColor', this.OnSetColor);
      //-----------
    },

    /*
     * removeEditorEventListener
     * @param eventTarget 为ThinkEditor或ThinkEditorManager
     * @returns
     * @constructor
     */
    removeEditorEventListener(thinkEditor) {
      if (!thinkEditor) return;
      thinkEditor.removeEventListener(E_EVENT_KEY.docModified, this.OnDocModified);
      thinkEditor.removeEventListener(E_EVENT_KEY.setElementContent, this.OnSetElementContent);
      //---------
      thinkEditor.removeEventListener(E_EVENT_KEY.setSources, this.OnSetSourcesRequest);
      thinkEditor.removeEventListener(E_EVENT_KEY.setOptions, this.OnSetOptions);
      thinkEditor.removeEventListener(E_EVENT_KEY.setAlgorithms, this.OnSetAlgorithms);
      //------
      thinkEditor.removeEventListener(E_EVENT_KEY.imageResource, this.OnImageResource);
      thinkEditor.removeEventListener('closeDoc', this.closeDocListener);
      thinkEditor.removeEventListener('parseDoc', this.parseDocListener);
      thinkEditor.removeEventListener('loaded', this.loadedListener);
      thinkEditor.removeEventListener('saveDoc', this.OnSaveDoc);
      thinkEditor.removeEventListener('visibleDocChange', this.OnVisibleDocChange);
      thinkEditor.removeEventListener('algorithmCalc', this.OnAlgorithmCalc);
      thinkEditor.removeEventListener('selectRangeChange', this.OnSelectRangeChange);

      thinkEditor.removeEventListener('printDoc', this.OnPrintDoc);
      thinkEditor.removeEventListener('annotateFocusChange', this.OnAnnotateFocusChange);

      thinkEditor.removeEventListener('setColor', this.OnSetColor);
    },

    OnDocModified(e) {
      let thinkEditor = e.data.editor;
      // debugger;
      // this.bindEditors = this.in
      thinkEditor.showName = thinkEditor.docName + '*';
      this.editorsInstance.CreateInstanceChangeEvent(); //or this.OnInstanceChange();

      //this.bindEditors = [];
      //this.bindEditors = this.editorsInstance.editors;
    },
    OnSetElementContent(e /*ThinkEditorEvent*/) {
      //console.log(data);
      let data = e.data; /*ThinkEditorEventData*/
      if (data.handle == E_EVENT_HANDLE.request) {
        let thinkEditor = e.data.editor;

        let elementData = e.data.data;

        //--------示例1:使用SetElementsContent，同时设置多个Element的值
        let setDatas = [];
        if (elementData.id == 'age') {
          setDatas.push({ id: 'age', content: '19' });
        } else if (elementData.id == 'name') {
          setDatas.push({ id: 'name', content: '用户名3' });
        }
        if (setDatas.length > 0) {
          thinkEditor.SetElementsContent(setDatas);
        }
        //--------示例2:使用SetElementContent，设置1个Element的值
        if (elementData.id == 'sign') {
          this.DoSign(thinkEditor, elementData);
        } else if (elementData.id == 'signChief') {
          this.DoSignChief(thinkEditor, elementData);
        }

        e.preventDefault(); //中止内部 可能使用源 来填充元素
      }
    },
    async DoSign(thinkEditor, elementData) {
      //用户使用自己的方式获得signImgData
      var url = '/system/test/sign/sign.png';
      let response = await fetch(url, { credentials: 'same-origin' });
      let signImgData = await response.arrayBuffer();

      //使用SetImageResource或SetImageResourceBase64加载图像数据

      var md5 = this.thinkEditor.SetImageResource('当前医师电子签名.jpg', signImgData, signImgData.byteLength, {});

      var signFragment = "<Fragment><Image width='2' height='0.8' file='当前医师电子签名.jpg' md5='" + md5 + "'/></Fragment>";
      thinkEditor.SetElementContent(elementData.id, signFragment);
    },
    async DoSignChief(thinkEditor, elementData) {
      //用户使用自己的方式获得signChiefImgData
      var url = '/system/test/sign/signChief.png';
      let response = await fetch(url, { credentials: 'same-origin' });
      let signChiefImgData = await response.arrayBuffer();

      //使用SetImageResource或SetImageResourceBase64加载图像数据
      var md5 = this.thinkEditor.SetImageResource('上级医师电子签名.jpg', signChiefImgData, signChiefImgData.byteLength, {});

      var signFragment = "<Fragment><Image width='2' height='0.8' file='审核医师电子签名.jpg' md5='" + md5 + "'/><Format color='c93756' size='0.6'/>/</Fragment>";

      thinkEditor.SetElementContent(elementData.id, signFragment);
    },
    OnSetOptionsEvent(e /*ThinkEditorEvent*/) {
      //optionsManager.AddOptions(e.data.data);
    },
    OnSetOptionsRequest(e /*ThinkEditorEvent*/) {
      /*
      let editorId = e.data.editorId;

      var thinkEditor = editorsInstanceManager.GetEditor(editorId);
      if (thinkEditor === undefined) {
        return; //error
      }
      var requestOptionIds = e.data.data;
      var setOptionIds = new Array();
      for (let requestOptionId of requestOptionIds) {
        var optionId = optionsManager.GetOption(requestOptionId.optionClass, requestOptionId.optionId);
        if (optionId !== undefined) {
          setOptionIds.push(optionId);
        }
      }
      thinkEditor.SetOptions(setOptionIds);
      */
    },
    /*
  [
    {
        "optionClass": "系统",
        "optionIds": [
            {
                "optionId": "属地",
                "notSave": false,
                "multipleChoice": true,
                "groupExclusion": true,
                "items": [
                    {
                        "id": "1",
                        "group": "",
                        "weight": 1.00,
                        "content": "本县区"
                    },
                    {
                        "id": "2",
                        "group": "",
                        "weight": 1.00,
                        "content": "本市其他县区"
                    },
                    {
                        "id": "3",
                        "group": "",
                        "weight": 1.00,
                        "content": "本市其他县区"
                    }
                ]
            }
        ]
    }
]
  */
    OnSetOptions(e /*ThinkEditorEvent*/) {
      if (e.data.handle == E_EVENT_HANDLE.event) {
        //文档解析到选项，产生本事件
        this.OnSetOptionsEvent(e);
      } else if (e.data.handle == E_EVENT_HANDLE.request) {
        //文档 缺失选项内容，请求加载
        this.OnSetOptionsRequest(e);
      }
    },

    OnSetSourcesRequest(e /*ThinkEditorEvent*/) {
      //console.log(data);

      let eventData = e.data; /*ThinkEditorEventData*/
      let bodyData = eventData.data;
      let handle = eventData.handle;
      if (handle != E_EVENT_HANDLE.request) {
        //只处理-请求设置源的事件
        return;
      }
      let thinkEditor = e.data.editor;
      let sourcesManager = thinkEditor.sourcesManager;

      let requestSources = bodyData;
      let setSources = [];
      let addSources = [];
      let missSources = [];

      for (let sourceIdx in requestSources) {
        //从该文档的sourcesManager中获取以加载的Source
        let requestSource = requestSources[sourceIdx];
        let sourceItem = sourcesManager.GetSource(requestSource.sourceClass, requestSource.sourceId);
        if (sourceItem === undefined || sourceItem.content === undefined) {
          //sourceItem.content=undefined时表示由编辑器内部事件产生的Source, 需要应用更新content
          missSources.push(requestSource);
        } else {
          setSources.push(requestSource);
        }
      }

      /***************************************************************************************************
      自定义begin:以下代码应用结合自己的系统[同步 !!await!!]实现*************************************************
      ***************************************************************************************************/
      for (let sourceIdx in missSources) {
        let requestSource = missSources[sourceIdx];
        let source = undefined;

        /***************************************************************************************************
        可根据docName或其他应用添加的标识，来判断给该文档添加什么源
        ***************************************************************************************************/
        if (thinkEditor.docName == 'xxx' /*|| docEditor.userName=='xxx'*/) {
        }

        /***************************************************************************************************
         ***************************************************************************************************/
        if (requestSource.sourceClass == '基本资料' && requestSource.sourceId == '姓名') {
          source = new Source(requestSource.sourceClass, requestSource.sourceId, '用户名2');
        } else if (requestSource.sourceClass == '基本资料' && requestSource.sourceId == '年龄') {
          source = new Source(requestSource.sourceClass, requestSource.sourceId, '19');
        }

        if (source !== undefined) {
          addSources.push(source);
          setSources.push(source);
        }
      }
      /***************************************************************************************************
      自定义end*************************************************
      ***************************************************************************************************/
      if (addSources.length > 0) {
        sourcesManager.AddSources(addSources); //更新以加载源选项
      }
      if (setSources.length > 0) {
        thinkEditor.SetSources(addSources); //加载到编辑器内部
      }
    },

    /*
  [
    {
        "algoGroup": "评分加法",
        "algoMode": 1,
        "algoScope": 1,

    }
]
  */
    OnSetAlgorithms(e /*ThinkEditorEvent*/) {
      algorithmsManager.AddAlgorithms(e.data.data);
    },
    OnElementContentChangeEvent(e /*ThinkEditorEvent*/) {
      let thinkEditor = e.data.editor;
      let eventData = e.data; /*ThinkEditorEventData*/
      let bodyData = eventData.data;
      let eleId = bodyData.id;

      if (eventData.handle != E_EVENT_HANDLE.event) {
        return;
      }
      /***********************************************
       * 动态设置 省 市 区 选项
       * ********************************************/
      //省 DE02.01.009.01
      let provinceId = 'DE02.01.009.01';
      //市 DE02.01.009.02
      let cityId = 'DE02.01.009.02';
      //区 DE02.01.009.03
      let areaId = 'DE02.01.009.03';

      if (eleId != provinceId && eleId != cityId && eleId != areaId) {
        return false;
      }
      if (eleId == provinceId) {
        let currentProvinceName = bodyData.text;
        let currentCityName = thinkEditor.GetObjectFragment(E_IDENTITY_OBJECT_TYPE.Element, cityId, E_DOC_FORMAT.TEXT, {});
        let cityNames = provinceCityAreaHelper.GetCityList(currentProvinceName);

        if (cityNames.length > 0 && cityNames.indexOf(currentCityName) == -1) {
          currentCityName = cityNames[0];
          thinkEditor.SetElementContent(cityId, currentCityName);
        }

        let currentAreaName = thinkEditor.GetObjectFragment(E_IDENTITY_OBJECT_TYPE.Element, areaId, E_DOC_FORMAT.TEXT, {});
        let areaNames = provinceCityAreaHelper.GetAreaList(currentProvinceName, currentCityName);

        if (areaNames.length > 0 && areaNames.indexOf(currentAreaName) == -1) {
          currentAreaName = areaNames[0];
          thinkEditor.SetElementContent(areaId, currentAreaName);
        }
        return true;
      } else if (eleId == cityId) {
        let currentProvinceName = thinkEditor.GetObjectFragment(E_IDENTITY_OBJECT_TYPE.Element, provinceId, E_DOC_FORMAT.TEXT, {});
        let currentCityName = bodyData.text;
        let currentAreaName = thinkEditor.GetObjectFragment(E_IDENTITY_OBJECT_TYPE.Element, areaId, E_DOC_FORMAT.TEXT, {});
        let areaNames = provinceCityAreaHelper.GetAreaList(currentProvinceName, currentCityName);
        if (areaNames.length > 0 && areaNames.indexOf(currentAreaName) == -1) {
          currentAreaName = areaNames[0];
          thinkEditor.SetElementContent(areaId, currentAreaName);
        }
        return true;
      }
      return false;
    },
    ProcessProvinceCityArea(e /*ThinkEditorEvent*/) {
      let thinkEditor = e.data.editor;
      let bodyData = e.data.data;
      let eleId = bodyData.id;
      let inputHandle = bodyData.inputHandle;
      if (inputHandle.optionClass == '' && inputHandle.optionId == '') {
        return false;
      }
      /***********************************************
       * 动态设置 省 市 区 选项
       * ********************************************/
      //省 DE02.01.009.01
      let provinceId = 'DE02.01.009.01';
      //市 DE02.01.009.02
      let cityId = 'DE02.01.009.02';
      //区 DE02.01.009.03
      let areaId = 'DE02.01.009.03';

      if (eleId != provinceId && eleId != cityId && eleId != areaId) {
        return false;
      }

      if (eleId == provinceId) {
        let provinceNames = provinceCityAreaHelper.GetProvinceList();
        let newOption = new Option(inputHandle.optionClass, inputHandle.optionId);
        newOption.items = new Array();
        for (let provinceName of provinceNames) {
          newOption.items.push(new OptionItem(provinceName, '1', 1, ''));
        }
        thinkEditor.SetOptions([newOption]);
        return true;
      } else if (eleId == cityId) {
        let currentProvinceName = thinkEditor.GetObjectFragment(E_IDENTITY_OBJECT_TYPE.Element, provinceId, E_DOC_FORMAT.TEXT, {});
        let cityNames = provinceCityAreaHelper.GetCityList(currentProvinceName);
        let newOption = new Option(inputHandle.optionClass, inputHandle.optionId);
        newOption.items = new Array();
        for (let cityName of cityNames) {
          newOption.items.push(new OptionItem(cityName, '1', 1, ''));
        }
        thinkEditor.SetOptions([newOption]);
        return true;
      } else if (eleId == areaId) {
        let currentProvinceName = thinkEditor.GetObjectFragment(E_IDENTITY_OBJECT_TYPE.Element, provinceId, E_DOC_FORMAT.TEXT, {});
        let currentCityName = thinkEditor.GetObjectFragment(E_IDENTITY_OBJECT_TYPE.Element, cityId, E_DOC_FORMAT.TEXT, {});

        let areaNames = provinceCityAreaHelper.GetAreaList(currentProvinceName, currentCityName);
        let newOption = new Option(inputHandle.optionClass, inputHandle.optionId);
        newOption.items = new Array();
        for (let areaName of areaNames) {
          newOption.items.push(new OptionItem(areaName, '1', 1, ''));
        }
        thinkEditor.SetOptions([newOption]);
        return true;
      }
      return false;
    },

    OnOptionResourceRequest(e /*ThinkEditorEvent*/) {
      //let editorId = e.data.editorId;
      let thinkEditor = e.data.editor;
      let eventData = e.data; /*ThinkEditorEventData*/
      let bodyData = eventData.data;
      let handle = eventData.handle;
      if (handle != E_EVENT_HANDLE.request) {
        //只处理-请求设置源的事件
        return;
      }
      if (this.ProcessProvinceCityArea(e)) {
        return;
      }
      let inputHandle = bodyData.inputHandle;
      if (inputHandle.optionClass == '医生' && inputHandle.optionId == '当前患者') {
        //应用利用元素Id、source、option等信息动态决定加载需要使用的资源
        //let thinkEditor = this.editorsInstance.GetEditorByEditorId(editorId);
        let option = optionsManager.GetOption(inputHandle.optionClass, inputHandle.optionId);
        if (option == undefined) {
          //今日患者
          let newOption = new Option('医生', '当前患者');
          newOption.items = new Array();
          newOption.items.push(new OptionItem('张三', '1', 1, ''));
          newOption.items.push(new OptionItem('李四', '1', 1, ''));
          newOption.items.push(new OptionItem('王五', '1', 1, ''));

          thinkEditor.SetOptions([newOption]);
        } else {
          thinkEditor.SetOptions([option]);
        }
      }
    }
  }
};
