<template>
  <VacantDialog title="选框" width="600px" ref="thisDialog" :top="'10%'" :confirmTxt="confirmTxt" @close="closeDialog" @confirm="confirm">
    <div style="min-height: 400px; max-height: 600px; overflow-y: auto">
      <div class="tab-style">
        <div @click="changeActive(item)" :class="active === item.value ? 'active' : ''" class="tab" v-for="item in tabList" :key="item.value">
          {{ item.label }}
        </div>
      </div>
      <attrSet :optionsManager="optionsManager" :algorithmsManager="algorithmsManager" @update:optionsManager="updateOptionsManager" :configData="configData" @tabsChange="tabsChange" v-show="active === 1"></attrSet>
      <expressionSet :configData="configData" v-show="active === 2"></expressionSet>
      <optionSet :optionsManager="optionsManager" :configData="configData" v-show="active === 3"></optionSet>
    </div>
  </VacantDialog>
</template>

<script>
import attrSet from './component/attrSet.vue';
import expressionSet from './component/expressionSet.vue';
import optionSet from './component/optionSet.vue';
import { E_EVENT_KEY, E_SET_MODE, OptionsManager, AlgorithmsManager } from '@/components/editor/ThinkEditor.Defined';
import { UpdateConfigData } from '@/components/editor/ThinkEditor.Utils';
import { editorsInstanceManager } from '@/components/editorHelper/ThinkEditorInstance.Manager';
export default {
  name: 'index',
  components: { attrSet, expressionSet, optionSet },
  data() {
    return {
      thinkEditor: undefined,
      optionsManager: new OptionsManager(),
      algorithmsManager: new AlgorithmsManager(),
      isCheckBoxGroup: undefined,
      configData: { isCheckBoxGroup: false, option: {}, algorithm: {}, expressions: [] },
      tabList: [
        { value: 1, label: '属性' },
        { value: 2, label: '事件' }
      ],

      active: 1,
      flag: false,
      confirmTxt: '插入'
    };
  },
  created() {
    editorsInstanceManager.addEventListener(E_EVENT_KEY.editorFocus, this.OnDocEditorFocusChange);
    let currentEditorsInstance = editorsInstanceManager.GetSelectedEditorsInstance();

    this.thinkEditor = currentEditorsInstance?.GetSelectedEditor();
  },
  methods: {
    OnDocEditorFocusChange(e) {
      this.thinkEditor = e.data.editor;
    },
    updateOptionsManager(newOptionsManager) {
      this.optionsManager = newOptionsManager;
    },
    tabsChange(isCheckBoxGroup) {
      if (isCheckBoxGroup == 1) {
        this.tabList = [
          { value: 1, label: '属性' },
          { value: 2, label: '事件' },
          { value: 3, label: '选项' }
        ];
      } else {
        this.tabList = [
          { value: 1, label: '属性' },
          { value: 2, label: '事件' }
        ];
      }
    },
    /**
     * 切换表头
     * @param item
     */
    changeActive(item) {
      this.active = item.value;
    },
    /*
    {
    "setMode": 1,
    "object": "checkBox",
    "checkFigureStyle": 1,
    "enableEmpty": 0,
    "id": "",
    "name": "",
    "text": "勾选是否显示任意位置元素",
    "content": "勾选是否显示任意位置元素",
    "fragment": "<Fragment><CheckBox xCfg='11'><Expression event='1'>this.checkbox.checked==true ?any_where.element.visible=true :any_where.ele.visible=false</Expression><CF/>勾选是否显示任意位置元素</CheckBox></Fragment>",
    "checked": 1,
    "onFocus": 1,
    "itemId": "",
    "group": "",
    "weight": "0.00",
    "option": {
        "optionName": "",
        "optionClass": "",
        "optionId": "",
        "multipleChoice": 0,
        "dynamicLoad": 0
    },
    "algorithm": {},
    "expressions": [
        {
            "event": 1,
            "mode": 0,
            "action": "this.checkbox.checked==true ?any_where.element.visible=true :any_where.ele.visible=false"
        }
    ]

}
    */
    openDialog(data, optionsManager, algorithmsManager) {
      if (data.setMode == E_SET_MODE.InsertCommon) {
        this.configData = { isCheckBoxGroup: false, option: {}, algorithm: {}, expressions: [] };
      }
      UpdateConfigData(this, data);

      this.optionsManager = optionsManager;
      this.algorithmsManager = algorithmsManager;
      if (this.configData.option.optionClass !== undefined && this.configData.option.optionClass != '') {
        this.configData.isCheckBoxGroup = true;
      } else {
        this.configData.isCheckBoxGroup = false;
      }
      if (this.configData.setMode == E_SET_MODE.InsertCommon) {
        this.confirmTxt = '插入';
      } else {
        this.confirmTxt = '确定';
      }

      if (this.configData.isCheckBoxGroup) {
        this.tabList = [
          { value: 1, label: '属性' },
          { value: 2, label: '事件' },
          { value: 3, label: '选项' }
        ];
      } else {
        this.tabList = [
          { value: 1, label: '属性' },
          { value: 2, label: '事件' }
        ];
      }

      this.$refs.thisDialog.openDialog();
    },

    closeDialog() {
      this.$refs.thisDialog.closeDialog();
    },
    confirm() {
      let data = this.configData;
      if (data.setMode == E_SET_MODE.InsertCommon) {
        if (data.isCheckBoxGroup) {
          this.thinkEditor.InsertCheckBoxGroup(data);
        } else {
          this.thinkEditor.InsertCheckBox(data);
        }
      } else {
        //修改
        this.thinkEditor.SetCheckBoxConfig('', data);
      }
      this.closeDialog();
    }
  }
};
</script>

<style scoped lang="scss">
::v-deep .el-input--medium .el-input__inner {
  height: 60px;
}

::v-deep .el-select-dropdown__item {
  height: 60px !important;
  line-height: 60px;
  border-bottom: 2px solid #ccc;
  margin: 0 10px;
}

::v-deep .el-form-item {
  margin-bottom: -8px !important;
}

.tab-style {
  display: flex;
  align-items: center;
  justify-content: flex-start;

  .tab {
    padding: 6px 10px;
    border: 1px solid #ccc;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    margin-right: 1px;
    background: #e0e0e0;
    cursor: pointer;
  }

  .active {
    background: #61a3e6;
  }
}
</style>
