<template>
  <VacantDialog title="元素" width="608px" ref="thisDialog" :footer-show="false">
    <el-form ref="form" :inline="true" :model="configData" label-width="80px" size="mini">
      <el-tabs v-model="activeName" type="card" @tab-click="handleClick">
        <el-tab-pane label="属性" name="first">
          <el-form-item label="ID">
            <el-input v-model="configData.id"></el-input>
          </el-form-item>
          <el-form-item label="名称">
            <el-input v-model="configData.name"></el-input>
          </el-form-item>
          <el-form-item label="起始边框">
            <el-input v-model="configData.beginBorder"></el-input>
          </el-form-item>
          <el-form-item label="结束边框">
            <el-input v-model="configData.endBorder"></el-input>
          </el-form-item>
          <el-form-item label="前导标签">
            <el-input v-model="configData.beforeTag"></el-input>
          </el-form-item>
          <el-form-item label="后置标签">
            <el-input v-model="configData.afterTag"></el-input>
          </el-form-item>
          <el-form-item label="背景文本">
            <el-input v-model="configData.hint"></el-input>
          </el-form-item>
          <el-form-item label="提示文本">
            <el-input v-model="configData.tip"></el-input>
          </el-form-item>
          <el-form-item label="宽度">
            <el-input-number v-model="configData.width" controls-position="right" @change="fNum" :min="0" :max="10" style="width: 178px"></el-input-number>
          </el-form-item>
          <el-form-item label="对齐方式">
            <el-select v-model="configData.alignHorizontalMode" placeholder="" :disabled="alignHorizontalD" style="width: 178px">
              <el-option v-for="item in eAlign" :key="item.value" :label="item.label" :value="item.value"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="加密">
            <el-select v-model="configData.hideKeyWord" placeholder="" style="width: 178px">
              <el-option v-for="item in keyWord" :key="item.value" :label="item.label" :value="item.value"></el-option>
            </el-select>
          </el-form-item>

          <el-divider content-position="left">源加载</el-divider>
          <el-form-item label="源类型">
            <el-select v-model="configData.source.sourceClass" placeholder="" @change="changeSourceClass" style="width: 178px">
              <el-option v-for="item in sourceClassArr" :key="item.sourceClass" :label="item.sourceClass" :value="item.sourceClass"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="源">
            <el-select v-model="configData.source.sourceId" placeholder="" style="width: 178px">
              <el-option v-for="item in sourceIdArr" :key="item.sourceId" :label="item.sourceId" :value="item.sourceId"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label=" ">
            <el-checkbox label="动态加载" name="type" v-model="configData.source.dynamicLoad" @change="cLoad" :true-label="1" :false-label="0"></el-checkbox>
            <el-checkbox label="回填" name="type" v-model="configData.source.writeBack" @change="cLoad" :true-label="1" :false-label="0" disabled></el-checkbox>
          </el-form-item>
        </el-tab-pane>
        <el-tab-pane label="配置" name="second">
          <el-form-item label="">
            <el-checkbox label="禁止删除" name="type" v-model="configData.forbidDelete" :true-label="1" :false-label="0"></el-checkbox>
            <el-checkbox label="锁定内容" name="type" v-model="configData.lockedContent" :true-label="1" :false-label="0"></el-checkbox>
            <el-checkbox label="禁止空格" name="type" v-model="configData.forbidSpace" :true-label="1" :false-label="0"></el-checkbox>
            <el-checkbox label="背景特效" name="type" v-model="configData.enableBackgroundEffects" :true-label="1" :false-label="0"></el-checkbox>
            <el-checkbox label="焦点特效" name="type" v-model="configData.enableFocusedEffects" :true-label="1" :false-label="0"></el-checkbox>
            <el-checkbox label="下划线" name="type" v-model="configData.widthUnderline" :true-label="1" :false-label="0"></el-checkbox>
            <el-checkbox label="允许跳转" name="type" v-model="configData.allowJump" :true-label="1" :false-label="0"></el-checkbox>
            <el-checkbox label="内容适应宽度" name="type" v-model="configData.fitContent" :true-label="1" :false-label="0"></el-checkbox>
            <el-checkbox label="显示边框" name="type" v-model="configData.displayBorder" :true-label="1" :false-label="0"></el-checkbox>
          </el-form-item>
          <br />
          <el-form-item label="分段模式">
            <el-select v-model="configData.splitParagraphMode" placeholder="">
              <el-option v-for="item in splitPara" :key="item.val" :label="item.label" :value="item.val"></el-option>
            </el-select>
          </el-form-item>
          <el-divider content-position="left">运算配置</el-divider>
          <el-form-item label="运算集">
            <el-tooltip class="item" effect="dark" content="请在[知识库管理-算法]中预设运算集！" placement="top-start">
              <el-select v-model="configData.algorithm.algoGroup" placeholder="" @change="algoGroupChange">
                <el-option v-for="item in algorithmsManager.algorithms" :key="item.algoGroup" :label="item.algoGroup" :value="item.algoGroup"></el-option>
              </el-select>
            </el-tooltip>
          </el-form-item>
          <el-form-item label="角色">
            <el-tooltip class="item" effect="dark" content="[输入角色]表示参数用于运算，[输出角色]表示用于显示计算结果。" placement="top-end">
              <el-select v-model="configData.algorithm.algoRole" placeholder="">
                <el-option v-for="item in sources" :key="item.val" :label="item.label" :value="item.val"></el-option>
              </el-select>
            </el-tooltip>
          </el-form-item>
          <el-form-item label="算法">
            <el-select v-model="configData.algorithm.algoMode" placeholder="" :disabled="true">
              <el-option v-for="item in algoModeArr" :key="item.val" :label="item.label" :value="item.val"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="范围">
            <el-select v-model="configData.algorithm.algoScope" placeholder="" :disabled="true">
              <el-option v-for="item in algoScopeArr" :key="item.val" :label="item.label" :value="item.val"></el-option>
            </el-select>
          </el-form-item>
          <el-divider content-position="left">输入模式</el-divider>
          <el-form-item label="">
            <el-radio-group v-model="configData.inputHandle.inputMode" @change="inputModeFun">
              <el-radio v-for="item in inputModeArr" :key="item.key" :label="item.val" :value="item.val">{{ item.label }}</el-radio>
            </el-radio-group>
          </el-form-item>
          <div v-if="this.inputMo">
            <el-form-item label="选项类">
              <el-select v-model="configData.inputHandle.optionClass" placeholder="" @change="OnOptionClassChange">
                <el-option v-for="item in optionsManager.options" :key="item.optionClass" :label="item.optionClass" :value="item.optionClass"></el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="选项组">
              <el-select v-model="configData.inputHandle.optionId" placeholder="" @change="OnOptionIdChange">
                <el-option v-for="item in currentOptionClassObj.optionIds" :key="item.optionId" :label="item.optionId" :value="item.optionId"></el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="拼接符">
              <el-select v-model="configData.inputHandle.linkStr" placeholder="">
                <el-option v-for="item in linkString" :key="item.val" :label="item.label" :value="item.val"></el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="模式">
              <el-select v-model="configData.inputHandle.optionShowMode" placeholder="">
                <el-option v-for="item in optionMe" :key="item.val" :label="item.label" :value="item.val"></el-option>
              </el-select>
            </el-form-item>
            <el-form-item label=" ">
              <el-checkbox label="强制多选" name="type" v-model="configData.inputHandle.multipleChoice" :true-label="1" :false-label="0"></el-checkbox>
              <el-checkbox label="分组互斥" name="type" v-model="configData.inputHandle.groupExclusion" :true-label="1" :false-label="0"></el-checkbox>
            </el-form-item>
          </div>
          <div v-if="this.timeMo">
            <el-form-item label="">
              <el-select v-model="configData.inputHandle.timeType" placeholder="" style="width: 350px">
                <el-option v-for="item in timeTypeArr" :key="item.val" :label="item.label" :value="item.val"></el-option>
              </el-select>
            </el-form-item>
          </div>
        </el-tab-pane>
        <el-tab-pane label="质控" name="third">
          <el-form-item label="异常类型">
            <el-select v-model="configData.qualityControl.level" placeholder="" style="width: 178px">
              <el-option v-for="item in levelArr" :key="item.val" :label="item.label" :value="item.val"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="">
            <el-checkbox label="禁止为空" name="type" v-model="configData.qualityControl.forbidEmpty" :true-label="1" :false-label="0"></el-checkbox>
          </el-form-item>
          <el-form-item label="提示信息">
            <el-input v-model="configData.qualityControl.notice" style="width: 500px"></el-input>
          </el-form-item>
          <el-form-item label="禁止关键字">
            <el-input v-model="configData.qualityControl.exclude" style="width: 500px" placeholder="多个禁止关键字使用英文;分号隔开"></el-input>
          </el-form-item>
          <el-divider content-position="left">格式限定</el-divider>
          <el-form-item label="">
            <el-radio-group v-model="configData.qualityControl.qcClass" @change="qcClassFun">
              <el-radio v-for="item in qcClassArr" :key="item.val" :label="item.val" :value="item.val">{{ item.label }}</el-radio>
            </el-radio-group>
          </el-form-item>
          <div v-if="controlVisText">
            <el-form-item label="" label-width="100px">
              <el-checkbox label="字节长度模式" name="type" v-model="configData.qualityControl.useByteLen" :true-label="1" :false-label="0"></el-checkbox>
            </el-form-item>
            <br />
            <el-form-item label="">
              <el-checkbox label="最小长度 " name="type" v-model="configData.qualityControl.useMinLimit" :true-label="1" :false-label="0"></el-checkbox>
              <el-input-number v-model="configData.qualityControl.min" controls-position="right" style="margin-left: 10px"></el-input-number>
            </el-form-item>
            <br />
            <el-form-item label="">
              <el-checkbox label="最大长度 " name="type" v-model="configData.qualityControl.useMaxLimit" :true-label="1" :false-label="0"></el-checkbox>
              <el-input-number v-model="configData.qualityControl.max" controls-position="right" style="margin-left: 10px"></el-input-number>
            </el-form-item>
          </div>
          <div v-if="controlVisNum">
            <el-form-item label="">
              <el-checkbox label="整数" name="type" v-model="configData.qualityControl.useInteger" @change="contr()" :true-label="1" :false-label="0"></el-checkbox>
            </el-form-item>
            <br />

            <el-form-item label="">
              <el-checkbox label="最小值 " name="type" v-model="configData.qualityControl.useMinLimit" :true-label="1" :false-label="0"></el-checkbox>
              <el-input-number v-model="configData.qualityControl.min" controls-position="right" :min="0" :step="1.0" :precision="2" style="margin-left: 10px"></el-input-number>
            </el-form-item>
            <br />
            <el-form-item label="">
              <el-checkbox label="最大值 " name="type" v-model="configData.qualityControl.useMaxLimit" :true-label="1" :false-label="0"></el-checkbox>
              <el-input-number v-model="configData.qualityControl.max" controls-position="right" :min="0" :step="1.0" :precision="2" style="margin-left: 10px"></el-input-number>
            </el-form-item>
            <br />
            <el-form-item label="">
              <el-checkbox label="最多小数位数 " name="type" v-model="configData.qualityControl.useDecimalPlacesLimit" :true-label="1" :false-label="0"></el-checkbox>
              <el-input-number v-model="configData.qualityControl.decimalPlaces" controls-position="right" :disabled="convis" style="margin-left: 10px"></el-input-number>
            </el-form-item>
          </div>
          <div v-if="controlVisTime">
            <el-form-item label="">
              <el-checkbox label="只包括时间 " name="type" v-model="configData.qualityControl.onlyTime" @change="contrtime()" :true-label="1" :false-label="0"></el-checkbox>
            </el-form-item>
            <br />

            <el-form-item label="">
              <el-form-item prop="">
                <el-checkbox label="不早于 " name="type" v-model="configData.qualityControl.useMinLimit" :disabled="conTimevis" :true-label="1" :false-label="0"></el-checkbox>
                <!-- <el-date-picker type="date" placeholder="选择日期" v-model="configData.qualityControl.min" style="width: 100%;" :disabled="conTimevis"></el-date-picker> -->
                <el-date-picker v-model="configData.qualityControl.min" type="datetime" placeholder="选择日期时间" default-time="12:00:00" style="margin-left: 10px"></el-date-picker>
              </el-form-item>
              <!-- <el-col class="line" :span="2">-</el-col> -->
              <!-- <el-col :span="11">
                        <el-form-item prop="">
                            <el-time-picker placeholder="选择时间" v-model="configData.qualityControl.min" style="width: 100%;" :disabled="conTimevis"></el-time-picker>
                        </el-form-item>
                        </el-col> -->
            </el-form-item>
            <br />
            <el-form-item label="">
              <el-form-item prop="">
                <el-checkbox label="不晚于 " name="type" v-model="configData.qualityControl.useMaxLimit" :disabled="conTimevis" :true-label="1" :false-label="0"></el-checkbox>
                <!-- <el-date-picker type="date" placeholder="选择日期" v-model="configData.qualityControl.max" style="width: 100%;" :disabled="conTimevis"></el-date-picker> -->
                <el-date-picker v-model="configData.qualityControl.max" type="datetime" placeholder="选择日期时间" default-time="12:00:00" style="margin-left: 10px"></el-date-picker>
              </el-form-item>
              <!-- <el-col class="line" :span="2">-</el-col> -->
              <!-- <el-col :span="11">
                        <el-form-item prop="">
                            <el-time-picker placeholder="选择时间" v-model="configData.qualityControl.max" style="width: 100%;" :disabled="conTimevis"></el-time-picker>
                        </el-form-item>
                        </el-col> -->
            </el-form-item>
          </div>
          <div v-if="controlVisZ">
            <el-form-item label="" prop="name">
              <el-input v-model="configData.qualityControl.regex" style="width: 432px"></el-input>
            </el-form-item>
          </div>
        </el-tab-pane>
        <el-tab-pane label="事件" name="fourth">
          <el-table height="350" :data="configData.expressions">
            <el-table-column label="触发事件" prop="event" width="120">
              <template slot-scope="scope">
                <el-select v-model="configData.expressions[scope.$index].event" placeholder="" size="mini">
                  <el-option v-for="item in eventArr" :key="item.val" :label="item.label" :value="item.val"></el-option>
                </el-select>
              </template>
            </el-table-column>
            <el-table-column label="执行动作" prop="action">
              <template slot-scope="scope">
                <el-input v-model="configData.expressions[scope.$index].action" clearble size="mini" @input="handleAdd(scope.$index, scope.row)">
                  <template slot="append">
                    <el-popover placement="bottom" title="" width="300" trigger="click" v-model="configData.expressions[scope.$index].popVisible" content="" @show="actionShow(configData.expressions[scope.$index].action)">
                      <el-form-item label="如果满足条件" label-width="100px">
                        <el-input style="width: 200px" v-model="ifAction" @input="actionFun(1, ifAction)"></el-input>
                      </el-form-item>
                      <el-form-item label="则执行" label-width="100px">
                        <el-input style="width: 200px" v-model="oneAction" @input="actionFun(2, oneAction)"></el-input>
                      </el-form-item>
                      <el-form-item label="否则执行" label-width="100px">
                        <el-input style="width: 200px" v-model="towAction" @input="actionFun(3, towAction)"></el-input>
                      </el-form-item>
                      <el-form-item label="">
                        <el-input type="textarea" v-model="awAction" disabled></el-input>
                      </el-form-item>
                      <div style="text-align: right; margin: 16px 0px 8px">
                        <el-button size="mini" type="text" @click="configData.expressions[scope.$index].popVisible = false">取消</el-button>
                        <el-button type="primary" size="mini" @click="writeIn(scope.$index)">确定</el-button>
                      </div>
                      <el-button slot="reference">.</el-button>
                    </el-popover>
                  </template>
                </el-input>
              </template>
            </el-table-column>
            <el-table-column label="模式" width="90">
              <template slot-scope="scope">
                <el-select v-model="configData.expressions[scope.$index].mode" placeholder="" size="mini">
                  <el-option v-for="item in modeArr" :key="item.val" :label="item.label" :value="item.val"></el-option>
                </el-select>
              </template>
            </el-table-column>
          </el-table>
        </el-tab-pane>
        <el-tab-pane label="选项" name="four" v-if="this.inputMo">
          <el-form-item label="选项类">
            <el-select v-model="configData.inputHandle.optionClass" placeholder="" @change="OnOptionClassChange" size="mini">
              <el-option v-for="item in optionsManager.options" :key="item.optionClass" :label="item.optionClass" :value="item.optionClass"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="选项组">
            <el-select v-model="configData.inputHandle.optionId" placeholder="" size="mini" @change="OnOptionIdChange">
              <el-option v-for="item in currentOptionClassObj.optionIds" :key="item.optionId" :label="item.optionId" :value="item.optionId"></el-option>
            </el-select>
          </el-form-item>
          <el-table height="350" :data="configData.inputHandle.items" class="reset-table-style">
            <el-table-column label="选项ID" width="120">
              <template slot-scope="scope">
                <el-input v-model="configData.inputHandle.items[scope.$index].id" clearble size="mini" @input="inputHandleAdd(scope.$index)" />
              </template>
            </el-table-column>
            <el-table-column label="组ID" width="120">
              <template slot-scope="scope">
                <el-input v-model="configData.inputHandle.items[scope.$index].group" clearble size="mini" @input="inputHandleAdd(scope.$index)" />
              </template>
            </el-table-column>
            <el-table-column label="权值" width="150">
              <template slot-scope="scope">
                <el-input-number v-model="configData.inputHandle.items[scope.$index].weight" controls-position="right" :min="0" :step="0.01" :precision="2" size="mini"></el-input-number>
              </template>
            </el-table-column>
            <el-table-column label="内容" width="168">
              <template slot-scope="scope">
                <el-input v-model="configData.inputHandle.items[scope.$index].content" clearble size="mini" @input="inputHandleAdd(scope.$index)" />
              </template>
            </el-table-column>
          </el-table>
        </el-tab-pane>
      </el-tabs>
      <el-form-item size="large" style="margin-left: 398px; margin-top: 20px">
        <el-button type="primary" @click="onConfirm" v-if="this.configData.setMode === 0">插入</el-button>
        <el-button type="primary" @click="onConfirm" v-else>确定</el-button>
        <el-button @click="onCancle">取消</el-button>
      </el-form-item>
    </el-form>
  </VacantDialog>
</template>
<script>
//import { defaultElementSetData } from './defaultElementSetData';

import { E_EVENT_KEY, QCCfg, ElementCfg, E_QC_CLASS, E_DOC_TYPE, E_DISPLAY_MODE, E_SET_MODE, E_ALGO_SCOPE, E_ALGO_ROLE, E_TIME_TYPE, OptionsManager, AlgorithmsManager, OptionClass } from '@/components/editor/ThinkEditor.Defined';
import { editorsInstanceManager } from '@/components/editorHelper/ThinkEditorInstance.Manager';

import { UpdateConfigData } from '@/components/editor/ThinkEditor.Utils';
export default {
  props: {},
  data() {
    return {
      thinkEditor: undefined,
      sourceClassArr: [],
      optionsManager: new OptionsManager(),
      algorithmsManager: new AlgorithmsManager(),
      currentOptionClassObj: new OptionClass(),
      currentOptionIdObj: undefined,

      configData: {
        source: {},
        algorithm: {},
        inputHandle: {},
        qualityControl: {},
        expressions: {
          list: [{}]
        }
      },

      visible: false,
      ifAction: '',
      oneAction: '',
      towAction: '',
      awAction: '',
      expreData: [
        {
          date: '',
          name: '',
          address: ''
        }
      ],
      alignHorizontalD: true,
      inputMo: false,
      inputMoIs: '',
      timeMo: false,
      controlVisText: true,
      controlVisNum: false,
      controlVisTime: false,
      controlVisZ: false,
      convis: false,
      conTimevis: false,
      activeName: 'first',
      eAlign: [
        {
          key: 'Left',
          label: '左对齐',
          value: 0
        },
        {
          key: 'Center',
          label: '水平居中',
          value: 1
        },
        {
          key: 'Right',
          label: '右对齐',
          value: 2
        },
        {
          key: 'Justify',
          label: '两端对齐',
          value: 3
        },
        {
          key: 'Distribute',
          label: '分散对齐',
          value: 4
        }
      ],

      keyWord: [
        {
          label: '不加密',
          value: 0
        },
        {
          label: '加密',
          value: 1
        },
        {
          label: '全加密',
          value: 2
        }
      ],

      performArr: [
        {
          label: '禁止删除',
          val: 'forbidDelete'
        },
        {
          label: '锁定内容',
          val: 'lockedContent'
        },
        {
          label: '锁定样式',
          val: 'lockedFormat'
        },
        {
          label: '禁止空格',
          val: 'forbidSpace'
        },
        {
          label: '背景特效',
          val: 'enableBackgroundEffects'
        },
        {
          label: '焦点特效',
          val: 'enableFocusedEffects'
        },
        {
          label: '下划线',
          val: 'widthUnderline'
        },
        {
          label: '允许跳转',
          val: 'allowJump'
        },
        {
          label: '内容适应宽度',
          val: 'fitContent'
        },
        {
          label: '显示边框',
          val: 'displayBorder'
        }
      ],

      sourceClassArr: [],
      sourceIdArr: [],
      inputModeArr: [
        {
          key: 'InputText',
          val: 0,
          label: '文本'
        },
        {
          key: 'InputNumber',
          val: 1,
          label: '数值'
        },
        {
          key: 'SelectOption',
          val: 2,
          label: '选项'
        },
        {
          key: 'SelectDateTime',
          val: 3,
          label: '时间'
        }
      ],

      multiple: [
        {
          label: '强制多选',
          val: 'multipleChoice'
        },
        {
          label: '分组互斥',
          val: 'groupExclusion'
        }
      ],

      namicLoad: [
        {
          label: '动态加载',
          value: 1
        }
      ],

      splitPara: [
        {
          label: '跟随父级',
          key: 'FollowParent',
          val: 0
        },
        {
          label: '不断行',
          key: 'BreakLine0SplitParagraph0',
          val: 1
        } /*
        {
          label: '不断行不分段',
          key: 'BreakLine0SplitParagraph0',
          val: 1
        },
        {
          label: '可断行不分段',
          key: 'BreakLine1SplitParagraph0',
          val: 2
        },
        {
          label: '可断行可分段',
          key: 'BreakLine1SplitParagraph1',
          val: 3
        }*/
      ],
      algoModeArr: [
        {
          label: '通用',
          val: 0
        },
        {
          label: '加法',
          val: 1
        }
      ],
      algoScopeArr: [
        {
          label: '文档',
          val: E_ALGO_SCOPE.Doc
        },
        {
          label: '表格',
          val: E_ALGO_SCOPE.Table
        },
        {
          label: '行',
          val: E_ALGO_SCOPE.Row
        }
      ],
      sources: [
        {
          label: '输入',
          val: 0
        },
        {
          label: '输出',
          val: 1
        }
      ],

      linkString: [
        {
          label: '',
          val: ''
        },
        {
          label: ',',
          val: ','
        },
        {
          label: '、',
          val: '、'
        },
        {
          label: '#',
          val: '#'
        },
        {
          label: '*',
          val: '*'
        }
      ],
      optionMe: [
        {
          label: '普通模式',
          val: 0
        },
        {
          label: '有无模式',
          val: 1
        }
      ],

      timeTypeArr: [
        {
          label: '日期格式,列如：2019-09-09',
          val: 0
        },
        {
          label: '日期时间格式,列如：2019-09-09 09:00:01',
          val: 1
        },
        {
          label: '日期时间格式，不含秒,列如：2019-09-09 09:00',
          val: 2
        },
        {
          label: '时间格式,列如：09:00:01',
          val: 3
        },
        {
          label: '时间格式，不含秒,列如：09:00',
          val: 4
        }
      ],

      levelArr: [
        {
          label: '错误',
          val: 0
        },
        {
          label: '警告',
          val: 1
        },
        {
          label: '提示',
          val: 2
        }
      ],

      qcClassArr: [
        {
          label: '文本',
          val: 0
        },
        {
          label: '数值',
          val: 1
        },
        {
          label: '日期时间',
          val: 2
        },
        {
          label: '正则表达式',
          val: 3
        }
      ],

      eventArr: [
        {
          label: 'valueChange',
          val: 0
        },
        {
          label: 'click',
          val: 1
        },
        {
          label: 'dbClick',
          val: 2
        }
      ],
      modeArr: [
        {
          label: '每次',
          val: 0
        },
        {
          label: '一次',
          val: 1
        },
        {
          label: '重置',
          val: 2
        }
      ]
    };
  },
  watch: {
    configData: {
      handler(newVal, oldVal) {
        this.inputModeFun(this.configData.inputHandle.inputMode);
      },
      immediate: true
    }
  },

  created() {
    editorsInstanceManager.addEventListener(E_EVENT_KEY.editorFocus, this.OnDocEditorFocusChange);
    let currentEditorsInstance = editorsInstanceManager.GetSelectedEditorsInstance();
    this.thinkEditor = currentEditorsInstance?.GetSelectedEditor();
    this.SelfInit();
  },
  methods: {
    SelfInit() {
      if (this.thinkEditor === undefined) {
        return;
      }
    },
    Init() {
      if (this.configData.expressions.length === 0) {
        this.configData.expressions.push({
          event: 0,
          mode: 0,
          action: ''
        });
      }
      if (!this.configData.inputHandle.items) {
        this.configData.inputHandle.items = [
          {
            content: '',
            group: '',
            id: '',
            weight: 0.0
          }
        ];
      }

      this.inputModeFun(this.configData.inputHandle.inputMode);
      this.qcClassFun(this.configData.qualityControl.qcClass);
    },
    openDialog(configData, sourcesManager, optionsManager, algorithmsManager) {
      if (configData.setMode == E_SET_MODE.InsertCommon) {
        this.configData = {
          source: {},
          algorithm: {},
          inputHandle: {},
          qualityControl: {},
          expressions: []
        };
      }
      UpdateConfigData(this, configData);

      this.sourceClassArr = sourcesManager.sources;
      this.optionsManager = optionsManager;
      this.algorithmsManager = algorithmsManager;

      this.Init();
      this.$refs.thisDialog.openDialog();
      this.thinkEditor.SetInputFocus(false);
    },
    OnDocEditorFocusChange(e) {
      this.thinkEditor = e.data.editor;

      this.SelfInit();
    },
    closeDialog() {
      this.$refs.thisDialog.closeDialog();
      console.warn('关闭ElementSetBox', new Date());
      this.thinkEditor.SetInputFocus(true);

      this.optionsManager.RemoveInvalidData();
      this.algorithmsManager.RemoveInvalidData();
    },
    onCancle() {
      this.closeDialog();
    },
    onConfirm() {
      let data = this.configData;
      if (data.setMode === E_SET_MODE.InsertCommon) {
        //插入
        this.thinkEditor.InsertElement(data);
      } else {
        this.thinkEditor.SetElementConfig('', data);
      }
      this.closeDialog();
    },

    handleClick(tab, event) {},

    // 选集更改了
    algoGroupChange(val) {
      let that = this;

      this.algorithmsManager.algorithms.forEach(item => {
        if (item.algoGroup == that.configData.algorithm.algoGroup) {
          //联动更新 其它3个配置
          that.configData.algorithm.algoMode = item.algoMode;
          that.configData.algorithm.algoScope = item.algoScope;
          if (that.configData.algorithm.algoRole === undefined) {
            that.configData.algorithm.algoRole = E_ALGO_ROLE.In;
          }
        }
      });

      //for (let i of this.optionC) {
      //  if (val === i.optionClass) {
      //    this.optionI = i.optionIds;
      //    this.$set(this.configData.inputHandle, 'optionId', i.optionIds[0].optionId);
      // this.configData.inputHandle.optionId = i.optionIds[0].optionId;
      //    this.optionIFun(i.optionIds[0].optionId);
      //  }
      //}
    },
    // 源加载
    changeSourceClass(val) {
      for (let i of this.sourceClassArr) {
        if (val.trim() === i.sourceClass.trim()) {
          this.sourceIdArr = i.sourceIds;
          this.configData.source.sourceId = i.sourceIds[0].sourceId;
        }
      }
    },

    // 选项类
    OnOptionClassChange(val) {
      for (let optionClassObj of this.optionsManager.options) {
        if (val === optionClassObj.optionClass) {
          this.currentOptionClassObj = optionClassObj;
          this.currentOptionIdObj = optionClassObj.optionIds[0];
          this.$set(this.configData.inputHandle, 'optionId', this.currentOptionIdObj.optionId);
          // this.configData.inputHandle.optionId = i.optionIds[0].optionId;
          this.OnOptionIdChange(this.currentOptionIdObj.optionId);
          if (this.configData.inputHandle.linkStr === undefined) {
            this.$set(this.configData.inputHandle, 'linkStr', '、');
          }
          if (this.configData.inputHandle.optionShowMode === undefined) {
            this.$set(this.configData.inputHandle, 'optionShowMode', 0);
          }
        }
      }
    },
    // 选项组
    OnOptionIdChange(val) {
      for (let optionIdObj of this.currentOptionClassObj.optionIds) {
        if (optionIdObj.optionId === val) {
          this.configData.inputHandle.items = optionIdObj.items;
          this.configData.inputHandle.items.push({
            content: '',
            group: '',
            id: '',
            weight: 0.0
          });
        }
      }
    },
    // 输入模式修改
    inputModelFunModify(val) {
      let inputData = this.configData;
      switch (val) {
        case 0:
          this.thinkEditor.SetElementInputText(inputData.id);
          break;
        case 1:
          this.thinkEditor.SetElementInputNumber(inputData.id);
          break;
        case 2:
          let da = this.thinkEditor.SetElementSelectOption(inputData.id, inputData.inputHandle.optionClass, inputData.inputHandle.optionId, inputData.inputHandle.linkStr, ElementCfg);
          break;
        case 3:
          this.thinkEditor.SetElementSelectDateTime(inputData.id, inputData.inputHandle.timeType);
          break;
      }
    },
    inputModeFun(val) {
      switch (val) {
        case 0:
          this.timeMo = false;
          this.inputMo = false;
          break;
        case 1:
          this.timeMo = false;
          this.inputMo = false;
          break;
        case 2:
          this.inputMo = true;
          this.timeMo = false;
          break;
        case 3:
          this.inputMo = false;
          this.timeMo = true;
          break;
      }

      if (this.timeMo == true && this.configData.inputHandle.timeType === undefined) {
        this.$set(this.configData.inputHandle, 'timeType', E_TIME_TYPE.Date);
      }
    },

    qcClassFun(val) {
      let qcdata = this.configData;
      let currentEditorsInstance = editorsInstanceManager.GetSelectedEditorsInstance();
      this.thinkEditor = currentEditorsInstance?.GetSelectedEditor();
      switch (val) {
        case E_QC_CLASS.String:
          this.configData.qualityControl = {
            qcClass: this.configData.qualityControl.qcClass,
            level: this.configData.qualityControl.level,
            forbidEmpty: this.configData.qualityControl.forbidEmpty,
            notice: this.configData.qualityControl.notice,
            exclude: this.configData.qualityControl.exclude,
            useByteLen: 0,
            useMinLimit: 0,
            min: 0,
            useMaxLimit: 0,
            max: 0
          };
          this.controlVisText = true;
          this.controlVisNum = false;
          this.controlVisTime = false;
          this.controlVisZ = false;
          //let datase = this.thinkEditor.SetElementQCString(qcdata.id, this.//configData.qualityControl.useMinLimit, this.configData.qualityControl.//max, QCCfg);

          // this.thinkEditor.SetElementQCString(qcdata.id, this.configData.qualityControl.useMinLimit, this.configData.qualityControl.max,ThinkEditor.QCCfg);
          break;
        case E_QC_CLASS.Number:
          this.configData.qualityControl = {
            qcClass: this.configData.qualityControl.qcClass,
            level: this.configData.qualityControl.level,
            forbidEmpty: this.configData.qualityControl.forbidEmpty,
            notice: this.configData.qualityControl.notice,
            exclude: this.configData.qualityControl.exclude,
            useInteger: 0,
            useMinLimit: 0,
            min: 0,
            useMaxLimit: 0,
            max: 0,
            useDecimalPlacesLimit: 0,
            decimalPlaces: 0
          };
          this.controlVisText = false;
          this.controlVisNum = true;
          this.controlVisTime = false;
          this.controlVisZ = false;
          let sege = this.thinkEditor.SetElementQCNumber(qcdata.id, this.configData.qualityControl.min, this.configData.qualityControl.max, this.configData.decimal_count, QCCfg);

          break;
        case E_QC_CLASS.DateTime:
          this.configData.qualityControl = {
            qcClass: this.configData.qualityControl.qcClass,
            level: this.configData.qualityControl.level,
            forbidEmpty: this.configData.qualityControl.forbidEmpty,
            notice: this.configData.qualityControl.notice,
            exclude: this.configData.qualityControl.exclude,
            onlyTime: 0,
            useMinLimit: 0,
            min: 0,
            useMaxLimit: 0,
            max: 0
          };
          this.controlVisText = false;
          this.controlVisNum = false;
          this.controlVisTime = true;
          this.controlVisZ = false;
          let segesf = this.thinkEditor.SetElementQCDateTime(qcdata.id, this.configData.qualityControl.min, this.configData.qualityControl.max, QCCfg);

          break;
        case E_QC_CLASS.RegularExpression:
          this.configData.qualityControl = {
            qcClass: this.configData.qualityControl.qcClass,
            level: this.configData.qualityControl.level,
            forbidEmpty: this.configData.qualityControl.forbidEmpty,
            notice: this.configData.qualityControl.notice,
            exclude: this.configData.qualityControl.exclude,
            regex: ''
          };
          this.controlVisText = false;
          this.controlVisNum = false;
          this.controlVisTime = false;
          this.controlVisZ = true;
          break;
      }
    },

    contr() {
      this.convis = !this.convis;
    },
    contrtime() {
      this.conTimevis = !this.conTimevis;
    },

    fNum(oneV) {
      oneV === 0 ? (this.alignHorizontalD = true) : (this.alignHorizontalD = false);
    },
    cLoad(value) {},

    // 事件执行动作
    actionFun(num, val) {
      // ifAction:'',
      // oneAction:'',
      // towAction:'',
      // awAction:'',
      if (num === 1) {
        this.awAction = val + '?' + this.oneAction + ':' + this.towAction;
      } else if (num === 2) {
        this.awAction = this.ifAction + '?' + val + ':' + this.towAction;
      } else {
        this.awAction = this.ifAction + '?' + this.oneAction + ':' + val;
      }
    },
    actionShow(val) {
      this.visible = true;
      if (val.indexOf('?') != -1 || val.indexOf(':') != -1) {
        let shows = val.split('?');
        this.ifAction = shows[0];
        if (shows[1].indexOf('?') != -1 || shows[1].indexOf(':') != -1) {
          this.oneAction = shows[1].split(':')[0];
          this.towAction = shows[1].split(':')[1];
        }
        this.awAction = val;
      } else {
        this.ifAction = '';
        this.oneAction = '';
        this.towAction = '';
        this.awAction = '';
      }
    },
    writeIn(index) {
      this.configData.expressions[index].action = this.awAction;
      this.configData.expressions[index].popVisible = false;
      this.handleAdd(index);
    },

    // 添加一行表格
    handleAdd(index, row) {
      if (index + 1 === this.configData.expressions.length) {
        this.configData.expressions.push({
          event: 0,
          mode: 0,
          action: '',
          popVisible: false
        });
      }
    },

    inputHandleAdd(index) {
      if (index + 1 === this.configData.inputHandle.items.length) {
        this.configData.inputHandle.items.push({
          content: '',
          group: '',
          id: '',
          weight: 0.0
        });
      }
    }
  }
};
</script>

<style scoped>
.reset-table-style {
  max-height: 300px;
  overflow: auto;
}
.el-divider--horizontal {
  margin: 14px 0;
}
.el-form-item--mini.el-form-item,
.el-form-item--small.el-form-item {
  margin-bottom: 5px;
}
:deep(.el-input-group__append, .el-input-group__prepend) {
  padding: 0 5px;
}
:deep(.dialog-footer) {
  height: 0;
}

:deep(.el-input-number--mini) {
  width: 90px;
}
:deep(.el-divider--horizontal) {
  margin: 15px 0;
}

:deep(.el-form-item--mini.el-form-item, .el-form-item--small.el-form-item) {
  margin-bottom: 8px;
}

::v-deep .el-table__cell {
  padding: 5px 0 !important;
}
</style>
