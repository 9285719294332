<template>
  <div class="fold-filed">
    <el-card>
      <el-button style="width: 85px" size="mini" class="single" v-for="(item, idx) in funcList" @click="$emit('OnFuncClick', item)" :key="idx">{{ item.label }}</el-button>
    </el-card>
    <el-card style="width: 85px">
      <el-button size="mini" v-for="(item, idx) in commentList" :key="idx" @click="$emit('OnFuncClick', item)">{{ item.label }}</el-button>
    </el-card>
  </div>
</template>
<script>
export default {
  data() {
    return {
      funcList: [
        {
          label: '护眼模式',
          val: 'eyeCare'
        },
        {
          label: '表单模式',
          val: 'strictEdit'
        }
      ],
      commentList: [
        {
          label: '插入批注',
          val: 'insertComment'
        },
        {
          label: '删除批注',
          val: 'deleteComment'
        }
      ]
    };
  }
};
</script>

<style lang="scss">
.custom-card {
  width: 500px;
}
.fold-filed {
  width: 75%;
  display: flex;
  .el-card {
    overflow: visible;
    margin-right: 3px;
  }
  .el-card__body,
  .el-main {
    padding: 2px;
  }
  .el-card__body .el-button:first-child {
    margin-left: 4px;
  }
  .el-button {
    &.single {
      margin-top: 6px;
    }
  }

  .el-button + .el-button {
    margin-left: 4px;
    margin-top: 6px;
  }
  .el-button--mini,
  .el-button--mini.is-round {
    padding: 7px 12px;
  }
}
</style>
