import { E_VIEW_MODE, E_SHOW_BG_MODE } from '@/components/editor/ThinkEditor.Defined';

export const DemoCaseQualityControl = {
  methods: {
    /**
     * @function DoDemoCaseQualityControl
     * @description  thinkEditor.SetDocConfig('', { excludeKeywords: '咳嗽;编程;' })
     * @param {}
     * @return {}
     * @example
     */
    async DoDemoCaseQualityControl() {
      let editorsTabsView = this.defaultEditorsTabsView;
      let currentEditorsInstance = editorsTabsView.editorsInstance;

      var docName = '全文违禁字&元素质控';
      let thinkEditor = currentEditorsInstance.GetEditor(docName);

      if (thinkEditor === undefined) {
        thinkEditor = await editorsTabsView.CreateDocEditor(docName);

        var url = '/system/emr/演示/00-演示病例/AdmissionRecord.xml'; //"/system/test/pacsdemo.jpg";
        let response = await fetch(url, {
          credentials: 'same-origin'
        });
        let xml = await response.arrayBuffer();

        thinkEditor.CreateDoc(docName); //@20220722 必需，才能设置编辑者信息
        thinkEditor.SelectDoc(docName);
        thinkEditor.SetEditorInfo('pc', 'zltest', 'zl', 1, '');
        thinkEditor.ParseDocs(docName, '', xml, {});
        /*************************************************
         * 设置全文违禁关键字
         * **********************************************/
        thinkEditor.SetDocConfig('', { excludeKeywords: '咳嗽;编程;' });
        /*************************************************
         * 配置显示质控信息框 和 质控内容背景色
         * **********************************************/
        thinkEditor.SetViewMode(E_VIEW_MODE.Edit, { displayQCInfobox: true, displayQCBgMode: E_SHOW_BG_MODE.AlwaysShow });
      }
      //UI 中显示该文档
      editorsTabsView.SelectDocEditor(docName);
    },
    InitQualityControlReport(currentThinkEditor) {
      currentThinkEditor.qualityControlBiz = currentThinkEditor.qualityControlBiz || {};
      currentThinkEditor.qualityControlBiz.moveIdx = currentThinkEditor.qualityControlBiz.moveIdx || -1;
      currentThinkEditor.qualityControlBiz.report = currentThinkEditor.GetQualityControlReport();
    },
    async OnGetQualityControlReportTest() {
      let currentTabsView = this.defaultEditorsTabsView;
      let currentEditorsInstance = currentTabsView.editorsInstance;
      let currentThinkEditor = currentEditorsInstance.GetSelectedEditor();
      if (currentThinkEditor === undefined) {
        return;
      }

      this.InitQualityControlReport(currentThinkEditor);

      this.popDialogTitle = 'GetQualityControlReport';
      this.popDialogText = JSON.stringify(currentThinkEditor.qualityControlBiz.report, null, 2);
      this.popDialogVisible = true;
    },
    async OnGotoNextQualityControlTest() {
      let currentTabsView = this.defaultEditorsTabsView;
      let currentEditorsInstance = currentTabsView.editorsInstance;
      let currentThinkEditor = currentEditorsInstance.GetSelectedEditor();
      if (currentThinkEditor === undefined) {
        return;
      }
      if (currentThinkEditor.qualityControlBiz == undefined) {
        this.InitQualityControlReport(currentThinkEditor);
      }
      let qcReport = currentThinkEditor.qualityControlBiz.report;
      let qcCount = qcReport.code;
      if (++currentThinkEditor.qualityControlBiz.moveIdx >= qcCount) {
        currentThinkEditor.qualityControlBiz.moveIdx = 0;
      }
      let qcItem = qcReport.data[currentThinkEditor.qualityControlBiz.moveIdx];

      currentThinkEditor.SelectQualityControl({ id: qcItem.id });
    },
    async OnGotoPreviousQualityControlTest() {
      let currentTabsView = this.defaultEditorsTabsView;
      let currentEditorsInstance = currentTabsView.editorsInstance;
      let currentThinkEditor = currentEditorsInstance.GetSelectedEditor();
      if (currentThinkEditor === undefined) {
        return;
      }
      if (currentThinkEditor.qualityControlBiz == undefined) {
        this.InitQualityControlReport(currentThinkEditor);
      }
      let qcReport = currentThinkEditor.qualityControlBiz.report;
      let qcCount = qcReport.code;
      if (--currentThinkEditor.qualityControlBiz.moveIdx < 0) {
        currentThinkEditor.qualityControlBiz.moveIdx = qcCount - 1;
      }
      let qcItem = qcReport.data[currentThinkEditor.qualityControlBiz.moveIdx];

      currentThinkEditor.SelectQualityControl({ id: qcItem.id });
    },
    async OnDocQualityControlTest() {
      let currentTabsView = this.defaultEditorsTabsView;
      let currentEditorsInstance = currentTabsView.editorsInstance;
      let currentThinkEditor = currentEditorsInstance.GetSelectedEditor();
      if (currentThinkEditor === undefined) {
        return;
      }
      if (currentThinkEditor.qualityControlBiz == undefined) {
        this.InitQualityControlReport(currentThinkEditor);
      }

      let alertCount = currentThinkEditor.qualityControlBiz.report.code;
      if (alertCount) {
        this.popDialogTitle = '错误';
        this.popDialogText = `文档存在${alertCount}个错误，详见GetQualityControlReport()质控报告。`;
        this.popDialogVisible = true;
      } else {
        this.popDialogTitle = '提示';
        this.popDialogText = '文档通过校验';
        this.popDialogVisible = true;
      }
    }
  }
};
