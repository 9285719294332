import { E_EVENT_KEY, E_VIEW_MODE, E_SHOW_BG_MODE } from '@/components/editor/ThinkEditor.Defined';

export const DemoCaseRevise = {
  methods: {
    async DoDemoCaseRevise() {
      let editorsTabsView = this.defaultEditorsTabsView;
      let currentEditorsInstance = editorsTabsView.editorsInstance;

      var docName = '痕迹报告';
      let thinkEditor = currentEditorsInstance.GetEditor(docName);

      if (thinkEditor === undefined) {
        thinkEditor = await editorsTabsView.CreateDocEditor(docName);

        var url = '/system/emr/演示/00-演示病例/AdmissionRecord.xml'; //"/system/test/pacsdemo.jpg";
        let response = await fetch(url, {
          credentials: 'same-origin'
        });
        let xml = await response.arrayBuffer();

        thinkEditor.CreateDoc(docName); //@20220722 必需，才能设置编辑者信息
        thinkEditor.SelectDoc(docName);

        thinkEditor.ParseDocs(docName, '', xml, {});
        /*************************************************
         * 配置编辑者信息
         * **********************************************/
        thinkEditor.SetEditorInfo('pc', 'appGuid1', '高权限编辑者', 1, '');
        /*************************************************
         * 配置启用权限管理 配置审阅痕迹行为
         * **********************************************/
        thinkEditor.SetEditMode(true, true, true, true, true);
        /*************************************************
         * 显示痕迹
         * **********************************************/
        thinkEditor.SetViewMode(E_VIEW_MODE.Edit, { displayReviseAuxiliary: true, displayReviseDel: true });

        thinkEditor.addEventListener(E_EVENT_KEY.docModified, this.OnDocModified);
        //or currentEditorsInstance.addEventListener(E_EVENT_KEY.docModified, this.OnDocModified);
      }
      //UI 中显示该文档
      editorsTabsView.SelectDocEditor(docName);
    },
    async OnDocModified(e) {
      let thinkEditor = e.data.editor;
      this.InitReviseReport(thinkEditor);
    },
    InitReviseReport(currentThinkEditor) {
      currentThinkEditor.reviseBiz = currentThinkEditor.reviseBiz || {};
      currentThinkEditor.reviseBiz.moveIdx = currentThinkEditor.reviseBiz.moveIdx || -1;
      currentThinkEditor.reviseBiz.report = currentThinkEditor.GetReviseReport();
    },
    async OnGetReviseReportTest() {
      let currentTabsView = this.defaultEditorsTabsView;
      let currentEditorsInstance = currentTabsView.editorsInstance;
      let currentThinkEditor = currentEditorsInstance.GetSelectedEditor();
      if (currentThinkEditor === undefined) {
        return;
      }

      this.InitReviseReport(currentThinkEditor);

      this.popDialogTitle = 'GetReviseReport';
      this.popDialogText = JSON.stringify(currentThinkEditor.reviseBiz.report, null, 2);
      this.popDialogVisible = true;
    },
    async OnGotoNextReviseTest() {
      let currentTabsView = this.defaultEditorsTabsView;
      let currentEditorsInstance = currentTabsView.editorsInstance;
      let currentThinkEditor = currentEditorsInstance.GetSelectedEditor();
      if (currentThinkEditor === undefined) {
        return;
      }
      if (currentThinkEditor.reviseBiz == undefined) {
        this.InitReviseReport(currentThinkEditor);
      }
      let report = currentThinkEditor.reviseBiz.report;
      let count = report.code;
      if (++currentThinkEditor.reviseBiz.moveIdx >= count) {
        currentThinkEditor.reviseBiz.moveIdx = 0;
      }
      let item = report.data[currentThinkEditor.reviseBiz.moveIdx];

      currentThinkEditor.SelectRevise({ uuid: item.uuid });
    },
    async OnGotoPreviousReviseTest() {
      let currentTabsView = this.defaultEditorsTabsView;
      let currentEditorsInstance = currentTabsView.editorsInstance;
      let currentThinkEditor = currentEditorsInstance.GetSelectedEditor();
      if (currentThinkEditor === undefined) {
        return;
      }
      if (currentThinkEditor.reviseBiz == undefined) {
        this.InitReviseReport(currentThinkEditor);
      }
      let report = currentThinkEditor.reviseBiz.report;
      let count = report.code;
      if (--currentThinkEditor.reviseBiz.moveIdx < 0) {
        currentThinkEditor.reviseBiz.moveIdx = count - 1;
      }
      let item = report.data[currentThinkEditor.reviseBiz.moveIdx];

      currentThinkEditor.SelectRevise({ uuid: item.uuid });
    },
    async OnLowerPermissionEditTest() {
      let currentTabsView = this.defaultEditorsTabsView;
      let currentEditorsInstance = currentTabsView.editorsInstance;
      let currentThinkEditor = currentEditorsInstance.GetSelectedEditor();
      if (currentThinkEditor === undefined) {
        return;
      }

      currentThinkEditor.SetEditorInfo('pc', 'appGuid0', '低权限编辑者', 0, '');
    },
    async OnMiddlePermissionEditTest() {
      let currentTabsView = this.defaultEditorsTabsView;
      let currentEditorsInstance = currentTabsView.editorsInstance;
      let currentThinkEditor = currentEditorsInstance.GetSelectedEditor();
      if (currentThinkEditor === undefined) {
        return;
      }
      currentThinkEditor.SetEditorInfo('pc', 'appGuid1', '中权限编辑者', 1, '');
    },
    async OnHigherPermissionEditTest() {
      let currentTabsView = this.defaultEditorsTabsView;
      let currentEditorsInstance = currentTabsView.editorsInstance;
      let currentThinkEditor = currentEditorsInstance.GetSelectedEditor();
      if (currentThinkEditor === undefined) {
        return;
      }
      currentThinkEditor.SetEditorInfo('pc', 'appGuid2', '高权限编辑者', 2, '');
    }
  }
};
