<template>
  <VacantDialog title="乳牙牙位图" width="320px" ref="thisDialog" :top="'10%'" :confirmTxt="confirmTxt" style="position: relative" @confirm="onConfirm">
    <div style="text-align: center">上颌</div>
    <div style="display: flex; justify-content: space-between; margin-right: 26px; margin-left: 10px; margin-top: 94px">
      <div style="display: flex; justify-content: space-between; position: relative">
        <div
          v-for="(item, index) in itemListTwo"
          :key="item.value"
          :style="{
            width: '25px',
            position: 'absolute',
            bottom: '0',
            left: index * 24 + 'px',
            marginLeft: '5px'
          }"
        >
          {{ item.label }}
        </div>
      </div>
      <div style="display: flex; justify-content: space-between; position: relative">
        <div
          v-for="(item, index) in itemList"
          :key="item.value"
          :style="{
            width: '25px',
            position: 'absolute',
            bottom: '0',
            right: index * 24 + 'px',
            marginRight: '-2px'
          }"
        >
          {{ item.label }}
        </div>
      </div>
    </div>

    <div style="display: flex; justify-content: space-between; margin-right: 10px">
      <div style="width: 15px; position: absolute; top: 337px; left: 10px">右</div>
      <div style="border-bottom: 1px solid; flex: 1; position: absolute; top: 349px; width: 235px; left: 32px"></div>
      <div style="width: 15px; position: absolute; top: 337px; right: 26px">左</div>
    </div>
    <div style="display: grid; grid-template-columns: 50% 50%; justify-content: center; align-items: center; padding-left: 10px">
      <div v-for="(item, inde) in teethViewList" :key="inde" style="margin-bottom: 10px; margin-top: 10px; margin-right: 10px">
        <div style="display: flex; align-items: center">
          <div v-for="(ite, ind) in item.items" :key="ind">
            <div v-for="(it, index) in ite" :key="index">
              <div @click="changeChecked(inde, ind)" v-if="index === 'index' && index !== 'checked'" class="check-style" :class="ite['checked'] === 1 ? 'active' : ''">
                {{ ite[index] + 1 }}
              </div>
              <div v-else-if="index !== 'index' && index !== 'checked'">
                <el-tooltip class="item" effect="dark" :content="index === 'p' ? '腭侧' : index === 'l' ? '舌侧' : index === 'b' ? '颊面' : index === 'd' ? '远中' : index === 'o' ? '颌面' : index === 'm' ? '近中' : ''" placement="right" :offset="20" :visible-arrow="false" :open-delay="100">
                  <div class="border-div" @click="changeCheckedTwo(inde, ind, index)" :class="ite[index] === 1 ? 'activeTwo' : ''">
                    {{ index.toUpperCase() }}
                  </div>
                </el-tooltip>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div style="text-align: center">上颌</div>
  </VacantDialog>
</template>

<script>
import elDragDialog from '@/directive';
import { E_EVENT_KEY, E_DISPLAY_MODE, E_LAYOUT_VERTICAL_ALIGN, E_FORMULA_STYLE, E_SET_MODE } from '@/components/editor/ThinkEditor.Defined';
import { editorsInstanceManager } from '@/components/editorHelper/ThinkEditorInstance.Manager';

export default {
  name: 'index',
  props: {
    dataList: {
      type: Array,
      default: () => []
    }
  },
  directives: {
    elDragDialog
  },
  data() {
    return {
      configData: {
        setMode: E_SET_MODE.InsertCommon,
        id: '',
        name: '',
        alignVerticalLayout: E_LAYOUT_VERTICAL_ALIGN.SelfMiddle,
        styles: E_FORMULA_STYLE.Common,
        union: []
      },
      confirmTxt: '确定',
      ceList: {
        b: 0,
        checked: 0,
        d: 0,
        index: 7,
        l: 0,
        m: 0,
        o: 0,
        p: 0
      },
      itemList: [
        { value: 1, label: '乳中切牙' },
        { value: 2, label: '乳侧切牙' },
        { value: 3, label: '乳牙尖' },
        { value: 4, label: '第一乳磨牙' },
        { value: 5, label: '第二乳磨牙' }
      ],
      itemListTwo: [
        { value: 5, label: '乳中切牙' },
        { value: 4, label: '乳侧切牙' },
        { value: 3, label: '乳牙尖' },
        { value: 2, label: '第一乳磨牙' },
        { value: 1, label: '第二乳磨牙' }
      ],
      teethViewList: [
        {
          index: 0,
          items: [
            {
              p: 0,
              l: 0,
              b: 0,
              d: 0,
              o: 0,
              m: 0,
              checked: 0,
              index: 4
            },
            {
              p: 0,
              l: 0,
              b: 0,
              d: 0,
              o: 0,
              m: 0,
              checked: 0,
              index: 3
            },
            {
              p: 0,
              l: 0,
              b: 0,
              d: 0,
              o: 0,
              m: 0,
              checked: 0,
              index: 2
            },
            {
              p: 0,
              l: 0,
              b: 0,
              d: 0,
              o: 0,
              m: 0,
              checked: 0,
              index: 1
            },
            {
              p: 0,
              l: 0,
              b: 0,
              d: 0,
              o: 0,
              m: 0,
              checked: 0,
              index: 0
            }
          ],
          object: 'formulaUnit'
        },
        {
          index: 1,
          items: [
            {
              p: 0,
              l: 0,
              b: 0,
              d: 0,
              o: 0,
              m: 0,
              checked: 0,
              index: 0
            },
            {
              p: 0,
              l: 0,
              b: 0,
              d: 0,
              o: 0,
              m: 0,
              checked: 0,
              index: 1
            },
            {
              p: 0,
              l: 0,
              b: 0,
              d: 0,
              o: 0,
              m: 0,
              checked: 0,
              index: 2
            },
            {
              p: 0,
              l: 0,
              b: 0,
              d: 0,
              o: 0,
              m: 0,
              checked: 0,
              index: 3
            },
            {
              p: 0,
              l: 0,
              b: 0,
              d: 0,
              o: 0,
              m: 0,
              checked: 0,
              index: 4
            }
          ],
          object: 'formulaUnit'
        },
        {
          index: 2,
          items: [
            {
              index: 4,
              p: 0,
              l: 0,
              b: 0,
              d: 0,
              o: 0,
              m: 0,
              checked: 0
            },
            {
              index: 3,
              p: 0,
              l: 0,
              b: 0,
              d: 0,
              o: 0,
              m: 0,
              checked: 0
            },
            {
              index: 2,
              p: 0,
              l: 0,
              b: 0,
              d: 0,
              o: 0,
              m: 0,
              checked: 0
            },
            {
              index: 1,
              p: 0,
              l: 0,
              b: 0,
              d: 0,
              o: 0,
              m: 0,
              checked: 0
            },
            {
              index: 0,
              p: 0,
              l: 0,
              b: 0,
              d: 0,
              o: 0,
              m: 0,
              checked: 0
            }
          ],
          object: 'formulaUnit'
        },
        {
          index: 3,
          items: [
            {
              index: 0,
              p: 0,
              l: 0,
              b: 0,
              d: 0,
              o: 0,
              m: 0,
              checked: 0
            },
            {
              index: 1,
              p: 0,
              l: 0,
              b: 0,
              d: 0,
              o: 0,
              m: 0,
              checked: 0
            },
            {
              index: 2,
              p: 0,
              l: 0,
              b: 0,
              d: 0,
              o: 0,
              m: 0,
              checked: 0
            },
            {
              index: 3,
              p: 0,
              l: 0,
              b: 0,
              d: 0,
              o: 0,
              m: 0,
              checked: 0
            },
            {
              index: 4,
              p: 0,
              l: 0,
              b: 0,
              d: 0,
              o: 0,
              m: 0,
              checked: 1
            }
          ],
          object: 'formulaUnit'
        }
      ],
      flag: false
    };
  },
  watch: {
    dataList: {
      handler(val) {
        //配置视图联动
        for (let i in val) {
          for (let iOne in this.teethViewList) {
            if (i === iOne) {
              const newItem = val[i].items;
              const newItemTwo = this.teethViewList[iOne].items;
              for (let b in newItem) {
                for (let bOne in newItemTwo) {
                  if (b === bOne) {
                    for (let c in newItem[b]) {
                      for (let cOne in newItemTwo[bOne]) {
                        if (c === cOne) {
                          newItemTwo[bOne][cOne] = newItem[b][c];
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      },
      immediate: true
    }
  },
  created() {
    editorsInstanceManager.addEventListener(E_EVENT_KEY.editorFocus, this.OnDocEditorFocusChange);
    let currentEditorsInstance = editorsInstanceManager.GetSelectedEditorsInstance();
    this.thinkEditor = currentEditorsInstance?.GetSelectedEditor();
  },
  methods: {
    openDialog(configData) {
      this.configData = {
        ...configData
      };
      this.configDataToTeethViewList(this.configData);
      this.$refs.thisDialog.openDialog();
    },
    OnDocEditorFocusChange(e) {
      this.thinkEditor = e.data.editor;
    },
    closeDialog() {
      this.$refs.thisDialog.closeDialog();
    },

    async onCancle() {
      this.closeDialog();
    },
    onConfirm() {
      this.teethViewListToConfigData(this.configData);
      this.thinkEditor.SetFormulaConfig('', this.configData);
      this.closeDialog();
    },
    changeChecked(a, b) {
      if (this.teethViewList[a].items[b].checked) {
        this.teethViewList[a].items[b].checked = 0;
        for (let i in this.teethViewList[a].items[b]) {
          if (i !== 'index') {
            this.teethViewList[a].items[b][i] = 0;
          }
        }
      } else {
        this.teethViewList[a].items[b].checked = 1;
      }
    },
    changeCheckedTwo(a, b, c) {
      if (this.teethViewList[a].items[b][c]) {
        this.teethViewList[a].items[b][c] = 0;
      } else {
        this.teethViewList[a].items[b][c] = 1;
        this.teethViewList[a].items[b].checked = 1;
      }
    },
    configDataToTeethViewList(configData) {
      let teethList = this.teethViewList;
      for (let i in teethList) {
        for (let iOne in configData.union) {
          if (i === iOne) {
            const newItem = teethList[i].items;
            const newItemTwo = configData.union[iOne].items;
            for (let b in newItem) {
              for (let bOne in newItemTwo) {
                if (b === bOne) {
                  for (let c in newItem[b]) {
                    for (let cOne in newItemTwo[bOne]) {
                      if (c === cOne) {
                        newItem[b][c] = newItemTwo[bOne][cOne];
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    },
    teethViewListToConfigData(configData) {
      let teethList = this.teethViewList;
      for (let i in teethList) {
        for (let iOne in configData.union) {
          if (i === iOne) {
            const newItem = teethList[i].items;
            const newItemTwo = configData.union[iOne].items;
            for (let b in newItem) {
              for (let bOne in newItemTwo) {
                if (b === bOne) {
                  for (let c in newItem[b]) {
                    for (let cOne in newItemTwo[bOne]) {
                      if (c === cOne) {
                        newItemTwo[bOne][cOne] = newItem[b][c];
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
};
</script>

<style scoped>
::v-deep .el-input--medium .el-input__inner {
  height: 60px;
}

::v-deep .el-select-dropdown__item {
  height: 60px !important;
  line-height: 60px;
  border-bottom: 2px solid #ccc;
  margin: 0 10px;
}

.border-div {
  height: 20px;
  width: 20px;
  border: 1px solid #ccc;
  border-radius: 5px;
  text-align: center;
  line-height: 20px;
  cursor: pointer;
  margin-bottom: 2px;
  margin-right: 2px;
}

.check-style {
  background: #a7ea72;
  height: 20px;
  width: 20px;
  border: 1px solid #ccc;
  border-radius: 5px;
  text-align: center;
  line-height: 20px;
  cursor: pointer;
  margin-bottom: 2px;
  margin-right: 2px;
}

.active {
  background: #359965;
}

.activeTwo {
  background: #bebebe;
}
</style>
