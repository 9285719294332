import Vue from 'vue';
import App from './App.vue';
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import rightContext from './demo/rightContext.js';
import VacantDialog from '@/components/VacantDialog';
import PFrom from '@/components/PFrom.vue';
import CustDirective from '@/directive/index';

import { optionsManager, E_LOG_LEVEL } from '@/components/editor/ThinkEditor.Defined';

Vue.config.productionTip = false;

Vue.use(rightContext);
Vue.component('VacantDialog', VacantDialog); // 弹窗公共模块
Vue.component('PFrom', PFrom); // 表单

Vue.config.productionTip = false;
Vue.use(ElementUI);
Vue.use(CustDirective);

let Base64 = require('js-base64').Base64;
Vue.prototype.$Base64 = Base64;
//Vue.prototype.$debugFlush = false;
//Vue.prototype.$logLevel = E_LOG_LEVEL.Info; //E_LOG_LEVEL.Info
Vue.prototype.$enableRevise = 0;
Vue.prototype.$useParseFontMode = 0;

/******************************************************************/
//Vue.prototype.$optionsManager1 = optionsManager; //改为import optionsManager
//Vue.prototype.$algorithmsManager1 = window.algorithmsManager; //改为import algorithmsManager
//SourcesManager跟随文档或用户信息
/******************************************************************/

//Vue.prototype.$editorLibPath = '/js/';
//Vue.prototype.$editorFontPath = '/system/user_font/';
//Vue.prototype.$thinkEditorInstanceManager1 = new ThinkEditorInstanceManager();

new Vue({
  render: h => h(App)
}).$mount('#app');
