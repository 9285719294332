import custDragDialog from './custDragDialog';
const install = function(Vue) {
  Vue.directive('cust-drag-dialog', custDragDialog);
};

if (window.Vue) {
  window['cust-drag-dialog'] = custDragDialog;
  Vue.use(install); // eslint-disable-line
}

export default install;
