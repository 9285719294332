import { E_EVENT_KEY, E_VIEW_MODE, E_PAGES_LAYOUT_MODE } from '@/components/editor/ThinkEditor.Defined';

export const DemoCaseDisplayScale = {
  methods: {
    async DoDemoCaseDisplayScale() {
      let editorsTabsView = this.defaultEditorsTabsView;
      let currentEditorsInstance = editorsTabsView.editorsInstance;

      var docName = '视图比例';
      let thinkEditor = currentEditorsInstance.GetEditor(docName);

      if (thinkEditor === undefined) {
        thinkEditor = await editorsTabsView.CreateDocEditor(docName);

        var url = '/system/emr/演示/00-演示病例/AdmissionRecord.xml'; //"/system/test/pacsdemo.jpg";
        let response = await fetch(url, {
          credentials: 'same-origin'
        });
        let xml = await response.arrayBuffer();

        thinkEditor.CreateDoc(docName); //@20220722 必需，才能设置编辑者信息
        thinkEditor.SelectDoc(docName);

        thinkEditor.ParseDocs(docName, '', xml, {});
        /*************************************************
         * 配置编辑者信息
         * **********************************************/
        thinkEditor.SetEditorInfo('pc', 'appGuid1', 'zl', 1, '');

        thinkEditor.SetViewMode(E_VIEW_MODE.Edit, {});

        thinkEditor.addEventListener(E_EVENT_KEY.docModified, this.OnDocModified);
        //or currentEditorsInstance.addEventListener(E_EVENT_KEY.docModified, this.OnDocModified);
      }
      //UI 中显示该文档
      editorsTabsView.SelectDocEditor(docName);
    },
    async OnDocModified(e) {
      let thinkEditor = e.data.editor;
    },

    async OnSetDisplayScale1Test() {
      let currentTabsView = this.defaultEditorsTabsView;
      let currentEditorsInstance = currentTabsView.editorsInstance;
      let currentThinkEditor = currentEditorsInstance.GetSelectedEditor();
      if (currentThinkEditor === undefined) {
        return;
      }

      currentThinkEditor.SetDisplayScale(E_PAGES_LAYOUT_MODE.Ratio, 1.0);
    },
    async OnSetDisplayScale15Test() {
      let currentTabsView = this.defaultEditorsTabsView;
      let currentEditorsInstance = currentTabsView.editorsInstance;
      let currentThinkEditor = currentEditorsInstance.GetSelectedEditor();
      if (currentThinkEditor === undefined) {
        return;
      }

      currentThinkEditor.SetDisplayScale(E_PAGES_LAYOUT_MODE.Ratio, 1.5);
    },
    async OnSetDisplayScaleFillViewWidthTest() {
      let currentTabsView = this.defaultEditorsTabsView;
      let currentEditorsInstance = currentTabsView.editorsInstance;
      let currentThinkEditor = currentEditorsInstance.GetSelectedEditor();
      if (currentThinkEditor === undefined) {
        return;
      }

      currentThinkEditor.SetDisplayScale(E_PAGES_LAYOUT_MODE.FillViewWidth, 1);
    }
  }
};
