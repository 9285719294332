import { E_EVENT_KEY, ThinkEditorEvent } from '@/components/editor/ThinkEditor.Defined';

class ThinkEditorInstanceManager extends EventTarget {
  constructor() {
    super();
    this.editorsInstances = new Array();
  }
  AddEditorsInstance(editorsInstance) {
    if (editorsInstance === undefined) {
      return false;
    }
    let existEditorsInstance = this.GetEditorsInstance(editorsInstance);
    if (existEditorsInstance !== undefined) {
      return false;
    }
    this.editorsInstances.push(editorsInstance);
    this.addInnerEventListenerAgent(editorsInstance);
    return true;
  }

  GetEditorsInstance(editorsInstance) {
    for (var i = 0; i < this.editorsInstances.length; i++) {
      if (this.editorsInstances[i] === editorsInstance) {
        return editorsInstance;
      }
    }
    return undefined;
  }
  GetDefaultEditorsInstance() {
    return this.editorsInstances[0];
  }
  GetSelectedEditorsInstance() {
    for (var i = 0; i < this.editorsInstances.length; i++) {
      if (this.editorsInstances[i].selected) {
        return this.editorsInstances[i];
      }
    }
    return undefined;
  }
  SelectedEditorsInstance(instanceId) {
    for (var i = 0; i < this.editorsInstances.length; i++) {
      if (this.editorsInstances[i].instanceId === instanceId) {
        this.editorsInstances[i].selected = true;
      } else {
        this.editorsInstances[i].selected = false;
      }
    }
  }

  addInnerEventListenerAgent(editorsInstance) {
    //editor=》OnInnerEvent=》APP listener 内部产生的事件，被路由到this.OnInnerEvent

    for (let keyEvent in E_EVENT_KEY) {
      //console.log(keyEvent);
      editorsInstance.addEventListener(keyEvent, e => this.OnInnerEvent(e));
    }
  }
  removeInnerEventListenerAgent(editorsInstance) {
    //editor=》OnInnerEvent=》APP listener 内部产生的事件，被路由到this.OnInnerEvent
    for (let keyEvent in E_EVENT_KEY) {
      //console.log(keyEvent);
      editorsInstance.removeEventListener(keyEvent, e => this.OnInnerEvent(e));
    }
  }
  OnEditorsInstanceFocus(e) {
    //console.log(e);
    let selectedInstance = e.data.instance;
    let hasSelected = false;
    for (var i = 0; i < this.editorsInstances.length; i++) {
      if (this.editorsInstances[i] === selectedInstance) {
        this.editorsInstances[i].selected = true;
        hasSelected = true;
      } else {
        this.editorsInstances[i].selected = false;
      }
    }
    if (hasSelected == false && this.editorsInstances.length != 0) {
      this.editorsInstances[0].selected = true;
    }
  }
  OnInnerEvent(evt) {
    let e = evt; // as ThinkEditorEvent;
    //console.log(new Date(), ' OnInnerEditorsInstanceEvent==>', e);

    let ret = this.dispatchEvent(new ThinkEditorEvent(e.type, e.data));
    if (ret == false) {
      evt.preventDefault();
    }
    if (e.type == E_EVENT_KEY.editorFocus) {
      this.OnEditorsInstanceFocus(e);
    }
  }
}
const editorsInstanceManager = new ThinkEditorInstanceManager();
export { editorsInstanceManager };
