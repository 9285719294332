<template>
  <div class="opear-tags">
    <div v-show="isFold" class="left-field-tmp">
      <i class="el-icon-s-unfold fold-icon-left" @click="isFold = !isFold"></i>
    </div>
    <div class="left-field" v-show="!isFold" :style="{ width: tabsWidth }">
      <el-tabs v-model="editableTabsValue" type="card" closable @tab-click="leftTagSelected" @tab-remove="handleTabsEdit">
        <el-tab-pane v-for="(lItem, lIdx) in leftTags" :key="lIdx + '-' + lItem.showName" :label="lItem.showName" :name="lItem.showName"></el-tab-pane>
      </el-tabs>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    tabsWidth: {
      type: String,
      default: '100%'
    }
  },
  data() {
    return {
      editableTabsValue: '',
      leftTagsActive: false,
      leftTags: [],
      isFold: false
    };
  },
  methods: {
    leftTagSelected(tag) {
      this.$emit('tabsClick', tag.label);
    },
    handleTabsEdit(targetName) {
      this.$emit('tabsAlter', targetName);
    }
  }
};
</script>

<style lang="scss" scoped>
.opear-tags {
  position: relative;
  display: flex;
  // padding: 0 20px;
  min-height: 50px;
  .left-field-tmp {
    position: relative;
    flex: 0 20px;
    width: 50px;
    border: 1px solid #f5f5f5;
  }
  .left-field,
  .rigth-field {
    position: relative;
    flex: 1;
    border: 1px solid #f5f5f5;
    padding: 4px;
  }
  .left-tag {
    cursor: pointer;
    margin-right: 2px;
    &.active {
      background: #4682b4;
      color: #fff;
    }
  }
  .fold-icon-right {
    position: absolute;
    right: 0px;
    top: 8px;
    cursor: pointer;
  }
  .fold-icon-left {
    position: absolute;
    left: 0px;
    top: 8px;
    cursor: pointer;
  }
  :deep(.el-tabs--card > .el-tabs__header) {
    border: 0;
  }
  :deep(.el-tabs__item) {
    padding: 0 4px;
    height: 30px;
    line-height: 30px;
  }
  :deep(.el-tabs--top.el-tabs--card > .el-tabs__header .el-tabs__item:last-child) {
    padding-right: 4px;
  }
  :deep(.el-tabs--top.el-tabs--card > .el-tabs__header .el-tabs__item:nth-child(2)) {
    padding-left: 4px;
  }
  ::v-deep {
    .el-tabs__header {
      margin: 0;
    }
  }
  :deep(.el-tabs--card > .el-tabs__header .el-tabs__item.is-active.is-closable) {
    padding-left: 4px;
    padding-right: 4px;
  }
}
</style>
