<template>
  <div :style="pos" class="content" @keyup.enter="onKeyEnter">
    <el-date-picker v-if="showPicker" v-model="time" :type="type" placeholder="选择日期时间" align="right" :format="format" ref="datePickerRef" class="timnePickCSS" @change="commit" :picker-options="pickerOptions" />
    <!--    <el-button @click="commit" style="margin-left: 10px;margin-top: 5px" size="small">确定</el-button>-->
    <!--    <el-button @click="close" style="margin-left: 10px" size="small">取消</el-button>-->
  </div>
</template>

<script>
import { E_INPUT_MODE, E_TIME_TYPE } from '@/components/editor/ThinkEditor.Defined';
import { Date2TimeStr, UpdateConfigData } from '@/components/editor/ThinkEditor.Utils';
export default {
  name: 'index',
  props: {
    configData: {
      type: Object,
      default: {
        content: '2022-02-02',
        inputHandle: {
          inputMode: 0,
          timeType: 0,
          inView: {
            posX: 0,
            posY: 0,
            with: 1,
            height: 1
          }
        }
      }
    }
  },
  data() {
    return {
      showPicker: false,
      format: 'yyyy-MM-dd',
      valueFormat: 'yyyy-MM-dd',
      type: 'datetime',
      time: '2022-02-02',

      pos: {
        top: '1px',
        left: '1px'
      },
      pickerOptions: {
        shortcuts: [
          {
            text: '今天',
            onClick(picker) {
              picker.$emit('pick', new Date());
            }
          },
          {
            text: '昨天',
            onClick(picker) {
              const date = new Date();
              date.setTime(date.getTime() - 3600 * 1000 * 24);
              picker.$emit('pick', date);
            }
          },
          {
            text: '一周前',
            onClick(picker) {
              const date = new Date();
              date.setTime(date.getTime() - 3600 * 1000 * 24 * 7);
              picker.$emit('pick', date);
            }
          }
        ]
      }
    };
  },
  watch: {
    configData: {
      handler(data) {
        this.SelfInit(data);
      },
      immediate: true
    }
  },
  mounted() {
    this.focus();
  },

  methods: {
    SelfInit(data) {
      let timeType = data.inputHandle.timeType;
      this.showPicker = false;
      if (data.inputHandle.inputMode == E_INPUT_MODE.SelectDateTime) {
        this.pos.top = data.inView.posY + data.inView.height + 5 + 'px';
        this.pos.left = data.inView.posX + 'px';

        this.time = new Date(data.inputHandle.time); //例如：'2019-09-09 09:09:09'

        if (timeType == E_TIME_TYPE.Date) {
          this.type = 'date';
          this.format = 'yyyy-MM-dd';
          this.valueFormat = 'yyyy-MM-dd';
        } else if (timeType == E_TIME_TYPE.DateTime) {
          this.type = 'datetime';
          this.format = 'yyyy-MM-dd HH:mm:ss';
          this.valueFormat = 'yyyy-MM-dd HH:mm:ss';
        } else if (timeType == E_TIME_TYPE.DateTimeNoSecond) {
          this.type = 'datetime';
          this.format = 'yyyy-MM-dd HH:mm';
          this.valueFormat = 'yyyy-MM-dd HH:mm';
        } else {
          this.close();
          return;
        }
        console.warn('show DateTimeBox');

        this.$nextTick(() => {
          this.showPicker = true;
          setTimeout(() => {
            this.$refs.datePickerRef.focus();
          }, 100);
        });
      } else {
        this.close();
      }
    },
    show(data) {
      this.SelfInit(data);
      console.warn('inputHandleDateTime focus');
      //this.focus();
    },
    focus() {
      setTimeout(() => {
        //this.$refs.datePickerRef.focus();
      }, 100);
    },

    onKeyEnter() {
      this.close();
    },
    /**
     * 确定
     */
    commit() {
      this.$emit('action:setValue', Date2TimeStr(this.time)); //例如：'2019-09-09 09:09:09'
    },
    close() {
      console.warn('hide DateTimeBox');
      this.$emit('action:hide', 'dateTimeBox');
    }
  }
};
</script>

<style scoped>
.content {
  position: absolute;
  /*padding: 6px;*/
  /*border: 1px solid #ccc;*/
  /*border-radius: 5px;*/
  /*background: #fff;*/
}

.timnePickCSS {
  position: absolute;
  top: 30%;
  left: 70%;
  z-index: -1;
}
</style>
