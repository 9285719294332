<template>
  <VacantDialog title="页码设置" width="400px" ref="thisDialog" :top="'10%'" :confirmTxt="confirmTxt" @close="onCancle" @confirm="onConfirm">
    <el-form :model="configData" ref="configData" label-width="80px" class="demo-dynamic">
      <el-row>
        <el-col :span="24">
          <el-form-item label-width="80px" label="应用范围">
            <div style="margin-top: 20px; border-bottom: 1px solid #5b5b5b"></div>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label-width="80px" prop="selectBox" label="">
            <div style="display: flex; align-items: center; height: 40px">
              <el-radio v-model="configData.scope" :label="0">整篇文档</el-radio>
              <el-radio v-model="configData.scope" :label="1">节内</el-radio>
            </div>
          </el-form-item>
        </el-col>
        <el-col :span="24">
          <el-form-item label-width="80px" label="样式">
            <div style="margin-top: 20px; border-bottom: 1px solid #5b5b5b"></div>
          </el-form-item>
        </el-col>
        <el-col :span="16">
          <el-form-item label-width="100px" prop="content" label="数字格式">
            <el-select size="mini" style="width: 200px" v-model="configData.style" placeholder="请选择">
              <el-option v-for="item in pageNumStyle" :key="item.value" :label="item.label" :value="item.value" :disabled="item.disabled"></el-option>
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :span="16">
          <el-form-item label-width="100px" prop="content" label="格式化样式">
            <el-select size="mini" style="width: 200px" v-model="configData.format" placeholder="请选择">
              <el-option v-for="item in pageNumFormat" :key="item.value" :label="item.label" :value="item.value" :disabled="item.disabled"></el-option>
            </el-select>
          </el-form-item>
        </el-col>
      </el-row>
    </el-form>
  </VacantDialog>
</template>

<script>
import { E_EVENT_KEY, E_SET_MODE, E_PAGE_NUM_SCOPE, E_PAGE_NUM_STYLE, E_PAGE_NUM_FORMAT } from '@/components/editor/ThinkEditor.Defined';
import { UpdateConfigData } from '@/components/editor/ThinkEditor.Utils';
import { editorsInstanceManager } from '@/components/editorHelper/ThinkEditorInstance.Manager';

export default {
  name: 'index',
  components: {},
  data() {
    return {
      thinkEditor: undefined,
      active: 1,
      flag: false,
      confirmTxt: '插入',
      configData: {
        scope: E_PAGE_NUM_SCOPE.WholeDocnment,
        style: E_PAGE_NUM_STYLE.OrderListModel_0,
        format: E_PAGE_NUM_FORMAT.PageOrderPagesCountFormat
      },
      pageNumStyle: [
        { value: 0, label: '1 2 3' },
        { value: 1, label: 'a b c' },
        { value: 2, label: 'A B C' },
        { value: 3, label: 'i ii iii' },
        { value: 4, label: 'I II III' },
        { value: 5, label: '一 二 三' },
        { value: 6, label: '壹 贰 叁' },
        { value: 7, label: '甲 已 丙' },
        { value: 8, label: '子 丑 寅' },
        { value: 9, label: '① ② ③' }
      ],
      pageNumFormat: [
        { value: 0, label: '[当前页码]' },
        { value: 1, label: '[总页数]' },
        { value: 2, label: '第%页' },
        { value: 3, label: '共%页' },
        { value: 4, label: '第%页/共%页' }
      ]
    };
  },
  created() {
    editorsInstanceManager.addEventListener(E_EVENT_KEY.editorFocus, this.OnDocEditorFocusChange);
    let currentEditorsInstance = editorsInstanceManager.GetSelectedEditorsInstance();
    this.thinkEditor = currentEditorsInstance?.GetSelectedEditor();
  },
  methods: {
    /**
     * 切换表头
     * @param item
     */
    changeActive(item) {
      this.active = item.value;
    },
    openDialog(configData) {
      UpdateConfigData(this, configData);
      if (this.configData.setMode == E_SET_MODE.ModifyCommon) {
        this.confirmTxt = '确定';
      } else {
        this.confirmTxt = '插入';
      }

      this.$refs.thisDialog.openDialog();
    },

    closeDialog() {
      this.$refs.thisDialog.closeDialog();
      this.thinkEditor.SetInputFocus(true);
    },
    OnDocEditorFocusChange(e) {
      this.thinkEditor = e.data.editor;
    },
    onCancle() {
      this.closeDialog();
    },
    onConfirm() {
      if (this.configData.setMode == E_SET_MODE.ModifyCommon) {
        this.thinkEditor.SetPageNumConfig('', this.configData);
      } else {
        this.thinkEditor.InsertPageNum(this.configData);
      }
      this.closeDialog();
    }
  }
};
</script>

<style scoped lang="scss">
::v-deep .el-input--medium .el-input__inner {
  height: 60px;
}

::v-deep .el-select-dropdown__item {
  height: 60px !important;
  line-height: 60px;
  border-bottom: 2px solid #ccc;
  margin: 0 10px;
}

::v-deep .el-form-item {
  margin-bottom: -8px !important;
}

.tab-style {
  display: flex;
  align-items: center;
  justify-content: flex-start;

  .tab {
    padding: 6px 10px;
    border: 1px solid #ccc;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    margin-right: 1px;
    background: #e0e0e0;
    cursor: pointer;
  }

  .active {
    background: #61a3e6;
  }
}

::v-deep .el-popper {
  margin-top: 0;
}
</style>
