<template>
  <VacantDialog title="表格设置" width="40%" ref="thisDialog" @confirm="onSubmit">
    <div class="table-box">
      <el-tabs v-model="activeName" type="card" @tab-click="handleClick">
        <el-tab-pane label="表格" name="table">
          <TableCom :grid-data="configData"></TableCom>
        </el-tab-pane>
        <el-tab-pane label="行和列" name="rowcol">
          <RowColCom :row-col-data="configData"></RowColCom>
        </el-tab-pane>
        <el-tab-pane label="单元格" name="cell">
          <CellCom :cell-data="configData"></CellCom>
        </el-tab-pane>
      </el-tabs>
    </div>
  </VacantDialog>
</template>

<script>
import TableCom from './components/table.vue';
import RowColCom from './components/rowCol.vue';
import CellCom from './components/cell.vue';
import { E_EVENT_KEY } from '@/components/editor/ThinkEditor.Defined';
import { UpdateConfigData } from '@/components/editor/ThinkEditor.Utils';
import { editorsInstanceManager } from '@/components/editorHelper/ThinkEditorInstance.Manager';
export default {
  props: {},
  components: { TableCom, RowColCom, CellCom },
  data() {
    return {
      configData: {
        border: {},
        cell: {},
        row: {},
        col: {}
      },
      thinkEditor: undefined,
      activeName: 'table'
    };
  },
  created() {
    editorsInstanceManager.addEventListener(E_EVENT_KEY.editorFocus, this.OnDocEditorFocusChange);
    let currentEditorsInstance = editorsInstanceManager.GetSelectedEditorsInstance();
    this.thinkEditor = currentEditorsInstance?.GetSelectedEditor();
    //this.SelfInit();
  },
  methods: {
    handleClick(tab, event) {
      console.log(tab, event);
    },
    openDialog(configData) {
      UpdateConfigData(this, configData);

      //this.Init();
      this.$refs.thisDialog.openDialog();
    },
    closeDialog() {
      this.$refs.thisDialog.closeDialog();
    },
    OnDocEditorFocusChange(e) {
      this.thinkEditor = e.data.editor;

      //this.SelfInit();
    },
    onSubmit() {
      this.thinkEditor.SetTableConfig('', this.configData);
      this.close();
    },
    close() {
      this.$refs.thisDialog.closeDialog();
      this.thinkEditor.SetInputFocus(true);
    }
  }
};
</script>

<style lang="scss" scoped>
.table-box {
  :deep(.el-form-item--mini.el-form-item, .el-form-item--small.el-form-item) {
    margin-bottom: 8px;
  }
  :deep(.el-tabs__header) {
    margin: 0 0 5px;
  }
  :deep(.el-divider--horizontal) {
    margin: 15px 0;
  }
}
</style>
