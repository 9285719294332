import { E_EVENT_KEY, E_VIEW_MODE, E_ATTACH_LAYER } from '@/components/editor/ThinkEditor.Defined';

export const DemoCaseWaterMark = {
  methods: {
    async DoDemoCaseWaterMark() {
      let editorsTabsView = this.defaultEditorsTabsView;
      let currentEditorsInstance = editorsTabsView.editorsInstance;

      var docName = '水印';
      let thinkEditor = currentEditorsInstance.GetEditor(docName);

      if (thinkEditor === undefined) {
        thinkEditor = await editorsTabsView.CreateDocEditor(docName);

        var url = '/system/emr/演示/00-演示病例/AdmissionRecord.xml'; //"/system/test/pacsdemo.jpg";
        let response = await fetch(url, {
          credentials: 'same-origin'
        });
        let xml = await response.arrayBuffer();

        thinkEditor.CreateDoc(docName); //@20220722 必需，才能设置编辑者信息
        thinkEditor.SelectDoc(docName);

        thinkEditor.ParseDocs(docName, '', xml, {});
        /*************************************************
         * 配置编辑者信息
         * **********************************************/
        thinkEditor.SetEditorInfo('pc', 'appGuid1', 'zl', 1, '');

        thinkEditor.SetViewMode(E_VIEW_MODE.Edit, {});
      }
      //UI 中显示该文档
      editorsTabsView.SelectDocEditor(docName);
    },

    async OnAddTextWaterMarkTest() {
      let currentTabsView = this.defaultEditorsTabsView;
      let currentEditorsInstance = currentTabsView.editorsInstance;
      let currentThinkEditor = currentEditorsInstance.GetSelectedEditor();
      if (currentThinkEditor === undefined) {
        return;
      }
      var attach_id = 'waterMarkText';
      let json_cfg = {};
      json_cfg.fontName = '宋体';
      json_cfg.size = 1.0;
      json_cfg.fragment = '文字水印测试';

      json_cfg.angle = 315;
      json_cfg.color = 'c0c0c0';
      json_cfg.forbidPrint = 0;
      json_cfg.repeat = 1;
      json_cfg.fillMode = 0;

      json_cfg.topPadding = 2.8;
      json_cfg.bottomPadding = 2.8;
      json_cfg.leftPadding = 2.8;
      json_cfg.right_Padding = 2.8;

      currentThinkEditor.SetWaterMarkConfig(attach_id, json_cfg);
    },

    async OnAddImageWaterMarkTest() {
      let currentTabsView = this.defaultEditorsTabsView;
      let currentEditorsInstance = currentTabsView.editorsInstance;
      let currentThinkEditor = currentEditorsInstance.GetSelectedEditor();
      if (currentThinkEditor === undefined) {
        return;
      }
      var url = '/system/test/WaterMark/waterMark.png';
      let response = await fetch(url, { credentials: 'same-origin' });
      let img_data = await response.arrayBuffer();
      var img_name = 'waterMark';
      var img_md5 = currentThinkEditor.SetImageResource(img_name, img_data, img_data.byteLength, {});
      console.log(img_md5);

      let json_cfg = {};
      json_cfg.imageName = img_name;
      json_cfg.md5 = img_md5;
      json_cfg.alpha = 50;
      json_cfg.forbidPrint = 0;
      json_cfg.repeat = 1;
      json_cfg.fillMode = 0;
      json_cfg.layer = E_ATTACH_LAYER.Lower;

      json_cfg.topPadding = 2;
      json_cfg.bottomPadding = 2;
      json_cfg.leftPadding = 1;
      json_cfg.rightPadding = 1;

      var attach_id = 'waterMarkImg';
      currentThinkEditor.SetWaterMarkConfig(attach_id, json_cfg);
    },
    async OnDelTextWaterMarkTest() {
      let currentTabsView = this.defaultEditorsTabsView;
      let currentEditorsInstance = currentTabsView.editorsInstance;
      let currentThinkEditor = currentEditorsInstance.GetSelectedEditor();
      if (currentThinkEditor === undefined) {
        return;
      }

      currentThinkEditor.SetWaterMarkConfig('waterMarkText', { delete: true });
    },
    async OnDelImageWaterMarkTest() {
      let currentTabsView = this.defaultEditorsTabsView;
      let currentEditorsInstance = currentTabsView.editorsInstance;
      let currentThinkEditor = currentEditorsInstance.GetSelectedEditor();
      if (currentThinkEditor === undefined) {
        return;
      }
      currentThinkEditor.SetWaterMarkConfig('waterMarkImg', { delete: true });
    },
    async OnGetWaterMarkReportTest() {
      let currentTabsView = this.defaultEditorsTabsView;
      let currentEditorsInstance = currentTabsView.editorsInstance;
      let currentThinkEditor = currentEditorsInstance.GetSelectedEditor();
      if (currentThinkEditor === undefined) {
        return;
      }

      let report = currentThinkEditor.GetWaterMarkReport();
      this.popDialogTitle = 'WaterMark';
      this.popDialogText = JSON.stringify(report, null, 2);
      this.popDialogVisible = true;
    }
  }
};
