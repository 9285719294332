<template>
  <div>
    <el-form ref="form" label-width="70px" size="mini">
      <el-divider content-position="left">行</el-divider>
      <el-row>
        <el-col :span="12">
          <el-form-item label="行高">
            <el-input-number v-model="rowColData.row.height" controls-position="right" :step="0.05" :min="0.01" :max="100" :precision="2"></el-input-number>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="调整模式">
            <el-select v-model="rowColData.row.adjustMode" placeholder="请选择" size="mini" style="width:70%;">
              <el-option v-for="item in modelArr" :key="item.value" :label="item.label" :value="item.value"></el-option>
            </el-select>
          </el-form-item>
        </el-col>
      </el-row>
      <el-form-item>
        <el-button size="mini">上一行</el-button>
        <el-button size="mini">下一行</el-button>
      </el-form-item>
      <el-row>
        <el-col :span="24">
          <el-checkbox-group v-model="checkedSetBox" :min="1" :max="2">
            <el-checkbox v-for="(item, idx) in checkOptions" :label="item.value" :key="idx">{{ item.label }}</el-checkbox>
          </el-checkbox-group>
        </el-col>
      </el-row>
      <el-divider content-position="left">列</el-divider>
      <el-row>
        <el-col :span="12">
          <el-form-item label="列宽">
            <el-input-number v-model="rowColData.width" controls-position="right" :step="0.05" :min="0.01" :max="100" :precision="2"></el-input-number>
          </el-form-item>
        </el-col>
      </el-row>
      <el-form-item>
        <el-button size="mini">前一行</el-button>
        <el-button size="mini">后一行</el-button>
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
export default {
  props: {
    rowColData: {
      type: Object,
      default: () => ({})
    }
  },
  data() {
    return {
      modelVal: '',
      modelArr: [
        {
          value: 0,
          label: '最小行高'
        },
        {
          value: 1,
          label: '自动行高'
        },
        {
          value: 2,
          label: '固定行高'
        }
      ],
      checkedSetBox: [],
      checkOptions: [
        {
          value: 'forbidSplit',
          label: '禁止跨页拆分行'
        },
        {
          value: 'titleRow',
          label: '作为标题行'
        },
        {
          value: 'enterGenerateNewRow',
          label: '回车产生新行'
        }
      ]
    };
  }
};
</script>

<style></style>
