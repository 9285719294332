import { editorLogger, E_EVENT_KEY, E_VIEW_MODE, E_TABLE_DELETE_MODE } from '@/components/editor/ThinkEditor.Defined';
import { ThinkEditorEvent } from '../components/editor/ThinkEditor.Defined';

export const DemoCaseDragger = {
  methods: {
    async DoDemoCaseDragger() {
      let editorsTabsView = this.defaultEditorsTabsView;
      let currentEditorsInstance = editorsTabsView.editorsInstance;

      var docName = '拖拽演示';
      let thinkEditor = currentEditorsInstance.GetEditor(docName);

      if (thinkEditor === undefined) {
        thinkEditor = await editorsTabsView.CreateDocEditor(docName);

        var url = '/system/emr/演示/00-演示病例/AdmissionRecord.xml';
        let response = await fetch(url, {
          credentials: 'same-origin'
        });
        let xml = await response.arrayBuffer();

        thinkEditor.CreateDoc(docName); //@20220722 必需，才能设置编辑者信息
        thinkEditor.SelectDoc(docName);

        thinkEditor.ParseDocs(docName, '', xml, {});

        thinkEditor.SetViewMode(E_VIEW_MODE.Edit, {});

        //thinkEditor.addEventListener(E_EVENT_KEY.DraggerPressed, this.OnDraggerPressed);

        thinkEditor.addEventListener(E_EVENT_KEY.drop, this.OnDrop);
      }
      //UI 中显示该文档
      editorsTabsView.SelectDocEditor(docName);
    },
    async OnDrop(event /*ThinkEditorEvent*/) {
      let data = event.data.data; //用户自定义的数据
      editorLogger.LogEvent('OnDrop:', data);

      if (data.draggerType == 'InsertElement') {
        this.InsertElementTest(event.data);
      } else if (data.draggerType == 'InputFragment') {
        await this.InputFragmentTest(event.data);
      }
    },
    InsertElementTest(data) {
      let thinkEditor = data.editor;
      var param = {
        object: 'element',
        id: '',
        name: '',
        empty: true,
        text: '',
        content: '',
        beginBorder: '',
        endBorder: '',
        beforeTag: '',
        afterTag: '',
        hint: '请选择',
        tips: '',
        width: 0,
        alignHorizontalMode: 0,
        hideKeyWord: 0,
        forbidDelete: false,
        lockedContent: false,
        lockedFormat: false,
        forbidSpace: false,
        enableBackgroundEffects: true,
        enableFocusedEffects: true,
        widthUnderline: false,
        allowJump: 0,
        fitContent: 0,
        displayBorder: 0,
        splitParagraphMode: 0,
        source: {
          sourceClass: '',
          sourceId: '',
          dynamicLoad: 0,
          writeBack: 0
        },
        inputHandle: {
          inputMode: 2,
          optionClass: '专科检查-胸',
          optionId: '双肺呼吸音',
          items: [
            {
              id: '双肺呼吸音清',
              group: '1',
              weight: 1,
              content: '双肺呼吸音清'
            },
            {
              id: '双肺呼吸音粗',
              group: '1',
              weight: 1,
              content: '双肺呼吸音粗'
            }
          ],
          linkStr: '、',
          optionShowMode: 0
        }
      };

      thinkEditor.InsertElement(param);
    },
    async InputFragmentTest(data) {
      let thinkEditor = data.editor;
      var fragment_file = 'BingChengFragment.xml';
      var url = '/system/fragment/' + fragment_file;
      let response = await fetch(url, {
        credentials: 'same-origin'
      });
      let xmlArray = await response.arrayBuffer();
      const decoder = new TextDecoder('utf-8');
      const xmlStr = decoder.decode(xmlArray);
      thinkEditor.InputFragment(xmlStr, {});
    }
  }
};
